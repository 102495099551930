import { create } from 'zustand'
import { GetMachinesForRecordTableDTO } from '../ApiOld/ApiServerVov'

interface DiaryMachinesCopyState {
    machinesStore?: GetMachinesForRecordTableDTO[] | undefined
    loadMachinesStore: (machs: GetMachinesForRecordTableDTO[] | undefined) => void
}
export const useDiaryMachinesCopyStore = create<DiaryMachinesCopyState>()((set) => ({
    machinesStore: undefined,
    loadMachinesStore: (by) => set((state) => ({ machinesStore: by })),
}))