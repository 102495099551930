import { CustomFieldDataType } from "../../ApiOld/Api/ApiServerVov";

export const customDataFieldsCaptions = [
  { value: CustomFieldDataType.String, label: 'String' },
  { value: CustomFieldDataType.Integer, label: 'Integer' },
  { value: CustomFieldDataType.Decimal, label: 'Decimal' },
  { value: CustomFieldDataType.Boolean, label: 'Boolean' },
  { value: CustomFieldDataType.DateTime, label: 'DateTime' },
  { value: CustomFieldDataType.Enum, label: 'Enum' },
  { value: CustomFieldDataType.Paragraph, label: 'Paragraph' },
]