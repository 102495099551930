
import { AddCircle, Close, FilterAlt, FilterAltOff, Flag, FlagCircle, FlagRounded, LocationOn, PushPin, SaveOutlined, TableRowsOutlined, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import NewImageMapDialog from './ImageMap/NewImageMapDialog';
import ImageMapSelect from './ImageMap/ImageMapSelect';
import { ImageMapDto, MarkerWithPositionDto, TodoDTO, TodoListDTO, TodoMainVovClient, TodoState, UpdateMarkersPositionsRequest } from '../../../ApiOld/Api/ApiServerVov';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../ApiOld/Api/useApi';
import ImageMapTaskDetailDialog from './ImageMap/ImageMapTaskDetailDialog';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { StavarioModule } from '../../Shared/Layouts/StavarioModule';
import NoDataImageHolder from '../../Shared/Layouts/NoDataImageHolder';
// import ReactPannellum, { getConfig } from "react-pannellum";
const PopupCard = ({ todo, position, onClose }: {
    todo: TodoListDTO;
    position: { x: number; y: number };
    onClose: () => void;
}) => (
    <Box
        sx={{
            position: 'absolute',
            left: position.x,
            top: position.y,
            transform: 'translate(-50%, -100%)',
            backgroundColor: 'white',
            padding: 1,
            borderRadius: 1,
            boxShadow: 2,
            zIndex: 1000,
            marginTop: -2,
            height: '60px',
            width: '160px'
        }}
    >
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">{todo.name}</Typography>
            {/* <IconButton size="small" onClick={onClose}>
                <Close fontSize="small" />
            </IconButton> */}
        </Box>
        <Typography variant="body2" color="text.secondary">
            {todo.responsiblePerson}
        </Typography>
    </Box>
);
interface ITasksImageMapProps {
    externalTodo?: TodoDTO;
    onSaved?: (marker?: TodoListDTO) => void;
}
const TasksImageMap = (props: Readonly<ITasksImageMapProps>) => {
    const { t } = useTranslation();
    const [popupInfo, setPopupInfo] = useState<{
        todo: TodoListDTO;
        position: { x: number; y: number };
    } | null>(null);
    const [markers, setMarkers] = useState<TodoListDTO[]>([]);
    const [scale, setScale] = useState(1);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const lastClickTime = useRef(0);
    const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
    const [newMap, setNewMap] = useState(false);
    const [selectedMap, setSelectedMap] = useState<ImageMapDto | null>(null);
    const [selectedTodo, setSelectedTodo] = useState<TodoListDTO | null>(null);
    const [changed, setChanged] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
    const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [colorsByStates, setColorsByStates] = useState<boolean>(props.externalTodo === undefined);
    const navigate = useNavigate();
    const { authorizedApi } = useApi(TodoMainVovClient);
    const { setMenuButtons, setActiveModule, setBackMenuFunction } = useUserLayoutContext();
    const { i18n } = useTranslation();
    const params = useParams();
    const [showOthersInSingleView, setShowOthersInSingleView] = useState(false);

    useEffect(() => {
        setActiveModule(StavarioModule.TodosMap);
    }, [setActiveModule]);
    const currentId = useRef<number>(0);
    const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
        if (props.externalTodo !== undefined) return;
        const image = imageRef.current;
        if (!image) return;

        const rect = image.getBoundingClientRect();
        const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
        const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
        currentId.current--;
        setMarkers([...markers, { mapLeft: xPercent, mapTop: yPercent, id: currentId.current, temporaryMarker: true } as TodoListDTO]);
    };
    const calculateAdjustedPosition = (marker: any, imageRef: React.RefObject<HTMLImageElement>) => {
        const rect = imageRef.current!.getBoundingClientRect();

        // Initial position calculation
        let x = (marker.mapLeft! * rect.width) / 100 + rect.left;
        let y = ((marker.mapTop! - 8) * rect.height) / 100 + rect.top;

        // Assuming popup/tooltip dimensions (adjust these values as needed)
        const POPUP_WIDTH = 160;  // width of your popup/tooltip
        const POPUP_HEIGHT = 60; // height of your popup/tooltip

        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Adjust x position if too close to right edge
        if (x + POPUP_WIDTH > viewportWidth) {
            x = viewportWidth - POPUP_WIDTH - 10; // 10px padding from edge
        }

        // Adjust x position if too close to left edge
        console.log('tadyy', x, POPUP_WIDTH, x - POPUP_WIDTH)
        if (x - POPUP_WIDTH < 0) {
            x = 80; // 10px padding from edge
        }

        // Adjust y position if too close to bottom edge
        if (y + POPUP_HEIGHT > (viewportHeight - 160)) {
            y = viewportHeight - POPUP_HEIGHT - 260;
        }

        // Adjust y position if too close to top edge
        if (y < 0) {
            y = 10;
        }

        return { x, y };
    };
    {/*  */ }
    const updateMarkerPosition = (index: number, deltaX: number, deltaY: number) => {
        setMarkers((prevMarkers) =>
            prevMarkers.map((marker, i) => {
                if (i !== index) return marker;

                // Adjust deltaX and deltaY by scale and convert to percentage
                const deltaXPercent = (deltaX / imageRef.current!.offsetWidth) * 100 / scale;
                const deltaYPercent = (deltaY / imageRef.current!.offsetHeight) * 100 / scale;
                console.log({
                    mapLeft: (marker.mapLeft ?? 0) + deltaXPercent,
                    mapTop: (marker.mapTop ?? 0) + deltaYPercent,
                } as TodoListDTO);
                setChanged(true);
                return {
                    ...marker,
                    mapLeft: (marker.mapLeft ?? 0) + deltaXPercent,
                    mapTop: (marker.mapTop ?? 0) + deltaYPercent,
                } as TodoListDTO;
            })
        );
    };
    const refreshTodos = useCallback(async (allTasksRefresh: boolean) => {
        if (selectedMap?.id) {
            if (props.externalTodo === undefined) {
                setLoaded(false);
                const tasks = await authorizedApi.getTasksForMap(selectedMap?.id);
                setMarkers(tasks.todos ?? []);
                setLoaded(true);
            }
            else {
                if (allTasksRefresh) {
                    setLoaded(false);
                    const tasks = await authorizedApi.getTasksForMap(selectedMap?.id);
                    setMarkers((prevMarkers) => {
                        if (prevMarkers.length > 0)
                            return [...prevMarkers, ...tasks.todos?.filter(x => !prevMarkers.find(t => t.id === x.id)) ?? []];
                        else return [...tasks.todos ?? []];
                        // return [...prevMarkers, ...tasks.todos ?? []];
                    });

                    setLoaded(true);
                }
                else
                    setMarkers([new TodoListDTO({
                        id: props.externalTodo.id,
                        name: props.externalTodo.name,
                        mapLeft: props.externalTodo.mapLeft ?? 20,
                        mapTop: props.externalTodo.mapTop ?? 20,
                        responsiblePersonId: props.externalTodo.responsiblePersonId,
                        state: props.externalTodo.state,
                        mapTagColor: '#FF0000',
                        assignees: props.externalTodo.assignees?.map(x => x.name).join(',') ?? '',
                        buildingTodoMapId: props.externalTodo.buildingTodoMapId,
                        temporaryMarker: true


                    })]);

                setLoaded(true);
            }

        }
    }, [authorizedApi, selectedMap, props.externalTodo]);
    useEffect(() => {
        refreshTodos(showOthersInSingleView);
    }, [showOthersInSingleView, refreshTodos]);
    useEffect(() => {
        refreshTodos(false);
    }, [selectedMap]);
    const save = useCallback(async () => {
        if (markers.every(x => x.id && x.id > 0)) {
            const ok = await authorizedApi.changeMarkersPositions(new UpdateMarkersPositionsRequest({
                markers: markers.map((marker) => (

                    new MarkerWithPositionDto({
                        id: marker.id, mapLeft: marker.mapLeft, mapTop: marker.mapTop, buildingTodoMapId: selectedMap?.id,
                        mapTagColor: marker.mapTagColor
                    })
                ))
            }));
            if (ok) {
                props.onSaved?.();
                setChanged(false);
            }
        }
        else {
            props.onSaved?.(markers.find(x => x.temporaryMarker === true));
        }
    }, [authorizedApi, markers, props, selectedMap]);
    useEffect(() => {
        props.externalTodo === undefined && setMenuButtons(<Box display='flex' width='calc(100vw - 80px)' justifyContent={'space-between'} alignItems='center'>
            <NewImageMapDialog open={newMap} onClose={() => { setNewMap(false) }} />
            <Box display='flex' alignItems='center'>
                <Button onClick={() => setScale(scale + 0.1)}>
                    <ZoomIn color='action' />
                </Button>
                <Button onClick={() => setScale(scale - 0.1)}>
                    <ZoomOut color='action' />
                </Button>
                <Button variant='contained' size='small' startIcon={<AddCircle />} onClick={() => setNewMap(true)}>{t('NewF')}</Button>
                <Button sx={{ ml: 1 }} variant='contained' size='small' onClick={() => setColorsByStates(p => !p)}>{colorsByStates ? <Flag /> : <FlagCircle />}</Button>
                <Box display='flex' ml={5} alignItems='center'>
                    <Button size='small' sx={{ mr: 1 }} disabled={!changed} variant={!changed ? 'text' : 'contained'} onClick={save}><SaveOutlined /></Button>
                    <ImageMapSelect onChange={setSelectedMap} selectedMap={selectedMap} />
                </Box>
            </Box>

            <Button
                size='small'
                variant='contained'
                sx={{ mr: '20px' }}
                onClick={() => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos')}>
                <TableRowsOutlined />
            </Button>
        </Box>);

    }, [setMenuButtons, newMap, scale, changed, save, selectedMap, navigate, i18n, params, colorsByStates, props, t]);
    const getMarkerColor = (marker: TodoListDTO) => {
        if (colorsByStates) {
            switch (marker.state) {
                case TodoState.NEW:
                    return '#FFC600';
                case TodoState.CLOSED:
                case TodoState.RESOLVED:
                    return '#19CB40';
                case TodoState.ACTIVE:
                    return '#107AB0';
                default:
                    return '#000000';
            }
        }
        return marker.mapTagColor;
    };
    const config = {
        autoLoad: true,
    };
    return (
        // <div >
        //     <div >
        //         <ReactPannellum
        //             id="1"
        //             style={{ height: '60vh', width: '80vw' }}
        //             sceneId="firstScene"
        //             imageSource="https://media.licdn.com/dms/image/v2/D4E22AQFMYPqauDdQrw/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1732813067080?e=1735776000&v=beta&t=N2U2nLt0mPVRBksoX-BI5JHFpFnDzUBYI-mEXG-Wz6M"
        //             config={config}

        //         />
        //         {/* <div onClick={this.click}>Click me</div> */}
        //     </div>
        // </div>
        <div
            style={{
                position: 'relative',
                userSelect: 'none',
                width: '100%',
                height: '100%',
                // overflow: 'hidden',
            }}
        >
            {props.externalTodo !== undefined && <Box display='flex' width='calc(100vw - 80px)' justifyContent={'space-between'} alignItems='center'>
                <NewImageMapDialog open={newMap} onClose={() => { setNewMap(false) }} />
                <Box display='flex' alignItems='center'>
                    <Button onClick={() => setScale(scale + 0.1)}>
                        <ZoomIn color='action' />
                    </Button>
                    <Button onClick={() => setScale(scale - 0.1)}>
                        <ZoomOut color='action' />
                    </Button>
                    {props.externalTodo !== undefined && <Button variant='contained' size='small' startIcon={showOthersInSingleView ? <FilterAlt /> : <FilterAltOff />} onClick={() =>
                        setShowOthersInSingleView(p => !p)
                    }>{showOthersInSingleView ? t('One') : t('All')}</Button>}
                    {props.externalTodo === undefined && <Button variant='contained' size='small' startIcon={<AddCircle />} onClick={() => setNewMap(true)}>{t('NewF')}</Button>}
                    {props.externalTodo === undefined && <Button sx={{ ml: 1 }} variant='contained' size='small' onClick={() => setColorsByStates(p => !p)}>{colorsByStates ? <Flag /> : <FlagCircle />}</Button>}
                </Box>
                <Box display='flex' alignItems='center'>
                    <Button size='small' sx={{ mr: 1 }} disabled={!changed} variant={!changed ? 'text' : 'contained'} onClick={save}><SaveOutlined /></Button>

                    <ImageMapSelect initMapId={props.externalTodo?.buildingTodoMapId} buildingId={props.externalTodo?.buildingId} onChange={setSelectedMap} selectedMap={selectedMap} />
                </Box>
                {props.externalTodo === undefined && <Button
                    size='small'
                    variant='contained'
                    sx={{ mr: '20px' }}
                    onClick={() => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos')}>
                    <TableRowsOutlined />
                </Button>}
            </Box>}
            {selectedTodo && <ImageMapTaskDetailDialog
                open={selectedTodo !== null}
                mapId={selectedMap?.id ?? 0}
                onClose={(newId) => {
                    // if (newId && newId > 0)
                    setMarkers((prevMarkers) => prevMarkers.map((marker) => marker.id === selectedTodo?.id ? { ...marker, temporaryMarker: false, id: newId } as TodoListDTO : marker));

                    setSelectedTodo(null);
                }}
                task={selectedTodo}
                setTask={(task) => {
                    setMarkers((prevMarkers) => prevMarkers.map((marker, i) => i === markers.indexOf(selectedTodo) ? task : marker));
                    setSelectedTodo(task);
                }}
            />}

            <div
                onContextMenu={(e) => {
                    e.preventDefault(); // Prevent default context menu
                }}
                style={{
                    overflow: 'auto', // Make this div scrollable
                    width: '100%',
                    height: 'calc(100% - 50px)', // Adjust height to leave space for buttons
                    // paddingLeft: '20px',
                    // paddingTop: '20px',
                }}
            >
                {selectedMap === null && <Box width='100%' display='flex' justifyContent='center'><NoDataImageHolder title={t('SelectDrawingDocumentation')} /></Box>}
                {selectedMap && <div
                    style={{
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left',
                        position: 'relative',
                        width: 'fit-content',
                    }}
                >{popupInfo && (
                    <PopupCard
                        todo={popupInfo.todo}
                        position={popupInfo.position}
                        onClose={() => setPopupInfo(null)}
                    />
                )}
                    {loaded && markers.map((marker, index) => (
                        <Draggable

                            onMouseDown={(e) => {
                                if (e.button === 2) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    // const marker = markers[index];
                                    // const rect = imageRef.current!.getBoundingClientRect();
                                    // const x = (marker.mapLeft! * rect.width) / 100 + rect.left;
                                    // const y = ((marker.mapTop! - 12) * rect.height) / 100 + rect.top;
                                    // setPopupInfo({ todo: marker, position: { x, y } });
                                    // return;
                                    setMarkers((prevMarkers) => prevMarkers.filter((_, i) => i !== index));
                                    return;
                                }

                                const currentTime = Date.now();
                                const timeDifference = currentTime - lastClickTime.current;

                                if (timeDifference < 300) {
                                    // Double-click detected
                                    if (clickTimeout) {
                                        clearTimeout(clickTimeout);
                                        setClickTimeout(null);
                                    }
                                    // if (markers.find((_, i) => i === index)?.id && (markers.find((_, i) => i === index)?.id ?? 0) > 0)
                                    //     navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos/' + markers.find((_, i) => i === index)?.id);
                                    // else {
                                    setSelectedTodo(markers.find((_, i) => i === index) ?? null)
                                    // }
                                    // Handle double-click action here
                                } else {
                                    // Delay single click handling
                                    const timeout = setTimeout(() => {

                                        // Handle single-click action here
                                    }, 300);
                                    setClickTimeout(timeout);
                                }

                                lastClickTime.current = currentTime;
                            }}
                            key={index}
                            position={{
                                x: ((marker.mapLeft ?? 0) / 100) * imageRef.current!.clientWidth,
                                y: ((marker.mapTop ?? 0) / 100) * imageRef.current!.clientWidth,
                            }}
                            onDrag={(e, data) => {
                                console.log(data);
                                const deltaX = data.deltaX;
                                const deltaY = data.deltaY;
                                // data.x
                                updateMarkerPosition(index, deltaX, deltaY);
                            }}
                        >
                            <LocationOn
                                onMouseEnter={(e) => {
                                    // Clear any existing timeouts
                                    if (hoverTimeout) {
                                        clearTimeout(hoverTimeout);
                                    }
                                    if (hideTimeout) {
                                        clearTimeout(hideTimeout);
                                    }

                                    // Set show timeout
                                    const showTimeout = setTimeout(() => {
                                        const { x, y } = calculateAdjustedPosition(marker, imageRef);
                                        setPopupInfo({ todo: marker, position: { x, y } });

                                        // Set hide timeout after showing
                                        const hideTimer = setTimeout(() => {
                                            setPopupInfo(null);
                                        }, 1800);
                                        setHideTimeout(hideTimer);
                                    }, 300);
                                    setHoverTimeout(showTimeout);
                                }}
                                onMouseLeave={() => {
                                    // Clear both timeouts
                                    if (hoverTimeout) {
                                        clearTimeout(hoverTimeout);
                                        setHoverTimeout(null);
                                    }
                                    if (hideTimeout) {
                                        clearTimeout(hideTimeout);
                                        setHideTimeout(null);
                                    }
                                    setPopupInfo(null);
                                }}
                                onMouseDown={(e) => {
                                    setPopupInfo(null);
                                }}
                                htmlColor={getMarkerColor(marker)}
                                style={{
                                    minWidth: scale >= 1 ? '32px' : '40px',
                                    minHeight: scale >= 1 ? '32px' : '40px',
                                    position: 'absolute',
                                    left: `${marker.mapLeft}%`,
                                    top: `${marker.mapTop}%`,
                                    transform: 'translate(-50%, -50%)',
                                    cursor: 'grab',
                                    animation: (!marker.temporaryMarker) ? undefined : 'pulse 1s infinite', // Add animation
                                }}
                            />
                        </Draggable>
                    ))}
                    <img
                        src={selectedMap.sasUri}
                        alt="Map"
                        ref={imageRef}
                        style={{ width: '100%', zIndex: 1, cursor: 'pointer', height: '70vh' }}
                        onClick={handleImageClick}
                    />
                </div>}
            </div>
        </div>
    );
};
export default TasksImageMap;
