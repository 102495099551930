import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, Grid, Slide, Snackbar, TextField, Typography } from '@mui/material';
import * as React from 'react';
import LocalizationProviderWithLanguages from '../../Shared/Localization/LocalizationProviderWithLanguages';
import { StaticDateRangePicker } from '@mui/lab';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import BuildingDiarySelect from '../../Shared/BuildingDiarySelect';
import { useApi } from '../../../ApiOld/Api/useApi';
import { GetGptDiaryDescriptionsFromPhotosRequest, GptVovClient } from '../../../ApiOld/Api/ApiServerVov';

export interface IAutoCompleteDiaryDialogProps {
    open: boolean;
    onClosed: () => void;
    buildingId: number;
}

export default function AutoCompleteDiaryDialog(props: IAutoCompleteDiaryDialogProps) {
    const { open, onClosed } = props;
    const [startDate, setStartDate] = React.useState<Date | null>(new Date());
    const [endDate, setEndDate] = React.useState<Date | null>(new Date());
    const [selectedDiary, setSelectedDiary] = React.useState<number | null>(null);
    const { authorizedApi } = useApi(GptVovClient);
    const fillDiary = React.useCallback(async () => {
        if (selectedDiary && startDate && endDate) {
            authorizedApi.writeDiaryInRange(new GetGptDiaryDescriptionsFromPhotosRequest({
                buildingDiaryId: selectedDiary,
                start: startDate,
                end: endDate,
                buildingId: props.buildingId

            }));
            setOpenApiInfo(true);
            // onClosed();
        }
    }, [authorizedApi, selectedDiary, startDate, endDate, props.buildingId]);
    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const [openApiInfo, setOpenApiInfo] = React.useState(false);
    return (
        <Dialog open={open} onClose={() => { onClosed() }} sx={{ marginTop: '80px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "255", backgroundColor: '#F5F5F5' }}>
                <Typography fontWeight='bold' mt={-0.5}>AI psaní stavebního deníku</Typography>
            </DialogTitle>
            <DialogContent>
                <Snackbar
                    TransitionComponent={TransitionLeft}
                    open={openApiInfo}
                    autoHideDuration={5000}
                    onClose={() => { setOpenApiInfo(false); onClosed(); }}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                    <Alert onClose={() => { setOpenApiInfo(false); onClosed(); }} severity="warning">
                        <AlertTitle>Zahájeno AI psaní deníku</AlertTitle>
                        V závislosti na počtu dní může tato akce trvat delší dobu, mrknětě na deníky později.
                    </Alert>
                </Snackbar>
                <Grid mt={2} container>

                    <LocalizationProviderWithLanguages>
                        <Grid item xs={6} sx={{ padding: '10px' }}>
                            <DatePicker
                                value={startDate ? dayjs(startDate) : null}
                                onChange={(newValue) => {
                                    if (newValue)
                                        setStartDate(newValue.toDate());
                                    else setStartDate(null);
                                }}
                                renderInput={(params) => <TextField size='small' {...params} />}
                            // sx={{
                            //     [`.${pickersLayoutClasses.contentWrapper}`]: {
                            //         alignItems: 'center',
                            //     },
                            // }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ padding: '10px' }}>
                            <DatePicker
                                minDate={dayjs(startDate)}
                                value={endDate ? dayjs(endDate) : null}
                                onChange={(newValue) => {
                                    if (newValue)
                                        setEndDate(newValue.toDate());
                                    else setEndDate(null);
                                }}
                                renderInput={(params) => <TextField size='small' {...params} />}
                            // sx={{
                            //     [`.${pickersLayoutClasses.contentWrapper}`]: {
                            //         alignItems: 'center',
                            //     },
                            // }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ padding: '10px', paddingRight: '44px' }}>

                            <BuildingDiarySelect
                                buildingId={props.buildingId}
                                onChange={(e) => { if (e) setSelectedDiary(e.id!); else setSelectedDiary(null); }}
                                isMandatory={true}
                                selectedDiaryId={selectedDiary}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ padding: '10px' }}>

                            <Button

                                sx={{ mx: 10, width: '70%', mt: 2 }}
                                variant='contained'
                                disabled={selectedDiary === null || startDate === null || endDate === null}
                                onClick={() => fillDiary()}>Napsat deník</Button>
                        </Grid>
                    </LocalizationProviderWithLanguages>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
