import { ArrowBack, ArrowLeft } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, IconButton, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BookOnlineOutlined, ConnectWithoutContact, ConstructionOutlined, Dashboard, FolderOpenOutlined, FolderOutlined, Group, HelpOutline, Home, MailOutline, Outbound, PrecisionManufacturingOutlined, QueryStatsOutlined, Report, Settings, SettingsOutlined, Task } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { StavarioModule } from './StavarioModule';
import { useNavigate } from 'react-router';
import UserAvatar from './UserAvatar';
import LayoutUserMenu from './LayoutUserMenu';
import UserLayoutMobileMenu from './UserLayoutMobileMenu';
import { AuthVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../../ApiOld/Api/useApi';

interface UserLayoutUpperMenuProps {
  drawerOpen: boolean;
  onOpenDrawer: () => void;
  onChangeView: () => void;
  showMenuExpandButton: boolean;
}

export default function UserLayoutUpperMenu(props: Readonly<UserLayoutUpperMenuProps>) {
  const { drawerOpen, onOpenDrawer, onChangeView, showMenuExpandButton } = props;
  const biggerThan1100 = useMediaQuery('(min-width:1100px)');
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const { authorizedApi } = useApi(AuthVovClient);
  const [logo, setLogo] = React.useState<string | null>(null);
  const getAppLogo = useCallback(() => {
    authorizedApi.getAppLogoUrl().then((i) => setLogo(i.url!));
  }, [authorizedApi]);
  React.useEffect(() => {
    getAppLogo();
  }, [getAppLogo]);


  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvatar(event.currentTarget);
  };
  const [anchorElAvatar, setAnchorElAvatar] = React.useState<null | HTMLElement>(null);
  const handleCloseAvatarMenu = () => {
    setAnchorElAvatar(null);
  };
  return (
    <Box sx={{ flexGrow: 1, width: '100%', }}>
      <AppBar color='transparent' position="static">
        <Toolbar sx={{ minHeight: '64px', maxHeight: '64px' }}>
          <Box alignItems='center' display='flex' justifyContent='space-between' width='100%' >
            <Box display='flex' alignItems='center'>
              {showMenuExpandButton && <IconButton
                onClick={onOpenDrawer}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >
                {!drawerOpen && <MenuIcon />}
                {drawerOpen && <ArrowBack />}
              </IconButton>}
              {!biggerThan1100 && <IconButton
                onClick={() => { setShowMobileMenu(p => !p) }}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >
                {!showMobileMenu && <MenuIcon />}
                {showMobileMenu && <Close />}
              </IconButton>}
              <UserLayoutMobileMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu}></UserLayoutMobileMenu>
              {logo &&
                <Box onClick={() => { onChangeView(); }}
                >
                  <img
                    // onClick={() => { onChangeView(); }}
                    width="160px"
                    height="24px"
                    src={logo}
                    alt="stavario"
                  ></img>
                </Box>}
            </Box>
            {biggerThan1100 && <Box display='flex' alignItems='center'>
              <Typography fontSize={12} fontWeight='bold'>Poraď se s ostatními</Typography>
              <Typography fontSize={12} ml={0.5}> a buď aktivní v komunitě Stavario</Typography>
              <Box onClick={() => { window.location.href = 'https://stavario.zendesk.com/hc/cs/community/topics'; }}>
                <Typography fontSize={12} ml={0.5} sx={{ textDecoration: 'underline', cursor: 'pointer' }} fontWeight='bold'> více</Typography>
              </Box>
              <Avatar sx={{ boxShadow: 2, ml: 1 }} src="https://cc.cz/wp-content/uploads/fly-images/132861/josefbenes-820x436-c.jpg"></Avatar>
            </Box>}
            {!biggerThan1100 && <Box display='flex' alignItems='center'>
              <UserAvatar isUppside handleAvatarClick={handleAvatarClick} />
              <LayoutUserMenu isUppside
                anchorEl={anchorElAvatar} handleClose={handleCloseAvatarMenu} onChangeView={onChangeView} />
            </Box>}
          </Box>

          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </Box>)
}
