import { Cloud, Settings } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { GetHintForUserResponse, GetJokeForUserResponse, UserVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../../ApiOld/Api/useApi';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { VovClient } from '../../../ApiOld/ApiServerVov';

export interface IAdLayoutProps {
    mt: number;
    isSmall: boolean;
    isSubdodaval: boolean;
}

export default function AdLayout(props: IAdLayoutProps) {
    const { authorizedApi } = useApi(UserVovClient);
    const { authorizedSubdodavatelApi } = useApi(VovClient);
    const { i18n } = useTranslation();
    const [joke, setJoke] = React.useState<GetJokeForUserResponse | null>(null);
    const [hint, setHint] = React.useState<GetHintForUserResponse | null>(null);
    const { t } = useTranslation();
    const refreshJoke = async () => {
        const lastJokeId = localStorage.getItem('lastJokeId');

        const response = props.isSubdodaval ? await authorizedSubdodavatelApi.getJokeForSubcontractor(lastJokeId ? Number(lastJokeId) : 0, i18n.language) : await authorizedApi.getJokeForUser(lastJokeId ? Number(lastJokeId) : 0, i18n.language);
        localStorage.setItem('lastJokeId', response.id!.toString());
        setJoke(response);
    };
    const refreshHint = async () => {
        const lastHintId = localStorage.getItem('lastHintId');

        const response = props.isSubdodaval ? await authorizedSubdodavatelApi.getHintForSubcontractor(lastHintId ? Number(lastHintId) : 0, i18n.language) : await authorizedApi.getHintForUser(lastHintId ? Number(lastHintId) : 0, i18n.language);
        localStorage.setItem('lastHintId', response.id!.toString());
        setHint(response);
    };
    const startRefreshCycle = () => {
        refreshJoke(); // Initial call to refresh the joke immediately
        setInterval(refreshJoke, 60000); // Refresh every 60 seconds
        refreshHint(); // Initial call to refresh the hint immediately
        setInterval(refreshHint, 60000); // Refresh every 60 seconds
    };
    React.useEffect(() => {
        startRefreshCycle();
    }, [i18n.language]);
    const navigate = useNavigate();
    const params = useParams();
    return (
        <Stack px={3} spacing={2} mt={props.mt} alignItems={'stretch'} width={'100%'}>
            <Card elevation={3} sx={{ width: '100%', p: 2, height: '220px' }}>
                <Stack spacing={2}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography fontWeight={'bold'}>{joke?.heading}</Typography>
                        {joke?.isSuperAdmin && <IconButton onClick={() => {
                            navigate('/' + params['lang'] + '/' + params['domain'] + '/userlayout/jokes')
                        }}><Settings /></IconButton>}
                    </Box>
                    {/* <Stack alignItems={'center'} direction={'row'}>
                        <Typography fontWeight={'bold'} variant='h4'>28°C</Typography>
                        <Cloud sx={{ fontSize: '32px', ml: 4 }} />
                    </Stack> */}
                    <Typography fontSize={'12px'}>{joke?.content}</Typography>
                </Stack>
            </Card>
            <Card elevation={3} sx={{ width: '100%', p: 2 }}>
                <Stack spacing={2}>
                    <Typography fontWeight={'bold'}>{t('You have few projects')}</Typography>

                    <Typography fontSize={'12px'}>{t("Someone is probably stealing them from you. Get our Stavario guard tower, and you'll have everything on your construction sites under control.")}</Typography>
                    <Button variant={'contained'} onClick={() => window.open('https://webforms.pipedrive.com/f/72mqihpIqYJxSY7SJBcIkkwamZIdJDweIbOGC492g6aXKzFIehdQ8R2BKgjoXw0qor', '_blank')}>{t('RequestEyetower')}</Button>
                </Stack>
            </Card>
            <Card elevation={3} sx={{ width: '100%', p: 2, height: '220px' }}>
                <Stack>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography fontWeight={'bold'}>{hint?.heading}</Typography>
                        {hint?.isSuperAdmin && <IconButton onClick={() => {
                            navigate('/' + params['lang'] + '/' + params['domain'] + '/userlayout/hints')
                        }}><Settings /></IconButton>}
                    </Box>
                    {/* <Stack alignItems={'center'} direction={'row'}>
                        <Typography fontWeight={'bold'} variant='h4'>28°C</Typography>
                        <Cloud sx={{ fontSize: '32px', ml: 4 }} />
                    </Stack> */}
                    <Typography fontSize={'12px'}>{hint?.content}</Typography>
                </Stack>
            </Card>
        </Stack>
    );
}
