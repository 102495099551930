import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack, Box, Grid, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import UserLayoutUpperMenu from './UserLayoutUpperMenu';
import AdLayout from './AdLayout';
import { Outlet } from 'react-router-dom';
import { StavarioModule } from './StavarioModule';
import { ArrowBack, BackspaceOutlined } from '@mui/icons-material';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { getModuleName } from './getModuleName';
import { DiaryTabsProvider } from '../../../Contexts/Providers/DiaryTabsProvider';
import UserLayoutModuleSideMenu from './UserLayoutModuleSideMenu';
import UserLayoutModuleTopMenu from './UserLayoutModuleTopMenu';
import ProductFruitsAdminUser from '../../../Components/product-fruits/ProductFruitsAdminUser';
import { StavarioGlobalContext, StavarioUpdateContext } from '../../../Contexts/LoginMainContext';
import { useApi } from '../../../ApiOld/Api/useApi';
import { AuthVovClient, SubcontractorVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { VovClient } from '../../../ApiOld/ApiServerVov';

const UserLayout: React.FC = () => {
  const { activeModule, isActiveModuleHasAd, menuOpen, setActiveModule, setMenuOpen, backMenuFunction, menuButtons, initials, setInitials, title, setTitle, hideTopBar, isSubdodavatel, setModulesVisibility } = useUserLayoutContext();
  const theme = useTheme();
  const biggerThan1100 = useMediaQuery('(min-width:1100px)');

  const [leftMenuVisible, setLeftMenuVisible] = React.useState(false);
  const { t } = useTranslation();


  const getWidthMainPanel = () => {
    if (leftMenuVisible && biggerThan1100)
      return menuOpen ? 'calc(100vw - 240px)' : 'calc(100vw - 56px)';
    else return '100vw';
  }
  const getHeightMainPanel = () => {
    if (hideTopBar) return 'calc(100vh)';

    if (leftMenuVisible)
      return 'calc(100vh - 112px)';
    else return 'calc(100vh - 176px)';
  }
  const getMarginTop = () => {
    if (hideTopBar) return '0px';
    else if (!biggerThan1100) return '64px';
    else {

      return leftMenuVisible ? '64px' : '100px'
    }
  }

  const { authorizedApi } = useApi(AuthVovClient);
  const { authorizedSubdodavatelApi } = useApi(VovClient);
  useEffect(() => {
    if (isSubdodavatel)
      authorizedSubdodavatelApi.getMeDetail().then((i) => setInitials(i.initials ?? ''));
    else
      authorizedApi.me().then((i) => {
        setInitials(i.initials ?? '');
        setModulesVisibility(i.modulesVisibility ?? null);
      });

  }, [authorizedApi, setInitials, setModulesVisibility, isSubdodavatel, authorizedSubdodavatelApi]);
  useEffect(() => {
    console.log('layout-view', localStorage.getItem('layout-view'));
    localStorage.getItem('layout-view') === 'true' ? setLeftMenuVisible(true) : setLeftMenuVisible(false);
  }, []);
  const onChangeView = () => {
    localStorage.setItem('layout-view', (!leftMenuVisible).toString());
    setLeftMenuVisible(p => !p);
  }

  const biggerThan700 = useMediaQuery('(min-width:700px)');
  const getModuleNameCallback = useCallback(() => {
    console.log('test', activeModule, title);
    return activeModule === StavarioModule.ProjectDetail && title ? title : t(getModuleName(activeModule, biggerThan700))
  }, [activeModule, biggerThan700, t, title]);
  return (
    <Stack sx={{ height: '100vh', overflow: 'hidden', marginTop: isSubdodavatel ? '-50px' : '' }}>
      {/* <Stack sx={{ height: '100vh', overflow: activeModule === StavarioModule.TodosMap ? 'hidden' : 'auto', marginTop: isSubdodavatel ? '-50px' : '' }}> */}
      {!isSubdodavatel && <Box sx={{ position: 'fixed', top: 0, width: '100%' }}>
        {!hideTopBar && <UserLayoutUpperMenu showMenuExpandButton={leftMenuVisible && biggerThan1100} onChangeView={onChangeView}
          drawerOpen={menuOpen} onOpenDrawer={() => setMenuOpen(p => !p)} />}
        {(!leftMenuVisible && biggerThan1100) &&
          <UserLayoutModuleTopMenu
            selectedModule={activeModule}
            onModuleChange={(m) => { setActiveModule(m); console.log('modul před providerem', m); }}
            onChangeView={onChangeView}
          />}

      </Box>}
      <Stack direction={'row'} sx={{ flex: 1, marginTop: getMarginTop() }}>
        {leftMenuVisible && biggerThan1100 && !isSubdodavatel && <UserLayoutModuleSideMenu
          onChangeView={onChangeView}
          selectedModule={activeModule}
          onModuleChange={(m) => { setActiveModule(m); console.log('modul před providerem', m); }}
          isDrawerOpen={menuOpen}
          setDrawerOpen={setMenuOpen} />}
        <Stack >
          <ProductFruitsAdminUser />
          <Box display='flex' alignItems='center' sx={{ bgcolor: '#C3C3C3', height: '48px' }} justifyContent='space-between' width='100%'>
            <Stack alignItems='center' direction='row' ml={2}>
              {backMenuFunction !== undefined && <IconButton onClick={backMenuFunction}><ArrowBack /></IconButton>}
              <Typography fontWeight='bold'>{getModuleNameCallback()}</Typography>

            </Stack>
            <Box>
              {menuButtons}
            </Box>
          </Box>
          <Grid container mb={4} sx={{ flex: 1, overflow: activeModule === StavarioModule.TodosMap ? 'hidden' : 'auto', maxHeight: getHeightMainPanel(), minHeight: getHeightMainPanel(), maxWidth: getWidthMainPanel(), minWidth: getWidthMainPanel() }}>
            <Grid item lg={isActiveModuleHasAd ? 10.5 : 12} md={12} sm={12}>
              {/* <DiaryTabsProvider> */}
              <Outlet />
              {/* </DiaryTabsProvider> */}
            </Grid>
            {isActiveModuleHasAd && <Grid item lg={1.5} md={12} sm={12} sx={{ pl: { lg: 0, md: 2, sm: 2 } }}>
              <AdLayout isSubdodaval={isSubdodavatel} isSmall={!biggerThan1100} mt={!biggerThan1100 ? 1 : (activeModule === StavarioModule.ProjectList ? 4 : 8)} />
            </Grid>}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserLayout;