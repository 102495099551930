import { create } from 'zustand'
import { GetKrosAdminUserInfoResponse } from '../ApiOld/ApiServerVov'
import { APIVovKrosAdminHelper } from '../ApiOld/ApiKrosAdminOldClient'

interface KrosAdminStoreState {
    credentials?: GetKrosAdminUserInfoResponse
    load: (perms: GetKrosAdminUserInfoResponse) => void
}
export const loadKrosAdminStore = () => {
    return new APIVovKrosAdminHelper().GetKrosAdminUserInfo();
}
export const useKrosAdminStore = create<KrosAdminStoreState>()((set) => ({
    credentials: undefined,
    load: (by) => set((state) => ({ credentials: by })),
}))