import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import React from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
export interface MessageDialogProps {
    onClose: () => void;
    text: string;
    title: string;
    open: boolean;
}
export default function MessageDialogDialog(props: MessageDialogProps) {
    const { onClose, open, text, title } = props;
    const theme = useTheme();

    const handleClose = () => {
        onClose();
    };
    const { t } = useTranslation();
    return (<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            {text}
        </DialogContent>
        <DialogActions>
            <Button sx={{ background: theme.palette.primary.main, color: 'black' }} onClick={handleClose}>OK</Button>
        </DialogActions>
    </Dialog>

    );
}