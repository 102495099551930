import { FamilyRestroomTwoTone } from "@mui/icons-material";
import { APIVovInvestorHelper } from "../ApiOld/ApiInvestorOldClient";
import { APIVovHelper } from "../ApiOld/ApiOldClient";
import { AddEmployeesToWorkersResponse, AddImageToProjectAndDiaryRecordResponse, AddImageToProjectResponse, AddRemoveFileDiaryRecordDTO, AddWealthsToMechanismsResponse, AuthOldErpAdminUsersResponse, AuthOldErpSubcontractorResponse, ChatGroupDTO, CreateSubcontractorUserResponse, DailyRecordSearchDTO, DiaryLogDTO, DiaryPrintType, EmployeeGroupDTO, EmployeeInGroupDTO, FileDiaryDTO, FileDirectoryDTO, FileParameter, FileResponse, FileToTransferDTO, GetBuildingWeatherByDiaryIdResponse, GetDailyFullRecordResponse, GetDailyRecordsForSubcontractorAndDiaryResponse, GetDailyRecordsForUserAndDiaryByRecordIdResponse, GetDailyRecordsForUserAndDiaryResponse, GetDailyRecordsForUserDTO, GetDiariesForUserDTO, GetDiaryDataDetailResponse, GetDiaryPermissionsResponse, GetDiaryRecordDataNotTablesResponse, GetImagesUrlsForRecordResponse, GetMachinesForRecordTableDTO, GetMaterialsForRecordTableDTO, GetPhotosFromBuildingToDiaryChooseResponse, GetPrintStatusResponse, GetSignInformationsForSigniWorkflowResponse, GetSignaturesForRecordResponse, GetSubcontractorDiaryPermissionsResponse, GetTodosForDiaryDTO, GetUserIdResponse, GetWeatherForRecordDTO, GetWorkerForRecordDTO, GroupAdminDTO, ImageDiaryDTO, InvestorsChatMessageDTO, SaveDiaryContentsResponse, SaveDiaryContentsSubcontractorResponse, SaveDiarySignatureAdminUserResponse, SaveMachinesTableReqDTO, SaveMaterialsTableReqDTO, SaveRotatedImageResponse, SaveRotatedImageSubcontractorResponse, SaveWeatherReqDTO, SaveWorkerReqDTO, SubDiaryDTO, SubcontractorsChatMessageDTO, UploadDigitalSignedPDFResponse, UploadFileToDiaryDirectResponse, UserDTO, UserGroupDTO, UserRoleDTO, UsersGroupDiaryDTO, WealthForDiaryDialogDTO } from "../ApiOld/ApiServerVov";
import { APIVovSubcontractorHelper } from "../ApiOld/ApiSubcontractorOldClient";

export class ApiDiaryBaseHelper {
    public _isSubdodavatel!: boolean;
    public _isInvestor!: boolean;
    constructor(isSubdodavatel: boolean, isInvestor: boolean) {
        this._isSubdodavatel = isSubdodavatel;
        this._isInvestor = isInvestor;
    }

    public async GetDiaryRecords(deleted: boolean): Promise<GetDailyRecordsForUserDTO[]> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (await new APIVovHelper().GetDiaryRecords(deleted));
        else if (this._isSubdodavatel)
            return (await new APIVovSubcontractorHelper().GetDiaryRecordsSubcontractor(deleted));
        else
            return [];
    }

    public async DeleteDiaryRecords(recordsIds: number[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (await new APIVovHelper().deleteDiaryRecords(recordsIds));
        else if (this._isSubdodavatel)
            return (await new APIVovSubcontractorHelper().deleteSubcontractorDiaryRecords(recordsIds));
        else
            return false;
    }

    public async GetDiaries(deleted: boolean, onlyMain?: boolean, buildingId?: number): Promise<GetDiariesForUserDTO[]> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetDiaries(deleted, onlyMain === undefined ? false : onlyMain, buildingId));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetSubcontractorDiaries(deleted, onlyMain === undefined ? false : onlyMain, buildingId));
        else
            return new APIVovInvestorHelper().GetDiariesForInvestor(deleted, onlyMain === undefined ? false : onlyMain, buildingId);
    }

    public async GetUser(): Promise<GetUserIdResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetUser());
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetUser());
        else
            return undefined;
    }

    public async DeleteDiaries(diaryIds: number[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().deleteDiaries(diaryIds);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().deleteSubcontractorDiaries(diaryIds));
        else
            return false;
    }

    public async GetPermissions(): Promise<GetDiaryPermissionsResponse | GetSubcontractorDiaryPermissionsResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetDiaryPermissions();
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetSubcontractorDiaryPermissions());
        else // investor?
            return new GetDiaryPermissionsResponse();
    }

    public async GetCompanyNameForDiary(): Promise<string> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetCompanyNameForDiary();
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetCompanyNameForSubcontractorDiary());
        else // investor?
            return "";
    }

    public async GetDiaryDataDetail(diaryId: number): Promise<GetDiaryDataDetailResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetDiaryDataDetail(diaryId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetSubcontractorDiaryDataDetail(diaryId));
        else // investor?
            return new GetDiaryDataDetailResponse();
    }

    public async SaveDiary(req: Partial<GetDiaryDataDetailResponse>): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().SaveDiary(req);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().SaveSubcontractorDiary(req));
        else // investor?
            return false;
    }

    public async PrintDiary(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, HasAttachments: boolean, otherUserId: number): Promise<FileResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintDiary(diaryId, onlyMyNotes, hasDigitalSignatureField, HasAttachments, otherUserId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintSubcontractorDiary(diaryId, onlyMyNotes, hasDigitalSignatureField));
        else if (this._isInvestor)
            return new APIVovInvestorHelper().PrintDiaryInvestor(diaryId, hasDigitalSignatureField);
    }

    public async PrintTitlePage(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, HasAttachments: boolean, otherUserId: number): Promise<FileResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintTitlePage(diaryId, onlyMyNotes, hasDigitalSignatureField, HasAttachments, otherUserId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintTitlePageSubcontractor(diaryId, onlyMyNotes, hasDigitalSignatureField));
        else if (this._isInvestor)
            return new APIVovInvestorHelper().PrintDiaryInvestor(diaryId, hasDigitalSignatureField);
    }

    public async PrintDiaryInDateRange(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, HasAttachments: boolean, dateFrom: Date, dateTo: Date, otherUserId: number): Promise<FileResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintDiaryInDateRange(diaryId, onlyMyNotes, hasDigitalSignatureField, HasAttachments, dateFrom, dateTo, otherUserId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintDiaryInDateRange(diaryId, onlyMyNotes, hasDigitalSignatureField, dateFrom, dateTo));
        else if (this._isInvestor)
            return undefined;
    }

    public async PrintDiaryInDateRangeBackground(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, HasAttachments: boolean, dateFrom: Date, dateTo: Date): Promise<void> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintDiaryInDateRangeBackground(diaryId, onlyMyNotes, hasDigitalSignatureField, HasAttachments, dateFrom, dateTo);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintDiaryInDateRangeBackground(diaryId, onlyMyNotes, hasDigitalSignatureField, dateFrom, dateTo));
        else if (this._isInvestor)
            return undefined;
    }

    public async PrintStatus(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, HasAttachments: boolean): Promise<GetPrintStatusResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetPrintStatus(diaryId, onlyMyNotes, hasDigitalSignatureField, HasAttachments);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetPrintStatusSubcontractor(diaryId, onlyMyNotes, hasDigitalSignatureField, false));
        else if (this._isInvestor)
            return new APIVovInvestorHelper().GetPrintStatusInvestor(diaryId, hasDigitalSignatureField, false, false);
    }

    public async PrintDiaryRecord(diaryRecordId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, otherUserId: number): Promise<FileResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintDiaryRecord(diaryRecordId, onlyMyNotes, hasDigitalSignatureField, otherUserId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintSubcontractorDiaryRecord(diaryRecordId, onlyMyNotes, hasDigitalSignatureField));
        else if (this._isInvestor)
            return new APIVovInvestorHelper().PrintDiaryRecordInvestor(diaryRecordId, hasDigitalSignatureField);
    }

    public async PrintWholeDiary(diaryId: number, onlyMyNotes: boolean): Promise<string | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintWholeDiary(diaryId, onlyMyNotes);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintWholeSubcontractorDiary(diaryId, onlyMyNotes));
        else // investor?
            return undefined;
    }

    public async DownloadFile(file: string): Promise<FileResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().DownloadFile(file);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().DownloadSubcontractorFile(file));
        else // investor?
            return undefined;
    }


    public async GetRecordsForViewing(recordId: number): Promise<GetDailyRecordsForUserAndDiaryResponse | GetDailyRecordsForSubcontractorAndDiaryResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetRecordsForViewing(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetRecordsForViewing(recordId));
        else // investor?
            return undefined;
    }


    public async LoginErp(token: string, adminUserId: number, domain: string): Promise<AuthOldErpAdminUsersResponse | AuthOldErpSubcontractorResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().LoginErp(token, adminUserId, domain);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().LoginErp(token, adminUserId, domain));
        else // investor?
            return undefined;
    }

    public async UserGroups(): Promise<UserGroupDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().UserGroups();
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().SubcontractorGroups());
        else // investor?
            return undefined;
    }

    public async GetRecordsForViewingStateless(recordId: number): Promise<GetDailyRecordsForUserAndDiaryByRecordIdResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetRecordsForViewingStateless(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetRecordsForViewingStateless(recordId));
        else
            return new APIVovInvestorHelper().GetRecordsForViewingStateless(recordId);
    }

    public async GetFullDailyRecordNotTables(recordId: number, sharedOnly: boolean): Promise<GetDiaryRecordDataNotTablesResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetFullDailyRecordNotTables(recordId, false);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetFullDailyRecordNotTables(recordId, false));
        else
            return new APIVovInvestorHelper().GetFullDailyRecordNotTables(recordId, false);
    }

    public async saveDiaryContents(diaryRecordId: number, stamp: number, works?: string | undefined,
        machines?: string | undefined, materials?: string | undefined, notes?: string | undefined, contents?: SubDiaryDTO[] | GroupAdminDTO[] | undefined): Promise<SaveDiaryContentsResponse | SaveDiaryContentsSubcontractorResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().saveDiaryContents(diaryRecordId, stamp, works, machines, materials, notes, contents);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().saveDiaryContents(diaryRecordId, stamp, works, machines, materials, notes, contents));
        else // investor?
            return undefined;
    }

    public async RingOthersDiary(diaryRecordId: number): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().RingOthersDiary(diaryRecordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().RingOthersDiary(diaryRecordId));
        else
            return new APIVovInvestorHelper().RingOthersDiary(diaryRecordId);
    }

    public async createDiaryRecord(diaryId: number, date: Date): Promise<number | string> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().createDiaryRecord(diaryId, date);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().createDiaryRecord(diaryId, date));
        else // investor?
            return (new APIVovInvestorHelper().CreateDiaryRecordInvestor(diaryId, date));
    }

    public async lockUnlockDiaryRecord(diaryRecordId: number, locked: boolean): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().lockUnlockDiaryRecord(diaryRecordId, locked);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().lockUnlockDiaryRecord(diaryRecordId, locked));
        else // investor?
            return false;
    }

    public async GetSignaturesForRecord(recordId: number): Promise<GetSignaturesForRecordResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetSignaturesForRecord(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetSignaturesForRecord(recordId));
        else
            return new APIVovInvestorHelper().GetSignaturesForRecord(recordId);
    }

    public async UploadDigitallySignedPDF(file: FileParameter, diaryIdRecord: number): Promise<UploadDigitalSignedPDFResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().UploadDigitallySignedPDF(file, diaryIdRecord);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().UploadDigitallySignedPDF(file, diaryIdRecord));
        else
            return new APIVovInvestorHelper().UploadDigitallySignedPDF(file, diaryIdRecord);
    }

    public async UploadSignature(file: FileParameter, diaryIdRecord: number, isInvestor: boolean): Promise<SaveDiarySignatureAdminUserResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().UploadSignature(file, diaryIdRecord, isInvestor);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().UploadSignature(file, diaryIdRecord, isInvestor));
        else
            return new APIVovInvestorHelper().UploadSignature(file, diaryIdRecord, isInvestor);
    }

    public async GetCompletedWorksRecord(recordId: number): Promise<string | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetCompletedWorksRecord(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetCompletedWorksRecord(recordId));
        else
            return new APIVovInvestorHelper().GetCompletedWorksRecord(recordId);
    }

    public async GetImagesForRecord(recordId: number): Promise<GetImagesUrlsForRecordResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetImagesForRecord(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetImagesForRecord(recordId));
        else
            return new APIVovInvestorHelper().GetImagesForRecord(recordId);
    }

    public async getPhotosBuildingToChoose(diaryRecordId: number): Promise<GetPhotosFromBuildingToDiaryChooseResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().getPhotosBuildingToChoose(diaryRecordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().getPhotosBuildingToChoose(diaryRecordId));
        else
            return new APIVovInvestorHelper().getPhotosBuildingToChoose(diaryRecordId);
    }

    public async deleteDiaryImageRel(diaryRecordId: number, imageName: string): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().deleteDiaryImageRel(diaryRecordId, imageName);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().deleteDiaryImageRel(diaryRecordId, imageName));
        else
            return false;
    }

    public async GetAttachmentsForRecord(recordId: number): Promise<FileDiaryDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetAttachmentsForRecord(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetAttachmentsForRecord(recordId));
        else
            return new APIVovInvestorHelper().GetAttachmentsForRecord(recordId);
    }

    public async deleteRecordFileRel(diaryRecordId: number, fileId: number): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().deleteRecordFileRel(diaryRecordId, fileId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().deleteRecordFileRel(diaryRecordId, fileId));
        else
            return false;
    }

    public async GetWeatherForRecord(recordId: number, onlyShared: boolean): Promise<GetWeatherForRecordDTO[]> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetWeatherForRecord(recordId, onlyShared);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetWeatherForRecord(recordId, onlyShared));
        else
            return new APIVovInvestorHelper().GetWeatherForRecord(recordId, onlyShared);
    }

    public async saveWeathers(diaryRecordId: number, weathers: SaveWeatherReqDTO[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().saveWeathers(diaryRecordId, weathers);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().saveWeathers(diaryRecordId, weathers));
        else
            return false;
    }

    public async GetWeatherFromBuildingForRecord(diaryRecordId: number, hour: number, date: Date): Promise<GetBuildingWeatherByDiaryIdResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetWeatherFromBuildingForRecord(diaryRecordId, hour, date);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetWeatherFromBuildingForRecord(diaryRecordId, hour, date));
        else
            return undefined;
    }

    public async SaveWeatherFromBuildingForRecord(diaryRecordId: number, hour: number, date: Date): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().SaveWeatherFromBuildingForRecord(diaryRecordId, hour, date);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().SaveWeatherFromBuildingForRecord(diaryRecordId, hour, date));
        else
            return undefined;
    }

    public async GetWorkersForRecord(recordId: number, onlyShared: boolean): Promise<GetWorkerForRecordDTO[]> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetWorkersForRecord(recordId, onlyShared);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetWorkersForRecord(recordId, onlyShared));
        else
            return new APIVovInvestorHelper().GetWorkersForRecord(recordId, onlyShared);
    }

    public async saveWorkers(diaryRecordId: number, workers: SaveWorkerReqDTO[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().saveWorkers(diaryRecordId, workers);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().saveWorkers(diaryRecordId, workers));
        else
            return false;
    }

    public async GetEmployeesWithGroups(diaryRecordId: number): Promise<EmployeeGroupDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetEmployeesWithGroups(diaryRecordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetEmployeesWithGroups(diaryRecordId));
        else
            return undefined;
    }

    public async AddEmpsToDiary(diaryRecordId: number, emps: EmployeeInGroupDTO[]): Promise<AddEmployeesToWorkersResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().AddEmpsToDiary(diaryRecordId, emps);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().AddEmpsToDiary(diaryRecordId, emps));
        else
            return undefined;
    }

    public async GetMaterialsForRecord(recordId: number, onlyShared: boolean): Promise<GetMaterialsForRecordTableDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetMaterialsForRecord(recordId, onlyShared);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetMaterialsForRecord(recordId, onlyShared));
        else
            return undefined;
    }

    public async saveMaterials(diaryRecordId: number, materials: SaveMaterialsTableReqDTO[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().saveMaterials(diaryRecordId, materials);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().saveMaterials(diaryRecordId, materials));
        else
            return false;
    }

    public async GetMachinesForRecord(recordId: number, onlyShared: boolean): Promise<GetMachinesForRecordTableDTO[]> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetMachinesForRecordTable(recordId, onlyShared);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetMachinesForRecord(recordId, onlyShared));
        else
            return new APIVovInvestorHelper().GetMachinesForRecordTable(recordId, onlyShared);
    }

    public async saveMachines(diaryRecordId: number, machines: SaveMachinesTableReqDTO[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().saveMachines(diaryRecordId, machines);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().saveMachines(diaryRecordId, machines));
        else
            return false;
    }

    public async GetWealthsForDiaryDialog(diaryRecordId: number): Promise<WealthForDiaryDialogDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetWealthsForDiaryDialog(diaryRecordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetWealthsForDiaryDialog(diaryRecordId));
        else
            return undefined;
    }

    public async AddWealthsToMechanisms(diaryRecordId: number, checkedWealthsIds: number[]): Promise<AddWealthsToMechanismsResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().AddWealthsToMechanisms(diaryRecordId, checkedWealthsIds);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().AddWealthsToMechanisms(diaryRecordId, checkedWealthsIds));
        else
            return undefined;
    }

    public async UploadPdfFileForSignatureLogged(file: FileParameter, diaryIdRecord: number): Promise<FileResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().UploadPdfFileForSignatureLogged(file, diaryIdRecord);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().UploadPdfFileForSignatureLogged(file, diaryIdRecord));
        else
            return undefined;
    }

    public async GetFullDailyRecord(recordId: number, domain: string, base64: boolean, sharedOnly: boolean, onlyMine?: boolean, subcontractorId?: number): Promise<GetDailyFullRecordResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetFullDailyRecord(recordId, domain, base64, sharedOnly, onlyMine, subcontractorId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetFullDailyRecord(recordId, domain, base64, sharedOnly, onlyMine, subcontractorId));
        else
            return new APIVovInvestorHelper().GetFullDailyRecord(recordId, domain, base64, sharedOnly, onlyMine, subcontractorId);
    }

    public async GetDiarySearchResult(diarId: number | undefined, sText: string, datFrom: Date | undefined, datTo: Date | undefined): Promise<DailyRecordSearchDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetDiarySearchResult(diarId, sText, datFrom, datTo);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetDiarySearchResult(diarId, sText, datFrom, datTo));
        else
            return undefined;
    }

    public async copyDiaryRecord(diaryId: number, dateFrom: Date, dateTo: Date, onlyWorkDays: boolean, completedWork: string, mach: SaveMachinesTableReqDTO[], mat: SaveMaterialsTableReqDTO[], works: SaveWorkerReqDTO[]): Promise<boolean> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().copyDiaryRecord(diaryId, dateFrom, dateTo, onlyWorkDays, completedWork, mach, mat, works);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().copyDiaryRecord(diaryId, dateFrom, dateTo, onlyWorkDays, completedWork, mach, mat, works));
        else
            return false;
    }

    public async GetTodosForRecord(recordId: number): Promise<GetTodosForDiaryDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetTodosForRecord(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetTodosForRecord(recordId));
        else
            return new APIVovInvestorHelper().GetTodosForRecord(recordId);
    }

    public async GetInvestorsChatMessages(recordId: number): Promise<InvestorsChatMessageDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetInvestorsChatMessages(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetInvestorsChatMessages(recordId));
        else
            return new APIVovInvestorHelper().GetInvestorsChatMessages(recordId);
    }

    public async PostInvestorsMessage(recordId: number, message: string): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PostInvestorsMessage(recordId, message);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PostInvestorsMessage(recordId, message));
        else
            return new APIVovInvestorHelper().PostInvestorsMessage(recordId, message);
    }

    public async SaveRotatedImageToDiary(diaryRecordId: number, imagePath: string, rotation: number): Promise<SaveRotatedImageResponse | SaveRotatedImageSubcontractorResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().SaveRotatedImageToDiary(diaryRecordId, imagePath, rotation);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().SaveRotatedImageToDiary(diaryRecordId, imagePath, rotation));
        else
            return undefined;
    }

    public async TransferPhotosFromProject(recordId: number, images: FileToTransferDTO[]): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().TransferPhotosFromProject(recordId, images);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().TransferPhotosFromProject(recordId, images));
        else
            return new APIVovInvestorHelper().TransferPhotosFromProject(recordId, images);
    }

    public async addImageToDiary(diaryRecordId: number, file: FileParameter): Promise<AddImageToProjectAndDiaryRecordResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().addImageToDiary(diaryRecordId, file);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().addImageToDiary(diaryRecordId, file));
        else
            return new APIVovInvestorHelper().addImageToDiary(diaryRecordId, file);
    }

    public async addFileToDiary(diaryRecordId: number, file: FileParameter): Promise<UploadFileToDiaryDirectResponse> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().addFileToDiary(diaryRecordId, file);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().addFileToDiary(diaryRecordId, file));
        else
            return new APIVovInvestorHelper().addFileToDiary(diaryRecordId, file);
    }

    public async GetFilesFromProject(recordId: number): Promise<FileDirectoryDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetFilesFromProject(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetFilesFromProject(recordId));
        else
            return new APIVovInvestorHelper().GetFilesFromProject(recordId);
    }

    public async addRemoveFileToDiaryRecord(diaryRecordId: number, files: AddRemoveFileDiaryRecordDTO[]): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().addRemoveFileToDiaryRecord(diaryRecordId, files);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().addRemoveFileToDiaryRecord(diaryRecordId, files));
        else
            return undefined;
    }

    public async GetSubcontractorsChatMessages(recordId: number): Promise<SubcontractorsChatMessageDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return undefined; //new APIVovHelper().GetSubcontractorsChatMessages(recordId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetSubcontractorsChatMessages(recordId));
        else
            return undefined;
    }

    public async GetChatSubcontractorsMessages(recordId: number): Promise<ChatGroupDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().GetSubcontractorsChatMessages(recordId);
        else if (this._isSubdodavatel)
            return undefined;//(new APIVovSubcontractorHelper().GetSubcontractorsChatMessages(recordId));
        else
            return undefined;
    }

    public async PostSubcontractorsMessage(recordId: number, message: string): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PostSubcontractorsMessage(recordId, message);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PostSubcontractorsMessage(recordId, message));
        else
            return undefined;
    }

    public async SaveShownChatMessages(recordId: number): Promise<boolean | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().SaveShownChatMessages(recordId);
        else if (this._isSubdodavatel)
            return undefined;
        else
            return undefined;
    }

    public async CreateSubcontractorUser(name: string, email: string, groupId: number | undefined, diaryId?: number, roleId?: number): Promise<CreateSubcontractorUserResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().CreateSubcontractorUser(name, email, groupId, diaryId, roleId));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().CreateSubcontractorUser(name, email, groupId, diaryId, roleId));
        else
            return undefined;
    }

    public async GetDiaryRecordLog(recordId: number): Promise<DiaryLogDTO[]> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetDiaryRecordLog(recordId));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetDiaryRecordLog(recordId));
        else
            return (new APIVovInvestorHelper().GetDiaryRecordLog(recordId));
    }

    public async GetDiaryUsersForGroup(groupId: number, buildingId: number): Promise<UsersGroupDiaryDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetDiaryUsersForGroup(groupId, buildingId));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetDiaryUsersForGroupSubcontractor(groupId, buildingId));
        else
            return undefined;
    }

    public async GetMaterialsForRecordsExcel(diaryId: number, start: Date, end: Date): Promise<GetMaterialsForRecordTableDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetMaterialsForRecordsExcel(diaryId, start, end));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetMaterialsForRecordsExcelSubcontractor(diaryId, start, end));
        else
            return undefined;
    }

    public async AddImageToProject(buildingId: number, file: FileParameter): Promise<AddImageToProjectResponse | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return undefined;
        else if (this._isSubdodavatel)
            return undefined;
        else
            return (new APIVovInvestorHelper().AddImageToProjectInvestor(buildingId, file));
    }

    public async GetUserRoleForSubcontractor(): Promise<UserRoleDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetUserRoleForSubcontractor());
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetUserRoleForSubcontractor());
        else
            return undefined;
    }
    public async GetUsersForSignFlow(diaryRecordId: number, lang: string): Promise<GetSignInformationsForSigniWorkflowResponse | null> {
        if (this._isSubdodavatel === false)
            return (await new APIVovHelper().GetUsersForSignFlow(diaryRecordId, lang));
        else return null;
    }
    public async StartSigniWorkflow(diaryRecordId: number): Promise<void | null> {
        if (this._isSubdodavatel === false)
            return (await new APIVovHelper().StartSigniInteractive(diaryRecordId));
        else return null;
    }



    public async PrintDiaryBackground(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, hasAttachments: boolean, dateFrom: Date | undefined, dateTo: Date | undefined): Promise<void> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().PrintDiaryBackground(DiaryPrintType.Diary, diaryId, onlyMyNotes, hasDigitalSignatureField, hasAttachments, dateFrom, dateTo));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintDiaryBackgroundSubcontractor(DiaryPrintType.DiarySubcontractor, diaryId, onlyMyNotes, hasDigitalSignatureField, hasAttachments, dateFrom, dateTo));
        else
            return (new APIVovHelper().PrintDiaryBackground(DiaryPrintType.DiaryInvestor, diaryId, onlyMyNotes, hasDigitalSignatureField, hasAttachments, dateFrom, dateTo));
    }


    public async PrintDiaryZIP(diaryId: number): Promise<void> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return new APIVovHelper().PrintSignedDiaryRecordsZIP(diaryId);
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().PrintSignedDiaryRecordsZIP(diaryId));
        else if (this._isInvestor)
            return new APIVovInvestorHelper().PrintSignedDiaryRecordsZIP(diaryId);
    }

    public async GetUsersForDiaryPrint(diaryId: number): Promise<UserDTO[] | undefined> {
        if (this._isSubdodavatel === false && this._isInvestor === false)
            return (new APIVovHelper().GetUsersForDiaryPrint(diaryId));
        else if (this._isSubdodavatel)
            return (new APIVovSubcontractorHelper().GetUserRoleForSubcontractor());
        else
            return undefined;
    }
}
