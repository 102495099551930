import { CloudUploadOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { FileParameter } from '../../../ApiOld/ApiServerVov';

interface BasicCommonUploadComponentProps {
    onlyImages?: boolean;
    onFileChange?: (file: FileParameter) => void;
}

export default function BasicCommonUploadComponent(props: BasicCommonUploadComponentProps) {
    const { onlyImages, onFileChange } = props;
    const inputFile = React.useRef<HTMLInputElement>(null);

    async function upload(files: File) {
        let fileParameter: FileParameter = { data: files, fileName: files.name };
        if (onFileChange) {
            onFileChange(fileParameter);
        }

        if (inputFile.current) {
            inputFile.current.value = "";
        }
    }

    const handleDragOver = (e: any) => {
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: any) => {
        console.log('drop', e);
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        console.log(files);
        if (files && files.length) {
            upload(files[0]);
        }
    };
    return (

        <div>
            {onlyImages !== undefined && onlyImages === true && <input
                accept="image/png, image/jpeg, image/jfif"
                onChange={(e) => {
                    if (e.target && e.target.files)
                        upload(e.target.files[0]);

                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />}
            {(onlyImages === undefined || onlyImages === false) && <input
                onChange={(e) => {
                    if (e.target && e.target.files)
                        upload(e.target.files[0]);

                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />}
            <div id='dropArea' onDrop={handleDrop} onDragOver={handleDragOver}>
                <div style={{
                    backgroundColor: 'white',
                    margin: '8px'

                }}>
                    <div>
                        <IconButton size='small' onClick={(e) => {
                            inputFile.current!.click();
                        }}><CloudUploadOutlined /></IconButton>
                    </div>

                </div>
            </div></div >);
}