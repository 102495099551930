import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { GetTasksForAdminUserResponse, GetTaskDetailsResponse } from "./ApiServerVov";

export class APIVovTaskHelper extends ApiOldBaseHelper {
    public async GetTasksForAdminUser(deleted: boolean): Promise<GetTasksForAdminUserResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getTasksForAdminUser(deleted));
    }
    // public async GetTaskDetails(id: number): Promise<GetTaskDetailsResponse> {
    //     return (await this.GetAdressedAuthRefreshableClient().getTaskDetails(id));
    // }

    // public async SaveTaskDetails(data: Partial<GetTaskDetailsResponse>): Promise<boolean | undefined> {
    //     return (await this.GetAdressedAuthRefreshableClient().saveTaskDetails(new SaveTaskDetailsRequest({
    //         id: data.id,
    //         name: data.name,
    //     }))).ok;
    // }


    // public async deleteWealths(wealthsIds: number[]): Promise<boolean> {
    //     return ((await this.GetAdressedAuthRefreshableClient().deleteWealths(new DeleteWealthRequest({ wealthIds: wealthsIds }))).ok!);
    // }
    // public async MoveWealthsFromTrash(wealthsIds: number[]): Promise<boolean> {
    //     return (await this.GetAdressedAuthRefreshableClient().moveWealthsFromTrash(new MoveWealthsFromTrashRequest({ deletedWealthsIds: wealthsIds }))).ok!;
    // }
}