import { Box, Button } from '@mui/material';
import React from 'react';
export default function Login(props: any) {

    return (
        <Box display='flex' flexDirection='column'>
            Vítejte ve Stavariu
            <Button variant='contained' disabled>Chci do aplikace</Button>
            <Button variant='contained' onClick={() => {
                window.location.href = "https://stavario.com/";
            }}>Mám zájem o více informací</Button>
        </Box>
    )
}