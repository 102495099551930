import { useState } from "react";
import { Typography, Box, TextField, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ProjectDocumentAutocompleteSearchBarProps {
    list: any[];
    onSelected: (selected: any) => void;
}

function ProjectDocumentAutocompleteSearchBar(props: ProjectDocumentAutocompleteSearchBarProps) {
    const { list, onSelected } = props;
    const [input, setInput] = useState("");
    const { t, i18n } = useTranslation();

    const handleInput = (e) => {
        console.log(e.target.value);
        setInput(e.target.value.toLowerCase());
    };

    return (
        <Box
            className="App"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly"
            }}
        >
            <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                options={list.map((item) => item.name)}
                onChange={(e, value) => { onSelected(value); }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("files.searchFiles")}
                        onSelect={handleInput}
                    />
                )}
            />
        </Box>
    );
}

export default ProjectDocumentAutocompleteSearchBar;