import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
export interface ColorPickerDialogProps {
    onChange?: (event: ColorResult | undefined, element: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCompleted?: (event: string | undefined) => void;
    onClose: () => void;
    open: boolean;
    color: string | undefined;
}
export default function ColorPickerDialog(props: ColorPickerDialogProps) {
    const { color, onChange, onClose, onChangeCompleted, open } = props;
    const [selectedColor, setSelectedColor] = React.useState(color);
    React.useEffect(() => {
        setSelectedColor(color);
    }, [color]);
    const handleClose = () => {
        onClose();
    };
    const { t } = useTranslation();
    return (<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {t('xxx Vyber barvu')}
        </DialogTitle>
        <DialogContent>
            <SketchPicker color={selectedColor} onChangeComplete={(e, d) => {
                if (onChange)
                    onChange!(e, d); setSelectedColor(e.hex);
            }}></SketchPicker>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{t('noL')}</Button>
            <Button onClick={() => { onChangeCompleted!(selectedColor); handleClose(); }} autoFocus>
                {t('yesL')}
            </Button>
        </DialogActions>
    </Dialog>

    );
}