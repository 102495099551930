import WarehouseDetail from './WarehouseDetail';
import WarehousesList from './WarehousesList';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface WarehousesProps {
    type: 'list' | 'detail' | 'new';
}

export default function Warehouses({ type }: Readonly<WarehousesProps>) {
    const params = useParams();
    const [warehouseId, setWarehouseId] = useState<number | undefined>();

    useEffect(() => {
        if (params.warehouseId) {
            setWarehouseId(parseInt(params.warehouseId));
        } else {
            setWarehouseId(undefined);
        }
    }, [params]);

    return (
        <Box>
            {type === 'list' && <WarehousesList />}
            {type === 'detail' && <WarehouseDetail warehouseId={warehouseId} />}
            {type === 'new' && <WarehouseDetail warehouseId={undefined} />}
        </Box>
    )
}