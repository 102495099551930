// @ts-nocheck
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    errorString: string;
}

// class ErrorHandlerScene extends React.Component {
//     state = {
//         hasError: false,
//         error: { message: "", stack: "" },
//         info: { componentStack: "" }
//     };

//     static getDerivedStateFromError = error => {
//         return { hasError: true };
//     };

//     componentDidCatch = (error, info) => {
//         this.setState({ error, info });
//     };

//     render() {
//         const { hasError, error, info } = this.state;
//         console.log(error, info);
//         const { children } = this.props;

//         return hasError ? <ErrorComponent /> : children;
//     }
// }
// const ErrorComponent = () => {
//     return <h1>Something went wrong</h1>;
// };


class ErrorHandlerScene extends Component<Props, State> {
    public state: State = {
        hasError: false,
        errorString: ''
    };

    public static getDerivedStateFromError(_: Error): State {
        console.log('derived state', _);
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorString: _.message };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error - error boundary:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <div><h1>Sorry.. there was an error</h1>{this.state.errorString}</div>;

        }

        return this.props.children;
    }
}

export default ErrorHandlerScene;