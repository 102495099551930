import * as React from 'react';
import { useApi } from '../../../ApiOld/Api/useApi';
import { JokeDTO, SuperadminVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import AddJokeHint from './AddJokeHintDialog';
import LanguagePicker from '../LanguagePicker';
import { useTranslation } from 'react-i18next';

export interface IJokesAdminSceneProps {
}

export default function JokesAdminScene(props: IJokesAdminSceneProps) {
    const { authorizedApi } = useApi(SuperadminVovClient);
    const { i18n } = useTranslation();
    const [jokes, setJokes] = React.useState<JokeDTO[]>([]);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [adding, setAdding] = React.useState<boolean>(false);
    const [lang, setLang] = React.useState<string>(i18n.language);
    const [selectedJoke, setSelectedJoke] = React.useState<JokeDTO | null>(null);
    const refresh = React.useCallback(async () => {
        const response = await authorizedApi.jokesList();
        setJokes(response.jokes ?? []);
    }, [authorizedApi]);
    const save = React.useCallback(async () => {
        if (selectedJoke) {
            await authorizedApi.updateJoke({
                content: selectedJoke.content,
                heading: selectedJoke.heading,
                id: selectedJoke.id,
                language: lang
            } as JokeDTO);
            refresh();
        }
    }, [authorizedApi, selectedJoke, refresh, lang]);
    React.useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Box display='flex'>
            <AddJokeHint isJoke open={adding} onClose={(e) => { setAdding(false); if (e) refresh(); }} />
            <Box p={1} sx={{ width: '30%' }}>
                <Button onClick={() => setAdding(true)} variant='contained' size='small'>Přidat</Button>
                {jokes.map((joke) => {
                    return <Box m={1} display='flex' alignItems='center' onClick={() => { setSelectedJoke(joke); setChanged(false); }}>
                        <Box>
                            {joke.heading}
                            <IconButton onClick={() => {
                                if (joke.id)
                                    authorizedApi.deleteJoke(joke.id).then(() => {
                                        refresh();
                                    });
                            }}><DeleteOutline /></IconButton>
                        </Box>
                    </Box>
                })}
            </Box>
            <Box sx={{ width: '70%' }}>
                {selectedJoke !== null && <Stack spacing={2} m={2}>
                    <LanguagePicker externalLang={selectedJoke.language} showText notChangeLanguage onLanguageChange={(language) => { setLang(language) }} />
                    <TextField size='small' label='Nadpis' value={selectedJoke.heading} onChange={(e) => {
                        setChanged(true);
                        setSelectedJoke({ ...selectedJoke, heading: e.target.value } as JokeDTO);
                        // setJokes(p => p.map((joke) => {
                        //     if (joke.id === selectedJoke.id) {
                        //         return { ...selectedJoke, heading: e.target.value } as JokeDTO;
                        //     }
                        //     else return joke;
                        // }))
                    }} />
                    <TextField size='small' label='Popis' value={selectedJoke.content} multiline minRows={2} maxRows={10} onChange={(e) => {
                        setChanged(true);
                        setSelectedJoke({ ...selectedJoke, content: e.target.value } as JokeDTO);
                        // setJokes(p => p.map((joke) => {
                        //     if (joke.id === selectedJoke.id) {
                        //         return { ...selectedJoke, content: e.target.value } as JokeDTO;
                        //     }
                        //     else return joke;
                        // }))
                    }} />
                    <Button onClick={save} disabled={!changed} variant='contained'>Save</Button>
                </Stack>}
            </Box>
        </Box>
    );
}
