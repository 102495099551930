import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Validate, ValidationGroup } from 'mui-validate';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { AddImageCategoryRequest, RenameImagesBuildingRequest, VovClient } from '../../../../ApiOld/ApiServerVov';

interface RenameNoteDialogProps {
    open: boolean;
    oldName: string;
    onClose: () => void;
    onConfirm?: (newName: string) => void;
}
export default function RenameNoteDialog(props: RenameNoteDialogProps) {
    const { open, oldName, onClose, onConfirm } = props;

    const [name, setName] = React.useState<string>();

    useEffect(() => {
        setName(oldName);
    }, [oldName]);

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="rename-dialog-title"
            aria-describedby="crrenameeate-dialog-description"
        >
            <DialogTitle id="rename-note-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('rename')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div>
                        <Validate name='name-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                            <TextField sx={{ margin: '12px', width: '526px' }} label={t('name')}
                                size='small'
                                name='userName'
                                variant='outlined'
                                value={name}
                                error={!name}
                                onChange={(e) => { setName(e.target.value) }} />
                        </Validate>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={name === undefined || name === ''} onClick={() => {
                                if (name) {
                                    if (onConfirm) onConfirm(name);
                                    onClose();
                                }
                            }}>{t('save')}</Button>
                        </div>
                    </div>
                </ValidationGroup>
            </DialogContent>
        </Dialog>
    )
}
