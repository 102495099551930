import { Box } from '@mui/material';
import * as React from 'react';
import { Outlet } from 'react-router';

export interface IInvestorLayoutProps {
}

export default function InvestorLayout(props: IInvestorLayoutProps) {
    return (
        <Box>
            <Outlet />
        </Box>
    );
}
