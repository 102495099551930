import { getFileExtension } from "../Functions/GetFileExtension";

export function IsImage(props: string): boolean | undefined {
    console.log('hodnota vole_ ', props);
    switch (getFileExtension(props)) {
        case '.jpg':
        case '.jpeg':
        case '.png':
        case '.tiff':
            return true;
        default: 
            return false;
    }
}