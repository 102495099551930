import { ProjectDTO } from "../../Api/UsersMicroserviceClient";
import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BuildingDTO, EmployeeDTO } from "../../ApiOld/ApiServerVov";
import { SolveEmployeesContext, SolveProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";

export interface EmployeeSelectProps {
    selectedEmployeeId?: number | null;
    onChange: (event: any | null) => void;
    isMandatory?: boolean;
    label?: string;
}
export default function EmployeeSelect(props: EmployeeSelectProps) {
    const { onChange, selectedEmployeeId, isMandatory, label } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedEmployee, setSelectedEmployee] = React.useState<EmployeeDTO | null>(null);
    const [employees, setEmployees] = React.useState<EmployeeDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    useEffect(() => {

        SolveEmployeesContext(globalContext, setGlobal).then((res) => {
            setEmployees(res)
        });

    }, []);
    useEffect(() => {
        if (employees !== undefined && employees !== null && employees.length > 0
            && selectedEmployeeId !== undefined &&
            selectedEmployeeId !== null && selectedEmployeeId > 0) {
            let q = employees.find(x => x.id == selectedEmployeeId);
            if (q !== undefined)
                setSelectedEmployee(q);
        }

    }, [employees]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedEmployee(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null && o !== undefined ? o.firstname! + ' ' + o.lastname : ""}
                value={selectedEmployee}
                options={employees}

                fullWidth={biggerThan700}
                sx={{ minWidth: 100 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedEmployee === null}  {...params} label={label}

                />}
            />
            {selectedEmployee === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}