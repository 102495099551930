import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Countries } from './Telephones';
import { CountryType } from './CountryType';
export interface TelephoneSelectProps {
    onChangeCompleted: (event: CountryType | null) => void;
    defaultValue?: string;
}

export default function TelephoneSelect(props: TelephoneSelectProps) {
    const { onChangeCompleted, defaultValue } = props;
    const [valueSelected, setValueSelected] = useState<CountryType | null>(null);
    useEffect(() => {
        if (defaultValue !== undefined && defaultValue !== null)
            setValueSelected(Countries.find(x => x.code === defaultValue)!)
        else setValueSelected(null);
    }, [defaultValue]);
    return (
        <Autocomplete

            id="country-select-demo"
            sx={{ minWidth: 200, maxWidth: 210, marginTop: '8px' }}
            options={Countries}
            autoHighlight
            value={valueSelected}
            onChange={(e, r) => { setValueSelected(r); onChangeCompleted(r); }}
            getOptionLabel={(option) => (option.code + "(+ " + option.phone + ")")}

            renderOption={(props, option) => (
                <Box /* key={option.code} */ component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.code} (+{option.phone})
                </Box>
            )}
            renderInput={(params) => (<div style={{ display: 'flex' }}>

                <TextField
                    sx={{ minWidth: '152px' }}
                    {...params}
                    label="+ 420"
                    size='small'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill

                    }}
                />
                {valueSelected && <img style={{ margin: '12px' }}
                    loading="lazy"
                    width="20"
                    height="16"
                    src={`https://flagcdn.com/w40/${valueSelected.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${valueSelected.code.toLowerCase()}.png 2x`}
                    alt=""
                />}
            </div>

            )}
        />
    );
}