import { CalendarMonth, Clear, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, Save, SaveAs, Search, Troubleshoot, Watch } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, InputAdornment, Popover, styled, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { DailyRecordSearchDTO, FileParameter, RequestsDTO } from '../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { Marker } from 'react-image-marker';
import withLoading, { WithLoadingProps } from '../../Shared/Loader';
import DiarySearchRecord from './DiarySearchRecord';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, RangeKeyDict } from "react-date-range";
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import LocalizedDateRangePicker from '../../Shared/DateTime/LocalizedDateRangePicker';


export interface DiarySearchDialogProps extends WithLoadingProps {
    open: boolean;
    searchText: string,
    diaryId: number | undefined,
    diaryDate: Date | undefined,
    onClose: (value: string) => void;
    onRefresh: (data: Partial<RequestsDTO>) => void;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    fromExternal: boolean;
}

interface DateRangeSelection {
    startDate: Date | undefined;
    endDate: Date | undefined;
}

function DiarySearchDialog(props: DiarySearchDialogProps) {
    const { onClose, searchText, diaryId, diaryDate, open, onRefresh, fireLoading, stopLoading, isInvestor, isSubdodavatel, fromExternal } = props;
    const { t, i18n } = useTranslation();
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const [records, setRecords] = useState<DailyRecordSearchDTO[]>([]);
    const [text, setText] = useState<string | undefined>(undefined);
    const [diary, setDiary] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = React.useState<DateRangeSelection>({
        startDate: new Date(),
        endDate: new Date()
    });

    const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);

    useEffect(() => {
        if (open === true)
            getSearchResult();
    }, [open]);

    useEffect(() => {
        setText(searchText);
    }, [searchText]);

    useEffect(() => {
        setDiary(props.diaryId);
    }, [diaryId]);

    useEffect(() => {
        getSearchResult();
    }, [dateRange]);

    function setDefaultState() {
        setRecords([]);
    }

    function getSearchResult() {
        //console.log('a ' + diary + ' b ' + open + ' c ');
        if (open && text) {
            //fireLoading!();
            setLoading(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetDiarySearchResult(diary, text, dateRange.startDate !== undefined ? dateRange.startDate : undefined, dateRange.endDate !== undefined ? dateRange.endDate : undefined).then((data) => {
                setRecords(data ?? []);
                setLoading(false);
                stopLoading!();
            }).catch(() => {
                stopLoading!(); console.log('err');
                setLoading(false);
            });
        }
        else stopLoading!();
    }

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            getSearchResult();
        }
    }

    const [fullScreen, setFullScreen] = useState(false);
    function searchClosed() { setDefaultState(); setDefaultDate(); onClose(""); }

    interface CustomMarker extends Marker {
        type: "success" | "error" | "warning";
        content: string;
        deviceId?: string | number;
    }

    const anchorRef = React.useRef<HTMLButtonElement>(null);
    function setDefaultDate() {
        setDateRange(
            {
                startDate: undefined,
                endDate: undefined
            }
        );
    }
    const handleSelect = (ranges: RangeKeyDict) => {
        const selectedRange = ranges.selection;
        setDateRange({
            startDate: selectedRange.startDate || new Date(),
            endDate: selectedRange.endDate || new Date()
        });
    };

    return (
        <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={open} onClose={onClose} sx={{ zIndex: "5001", marginTop: '96px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "28", backgroundColor: '#F5F5F5' }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('intelligentSearch')} </Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => {
                                setFullScreen(!fullScreen);
                            }} >
                                {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                            </IconButton>
                            <IconButton onClick={searchClosed} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5', minHeight: '500px' }} >
                <div style={{ display: 'flex-inline', flexDirection: 'column', flexWrap: 'wrap' }} >
                    <div style={{ margin: '20px' }}>
                        <TextField onKeyDown={handleKeyDown} onChange={(e) => { setText(e.target.value) }} value={text} variant='outlined' sx={{ height: '20px', float: 'left', display: 'flex' }} InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => { getSearchResult() }}>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} size='small' />
                    </div>
                    <div style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px', float: 'right' }}>
                        <div style={{ display: 'flex', margin: '5px', borderBottom: '1px solid', alignItems: 'center' }}>
                            {dateRange && (
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        minWidth: '80px',
                                        marginRight: '10px',
                                        marginLeft: 'auto',
                                        whiteSpace: 'nowrap', // Prevents text wrapping
                                        overflow: 'hidden', // Ensures text does not overflow
                                        textOverflow: 'ellipsis', // Adds ellipsis if text is too long
                                        verticalAlign: 'top'
                                    }}
                                >
                                    {dateRange.startDate === undefined
                                        ? ''
                                        : dateRange.startDate === dateRange.endDate
                                            ? dateRange.startDate?.toLocaleDateString(i18n.language)
                                            : `${dateRange.startDate?.toLocaleDateString(i18n.language)} - ${dateRange.endDate?.toLocaleDateString(i18n.language)}`}
                                </Typography>
                            )}
                            {dateRange && dateRange.startDate && (
                                <IconButton
                                    sx={{ marginTop: '-8px' }}
                                    onClick={() => {
                                        setDefaultDate();
                                    }}
                                >
                                    <Clear />
                                </IconButton>
                            )}
                            <LocalizedDateRangePicker onDateRangeChange={(range) => setDateRange(range)} />
                        </div>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {
                        !loading && records && records.map((item, i) => (
                            <div key={item.id} style={{ marginLeft: '20px' }}>
                                <DiarySearchRecord
                                    isSubdodavatel={isSubdodavatel}
                                    isInvestor={isInvestor}
                                    recordId={item.id!}
                                    dateInput={item.date!}
                                    employeesInput={item.employees}
                                    machinesInput={item.machines}
                                    materialsInput={item.materials}
                                    worksInput={item.works}
                                    otherNotesInput={item.otherNotes}
                                    fromExternal={fromExternal}
                                />
                            </div>
                        ))
                    }
                    {
                        loading && (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '120px', // Optional: Adjust based on your parent container's height
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )
                    }
                </div>

                {/* <Dialog open={isCalendarOpen} onClose={() => { setIsCalendarOpen(false); }} sx={{ zIndex: 9999 }}>
                    <div>
                        <RNG.DateRange editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={dateRange} onChange={item => setDateRange([item.selection])} locale={i18n.language == 'cs' ? cs : (i18n.language == 'pl' ? pl : (i18n.language == 'sk' ? sk : en))} />
                        <div style={{ display: 'flex', margin: '5px', borderRight: '1px solid black' }}>
                            <Button onClick={() => { setIsCalendarOpen(false); }} style={{
                                marginLeft: 'auto', marginRight: '10px', border: '1px solid', borderColor: 'lightgray'

                            }} aria-describedby='btnOk'>
                                <Typography color='#FFC600'>OK</Typography>
                            </Button>
                            <Button onClick={() => { setDefaultDate(); setIsCalendarOpen(false); }} style={{ marginRight: 'auto', border: '1px solid', borderColor: 'lightgray' }} aria-describedby='btnOk'>
                                <Typography color='#FFC600'>{t('Cancel')}</Typography>
                            </Button>
                        </div>
                    </div>
                </Dialog> */}
            </div >
        </Dialog >
    );
};

export default withLoading(DiarySearchDialog);
