import ProductDetail from './ProductDetail';
import ProductsList from './ProductsList';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface ProductsProps {
    type: 'list' | 'detail' | 'new';
}

export default function Products({ type }: Readonly<ProductsProps>) {
    const params = useParams();
    const [productId, setProductId] = useState<number | undefined>();

    useEffect(() => {
        if (params.productId) {
            setProductId(parseInt(params.productId));
        } else {
            setProductId(undefined);
        }
    }, [params]);

    return (
        <Box>
            {type === 'list' && <ProductsList />}
            {type === 'detail' && <ProductDetail productId={productId} />}
            {type === 'new' && <ProductDetail productId={undefined} />}
        </Box>
    )
}