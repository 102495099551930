import { useEffect, useRef, useState } from "react";
import { GetMachinesForRecordTableDTO, SaveMachinesTableReqDTO } from "../../../../../ApiOld/ApiServerVov";
import { ColumnChooser, ColumnDirective, ColumnsDirective, Edit, EditSettingsModel, ExcelExport, Filter, GridComponent, Group, IEditCell, Inject, Reorder, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDiaryMachinesCopyStore } from "../../../../../Contexts/DiaryMachinesCopyZContext";
import tabProps from "../../../../Shared/Tabs/TabProps";
import { TabPanel } from "../../../../Shared/Tabs/TabPanel";
import standardFilterOptions from "../../../../Shared/Grids/StandardFilterOptions";
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import WealthForDiaryDialog from "../../../Wealth/WealthForDiaryDialog";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface MachinesWidgetSceneProps {
    buildingDiaryRecordId: number | undefined;
    machinesNote?: string;
    machinesInput?: GetMachinesForRecordTableDTO[];
    noteChanged?: (t: string) => void;
    onChanged?: (d: GetMachinesForRecordTableDTO[]) => void;
    onChangeToSave?: () => void;
    onSaved?: () => void;
    saveChanged?: boolean;
    isLocked: boolean;
    isCopying: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    refresh?: boolean;
}

export default function MachinesWidgetScene(props: MachinesWidgetSceneProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, machinesInput, machinesNote, noteChanged, onChanged, saveChanged, isCopying, isLocked, isInvestor, isSubdodavatel, onChangeToSave, onSaved, refresh } = props;
    const [machines, setMachines] = useState<GetMachinesForRecordTableDTO[]>();
    const [machinesNoteLocal, setMachinesNoteLocal] = useState<string | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [copying, setCopying] = useState<boolean>(false);
    const [isWealthForDiaryDialogOpen, setIsWealthForDiaryDialogOpen] = useState<boolean>(false);

    const saving = useRef<boolean>(false);

    const { permissions, setRefreshDailyRecordsNeeded } = useDiaryTabsContext();
    const theme = useTheme();
    const { machinesStore } = useDiaryMachinesCopyStore();

    const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };
    const toolbarOptions: object[] = [
        { text: t('Add'), id: 'grid-component-machines_add', prefixIcon: 'e-add' },
        { text: t('Delete'), id: 'grid-component-machines_delete', prefixIcon: 'e-delete' },
        { text: t('save'), id: 'grid-component-machines_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-machines_cancel' },
        { text: t('loadFromSystem'), prefixIcon: 'e-expand', id: 'loadMachinesFromSystem' }];

    const toolbarOptionsSubdodavatel: object[] = [
        { text: t('Add'), id: 'grid-component-machines_add', prefixIcon: 'e-add' },
        { text: t('Delete'), id: 'grid-component-machines_delete', prefixIcon: 'e-delete' },
        { text: t('save'), id: 'grid-component-machines_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-machines_cancel' }];
    const toolbarOptionsNoDelete: object[] = [
        { text: t('Add'), id: 'grid-component-machines_add', prefixIcon: 'e-add' },
        { text: t('save'), id: 'grid-component-machines_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-machines_cancel' },
        { text: t('loadFromSystem'), prefixIcon: 'e-expand', id: 'loadMachinesFromSystem' }];

    const toolbarOptionsSubdodavatelNoDelete: object[] = [
        { text: t('Add'), id: 'grid-component-machines_add', prefixIcon: 'e-add' },
        { text: t('save'), id: 'grid-component-machines_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-machines_cancel' }];

    useEffect(() => {
        if (refresh === true)
            reloadData();
    }, [refresh]);

    function reloadData() {
        if (isCopying) {
            setMachines(machinesStore);
            saving.current = false;
        }
        else {
            if (buildingDiaryRecordId !== undefined)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetMachinesForRecord(buildingDiaryRecordId, false).then((d) => {
                    if (d !== undefined) {
                        setMachines(d);
                        if (grid.current)
                            grid.current!.dataSource = d;
                    }
                    saving.current = false;
                });
            else
                saving.current = false;
        }
    }
    useEffect(() => {
        if (isLocked) {
            if (document.getElementById('grid-component-machines_add'))
                document.getElementById('grid-component-machines_add')!.className += ' e-disabled';
            if (document.getElementById('grid-component-machines_delete'))
                document.getElementById('grid-component-machines_delete')!.className += ' e-disabled';
            if (document.getElementById('grid-component-machines_update'))
                document.getElementById('grid-component-machines_update')!.className += ' e-disabled';
            if (document.getElementById('grid-component-machines_cancel'))
                document.getElementById('grid-component-machines_cancel')!.className += ' e-disabled';
            if (document.getElementById('loadMachinesFromSystem'))
                document.getElementById('loadMachinesFromSystem')!.className += ' e-disabled';
        }
    }, [isLocked]);
    useEffect(() => {
        setMachines(machinesStore);
    }, [machinesStore]);
    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        setCopying(isCopying);
    }, [isCopying]);
    useEffect(() => {
        setMachinesNoteLocal(machinesNote);
    }, [machinesNote]);

    useEffect(() => {
        reloadData();
    }, [buildingDiaryRecordId]);

    useEffect(() => {
        if (expanded === true)
            reloadData();
    }, [expanded]);


    useEffect(() => {
        if (machinesInput)
            setMachines(machinesInput);
    }, [machinesInput]);
    const grid = useRef<GridComponent>(null);
    function handleEditAndSave(args: any): void {
        // console.log(args);

    }
    function beforeDeleteAsk(e: any) {
        // e.cancel = true;
    }
    function clickHandler(e: any) {
        let instance = (document.getElementById("grid-component-machines") as HTMLElement);
        if ((e.target as HTMLElement).classList.contains("e-rowcell")) {

            //@ts-ignore

            //@ts-ignore
            let index: number = parseInt((e.target as HTMLElement).parentElement.getAttribute("aria-rowindex"));
            console.log((e.target as HTMLElement));
            //@ts-ignore
            let colindex: number = parseInt((e.target as HTMLElement).getAttribute("data-colindex"));
            //@ts-ignore
            let field: string = instance.ej2_instances[0].getColumns()[colindex].field;
            // if (index === grid.current!.recordsCount - 1)
            //     delay(800).then(() => { grid.current!.editCell(index - 1, field); });
            // else 
            grid.current!.editCell(index - 1, field);


            // instance.ej2_instances[0].editModule.editCell((index - 1), field);
        };
    }
    const loaded = useRef(false);
    const singleClickLoad = (id: string): void => {
        //@ts-ignore
        let instance = (document.getElementById(id) as HTMLElement);
        if (instance) {
            if (!loaded.current) {
                instance.removeEventListener('mouseup', clickHandler);
                instance.addEventListener('mouseup', clickHandler);
                loaded.current = true;
            }
        }
    }
    function save(e: any) {
        e.cancel = true;
        if (saving.current) return;
        saving.current = true;
        if (!copying && buildingDiaryRecordId) {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new SaveMachinesTableReqDTO({ id: 0, name: add.name, code: add.code, isShared: add.isShared })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new SaveMachinesTableReqDTO({ id: chang.id, name: chang.name, code: chang.code, isShared: chang.isShared })
            });
            let deleted = permissions?.canDeleteInsideRecords === true ? e.batchChanges.deletedRecords.map((del: any) => {
                return new SaveMachinesTableReqDTO({ id: -del.id, name: del.name })
            }) : [];
            let b: SaveMachinesTableReqDTO[] = [...added, ...changed, ...deleted];
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveMachines(buildingDiaryRecordId, b).then((r) => {
                if (r) { reloadData(); setRefreshDailyRecordsNeeded(true); } else saving.current = false; if (onSaved) onSaved();
            })
            if (onChanged)
                onChanged(b);
        }
        else {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new GetMachinesForRecordTableDTO({ id: 0, name: add.name, code: add.code, isShared: add.isShared })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new GetMachinesForRecordTableDTO({ id: chang.id, name: chang.name, code: chang.code, isShared: chang.isShared })
            });
            let deleted = permissions?.canDeleteInsideRecords === true ? e.batchChanges.deletedRecords.map((del: any) => {
                return new GetMachinesForRecordTableDTO({ id: -del.id })
            }) : [];
            let b: GetMachinesForRecordTableDTO[] = [...added, ...changed, ...deleted];
            if (onChanged)
                onChanged(b);
            saving.current = false;
        }
    }

    const numericParams: IEditCell = {
        params: {
            decimals: 2,
            format: "N2",
            validateDecimalOnType: true,
            locale: i18n.language

        }
    };
    function setDefaultData(e: any) {
        e.defaultData.id = 0;
        e.defaultData.name = '';
        e.defaultData.code = '';
        e.defaultData.isShared = true;
        e.defaultData.adminCreatorName = '';

    }
    function toolBarClick(e: any) {
        if (isLocked) {
            e.cancel = true;
            return;
        }

        let id = e.item.properties.id;
        if (id === 'loadMachinesFromSystem')
            setIsWealthForDiaryDialogOpen(true);
    }

    return (
        <Box sx={{ marginX: '40px', background: 'white', borderRadius: '15px', overflow: 'hidden', minHeight: 500 }}>
            <Box >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => {
                        setSelectedTabIndex(n);
                    }} aria-label="basic tabs example">
                        <Tab label={t('table')} {...tabProps(0)} />
                        {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <GridComponent style={{ borderColor: 'transparent' }} id="grid-component-machines" allowSorting={true}
                        beforeBatchDelete={(e) => { beforeDeleteAsk(e) }}
                        beforeBatchAdd={(e) => { setIsGridEditting(true); setDefaultData(e); }}
                        beforeBatchSave={(e) => { save(e); setIsGridEditting(false); }}
                        // beginEdit={(e) => { console.log(e) }}
                        cellEdit={(e) => { setIsGridEditting(true); if (onChangeToSave) onChangeToSave(); }}
                        // dataSourceChanged={(e) => { grid.current!.refresh(); }}
                        allowPaging={true}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        ref={grid}
                        load={(e) => { singleClickLoad("grid-component-machines") }}
                        editSettings={editSettings}
                        toolbar={(permissions && permissions.canEditDiaryRecord && !isLocked) ? (isSubdodavatel === true ? (permissions.canDeleteInsideRecords ? toolbarOptionsSubdodavatel : toolbarOptionsSubdodavatelNoDelete) : (permissions.canDeleteInsideRecords ? toolbarOptions : toolbarOptionsNoDelete)) : undefined}
                        toolbarClick={toolBarClick}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language}
                        enablePersistence={false}
                        filterSettings={standardFilterOptions}
                        actionBegin={handleEditAndSave}
                        resizeSettings={{ mode: 'Normal' }}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={false}
                        dataSource={machines}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} showInColumnChooser={false} />
                            <ColumnDirective field='code' headerText={t('code')} maxWidth='150' width='150' minWidth='100' />
                            <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='400' minWidth='200' />
                            <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='30' />
                            {/* <ColumnDirective field='employeeFullName' headerText="xxx zodpovedny zamestnanec" allowEditing={false} maxWidth='800' width='300' minWidth='200' /> */}
                            <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='300' minWidth='200' />
                            {/* <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='300' minWidth='200' /> */}
                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                    </GridComponent>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div>
                        <RichTextEditorComponent actionBegin={(e) => { }} height={400} locale={i18n.language} value={machinesNoteLocal} change={(eve: ChangeEventArgs) => {
                            if (isSubdodavatel !== true) {
                                setMachinesNoteLocal(eve.value);
                                if (noteChanged)
                                    noteChanged(eve.value);
                            }
                        }} >
                            <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </div>
                </TabPanel>
            </Box>
            <WealthForDiaryDialog
                open={isWealthForDiaryDialogOpen}
                recordId={buildingDiaryRecordId}
                onClose={(wealthsForDiaryDialog) => {
                    if (wealthsForDiaryDialog) reloadData();
                    setIsWealthForDiaryDialogOpen(false);
                }}
            />
        </Box>
    )
}