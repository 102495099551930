import * as React from 'react';
import { useApi } from '../../../ApiOld/Api/useApi';
import { HintDTO, JokeDTO, SuperadminVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import AddJokeHint from './AddJokeHintDialog';
import LanguagePicker from '../LanguagePicker';
import { useTranslation } from 'react-i18next';

export interface IHintsAdminSceneProps {
}

export default function HintsAdminScene(props: IHintsAdminSceneProps) {
    const { authorizedApi } = useApi(SuperadminVovClient);
    const { i18n } = useTranslation();
    const [hints, setHints] = React.useState<HintDTO[]>([]);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [adding, setAdding] = React.useState<boolean>(false);
    const [lang, setLang] = React.useState<string>(i18n.language);
    const [selectedHint, setSelectedHint] = React.useState<HintDTO | null>(null);
    const refresh = React.useCallback(async () => {
        const response = await authorizedApi.hintsList();
        setHints(response.hints ?? []);
    }, [authorizedApi]);
    const save = React.useCallback(async () => {
        if (selectedHint) {
            await authorizedApi.updateHint({
                content: selectedHint.content,
                heading: selectedHint.heading,
                id: selectedHint.id,
                language: lang
            } as JokeDTO);
            refresh();
        }
    }, [authorizedApi, selectedHint, refresh, lang]);
    React.useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Box display='flex'>
            <AddJokeHint isJoke={false} open={adding} onClose={(e) => { setAdding(false); if (e) refresh(); }} />
            <Box p={1} sx={{ width: '30%' }}>
                <Button onClick={() => setAdding(true)} variant='contained' size='small'>Přidat</Button>
                {hints.map((hint) => {
                    return <Box m={1} display='flex' alignItems='center' onClick={() => { setSelectedHint(hint); setChanged(false); }}>
                        <Box>
                            {hint.heading}
                            <IconButton onClick={() => {
                                if (hint.id)
                                    authorizedApi.deleteHint(hint.id).then(() => {
                                        refresh();
                                    });
                            }}><DeleteOutline /></IconButton>
                        </Box>
                    </Box>
                })}
            </Box>
            <Box sx={{ width: '70%' }}>
                {selectedHint !== null && <Stack spacing={2} m={2}>
                    <LanguagePicker externalLang={selectedHint.language} showText notChangeLanguage onLanguageChange={(language) => { setLang(language) }} />
                    <TextField size='small' label='Nadpis' value={selectedHint.heading} onChange={(e) => {
                        setChanged(true);
                        setSelectedHint({ ...selectedHint, heading: e.target.value } as JokeDTO);
                        // setHints(p => p.map((joke) => {
                        //     if (joke.id === selectedJoke.id) {
                        //         return { ...selectedJoke, heading: e.target.value } as JokeDTO;
                        //     }
                        //     else return joke;
                        // }))
                    }} />
                    <TextField size='small' label='Popis' value={selectedHint.content} multiline minRows={2} maxRows={10} onChange={(e) => {
                        setChanged(true);
                        setSelectedHint({ ...selectedHint, content: e.target.value } as JokeDTO);
                        // setHints(p => p.map((joke) => {
                        //     if (joke.id === selectedJoke.id) {
                        //         return { ...selectedJoke, content: e.target.value } as JokeDTO;
                        //     }
                        //     else return joke;
                        // }))
                    }} />
                    <Button onClick={save} disabled={!changed} variant='contained'>Save</Button>
                </Stack>}
            </Box>
        </Box>
    );
}
