const saveBlob = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none";
    return function (blob: any, fileName: any) {
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());
export default saveBlob;

