import { Box } from '@mui/material';
import * as React from 'react';
import { Outlet } from 'react-router';

export interface IDiaryLayoutProps {
}

export default function DiaryLayout(props: IDiaryLayoutProps) {
    return (
        <Box>
            <Outlet />
        </Box>
    );
}
