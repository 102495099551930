import { Box, Button, Checkbox, CircularProgress, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyCutPasteType, DeleteImagesBuildingRequest, FileDirectoryDTO, FileParameter, GetImagesByIdForBuildingZipRequest, ImageCategoryDTO, ImageDiaryDTO, RequestsDTO, SetAllImagesShowToInvestorRequest, SetImagesShowToInvestorRequest, VovClient } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import './ProjectDocumentTreeScene.css'
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { Add, AddAlarm, AutoFixHighOutlined, BubbleChart, CloudUploadOutlined, Delete, DoneAll, Download, Folder, FormatListBulleted, RemoveDone, Tv } from '@mui/icons-material';
import ImageWindowScroller from '../../../Investor/Components/ImageWindowScrollerScene';
import { useApi } from '../../../../ApiOld/Api/useApi';
import ProjectPhotoAutocompleteSearchBar from './SearchBars/ProjectPhotoAutocompleteSearchBar';
import ProjectPhotosListScene from './ProjectPhotosListScene';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import { FileUpload } from '../../DiaryComponents/Widgets/ChoosePhotoDialog';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import ImageGallery from '../../../Shared/FileViewers/ImageGallery';
import ProjectPhotosTilesScene from './ProjectPhotosTilesScene';
import { UploadFileDC, useUploadFileStore } from '../../../../Contexts/UploadFileContext';
import { ContainsFolder } from '../../../Shared/Functions/ContainsFolder';
import saveBlob from '../../../Shared/Files/SaveBlob';
import MoveToCategoryDialog from '../Dialogs/MoveToCategoryDialog';
import AutoCompleteDiaryDialog from '../../GptChat/AutoCompleteDiaryDialog';
import MessageDialog from '../../../Shared/MessageDialog';

interface ProjectPhotosDetailSceneProps {
    buildingId: number;
    images: ImageDiaryDTO[];
    category: ImageCategoryDTO | undefined;
    categories: ImageCategoryDTO[];
    onSelect: (data: any) => void;
    setImages: (data: ImageDiaryDTO[]) => void;
    onNewRequest: (value: ImageDiaryDTO) => void;
    onRefresh?: () => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProjectPhotosDetailScene(props: ProjectPhotosDetailSceneProps) {
    const { buildingId, onSelect, images, category, setImages, onNewRequest, onRefresh, loading, categories, setLoading } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>();
    const [selectedPhotosIds, setSelectedPhotosIds] = useState<number[]>([]);
    const [viewList, setViewList] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);
    const [messageOpen, setMessageOpen] = useState<boolean>(false);

    const { authorizedApi } = useApi(VovClient);
    const inputFile = React.useRef<HTMLInputElement>(null);

    const [imagesLocal, setImagesLocal] = useState<ImageDiaryDTO[]>([]);

    const { uploadingFiles, addFile, uploadPhotos } = useUploadFileStore();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        setClearSearch(true);
        setTimeout(() => {
            setClearSearch(false);
        }, 100);

        setSelectAll(false);
    }, [category]);

    useEffect(() => {
        if (!uploadingFiles || uploadingFiles.length === 0 /* || !uploadingFiles.some(p => p.documentId === category?.id && !p.isDone) */) {
            if (onRefresh) onRefresh();
        }
    }, [uploadingFiles]);

    useEffect(() => {
        setSelectedPhotosIds([]);
        setResetSelect(true);
    }, [category]);

    function onDelete() {
        if (selectedPhotosIds && selectedPhotosIds.length > 0)
            authorizedApi.deleteImagesBuilding(new DeleteImagesBuildingRequest({ imagesId: selectedPhotosIds })).then(() => {
                setImages(images.filter(x => !selectedPhotosIds.includes(x.id!)));
                setDeleteDialogOpen(false);
            });
    }

    const getCanDelete = (): boolean => {
        if (!permissions) return false;
        return permissions.buildingsEdit!;
    }

    const getImages = useCallback(async () => {
        if (category !== undefined) {
            if (category.fromDiary) {
                setLoading(true);
                authorizedApi.getImageUrlsForDiary(category.id!, false).then((r) => {
                    setImagesLocal(r.images!);
                    setLoading(false);
                });
            }
            else
                setImagesLocal(category.id ? images.filter(x => x.categoryId === category.id) : images.filter(x => !x.categoryId));
        }
        else
            setImagesLocal(images.filter(x => !x.categoryId));
    }, [images, category, authorizedApi, setLoading]);

    useEffect(() => {
        getImages();
    }, [category, images]);

    async function upload(files: FileList) {
        if (buildingId) {
            let newUps: FileUpload[] = [];
            for (let i = 0; i < files.length; i++) {
                addFile(new UploadFileDC(files[i], false, false, buildingId, true, '', category ? category.id : undefined));
            }
            uploadPhotos();

        }
    }

    function handleNewRequestClick(value: ImageDiaryDTO, buildingId?: number | undefined): void {
        setSelectedImageIndex(undefined);
        if (onNewRequest) {
            onNewRequest(value);
        }
    }

    const [uploading, setUploading] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState(false);
    const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback(
        async (event: React.DragEvent<HTMLDivElement>) => {
            console.log(category);
            event.preventDefault();
            event.stopPropagation();
            setIsDragging(false);

            const { files, types } = event.dataTransfer;
            if (files.length > 0) {
                let hasFolder = ContainsFolder(event);

                if (hasFolder) {
                    return;
                }
                else {
                    for (let i = 0; i < files.length; i++) {
                        addFile(new UploadFileDC(files[i], false, false, buildingId, true, '', category ? category.id : undefined));
                    }
                    uploadPhotos();
                }
            }
        },
        [buildingId, category]
    );

    const [clearSearch, setClearSearch] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [resetSelect, setResetSelect] = useState<boolean>(false);

    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [openDiaryCompleteDialog, setOpenDiaryCompleteDialog] = useState<boolean>(false);
    if (images)
        return <Grid item xs={12} lg={12}>
            <AutoCompleteDiaryDialog buildingId={buildingId} open={openDiaryCompleteDialog} onClosed={() => { setOpenDiaryCompleteDialog(false) }}></AutoCompleteDiaryDialog>
            <Typography sx={{ marginLeft: '24px', marginBottom: '-16px', fontWeight: 'bold' }}>{category?.name ? (category.name === 'default' ? t('buildings.notAssignedPhotos') : category.name) : '...'}</Typography>
            <Box style={{ margin: '16px', padding: '8px', border: '1px solid lightgray', borderRadius: '10px' }}>
                <Grid container spacing={2}>
                    {!category?.fromDiary && <Grid item xs={12} lg={12}
                        sx={{
                            border: isDragging ? '2px dashed' : '1px dashed',
                            borderColor: isDragging ? theme.palette.text.primary : 'black',
                            cursor: 'pointer',
                            height: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px',
                            paddingTop: '10px',
                            marginTop: '24px',
                            marginLeft: '16px'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (inputFile.current) {
                                inputFile.current.click();
                            }
                        }}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={(e) => handleDrop(e)}
                    >
                        <CloudUploadOutlined sx={{ width: '20px', height: '20px', marginRight: '8px' }} />
                        <div>{t('ClickHereOrDragHere')}</div>
                    </Grid>}
                    <Grid item xs={12} md={6} lg={6}>
                        {!viewList && <ProjectPhotoAutocompleteSearchBar clear={clearSearch} list={imagesLocal} onSelected={(e) => { setSelectedPhotosIds(e ? [e.id!] : []) }} />}
                    </Grid>
                    {!category?.fromDiary && <Grid item xs={'auto'} md={2} lg={2} style={{ display: 'flex', justifyContent: 'right', margin: '8px', marginTop: '12px', gap: 8 }}>
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            authorizedApi.setAllImagesShowToInvestor(new SetAllImagesShowToInvestorRequest({ buildingId: buildingId, showToInvestor: true, categoryId: category ? (category.id ? category.id : 0) : undefined })).then(() => {
                                if (onRefresh) onRefresh();
                            });
                        }}>
                            <Tooltip title={t('buildings.showAllToInvestors')}>
                                <DoneAll color={/* viewList ?  */'primary' /* : undefined */} />
                            </Tooltip>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            authorizedApi.setAllImagesShowToInvestor(new SetAllImagesShowToInvestorRequest({ buildingId: buildingId, showToInvestor: false, categoryId: category ? (category.id ? category.id : 0) : undefined })).then(() => {
                                if (onRefresh) onRefresh();
                            });
                        }}>
                            <Tooltip title={t('buildings.unshowAllToInvestors')}>
                                <RemoveDone color={/* viewList ? */ 'primary' /* : undefined */} />
                            </Tooltip>
                        </div>
                        <div style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (buildingId !== undefined) {
                                setDownloading(true);
                                authorizedApi.getImagesByIdForBuildingZip(new GetImagesByIdForBuildingZipRequest({ buildingId: buildingId, imageIds: selectedPhotosIds, apiAdress: '' })).then(() => {
                                }).catch((e) => {
                                    console.log(e);
                                });
                                setDownloading(false);
                                setMessageOpen(true);
                            }
                        }}>
                            {!downloading && <Tooltip title={t('downloadSelected')}>
                                <Download color={/* viewList ? */ /* 'primary' */ undefined} />
                            </Tooltip>}
                            {downloading && <CircularProgress size='24px' />}
                        </div>
                    </Grid>}
                    <Grid item xs={'auto'} md={3} lg={3} style={{ display: 'flex', justifyContent: 'right', margin: '8px', marginTop: '8px', gap: 8 }}>
                        {!uploading && !category?.fromDiary && <div style={{ cursor: 'pointer' }} onClick={() => { if (inputFile && inputFile.current) inputFile.current.click() }}>
                            <Add />
                        </div>}
                        {uploading && !category?.fromDiary && <div>
                            <CircularProgress />
                        </div>}
                        {!category?.fromDiary && <div style={{
                            cursor: !selectedPhotosIds || selectedPhotosIds.length === 0 ? 'not-allowed' : 'pointer',
                            opacity: !selectedPhotosIds || selectedPhotosIds.length === 0 ? 0.5 : 1,
                            pointerEvents: !selectedPhotosIds || selectedPhotosIds.length === 0 ? 'none' : 'auto',
                            marginRight: '0px'
                        }} onClick={() => { if (selectedPhotosIds && selectedPhotosIds.length > 0) setDeleteDialogOpen(true); }}>
                            <Tooltip title={t('Delete')}>
                                <Delete />
                            </Tooltip>
                        </div>}
                        {!category?.fromDiary && <IconButton sx={{ mt: -1 }} onClick={() => setOpenDiaryCompleteDialog(true)}>
                            <AutoFixHighOutlined color={undefined} />
                        </IconButton>}
                        <div style={{ cursor: 'pointer' }} onClick={() => { setViewList(true); }}>
                            <Tooltip title={t('files.list')}>
                                <FormatListBulleted color={viewList ? 'primary' : undefined} />
                            </Tooltip>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => { setViewList(false); }}>
                            <Tooltip title={t('files.tiles')}>
                                <Tv color={viewList ? undefined : 'primary'} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                {!viewList && <div style={{ overflow: 'auto' }}>
                    {imagesLocal !== undefined && <ProjectPhotosTilesScene category={category} onCategoryChange={() => setMoveDialogOpen(true)} allSelect={selectAll} setAllSelect={(c) => setSelectAll(c)} loading={loading} setImages={setImages}
                        buildingId={buildingId!} onDelete={(file) => {
                            if (file.id)
                                authorizedApi.deleteImagesBuilding(new DeleteImagesBuildingRequest({ imagesId: [file.id!] })).then(() => {
                                    setImages(images.filter(x => x.fileName !== file.fileName));
                                    setDeleteDialogOpen(false);
                                });
                        }} onSelect={(e) => { setSelectedPhotosIds(e ?? []); }} selectedImagesIds={selectedPhotosIds} canDelete={getCanDelete()} images={imagesLocal} onImageClick={(index) => setSelectedImageIndex(index)}
                        onNameChange={(name, id) => {
                            if (onRefresh) onRefresh();
                        }}
                        onShowToInvestorChange={(image, checked) => {
                            authorizedApi.setImagesShowToInvestor(new SetImagesShowToInvestorRequest({ imageId: image.id, showToInvestor: checked }));
                            setImages(images.map(x => x.id === image.id ? new ImageDiaryDTO({ ...x, showToInvestor: checked }) : x));
                        }}></ProjectPhotosTilesScene>}
                </div>}
                {viewList && <div>
                    {imagesLocal !== undefined && <ProjectPhotosListScene category={category} onCategoryChange={() => setMoveDialogOpen(true)} loading={loading} buildingId={buildingId!} images={imagesLocal} onSelect={(e) => { setSelectedPhotosIds(e ?? []); }} selectedImagesIds={selectedPhotosIds} setImages={setImages} onImageDoubleClick={(index) => setSelectedImageIndex(index)} onNameChange={(name, id) => {
                        if (onRefresh) onRefresh();
                    }}></ProjectPhotosListScene>}
                </div>}

                <DeleteDialog data={selectedPhotosIds} onDeleteAgree={onDelete} onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} />
                <input
                    multiple
                    accept="image/png, image/jpeg, image/jfif"
                    onChange={(e) => {
                        if (e.target && e.target.files)
                            upload(e.target.files);

                    }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
            </Box>

            <ImageGallery<ImageDiaryDTO>
                canSaveChanged={false}
                canCreateTask
                files={imagesLocal}
                selected={selectedImageIndex}
                open={selectedImageIndex !== undefined}
                buildingId={buildingId}
                onClose={() => { setSelectedImageIndex(undefined); }}
                newRequestButton={true}
                onNewRequest={handleNewRequestClick}
            />

            <MoveToCategoryDialog open={moveDialogOpen} onClose={() => { setMoveDialogOpen(false) }} imageIds={selectedPhotosIds} categories={categories} onConfirm={() => { if (onRefresh) onRefresh() }} />
            <MessageDialog onClose={() => setMessageOpen(false)} text={t('downloadImagesZipText')} title={t('downloadImagesZipHeader')} open={messageOpen} />
        </Grid>
    else
        return <div></div>;
}
