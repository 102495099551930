import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import { useTranslation } from 'react-i18next';
import { GetWarehouseDataDetailResponse } from '../../../ApiOld/Api/ApiServerVov';
import BuildingSelect from '../../Shared/BuildingSelect';

interface WarehouseMainDetailProps {
  warehouseData: Partial<GetWarehouseDataDetailResponse>;
  setWarehouseData: (data: Partial<GetWarehouseDataDetailResponse> | null) => void;
}

export default function WarehouseMainDetail(props: Readonly<WarehouseMainDetailProps>) {
  const { warehouseData, setWarehouseData } = props;
  const { t } = useTranslation();

  return (
    <Box>
      <ValidationGroup>
        <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0px', marginTop: '16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12} p={0}>
                <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                  <TextField label={t('name')} variant="outlined" fullWidth size='small'
                    name='name'
                    value={warehouseData.name ?? ''}
                    onChange={(e) => { setWarehouseData({ ...warehouseData, name: e.target.value }) }}
                    sx={{ my: 1 }}
                  />
                </Validate>
              </Grid>
              <Grid container spacing={1} sx={{ ml: 2 }}>
                <Grid item xs={12} lg={4}>
                  <TextField sx={{ lineHeight: '1rem', my: 1 }} value={Number(warehouseData.central)} onChange={(e) => { setWarehouseData({ ...warehouseData, central: Boolean(e.target.value) }) }} variant="outlined" fullWidth label={t('warehouses.setAsCentralWarehouse')} select size='small' >
                    <MenuItem sx={{ height: '20px' }} value={1}>{t('yesL')}</MenuItem>
                    <MenuItem sx={{ height: '20px' }} value={0}>{t('noL')}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField sx={{ lineHeight: '1rem', my: 1 }} value={Number(warehouseData.inMenu)} onChange={(e) => { setWarehouseData({ ...warehouseData, inMenu: Boolean(e.target.value) }) }} variant="outlined" fullWidth label={t('warehouses.showWarehouseInMainMenu')} select size='small' >
                    <MenuItem sx={{ height: '20px' }} value={1}>{t('yesL')}</MenuItem>
                    <MenuItem sx={{ height: '20px' }} value={0}>{t('noL')}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField sx={{ lineHeight: '1rem', my: 1 }} value={Number(warehouseData.isActive)} onChange={(e) => { setWarehouseData({ ...warehouseData, isActive: Boolean(e.target.value) }) }} variant="outlined" fullWidth label={t('active')} select size='small' >
                    <MenuItem sx={{ height: '20px' }} value={1}>{t('yesL')}</MenuItem>
                    <MenuItem sx={{ height: '20px' }} value={0}>{t('noL')}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ ml: 2 }}>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ my: 1 }}>
                    <BuildingSelect
                      label={t('Projekt')}
                      selectedProjectId={warehouseData.buildingId}
                      onChange={(e) => { setWarehouseData({ ...warehouseData, buildingId: e ? e.id : undefined }) }} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ValidationGroup>
    </Box>
  )
}
