import { AddAPhoto, Photo, PhotoCamera } from '@mui/icons-material';
import React, { useEffect, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, Typography, useTheme } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import withLoading, { WithLoadingProps } from '../../../Shared/Loader';
import { DeleteImagesBuildingRequest, ImageCategoryDTO, ImageDiaryDTO, RequestsDTO, VovClient } from '../../../../ApiOld/ApiServerVov';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import ImageWindowScroller from '../../../Investor/Components/ImageWindowScrollerScene';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { useApi } from '../../../../ApiOld/Api/useApi';
import ProjectPhotosCategoryScene from './ProjectPhotosCategoryScene';
import ProjectPhotosDetailScene from './ProjectPhotosDetailScene';
import CreateRequestFromImageBuildingDialog from '../Dialogs/CreateRequestFromImageBuildingDialog';
import { set } from 'date-fns';

interface ProjectPhotosSceneProps extends WithLoadingProps {
    buildingId: number,
    buildingName: string
}
function ProjectPhotosScene(props: ProjectPhotosSceneProps) {
    const { buildingId, buildingName, fireLoading, stopLoading } = props;
    const [images, setImages] = React.useState<ImageDiaryDTO[]>([]);
    const [categories, setCategories] = React.useState<ImageCategoryDTO[]>([]);
    const [selectedCategory, setSelectedCategory] = React.useState<ImageCategoryDTO | null>();
    const [recordId, setRecordId] = React.useState<number | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { t, i18n } = useTranslation();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        refreshImages()
    }, []);

    useEffect(() => {
        refreshImages();
    }, [buildingId]);

    function setDefaultState() {
        if (buildingId !== undefined)
            setRecordId(buildingId);
        setImages([]);
    }
    function refreshImages() {
        setDefaultState();
        //fireLoading!();
        setLoading(true);
        new APIVovHelper().GetImagesBuilding(buildingId === undefined ? 0 : buildingId).then((data) => {
            console.log(data);
            if (data)
                setImages(data.images!);

            setCategories([new ImageCategoryDTO({ name: t('buildings.notAssignedPhotos'), id: undefined }), ...data.categories!]);
            stopLoading!();
            setLoading(false);

        }).catch(() => { stopLoading!(); console.log('err'); setLoading(false); });
    }

    const getCanDelete = (): boolean => {
        if (!permissions) return false;
        return permissions.buildingsEdit!;
    }

    const [todoImage, setTodoImage] = React.useState<ImageDiaryDTO | undefined>(undefined);

    return (
        <div style={{ maxWidth: biggerThan700 ? '100%' : '600px', margin: '12px', marginTop: '32px', }}>
            <Grid container spacing={3}>
                <Grid item lg={4} xs={12}>
                    <ProjectPhotosCategoryScene buildingName={buildingName} buildingId={buildingId} onSelect={(data) => { setSelectedCategory(data); }} categories={categories} onRefresh={() => refreshImages()} />
                </Grid>
                <Grid item lg={8} xs={12}>
                    <ProjectPhotosDetailScene categories={categories} loading={loading} setLoading={setLoading} category={selectedCategory ? selectedCategory : undefined} images={images} buildingId={buildingId!} onSelect={() => { }} setImages={setImages} onNewRequest={(image) => {
                        setTodoImage(image);
                    }} onRefresh={() => refreshImages()} />
                </Grid>
            </Grid>


            {todoImage && <CreateRequestFromImageBuildingDialog imageUrl={todoImage.url}
                selectedBuildingId={buildingId} onRefresh={() => { }}
                todo={new RequestsDTO()} open={todoImage !== undefined} selectedValue={t('singleTodo.newTask')} onClose={() => { setTodoImage(undefined) }} />}
        </div>
    );
}
export default withLoading(ProjectPhotosScene);