
import React, { useEffect } from 'react';
import LoaderComponent from './LoaderComponent';
import { useTranslation } from 'react-i18next';
import { use } from 'i18next';
import { useParams } from 'react-router-dom';

export interface WithLoadingProps {
    fireLoading?: () => void;
    stopLoading?: () => void;
}


const withLoading = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> => ({
    fireLoading,
    stopLoading,
    ...props
}: WithLoadingProps) => {
    const [loading, setLoading] = React.useState(true);
    const { i18n } = useTranslation();
    const params = useParams();
    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
    }, [i18n.language, i18n, params]);
    return <div>
        <div style={{ display: loading ? 'block' : 'none' }}>
            <div style={{ opacity: '0.8', background: '#000', width: '100%', height: '100%', zIndex: '10', top: '0', left: '0', position: 'fixed' }}>
                <LoaderComponent />
            </div>
        </div>
        <Component fireLoading={() => { if (loading === false) setLoading(true) }} stopLoading={() => { setLoading(false) }} {...props as P} />
    </div >
    // return loading ?
    //     <div>
    //         <div style={{ display: loading ? 'block' : 'none' }}>
    //             <img src={heml} alt='loading...'></img>
    //             <CircularProgress />
    //         </div>
    //         <Component fireLoading={() => { setLoading(true) }} stopLoading={() => { setLoading(false) }} {...props as P} />
    //     </div>
    //     : <Component fireLoading={() => { setLoading(true) }} stopLoading={() => { setLoading(false) }} {...props as P} />;
}

export default withLoading;