import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BankInputProps {
    label: string;
    value1?: string;
    value2?: string;
    onValue1Change: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onValue2Change: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const BankInput: React.FC<BankInputProps> = ({
    label,
    value1,
    value2,
    onValue1Change,
    onValue2Change,
    disabled
}) => {
    return (
        <Box display="flex" alignItems="center">
            <TextField
                disabled={disabled}
                label={label}
                variant="outlined"
                size="small"
                value={value1}
                onChange={onValue1Change}
                sx={{ mr: 1 }}
                fullWidth
            />
            <Typography variant="body1" sx={{ mx: 1 }}>
                /
            </Typography>
            <TextField
                disabled={disabled}
                variant="outlined"
                size="small"
                value={value2}
                onChange={onValue2Change}
                sx={{ ml: 1 }}
                fullWidth
            />
        </Box>
    );
};

export default BankInput;
