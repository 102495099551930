import { Tooltip, Grid, Typography, Checkbox, Box } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WindowScroller } from 'react-virtualized';
import { ConversationFileDto } from '../../../ApiOld/ApiServerVov';
import { useRequestsPermissionContext } from '../../../Contexts/Permissions/RequestsPermissionContext';
import i18n from '../../../Localization/i18nInitializer';
import { Flaky } from '@mui/icons-material';
import { GetFileIcon } from '../../Shared/Files/GetFileIcon';
import { IsImage } from '../../Shared/Files/IsImage';

interface TodoAttachmentTilesSceneProps {
    files: ConversationFileDto[];
    selectedIds: number[];
    allSelect: boolean;

    onSelect: (ids: number[] | undefined) => void;
    onImageClick: (index: number | undefined) => void;
    setAllSelect: (value: boolean) => void;
}

export default function TodoAttachmentTilesScene(props: TodoAttachmentTilesSceneProps) {
    const { files, selectedIds, allSelect, onSelect, onImageClick, setAllSelect } = props;
    const selectedIdsSet = useMemo(() => new Set(selectedIds), [selectedIds]);

    const { t } = useTranslation();
    const { permissions, reloadPermissions } = useRequestsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const [disableOuterTooltip, setDisableOuterTooltip] = useState(false);

    const handleSelect = useCallback(
        (imageId: number, checked: boolean) => {
            if (checked) {
                onSelect([...Array.from(selectedIdsSet), imageId]);
            } else {
                onSelect(selectedIds.filter(id => id !== imageId));
            }
        },
        [onSelect, selectedIds]
    );

    const renderTile = useCallback(
        (file: ConversationFileDto, index: number) => {
            return (
                <Grid
                    item
                    key={'key' + file.id}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {/* Outer Tooltip for image.name */}
                    <Tooltip
                        title={disableOuterTooltip ? '' : file.name} // Disable tooltip dynamically
                        placement="top"
                        arrow
                    >
                        <div
                            className="parent"
                            id={'image_' + file.id}
                            style={{
                                padding: '8px',
                                position: 'relative',
                                background: selectedIdsSet.has(file.id!) ? 'lightgray' : 'white',
                                borderRadius: '10px',
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                color="primary"
                                sx={{ position: 'absolute', top: '8px', left: '5px' }}
                                checked={selectedIdsSet.has(file.id!)}
                                onChange={(e, checked) => handleSelect(file.id!, checked)}
                            />
                            <Grid sx={{ padding: IsImage(file.fileName ?? '') ? '32px' : '16px' }}>
                                {
                                    !IsImage(file.fileName ?? '') && GetFileIcon(file.fileName ?? '', file.id + '')
                                }
                                {
                                    IsImage(file.fileName ?? '') && <img
                                        onClick={() => onImageClick && onImageClick(index)}
                                        src={file.fileThumbnailUrl ?? file.fileUrl}
                                        height={130}
                                        width={140}
                                        loading="lazy"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            borderRadius: '10px',
                                            border: '1px solid lightgray',
                                            cursor: 'pointer',
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid container spacing={1} style={{ padding: '4px' }}>
                                <Grid item xs={6}>
                                    <Typography variant="caption">
                                        {file.created ? file.created.toLocaleDateString(i18n.language) : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">{file.creator ?? ''}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Tooltip>
                </Grid>
            );
        },
        [disableOuterTooltip, t, selectedIdsSet, handleSelect]
    );



    return (
        <div style={{ maxHeight: '600px', width: '100%' }}>
            <div style={{ color: allSelect ? '#FFC600' : 'black', margin: '4px', marginLeft: '16px', cursor: 'pointer' }} onClick={() => {
                props.onSelect(!allSelect ? files.map(x => x.id!) : []);
                setAllSelect(!allSelect);
            }}><Box><Flaky color='inherit' />{t('selectUnselectAll')}</Box>
            </div>
            <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop, registerChild, width }) => (
                    //@ts-ignore
                    <div ref={registerChild}>
                        <Grid container spacing={2} style={{ padding: '16px' }}>
                            {files.map(renderTile)}
                        </Grid>
                    </div>
                )}
            </WindowScroller>

            {/* <Menu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (renameImage) {
                                setRenameOpen(true);
                            }
                        }}
                    >
                        {t('rename')}
                    </MenuItem>
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {t('buildings.moveToFolder')}
                    </MenuItem>
                </Menu> */}
        </div>
    );
}