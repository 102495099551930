import ProductCategoryDetail from './ProductCategoryDetail';
import ProductCategoriesList from './ProductCategoriesList';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface ProductCategoriesProps {
    type: 'list' | 'detail' | 'new';
}

export default function ProductCategories({ type }: Readonly<ProductCategoriesProps>) {
    const params = useParams();
    const [productCategoryId, setProductCategoryId] = useState<number | undefined>();

    useEffect(() => {
        if (params.productCategoryId) {
            setProductCategoryId(parseInt(params.productCategoryId));
        } else {
            setProductCategoryId(undefined);
        }
    }, [params]);

    return (
        <Box>
            {type === 'list' && <ProductCategoriesList />}
            {type === 'detail' && <ProductCategoryDetail productCategoryId={productCategoryId} />}
            {type === 'new' && <ProductCategoryDetail productCategoryId={undefined} />}
        </Box>
    )
}