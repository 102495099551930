import { StavarioModule } from "./StavarioModule";

export const getModuleName = (module: StavarioModule, biggerThan700: boolean) => {
    switch (module) {
        case StavarioModule.ProjectList:
            return 'Projekty';
        case StavarioModule.ProjectDetail:
            return 'ProjectDetail';
        case StavarioModule.DiaryRecordsList:
            return 'diary.dailyRecord';
        case StavarioModule.DiaryRecordDetail:
            return biggerThan700 ? 'DailyRecord' : '';
        case StavarioModule.DiariesList:
            return 'Diaries';
        case StavarioModule.DiaryDetail:
            return 'diary.diary';
        case StavarioModule.WealthList:
            return 'Majetek';
        case StavarioModule.WealthDetail:
            return 'WealthCard';
        case StavarioModule.WarehouseList:
            return 'warehouses.warehouses';
        case StavarioModule.ManufacturerList:
            return 'warehouses.manufacturers';
        case StavarioModule.ProductCategoryList:
            return 'warehouses.productCategories';
        case StavarioModule.ProductList:
            return 'warehouses.products';
        case StavarioModule.TodoList:
            return 'todo.tasks';
        case StavarioModule.TodoList:
            return 'employees';
        default:
            return '';
    }
}