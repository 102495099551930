import { AttachFile, Check, Clear, Close, CloseFullscreen, ColorLens, Delete, DoneAll, EventRepeat, Fullscreen, History, MoreHorizOutlined, Reply, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Popover, Select, Slider, styled, Switch, Tab, Tabs, TextareaAutosize, TextField, TextFieldProps, Tooltip, Typography, useTheme } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AssignedUserDTO, EmployeeDetailDTO } from '../../../../ApiOld/ApiServerVov';
import { groupBy } from '../../../Shared/GroupBy';

export interface EmployeeAdminUserVisibleSceneProps {
    employee: EmployeeDetailDTO | undefined;
    setEmployee: (value: EmployeeDetailDTO | undefined) => void;
}


export default function EmployeeAdminUserVisibleScene(props: EmployeeAdminUserVisibleSceneProps) {
    const { employee, setEmployee } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const [groupedUsers, setGroupedUsers] = useState<[string, AssignedUserDTO[]][]>([]);

    useEffect(() => {
        if (employee && employee.adminUserEmployees) {
            const data = groupBy(employee.adminUserEmployees, i => i.group === undefined ? "" : i.group);

            let tmp: any[] = [];
            for (const b in data) {
                tmp?.push([b, data[b]])
            }

            setGroupedUsers(tmp.sort((a, b) => {
                if (a[0] < b[0]) {
                    return -1;
                }
                if (a[0] > b[0]) {
                    return 1;
                }
                return 0;
            }));
        }
        else setGroupedUsers([]);
    }, [employee]);

    const checkAllAdminUser = useCallback((group: string | undefined, isAssigned: boolean) => {
        if (!employee) return;

        const updatedAssignedUsers: AssignedUserDTO[] = employee.adminUserEmployees!.map((p) =>
            p.group === group ? new AssignedUserDTO({ id: p.id, isAssigned: isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
        );
        setEmployee(new EmployeeDetailDTO({ ...employee, adminUserEmployees: updatedAssignedUsers }));
    }, [setEmployee, setGroupedUsers]);

    if (groupedUsers !== undefined) {
        return (
            <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <h4>{t('visibleForUsers')}</h4>
                {groupedUsers.map((adminUser, i) => {
                    return (<div key={i} style={{ boxShadow: '1px 3px 3px 3px lightgray', margin: '16px', padding: '8px' }}>
                        <div style={{ display: 'flex' }}>
                            <Typography sx={{ fontWeight: 'bold', margin: '8px', fontSize: '14px' }}>{adminUser[0].length > 0 ? adminUser[0] : t('users')}</Typography>
                            <div style={{ display: 'flex' }}>
                                <Tooltip title={t('chooseAll')} >
                                    <IconButton color="primary" onClick={(e) => { checkAllAdminUser(adminUser[0], true); }}>
                                        <DoneAll sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('unchooseAll')} >
                                    <IconButton color="primary" onClick={(e) => { checkAllAdminUser(adminUser[0], false); }}>
                                        <Clear sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                adminUser[1].map((item2, ii) => {
                                    return (<div key={ii} style={{ margin: '10px', marginLeft: '20px' }}> <FormControlLabel
                                        key={item2.id}
                                        control={
                                            <Checkbox
                                                id={item2.id?.toString()}
                                                checked={item2.isAssigned}
                                                onChange={(e) => {
                                                    if (!employee) return;

                                                    const updatedAssignedUsers: AssignedUserDTO[] = employee.adminUserEmployees!.map((p) =>
                                                        p.id === item2.id ? new AssignedUserDTO({ id: p.id, isAssigned: !p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                                    );
                                                    setEmployee(new EmployeeDetailDTO({ ...employee, adminUserEmployees: updatedAssignedUsers }));
                                                }}
                                            />
                                        }
                                        label={item2.name}
                                    /> </div>);
                                })
                            }
                        </div>
                    </div>);
                })}
            </Box>
        );
    }
    else return (<div></div>);
};
