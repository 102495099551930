import { ProjectDTO } from "../../Api/UsersMicroserviceClient";
import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BuildingDTO } from "../../ApiOld/ApiServerVov";
import { SolveProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";
import { APIVovSubcontractorHelper } from "../../ApiOld/ApiSubcontractorOldClient";

export interface BuildingSelectProps {
    selectedProjectId?: number | null;
    onChange: (event: BuildingDTO | null) => void;
    isMandatory?: boolean;
    isSubdodavatel?: boolean;
    subcontractorDiaryId?: number;
    label?: string;
    isReadOnly?: boolean;
}
export default function BuildingSelect(props: BuildingSelectProps) {
    const { onChange, selectedProjectId, isMandatory, isSubdodavatel, subcontractorDiaryId, label, isReadOnly } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedProject, setSelectedProject] = React.useState<BuildingDTO | null>(null);
    const [projects, setProjects] = React.useState<BuildingDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    const handleChange = (event: SelectChangeEvent<typeof selectedProject>) => {
        if (event.target.value !== undefined) {
            setSelectedProject(event.target.value as (ProjectDTO | null));

            onChange(event.target.value as (ProjectDTO | null));
        }

    };
    useEffect(() => {
        if (isSubdodavatel !== undefined && isSubdodavatel === true && subcontractorDiaryId !== undefined)
            new APIVovSubcontractorHelper().GetBuildingForSubcontractor(subcontractorDiaryId).then((res) => {
                setProjects(res)
            });
        else
            SolveProjectsContext(globalContext, setGlobal).then((res) => {
                setProjects(res)
            });

    }, []);
    useEffect(() => {
        if (projects !== undefined && projects !== null && projects.length > 0
            && selectedProjectId !== undefined &&
            selectedProjectId !== null && selectedProjectId > 0) {
            console.log('projects', projects);
            console.log('selectedProjectId', selectedProjectId);
            let q = projects.find(x => x.id === selectedProjectId);
            if (q !== undefined)
                setSelectedProject(q);
        }

    }, [projects, selectedProjectId]);
    return (
        <div>

            <Autocomplete

                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedProject(v);
                    onChange(v);
                }}
                readOnly={isReadOnly}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={selectedProject}
                options={projects ?? []}
                fullWidth
                renderInput={(params) => <TextField helperText={''} error={isMandatory === true && selectedProject === null}  {...params} label={label}
                />}
            />
            {selectedProject === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}