

import { Save, Close, CloudUploadOutlined, Upload } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { AddRemoveFileDiaryRecordDTO, FileDirectoryDTO, FileParameter, FileToTransferDTO, ImageDiaryDTO } from '../../../../ApiOld/ApiServerVov';

import BasicUpload from '../../../Shared/Files/BasicUploadComponent';
import delay from '../../../Shared/Functions/Delay';
import NoRights from '../../../Shared/Layouts/NoRights';
import '../Styles/ChoosePhotoDialog.css';
import ChooseProjectFiles from './ChooseProjectFiles';
import { useTranslation } from "react-i18next";
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';

interface ChooseFileDialogProps {
    recordId?: number;
    isUploading?: (open: boolean) => void;
    onUploaded?: (url: string) => void;
    onClose: (refresh: boolean) => void;
    open: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
}

export default function ChooseFileDialog(props: ChooseFileDialogProps) {
    const { recordId, isUploading, onUploaded, onClose, open, isInvestor, isSubdodavatel } = props;

    const [files, setFiles] = useState<FileDirectoryDTO[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    // const [filesChanged, setFilesChanged] = useState<FileDirectoryDTO[]>([]);
    let filesChanged = useRef<FileDirectoryDTO[]>([]);
    const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { permissions } = useDiaryTabsContext();
    const { t, i18n } = useTranslation();
    function reloadData() {
        if (recordId !== undefined) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor == undefined ? false : isInvestor).GetFilesFromProject(recordId).then((d) => {
                if (d)
                    setFiles(d);
            });
        }

    }
    useEffect(() => {
        if (open)
            reloadData();
    }, [open]);

    const handleClose = (val?: boolean | undefined) => {
        // if (!uploading)
        filesChanged.current = [];
        reloadData();
        if (val !== undefined)
            onClose(val);
        else onClose(refresh);
    };
    function saveProjectPhotosRels() {
        if (recordId && filesChanged.current.length > 0)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor == undefined ? false : isInvestor).addRemoveFileToDiaryRecord(recordId, filesChanged.current.map((file) => { return new AddRemoveFileDiaryRecordDTO({ id: file.id, isSelected: file.isSelected }) })).then((d) => {
                // console.log(d);
                // if (d) {
                // setRefresh(true);
                handleClose(true);
                // }

            });
    }
    return (
        <Dialog
            maxWidth='lg'
            fullWidth={isSubdodavatel !== true}
            open={open}
            onClose={() => { handleClose() }}
            style={isInvestor || isSubdodavatel ? { marginTop: "190px" } : {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('files.insertFiles')}
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                    <IconButton sx={{ marginRight: '-12px', marginTop: '-8px' }} onClick={(e) => handleClose(refresh)} >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>

                    {isSubdodavatel !== true && <Grid container spacing={6}>
                        <Grid item xs={biggerThan700 && ((permissions && permissions.directAttachments) || isInvestor) ? 4 : 1}>
                            {((permissions && permissions.directAttachments) || isInvestor) && <BasicUpload isSubdodavatel={isSubdodavatel} isInvestor={isInvestor === true} recordId={recordId} onlyImages={false} onNeedRefresh={() => { setRefresh(true) }} />}
                        </Grid>
                        {((permissions && permissions.attachments) || isInvestor) && <Grid item xs={biggerThan700 ? 8 : 11}>
                            {biggerThan700 && <Button size='small' variant='contained'
                                onClick={saveProjectPhotosRels}
                            >{t('save')}</Button>}
                            {!biggerThan700 && <Button sx={{ marginTop: '-50px', marginBottom: '8px' }} size='small' variant='contained'
                                onClick={saveProjectPhotosRels}
                            ><Save /></Button>}
                            <ChooseProjectFiles files={files} onFileSelected={(id, selected) => {
                                let file = filesChanged.current.find(f => f.id === id);
                                if (file !== undefined) {
                                    filesChanged.current = [...filesChanged.current.filter(f => f.id !== id)];
                                }
                                else {
                                    filesChanged.current = [...filesChanged.current, new FileDirectoryDTO({ id: id, isSelected: selected })];
                                }
                                // setFiles(filesCopy);
                            }} recordId={recordId} />
                        </Grid>}
                        {!isInvestor && (permissions === undefined || permissions?.attachments === undefined || permissions.attachments === false) && <NoRights title={t('noSelectFromProjectPermissions')} />}
                    </Grid>}
                    {isSubdodavatel === true && ((permissions && permissions.directAttachments) || isInvestor) && <div style={{ marginRight: '32px ' }}>
                        <BasicUpload isSubdodavatel={isSubdodavatel} isInvestor={isInvestor === true} recordId={recordId} onlyImages={false} onNeedRefresh={() => { setRefresh(true) }} />
                    </div>
                    }
                </div >
            </DialogContent>
        </Dialog >


    );

}