import { ApiKrosAdminOldBaseHelper } from "./ApiKrosAdminOldBaseHelper";
import { AdminApplicationDTO, AdminApplicationDataDTO, AdminUsersDTO, AuthKrosSuperAdminRequest, AuthKrosSuperAdminResponse, AuthSubcontractorRequest, DeleteApplicationKrosAdminRequest, DeleteUserKrosAdminRequest, GetApplicationDataKrosAdminResponse, GetApplicationPermissionsKrosAdminResponse, GetStatisticsKrosAdminResponse, KrosAdminApplicationPermissionDTO, ResetPasswordKrosAdminRequest, SaveApplicationDataKrosAdminRequest, SaveApplicationDataKrosAdminResponse, SaveUserDataKrosAdminRequest, SaveUserDataKrosAdminResponse, SetApplicationPermissionsKrosAdminRequest, SetApplicationPermissionsKrosAdminResponse, SetIsAppActiveKrosAdminRequest, SetIsAppPayingKrosAdminRequest, SetShowPayingKrosAdminRequest } from "./ApiServerVov";

export class APIVovKrosAdminHelper extends ApiKrosAdminOldBaseHelper {
    public async LoginKrosAdmin(userName: string, password: string): Promise<AuthKrosSuperAdminResponse> {
        let res = (await this.GetClientToAuth().authKrosSuperAdmin(new AuthKrosSuperAdminRequest({ username: userName, password: password })))!;

        localStorage.setItem('krosadmin_token_old', res.token!);
        /* localStorage.setItem('krosadmin_token_old_refresh', res.refreshToken!); */
        localStorage.setItem('krosadmin_token_old_expires', res.dateTimeExpires!.toString());
        return res;
    }

    public async GetAdminUsers(): Promise<AdminUsersDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getUsersKrosAdmin()).users!;
    }

    public async GetApplications(): Promise<AdminApplicationDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getApplicationsKrosAdmin()).applications!;
    }

    public async GetApplicationListKrosAdmin(): Promise<AdminApplicationDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getApplicationListKrosAdmin()).applications!;
    }

    public async GetUserData(userId: number): Promise<AdminUsersDTO> {
        return (await this.GetAdressedAuthRefreshableClient().getUserDataKrosAdmin(userId)).user!;
    }

    public async GetKrosAdminUserInfo(): Promise<AdminUsersDTO> {
        return (await this.GetAdressedAuthRefreshableClient().getKrosAdminUserInfo());
    }

    public async SaveUserData(userData: AdminUsersDTO): Promise<SaveUserDataKrosAdminResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveUserDataKrosAdmin(new SaveUserDataKrosAdminRequest({ user: new AdminUsersDTO({ id: userData.id!, name: userData.name, email: userData.email, application: 'kros', applicationId: userData.applicationId }) })));
    }

    public async DeleteUserKrosAdmin(userId: number): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().deleteUserKrosAdmin(new DeleteUserKrosAdminRequest({ userId: userId }))).ok!;
    }

    public async DeleteApplicationKrosAdmin(appId: number): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().deleteApplicationKrosAdmin(new DeleteApplicationKrosAdminRequest({ appId: appId }))).ok!;
    }

    public async ResetPasswordKrosAdmin(userId: number): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().resetPasswordKrosAdmin(new ResetPasswordKrosAdminRequest({ userId: userId }))).ok!;
    }

    public async SetIsAppActiveKrosAdmin(appId: number, active: boolean): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().setIsAppActiveKrosAdmin(new SetIsAppActiveKrosAdminRequest({ appId: appId, active: active }))).ok!;
    }

    public async SetIsAppPayingKrosAdmin(appId: number, paying: boolean): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().setIsAppPayingKrosAdmin(new SetIsAppPayingKrosAdminRequest({ appId: appId, paying: paying }))).ok!;
    }

    public async SetShowPayingKrosAdmin(appId: number, paying: boolean): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().setShowPayingKrosAdmin(new SetShowPayingKrosAdminRequest({ appId: appId, paying: paying }))).ok!;
    }

    public async GetApplicationDataKrosAdmin(appId: number): Promise<GetApplicationDataKrosAdminResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getApplicationDataKrosAdmin(appId));
    }

    public async SaveApplicationDataKrosAdmin(app: AdminApplicationDataDTO): Promise<SaveApplicationDataKrosAdminResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveApplicationDataKrosAdmin(new SaveApplicationDataKrosAdminRequest({ app: app })));
    }

    public async GetStatisticsKrosAdmin(): Promise<GetStatisticsKrosAdminResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getStatisticsKrosAdmin());
    }

    public async GetApplicationPermissionsKrosAdmin(appId: number): Promise<GetApplicationPermissionsKrosAdminResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getApplicationPermissionsKrosAdmin(appId));
    }

    public async SaveApplicationPermissionsKrosAdmin(appId: number, permissions: KrosAdminApplicationPermissionDTO): Promise<SetApplicationPermissionsKrosAdminResponse> {
        return (await this.GetAdressedAuthRefreshableClient().setApplicationPermissionsKrosAdmin(new SetApplicationPermissionsKrosAdminRequest({ applicationId: appId, permissions: permissions })));
    }

    /* public async RefreshKrosAdminToken(): Promise<boolean> {
        SingletonRefreshClientSubcontractorOld.setRefresh();
        let t = (await this.GetAdressedAuthRefreshableClient()
            .refreshSubcontractorToken(new RefreshTokenSubcontractorRequest(
                {
                    accessToken: localStorage.getItem('subcontractor_token_old')!,
                    refreshToken: localStorage.getItem('subcontractor_token_old_refresh')!
                }
            )));
        localStorage.setItem('subcontractor_token_old', t.accessToken!);
        localStorage.setItem('subcontractor_token_old_refresh', t.refreshToken!);
        localStorage.setItem('subcontractor_token_old_expires', t.expireAt!.toString());
        SingletonRefreshClientSubcontractorOld.completeRefresh();
        return true;
    } */

    /* public async LoginErp(token: string, adminUserId: number, domain: string): Promise<AuthOldErpSubcontractorResponse> {
        let t = (await this.GetClientToAuth().loginOldErp(new AuthOldErpSubcontractorRequest({ adminUserId: adminUserId, token: token })));
        localStorage.setItem('subcontractor_token_old', t.token!);
        localStorage.setItem('subcontractor_old_app_uri', domain);
        console.log(t);
        return t;
    } */
}