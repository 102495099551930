import React, { createContext, useCallback, useContext, useState, ReactNode } from 'react';
import { GetBuildingsPermissionsResponse, VovClient } from '../../ApiOld/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';

interface BuildingsPermissionContextProps {
    permissions: GetBuildingsPermissionsResponse | null;
    reloadPermissions: () => Promise<void>;
}

const BuildingsPermissionContext = createContext<BuildingsPermissionContextProps>({
    permissions: null,
    reloadPermissions: async () => { },
});

export const useBuildingsPermissionContext = () => useContext(BuildingsPermissionContext);


interface BuildingsPermissionProviderProps {
    children: ReactNode;
}

export const BuildingsPermissionProvider: React.FC<BuildingsPermissionProviderProps> = ({ children }) => {
    // const { permissions, reloadPermissions } = useReloadPermissions();
    const [permissions, setPermissions] = useState<GetBuildingsPermissionsResponse | null>(null);
    const { authorizedApi } = useApi(VovClient);
    const reloadPermissions = useCallback(async (): Promise<void> => {
        console.log('loading permissions');
        const permissions = await authorizedApi.getBuildingsPermissions();
        setPermissions(permissions);
        console.log('loaded permissions');
    }, [authorizedApi]);
    return (
        <BuildingsPermissionContext.Provider value={{ permissions, reloadPermissions }}>
            {children}
        </BuildingsPermissionContext.Provider>
    );
};