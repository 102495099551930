import { Check, Clear } from '@mui/icons-material';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FileParameter, GetSignatureForEmployeeResponse } from '../../../../ApiOld/ApiServerVov';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { APIVovEmployeeHelper } from '../../../../ApiOld/ApiOldEmployeeClient';
import QuestionDialog from '../../../Shared/QuestionDialog';

interface EmployeeSignatureSceneProps {
    employeeId: number;
    onSigned?: () => void;
    refresh?: boolean;
    onRefreshed?: () => void;
}

export default function EmployeeSignatureScene(props: EmployeeSignatureSceneProps) {
    const [signature, setSignature] = React.useState<GetSignatureForEmployeeResponse | null>(null);
    const [signatureChanged, setSignatureChanged] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const { employeeId, onSigned, refresh, onRefreshed } = props;

    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(1100));
    const signRef = useRef<SignatureComponent>(null);
    const { t, i18n } = useTranslation();
    function refreshSignatures() {
        if (employeeId !== undefined)
            new APIVovEmployeeHelper().GetSignatureForEmployee(employeeId).then((data) => {
                if (data !== undefined) {
                    setSignature(data);
                }

                if (onRefreshed)
                    onRefreshed();
            });
    }

    useEffect(() => {
        refreshSignatures();
    }, [employeeId]);

    useEffect(() => {
        if (refresh === true)
            refreshSignatures();
    }, [refresh]);

    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                <div style={{ margin: '6px', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: 'lightgray', padding: '8px', borderRadius: '6px' }}>
                    {(!signature || !signature.signature || !signature.signature.url) && <Typography sx={{ fontWeight: 'bold', fontSize: '13px', margin: '0px 20px' }}>{t('diary.yourSignature')}</Typography>}
                    {(!signature || !signature.signature || !signature.signature.url) && <SignatureComponent change={(e) => {
                        setSignatureChanged(true);
                    }} style={{
                        maxWidth: biggerThan700 ? '360px' : '300px', margin: '12px',
                        backgroundColor: '#F9F9F9'
                    }}
                        ref={signRef}>
                    </SignatureComponent>}
                    {(signature && signature.signature && signature.signature.url) && <div style={{
                        width: biggerThan700 ? '360px' : '300px',
                        height: '150px',
                        overflow: 'hidden'/* , transform: 'translateY(30%)', */
                    }}>
                        <img style={{
                            position: 'relative', width: biggerThan700 ? '360px' : '300px', backgroundColor: 'transparent'
                        }} height={150} src={signature.signature.url} />
                    </div>}
                    <div style={{ margin: '0px 10px' }}>
                        <Button sx={{ width: biggerThan700 ? '106px' : '32px', margin: '4px 12px' }} startIcon={<Clear />} variant='contained' size='small' onClick={(e) => {
                            setDeleteOpen(true);
                        }} disabled={(signRef && signRef.current && signRef.current.isEmpty()) === true}>{biggerThan700 && t('Cancel')}</Button>
                        <Button sx={{ width: biggerThan700 ? '186px' : '32px', margin: '4px 12px' }} disabled={!signatureChanged} startIcon={<Check />} color='success' variant='contained' size='small' onClick={(e) => {
                            setSignatureChanged(false);
                            var blob = signRef.current!.saveAsBlob();
                            let fileParameter: FileParameter = { data: blob, fileName: 'sign.png' };
                            new APIVovEmployeeHelper().SaveEmployeeSignature(employeeId, fileParameter).then((e) => {
                                if (e.ok === true) {
                                    refreshSignatures();
                                    if (onSigned)
                                        onSigned();
                                }
                            });
                        }}>{biggerThan700 && t('employee.saveSignature')}</Button>
                    </div>
                </div>

                <QuestionDialog onClose={(res) => {
                    if (res && employeeId) {
                        if (signRef && signRef.current) signRef.current!.clear();
                        setSignatureChanged(false);

                        new APIVovEmployeeHelper().DeleteEmployeeSignature(employeeId).then((d) => {
                            refreshSignatures();
                            if (onSigned)
                                onSigned();

                            setDeleteOpen(false);
                        });
                    }
                    else setDeleteOpen(false);
                }} text={t('employee.reallyDeleteSignature')} title={t('employee.deleteSignature')} open={deleteOpen} />
            </div>
        </div >
    );
}