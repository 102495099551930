import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react'
import ApplicationCustomFieldsDialog from './ApplicationCustomFieldsDialog';
import { useApi } from '../../ApiOld/Api/useApi';
import { CustomFieldWithValueDto, CustomFieldsVovClient, SaveBuildingCustomFieldValuesRequest } from '../../ApiOld/Api/ApiServerVov';
import CustomField from './CustomField';

interface BuildingCustomFieldsProps {
  buildingId: number;
  saveTriggered: boolean;
  settingsTriggered: boolean;
}

export default function BuildingCustomFields(props: Readonly<BuildingCustomFieldsProps>) {
  const { buildingId, saveTriggered, settingsTriggered } = props;
  const { authorizedApi } = useApi(CustomFieldsVovClient);
  const [customFields, setCustomFields] = React.useState<CustomFieldWithValueDto[]>([]); // CustomFieldDto[] ?? [
  const refresh = React.useCallback(async () => {
    const customFields = await authorizedApi.getCustomFieldsForBuilding(buildingId);
    if (customFields.customFields) {
      setCustomFields(customFields.customFields);
    }
  }, [authorizedApi, buildingId]);
  const save = React.useCallback(async () => {
    await authorizedApi.saveBuildingCustomFieldValues(new SaveBuildingCustomFieldValuesRequest({ buildingId: buildingId, customFieldValues: customFields.map((cf) => { return new CustomFieldWithValueDto({ customField: cf.customField, value: cf.value?.toString(), valueId: cf.valueId }) }) }));
  }, [authorizedApi, customFields, buildingId]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  useEffect(() => {
    if (saveTriggered) {
      save();
      // Perform save related actions
    }
  }, [saveTriggered, save]);
  useEffect(() => {
    if (settingsTriggered) {
      setSettingsOpen(true);
      // Perform save related actions
    }
  }, [settingsTriggered]);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  console.log("kastom fílds", customFields);

  return (
    <Box sx={{ mt: 4, minHeight: '20px', width: '100%' }}>

      <ApplicationCustomFieldsDialog open={settingsOpen} onClose={(needRefresh) => {
        setSettingsOpen(false);
        refresh();
      }} />

      <Grid container sx={{ width: '100%' }}>
        {customFields.map((customField, index) => {
          if (!customField.customField) return <div></div>;
          return <Grid item lg={4} md={6} xs={12} key={customField.customField?.id}>
            <Box m={2}>
              <CustomField customField={customField.customField} value={customField.value} onValueChange={(val) => {
                setCustomFields((prev) => {
                  return prev.map((cf) => {
                    if (cf.customField?.id === customField.customField?.id) {
                      cf.value = val;
                    }
                    return cf;
                  });
                });
              }} />
            </Box>
          </Grid>
        })}
      </Grid>
    </Box>
  )
}
