import { ProjectDTO } from "../../Api/UsersMicroserviceClient";
import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BuildingDTO, DiaryListDTO } from "../../ApiOld/ApiServerVov";
import { SolveProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";
import { APIVovSubcontractorHelper } from "../../ApiOld/ApiSubcontractorOldClient";
import { APIVovHelper } from "../../ApiOld/ApiOldClient";

export interface BuildingDiarySelectProps {
    selectedDiaryId?: number | null;
    buildingId: number;
    onChange: (event: DiaryListDTO | null) => void;
    isMandatory?: boolean;
}
export default function BuildingDiarySelect(props: BuildingDiarySelectProps) {
    const { onChange, selectedDiaryId, isMandatory, buildingId } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedDiary, setSelectedDiary] = React.useState<DiaryListDTO | null>(null);
    const [diaries, setDiaries] = React.useState<DiaryListDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    useEffect(() => {
        if (buildingId)
            new APIVovHelper().GetDiariesForBuilding(buildingId).then((res) => {
                setDiaries(res)
            });
        else setDiaries([])
    }, []);
    useEffect(() => {
        if (buildingId)
            new APIVovHelper().GetDiariesForBuilding(buildingId).then((res) => {
                setDiaries(res)
            });
        else setDiaries([])
    }, [buildingId]);
    useEffect(() => {
        if (diaries !== undefined && diaries !== null && diaries.length > 0
            && selectedDiaryId !== undefined && selectedDiary !== undefined &&
            selectedDiary !== null) {
            let q = diaries.find(x => x.id == selectedDiaryId);
            if (q !== undefined)
                setSelectedDiary(q);
        }

    }, [diaries, selectedDiaryId]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedDiary(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedDiary}
                options={diaries}

                fullWidth={biggerThan700}
                sx={{ minWidth: 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedDiary === null}  {...params} label={t('menu.constructionDiary')}

                />}
            />
            {selectedDiary === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}