import axios, { AxiosInstance } from "axios";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SingletonRefreshClient } from "../../Api/ApiBaseHelper";
import delay from "../../Components/Shared/Functions/Delay";
import { ApiOldBaseHelper } from "../ApiOldBaseHelper";
import { RefreshTokenRequest } from "./ApiServerVov";
import { ApiSubcontractorOldBaseHelper } from "../ApiSubcontractorOldBaseHelper";

export function useApi<T>(ApiClass: new (url: string, axios: AxiosInstance) => T) {
  const { i18n } = useTranslation(); // use your hook

  const GetApiAddress = useCallback((): string => {
    if (process.env.REACT_APP_VOV_API_URL === 'NOT')
      return 'https://localhost:7054';
    else return process.env.REACT_APP_VOV_API_URL!;
  }, []);
  const Unauthorized = useCallback((): T => {
    const axiosApiInstance = axios.create();

    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
      async config => {
        if (config.headers === undefined) config.headers = {};
        config.headers["Content-Type"] = 'application/json';
        config.headers["Accept"] = 'application/json';
        config.headers["accept-language"] = i18n.language;
        config.headers["TimeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return config;
      },
      error => {
        Promise.reject(error)
      });

    const apiInstance = new ApiClass(GetApiAddress(), axiosApiInstance);
    if (!apiInstance) {
      throw new Error("Failed to create Unauthorized API instance");
    }
    return apiInstance;
  }, [ApiClass, i18n.language, GetApiAddress]);
  const unauthorizedApi = useMemo(() => {
    let apiInstance = Unauthorized();
    return apiInstance;
  }, [Unauthorized]);
  const AuthorizedInvestor = useCallback((): T => {
    const axiosApiInstance = axios.create();

    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
      async config => {
        let token = localStorage.getItem('investor_token_old');
        if (config.headers === undefined) config.headers = {};
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Accept = 'application/json';
        config.headers['Content-Type'] = 'application/json';
        config.headers['accept-language'] = i18n.language;
        config.headers['TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return config;
      },
      error => {
        Promise.reject(error)
      });
    axiosApiInstance.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          if (error.response.headers['is-token-expired'] === 'true') {
            // if (!SingletonRefreshClientApi.isRefreshing) {
            await delay(600);
            originalRequest._retry = true;
            const tokens = await new ApiOldBaseHelper().GetAdressedAuthRefreshableClient().refreshToken(new RefreshTokenRequest({
              accessToken: localStorage.getItem('old_bearer')!,
              refreshToken: localStorage.getItem('old_refresh_token')!
            }));
            localStorage.setItem('old_bearer', tokens.accessToken!);
            localStorage.setItem('old_refresh_token', tokens.refreshToken!);
            axios.defaults.headers.common['Authorization'] = `Bearer ricpis`;
            error.config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
            return axiosApiInstance(error.config);

          }
          else throw new Error('unathorized but refresh token not expires');
        }
        catch (e) {
          console.log('error refresh', e);
          localStorage.removeItem('old_bearer');
          localStorage.removeItem('old_refresh_token');
          window.location.href = "https://stavario.vimovsem.cz";
          // throw new Error('unable to refresh token');
        }



      }
      return Promise.reject(error);
    });
    const apiInstance = new ApiClass(GetApiAddress(), axiosApiInstance);
    if (!apiInstance) {
      throw new Error("Failed to create Authorized API instance");
    }
    return apiInstance;
  }, [ApiClass, GetApiAddress, i18n.language]);
  const Authorized = useCallback((): T => {
    const axiosApiInstance = axios.create();

    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
      async config => {
        let token = localStorage.getItem('old_bearer');
        if (config.headers === undefined) config.headers = {};
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Accept = 'application/json';
        config.headers['Content-Type'] = 'application/json';
        config.headers['accept-language'] = i18n.language;
        config.headers['TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return config;
      },
      error => {
        Promise.reject(error)
      });
    axiosApiInstance.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          if (error.response.headers['is-token-expired'] === 'true') {
            // if (!SingletonRefreshClientApi.isRefreshing) {
            await delay(600);
            originalRequest._retry = true;
            const tokens = await new ApiOldBaseHelper().GetAdressedAuthRefreshableClient().refreshToken(new RefreshTokenRequest({
              accessToken: localStorage.getItem('old_bearer')!,
              refreshToken: localStorage.getItem('old_refresh_token')!
            }));
            localStorage.setItem('old_bearer', tokens.accessToken!);
            localStorage.setItem('old_refresh_token', tokens.refreshToken!);
            axios.defaults.headers.common['Authorization'] = `Bearer ricpis`;
            error.config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
            return axiosApiInstance(error.config);

          }
          else throw new Error('unathorized but refresh token not expires');
        }
        catch (e) {
          console.log('error refresh', e);
          localStorage.removeItem('old_bearer');
          localStorage.removeItem('old_refresh_token');
          window.location.href = "https://stavario.vimovsem.cz";
          // throw new Error('unable to refresh token');
        }



      }
      return Promise.reject(error);
    });
    const apiInstance = new ApiClass(GetApiAddress(), axiosApiInstance);
    if (!apiInstance) {
      throw new Error("Failed to create Authorized API instance");
    }
    return apiInstance;
  }, [ApiClass, GetApiAddress, i18n.language]);

  const AuthorizedSubdodavatel = useCallback((): T => {
    const axiosApiInstance = axios.create();

    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
      async config => {
        let token = localStorage.getItem('subcontractor_token_old');
        if (config.headers === undefined) config.headers = {};
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Accept = 'application/json';
        config.headers['Content-Type'] = 'application/json';
        config.headers['accept-language'] = i18n.language;
        config.headers['TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return config;
      },
      error => {
        Promise.reject(error)
      });
    axiosApiInstance.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          /* if (error.response.headers['is-token-expired'] === 'true') {
            // if (!SingletonRefreshClientApi.isRefreshing) {
            await delay(600);
            originalRequest._retry = true;
            const tokens = await new ApiSubcontractorOldBaseHelper().GetClientToAuth().refreshToken(new RefreshTokenRequest({
              accessToken: localStorage.getItem('old_bearer')!,
              refreshToken: localStorage.getItem('old_refresh_token')!
            }));
            localStorage.setItem('old_bearer', tokens.accessToken!);
            localStorage.setItem('old_refresh_token', tokens.refreshToken!);
            axios.defaults.headers.common['Authorization'] = `Bearer ricpis`;
            error.config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
            return axiosApiInstance(error.config);

          }
          else */ throw new Error('unathorized but refresh token not expires');
        }
        catch (e) {
          console.log('error refresh', e);
          localStorage.removeItem('old_bearer');
          localStorage.removeItem('old_refresh_token');
          window.location.href = "https://stavario.vimovsem.cz";
          // throw new Error('unable to refresh token');
        }



      }
      return Promise.reject(error);
    });
    const apiInstance = new ApiClass(GetApiAddress(), axiosApiInstance);
    if (!apiInstance) {
      throw new Error("Failed to create Authorized API instance");
    }
    return apiInstance;
  }, [ApiClass, GetApiAddress, i18n.language]);

  const authorizedApi = useMemo(() => {
    let apiInstance = Authorized();
    return apiInstance;
  }, [Authorized]);

  const authorizedInvestorApi = useMemo(() => {
    let apiInstance = AuthorizedInvestor();
    return apiInstance;
  }, [AuthorizedInvestor]);

  const authorizedSubdodavatelApi = useMemo(() => {
    let apiInstance = AuthorizedSubdodavatel();
    return apiInstance;
  }, [AuthorizedSubdodavatel]);

  return { unauthorizedApi, authorizedApi, Unauthorized, Authorized, authorizedInvestorApi, authorizedSubdodavatelApi, AuthorizedSubdodavatel };
}