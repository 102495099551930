import { AddCircleOutline } from '@mui/icons-material';
import { GridComponent, Group, Sort, Filter, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Inject, FilterSettingsModel, Page, ExcelQueryCellInfoEventArgs, Search } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../../../Api/UsersMicroserviceClient';
import InitSfGridLocale from '../../../../Localization/SfGridLanguageInitializer';
import TrashGridDialog from '../../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../../Shared/Trash/TrashType';
import { Box, Button, Card } from '@mui/material';
import { useUserLayoutContext } from '../../../../Contexts/Providers/UserLayoutMenuProvider';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { DeleteProductsRequest, ProductListPageDTO, WarehouseVovClient } from '../../../../ApiOld/Api/ApiServerVov';
import GridComponentStatefull from '../../../Shared/Datagrid/GridComponentStatefull';

InitSfGridLocale();
interface ProductsListProps {
}

export default function ProductsList(props: ProductsListProps) {
    const [products, setProducts] = useState<ProductListPageDTO[] | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);
    const { setBackMenuFunction, setMenuButtons } = useUserLayoutContext();
    const { authorizedApi } = useApi(WarehouseVovClient);
    const grid = useRef<GridComponent>(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const [loading, setLoading] = useState(true);

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };

    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const excelQueryCellInfo = (args: ExcelQueryCellInfoEventArgs): void => {
        args.value = t('yesL');
    }

    const refresh = () => {
        setLoading(true);
        authorizedApi.getProductsListPage().then((r) => {
            setProducts(r ? r.productsListPage : []);

            if (grid.current) {
                grid.current.dataSource = r ? r.productsListPage ?? [] : [];
                grid.current.refresh();
            }

            setLoading(false);
        });
    }

    useEffect(() => {
        setBackMenuFunction(undefined);
        setMenuButtons(<Button
            size='small'
            sx={{ mr: 2 }}
            startIcon={<AddCircleOutline />}
            variant='contained' onClick={() => {
                navigate({ pathname: '/' + i18n.language + '/' + params['domain']! + '/userlayout/products/new' });
            }
            }>{t('Add')}</Button>);
        refresh();
    }, [setBackMenuFunction, setMenuButtons, i18n.language, params, navigate, t]);

    function navigateToDetail(data: ProductListPageDTO) {
        navigate(
            { pathname: '/' + i18n.language + '/' + params['domain']! + '/userlayout/productDetail/' + data.id!.toString(), },
            { state: { id: data.id, inputRecords: products } }
        );
    }

    function GridTemplate(props: Readonly<ProductListPageDTO>) {
        const [hover, setHover] = useState(false);
        return (<div style={{ cursor: 'pointer' }} onClick={() => {
            navigateToDetail(props);
        }} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <a style={{ fontWeight: hover ? 'bold' : 'normal', cursor: 'pointer', textDecoration: 'underline' }}>{props.name!}</a>
        </div>);
    }

    return <Box style={{ maxWidth: '100vw' }}>
        <Card sx={{ margin: 3, padding: 3, height: '100%' }} elevation={3}>
            <GridComponentStatefull<ProductListPageDTO>
                gridType={GridType.Products}
                onDelete={(e) => { authorizedApi.deleteProducts(new DeleteProductsRequest({ productsIds: e as number[] })).then((e) => { if (e) refresh(); }) }}
                data={products ?? []}
                templateColumns={[
                    { field: 'name', template: GridTemplate }
                ]}
                canAdd={true}
                id="grid-component-products_list"
                allowSorting={true}
                enablePersistence={false}
                allowResizing={true}
                showColumnChooser={true}
                allowExcelExport={true}
                allowMultiSorting={true}
                allowGrouping={false}
                locale={i18n.language}
                excelQueryCellInfo={excelQueryCellInfo}
                recordDoubleClick={(e: any) => {
                    navigateToDetail(e.rowData as ProductListPageDTO);
                }}
                rowHeight={30}
                allowReordering
                filterSettings={filterOptions}
                allowPaging={true}
                style={{ backgroundColor: 'white', overflow: 'auto', height: '100%', minHeight: '450px' }}
                pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                toolbarClick={toolbarClickExcel}
                columnDataStateChange={(e) => { console.log(e) }}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                allowFiltering={true} groupSettings={groupOptions}
                ref={grid}
                searchLabel={t('warehouses.searchInProducts')}
                columnsXX={[
                    { width: '20', headerText: '', type: 'checkbox', showInColumnChooser: false },
                    { field: 'id', width: '46', headerText: 'ID', textAlign: 'Right', visible: false, showInColumnChooser: false },
                    { field: 'name', template: GridTemplate, headerText: t('name'), width: '120', minWidth: '100' }
                ]}
                moduleName={t('warehouses.products')}
                menuName={t('View')}
            >
                <Inject services={[Page, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Group]} />
            </GridComponentStatefull>
        </Card>
        {/* <Box>
            <TrashGridDialog trashType={TrashType.Warehouses} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refresh(); }} />
        </Box> */}
    </Box >
}
