export class InvitePeople {
    email: string | undefined;
    hasAccepted: boolean = false;
    hasPaid: boolean = false;
    id: number = 0;
    constructor(_id: number, _email?: string, _hasPaid?: boolean, _hasAccepted?: boolean) {
        this.id = _id;
        this.email = _email;
        if (_hasPaid !== undefined)
            this.hasPaid = _hasPaid;
        if (_hasAccepted !== undefined)
            this.hasAccepted = _hasAccepted;
    }
}