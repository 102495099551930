import { AccessTimeOutlined, AssignmentInd, Close, PersonOutline, Send, Settings } from '@mui/icons-material';
import { Box, CircularProgress, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConversationDto, FileParameter } from '../../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../../ApiOld/ApiToDoOldHelper';
import { useParams } from 'react-router';
import ChatMessageDetailDialog from './ChatMessageDetailDialog';
import BasicCommonUploadComponent from '../../Shared/Files/BasicCommonUploadComponent';
import { ImageDialog } from '../../Shared/ImageDialog';
import { IsImage } from '../../Shared/Files/IsImage';
import { GetFileIcon } from '../../Shared/Files/GetFileIcon';
import ConversationItem from './Components/ConversationItem';

export interface TodoChatScenePropsOld {
}
export default function TodoChatSceneOld(props: TodoChatScenePropsOld) {
    const theme = useTheme();
    const [selectedChatGroups, setSelectedChatGroups] = React.useState(() => ['1']);

    const [workersToggle, setWorkersToggle] = React.useState(true);
    const [officeToggle, setOfficeToggle] = React.useState(false);
    const [investorToggle, setInvestorToggle] = React.useState(true);
    const [newMessage, setNewMessage] = React.useState('');
    const [messageFile, setMessageFile] = React.useState<FileParameter>();

    const [conversations, setConversations] = React.useState<ConversationDto[]>([]);
    const [requestId, setRequestId] = React.useState<number | undefined>();
    const [detailOpen, setDetailOpen] = React.useState(false);
    const [selectedMessage, setSelectedMessage] = React.useState<ConversationDto>();

    const [selectedImage, setSelectedImage] = React.useState<string | null>(null);

    useEffect(() => {
        setNewMessage('');
    }, []);

    useEffect(() => {
        if (requestId)
            new APITodoOldHelper().GetTodoChatConversations(requestId).then((result) => {
                setConversations(result);
            });
        else setConversations([]);
    }, [requestId]);
    const handleChatGroupSelected = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        setSelectedChatGroups(newFormats);
    };
    const { t } = useTranslation();

    const params = useParams();

    useEffect(() => {
        if (params['id'] && !isNaN(parseInt(params['id']))) {
            setRequestId(parseInt(params['id']));
        }
        else if (params['id'] === 'new') {
            setRequestId(undefined);
        }
    }, [params]);

    const [sending, setSending] = React.useState(false);

    const sendConversation = () => {
        if (newMessage.length > 0) {
            setSending(true);
            new APITodoOldHelper().AddTodoConversation(requestId, newMessage, !workersToggle, !investorToggle, messageFile).then((result) => {
                if (result && result.sent) {
                    setNewMessage('');
                    new APITodoOldHelper().GetTodoChatConversations(requestId!).then((result) => {
                        setConversations(result);
                        setMessageFile(undefined);
                        setSending(false);
                    });
                }
                else
                    setSending(false);
            });
        }
    }

    return (
        <div style={{ margin: '0px', background: 'white' }}>
            <Grid container>
                <Grid item style={{ backgroundColor: theme.palette.primary.main }} xs={4}>
                    <Typography fontSize={18} fontWeight='bold' sx={{ m: '8px', float: 'left' }} color={theme.palette.secondary.main}>{t('mobileApp')}</Typography>
                    <AssignmentInd sx={{ float: 'right', margin: '10px' }} color='secondary' />
                </Grid>
                <Grid item style={{ backgroundColor: theme.palette.secondary.main }} xs={4}>
                    <Typography fontSize={18} fontWeight='bold' sx={{ m: '8px', float: 'left' }} color='white'>{t('office')}</Typography>
                    <AssignmentInd sx={{ float: 'right', margin: '10px' }} color='primary' />
                </Grid>
                <Grid item style={{ backgroundColor: theme.palette.success.main }} xs={4}>
                    <Typography sx={{ float: 'left', m: '8px' }} fontSize={18} fontWeight='bold' color='white'>{t('Investor')}</Typography>
                    <AssignmentInd sx={{ float: 'right', margin: '10px' }} color='primary' />
                </Grid>
            </Grid>
            <Box style={{ minHeight: '200px', maxHeight: '50vh', overflow: 'auto' }}>
                {conversations.map((conversation, index) =>
                    <ConversationItem key={index} conversation={conversation} setSelectedImage={setSelectedImage} setDetailOpen={setDetailOpen} setSelectedMessage={setSelectedMessage} />)}
            </Box>
            <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={4}>
                    <ToggleButtonGroup
                        size='small'
                        color="primary"

                        value={selectedChatGroups}
                        onChange={handleChatGroupSelected}
                        aria-label="Platform"
                        style={{ width: '100%' }}
                    >
                        <Grid container>
                            <Grid item style={{ justifyContent: 'center', padding: '5px' }} xs={4}>
                                <ToggleButton selected={workersToggle} sx={{ backgroundColor: workersToggle ? theme.palette.secondary.main : theme.palette.primary.main, width: '100%' }} size='small' value="0" onChange={(e, checked) => setWorkersToggle(!workersToggle)}>{t('workers')}</ToggleButton>
                            </Grid>
                            <Grid item style={{ justifyContent: 'center', padding: '5px' }} xs={4}>
                                <ToggleButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} selected={officeToggle} sx={{ backgroundColor: officeToggle ? theme.palette.secondary.main : theme.palette.primary.main, width: '100%', cursor: 'not-allowed !important' }} size='small' value="1" onChange={(e, checked) => setOfficeToggle(true)}>{t('office')}</ToggleButton>
                            </Grid>
                            <Grid item style={{ justifyContent: 'center', padding: '5px' }} xs={4}>
                                <ToggleButton selected={investorToggle} sx={{ backgroundColor: investorToggle ? theme.palette.secondary.main : theme.palette.primary.main, width: '100%' }} size='small' value="2" onChange={(e, checked) => setInvestorToggle(!investorToggle)}>{t('Investor')}</ToggleButton>
                            </Grid>
                        </Grid>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{ borderRadius: '16px', marginLeft: '12px', padding: '6px', border: 1, borderColor: 'primary.main' }}>
                        <Grid style={{ display: 'flex' }}>
                            <TextField value={newMessage} onChange={(e) => { setNewMessage(e.currentTarget.value) }} sx={{ marginLeft: '16px', marginRight: '16px', width: 'calc(100% - 60px)' }} size='small' variant='standard'></TextField>
                            {!sending && <div><IconButton disabled={!requestId || newMessage.length === 0} onClick={(e) => { e.preventDefault(); e.stopPropagation(); sendConversation(); }}><Send /></IconButton></div>}
                            {sending && <div><CircularProgress size={'30px'} /></div>}
                            <Box sx={{ marginTop: '-5px' }}>
                                <BasicCommonUploadComponent onlyImages onFileChange={(file) => { setMessageFile(file) }} />
                            </Box>
                        </Grid>
                        {messageFile && <Grid sx={{ display: 'flex' }}>
                            <IconButton size='small' onClick={() => setMessageFile(undefined)}><Close /></IconButton>
                            <Typography sx={{ marginTop: '5px' }}>{messageFile.fileName}</Typography>
                        </Grid>}
                    </Box>
                </Grid>
            </Grid>
            {
                selectedMessage && <ChatMessageDetailDialog conversation={selectedMessage} open={detailOpen} onClose={() => setDetailOpen(false)} onSave={(data) => {
                    setConversations(conversations.map(x => x.id === data.id ? new ConversationDto({ ...data, toEmployee: !data.toEmployee, toInvestor: !data.toInvestor }) : x));
                }} />
            }
            <ImageDialog open={selectedImage !== null} onClose={() => { setSelectedImage(null) }} imageUrl={selectedImage} />
        </div >);
}