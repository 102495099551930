import { Print } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import i18n from '../../../../Localization/i18nInitializer';
import downloadByLink from '../../../Shared/Files/DownloadByLink';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import saveBlob from '../../../Shared/Files/SaveBlob';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';

export interface DiaryPrintInRangeDialogProps {
    open: boolean;
    handleIsPrinting: (event: boolean) => void;
    onClose: (event: any) => void;
    data?: any;
}
export default function DiaryPrintInRangeDialog(props: DiaryPrintInRangeDialogProps, ref: any) {
    const { onClose, handleIsPrinting, open, data } = props;
    const { permissions, refreshPermissions } = useDiaryTabsContext();

    const handleClose = (e: any) => {
        onClose(e);
    };

    useEffect(() => {
        if (permissions === null)
            refreshPermissions(false);

    }, [permissions, refreshPermissions]);


    const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(new Date());
    const [selectedToDate, setSelectedToDate] = useState<Date | null>(new Date());

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { handleClose(data) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('diary.printInDateRange')}
            </DialogTitle>

            <DialogContent>
                <div>
                    <span style={{ position: 'relative', top: '18px' }}>{t('from')}:</span>
                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD.MM.YYYY"
                            value={selectedFromDate}
                            onChange={(e) => {
                                if (e)
                                    setSelectedFromDate(e);
                            }}
                            renderInput={(params) => <TextField sx={{ maxWidth: '160px', margin: '8px' }} size='small' {...params} />} />
                    </LocalizationProvider>
                </div>
                <div>
                    <span style={{ position: 'relative', top: '18px' }}>{t('to')}:</span>
                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD.MM.YYYY"
                            value={selectedToDate}
                            onChange={(e) => {
                                if (e)
                                    setSelectedToDate(e);
                            }}
                            renderInput={(params) => <TextField sx={{ maxWidth: '160px', margin: '8px' }} size='small' {...params} />} />
                    </LocalizationProvider>
                </div>

                <Button
                    onClick={() => {
                        handleIsPrinting(true);

                        if (selectedFromDate && selectedToDate) {
                            new ApiDiaryBaseHelper(false, false).PrintDiaryInDateRange(data.id, false, false, false, selectedFromDate, selectedToDate, 0).then((d) => {
                                handleIsPrinting(false);
                                if (d)
                                    saveBlob(d.data, data.diaryName + ".pdf");
                            });
                        }
                        handleClose(true);
                    }}
                    startIcon={<Print />}
                    sx={{ minWidth: '280px', margin: '20px' }} variant='contained'>{t('Print')}
                </Button>
            </DialogContent>
        </Dialog>
    );
}