import { Box, Button, Grid, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface InvoiceCommentsProps {
    text: string | undefined;
    onSend(comment: string): void;
}

export function InvoiceComments(props: InvoiceCommentsProps) {
    const { text, onSend } = props;
    const [comment, setComment] = useState("");
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box dangerouslySetInnerHTML={{ __html: text || "" }} />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize style={{ minWidth: '200px' }} value={comment} onChange={(e) => setComment(e.target.value)} placeholder={t("AddComment")} />
            </Grid>
            <Grid item xs={12}>
                <Button sx={{ mt: 1 }} variant="contained" size="small" onClick={() => {
                    onSend(comment)
                    setComment("")
                }
                }>{t("send")}</Button>
            </Grid>
        </Grid>

    )
}