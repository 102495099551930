import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { useApi } from '../../../ApiOld/Api/useApi';
import { AddJokeRequest, SuperadminVovClient } from '../../../ApiOld/Api/ApiServerVov';
import LanguagePicker from '../LanguagePicker';
import { useTranslation } from 'react-i18next';


export interface IAddJokeHintProps {
    open: boolean;
    onClose: (needRefresh: boolean) => void;
    isJoke: boolean;
}

export default function AddJokeHint(props: IAddJokeHintProps) {
    const { open, onClose, isJoke } = props;
    const { i18n } = useTranslation();
    // const [needRefresh, setNeedRefresh] = React.useState(false);
    const [heading, setHeading] = React.useState('');
    const [content, setContent] = React.useState('');
    const [lang, setLang] = React.useState(i18n.language);
    const { authorizedApi } = useApi(SuperadminVovClient);
    const add = React.useCallback(async () => {
        if (isJoke)
            await authorizedApi.createJoke({ heading: heading, content: content, language: lang } as AddJokeRequest);
        else await authorizedApi.createHint({ heading: heading, content: content, language: lang } as AddJokeRequest);
        setHeading('');
        setContent('');
        onClose(true);
    }, [authorizedApi, heading, content, isJoke, onClose, lang]);
    return (
        <Dialog open={open} onClose={() => { onClose(false) }}>
            <DialogTitle>Přidat</DialogTitle>
            <DialogContent>
                <Stack m={1} spacing={1}>
                    <LanguagePicker showText notChangeLanguage onLanguageChange={(language: string) => { setLang(language) }} />
                    <TextField size='small' label='Nadpis' value={heading} onChange={(e) => { setHeading(e.target.value); }} />
                    <TextField label='Popis' multiline minRows={2} maxRows={10} value={content} onChange={(e) => { setContent(e.target.value); }} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { add() }}>OK</Button>
                <Button onClick={() => { onClose(false) }}>Zavřít</Button>
            </DialogActions>
        </Dialog>
    );
}
