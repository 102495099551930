import React, { useEffect } from "react";
import { Autocomplete, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BuildingDTO, WealthDTO } from "../../ApiOld/ApiServerVov";
import { useMediaPredicate } from "react-media-hook";
import { APIOldWealthClient } from "../../ApiOld/ApiOldWealthClient";

export interface WealthSelectProps {
    selectedWealthId?: number | null;
    onChange: (event: BuildingDTO | null) => void;
    isMandatory?: boolean;
}
export default function WealthSelect(props: WealthSelectProps) {
    const { onChange, selectedWealthId, isMandatory } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedWealth, setSelectedWealth] = React.useState<WealthDTO | null>(null);
    const [wealths, setWealths] = React.useState<WealthDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");


    useEffect(() => {
        new APIOldWealthClient().GetWealthsForAdminUser(false).then((res) => {
            if (res && res.wealths)
                setWealths(res.wealths)
            else setWealths([]);
        });

    }, []);
    useEffect(() => {
        if (wealths !== undefined && wealths !== null && wealths.length > 0
            && selectedWealthId !== undefined &&
            selectedWealthId !== null && selectedWealthId > 0) {
            let q = wealths.find(x => x.id == selectedWealthId);
            if (q !== undefined)
                setSelectedWealth(q);
        }

    }, [wealths, selectedWealthId]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedWealth(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedWealth}
                options={wealths}

                fullWidth={biggerThan700}
                sx={{ minWidth: 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedWealth === null}  {...params} label={t('Majetek')}

                />}
            />
            {selectedWealth === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}