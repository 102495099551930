import { Handyman, Print, Save, Lock, NotInterested, Search, ViewColumn, ViewList } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { GetDiaryPermissionsInvestorResponse } from '../../../ApiOld/ApiServerVov';
import SplitLoadingButton from '../../Shared/Buttons/SplitLoadingButton';
import SplitButtonNoPush from '../../Shared/SplitButtonNoPush';

export interface IInvestorDailyRecordMenuButtonsProps {
    onTiskClick: (e: number) => void;
    saveDiary: (navigateAfterSave: boolean) => void;
    locked: boolean;
    saveButtonDisabled: boolean;
    loading: boolean;
    buildingName?: string;
    diaryName?: string;
    printing: boolean;
    handleMenuItemClick: (e: number) => void;
    investorPerm: GetDiaryPermissionsInvestorResponse | null;
}

export default function InvestorDailyRecordMenuButtons(props: IInvestorDailyRecordMenuButtonsProps) {
    const { t } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    const { onTiskClick, saveDiary, locked, loading, saveButtonDisabled, buildingName, diaryName, handleMenuItemClick, printing, investorPerm } = props;
    // React.useEffect(() => {

    //     if (!isInvestor) {
    //         if (permissions === null)
    //             refreshPermissions(isSubdodavatel);
    //     }

    // }, [permissions, refreshPermissions, isSubdodavatel, isInvestor]);

    if (investorPerm)
        return (
            <Stack spacing={2} mr={2} direction={'row'} alignItems='center'>
                <div>
                    {/* {biggerThan700 && !isInvestor && (
                        <TextField
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder={t('SearchDots')}
                            variant="outlined"
                            sx={{
                                background: 'white',
                                borderRadius: '16px',
                                color: 'black',
                                overflow: 'hidden',
                                width: '300px',
                                '& .MuiInputBase-input::placeholder': {
                                    opacity: 1,
                                    fontWeight: '500',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() => setSearchOpen(true)}
                                            sx={{ padding: '0px' }}
                                        >
                                            <Search sx={{ color: 'black' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                        />
                    )} */}
                    {/* {!biggerThan700 && !isInvestor && <IconButton onClick={() => { setSearchOpen(true) }}>
                        <Search sx={{ color: 'black' }} />
                    </IconButton>} */}
                </div>
                {((investorPerm && investorPerm.buildingDiaryRecordsEdit)) && <SplitLoadingButton disabled={saveButtonDisabled} variant='contained' options={[t('save'), t('saveAndClose')]}
                    startIcon={<Save />}
                    onMenuItemClick={(e, index) => {
                        if (index === 0) {
                            saveDiary(false);
                        }
                        else if (index === 1) {
                            saveDiary(true);
                        }
                    }} loading={loading} size={'small'}
                ></SplitLoadingButton>}

                {!locked /* && ((investorPerm && !investorPerm.buildingDiaryRecordsSignLock) )*/ && <NotInterested style={{ width: '18px', height: '18px', marginTop: '-4px' }} />}
                {locked && <Lock sx={{ marginTop: '4px' }} />}


                <SplitButtonNoPush selectedIndexInput={0} variant='contained'
                    selectOnClick
                    startIconSplitButton={<Handyman />}
                    buttonText={t('action')}
                    options={[
                        { text: locked ? t('unlock') : t('lock'), isEnabled: false/* (locked ? (permissions !== undefined && permissions.canUnlockDailyRecords === true) : (permissions !== undefined && permissions.canLockDailyRecords === true)) */ },
                        { text: t('copy'), isEnabled: false/* !isInvestor */ },
                        { text: t('collapseAll'), isEnabled: true },
                        { text: t('expandAll'), isEnabled: true },
                        {
                            text: t('diary.AlertOthers'), isEnabled: true
                        }

                    ]}
                    onMenuClick={(e) => handleMenuItemClick(e)}
                />



                {printing && <CircularProgress />}
                {!printing && <SplitButtonNoPush buttonText={t('Print')} selectedIndexInput={0} variant='outlined' selectOnClick
                    options={
                        [
                            { text: t('diary.printSharedRecords'), isEnabled: true },
                            { text: t('diary.printForDigitalSignature'), isEnabled: true },
                            { text: t('diary.printDiaryInvestor'), isEnabled: true },
                            { text: t('diary.printDiaryInvestorSignatures'), isEnabled: true },
                            { text: t('diary.printDiaryInvestorAttachments'), isEnabled: true },
                            { text: t('TitlePagePrint'), isEnabled: true }
                        ]
                    }
                    startIconSplitButton={<Print />}
                    onMenuClick={(e) => onTiskClick(e)}></SplitButtonNoPush>}


                {/* {biggerThan700 && !isInvestor &&
                    <Tooltip title={t('SwitchView')}>
                        <IconButton sx={{ p: 0 }} onClick={() => {
                            setDisplayAsTabs(!displayAsTabs);
                        }}>
                            {!displayAsTabs ? <ViewColumn sx={{ height: '30px', width: '30px' }} color="secondary" /> : <ViewList sx={{ height: '30px', width: '30px' }} color="secondary" />}
                        </IconButton>
                    </Tooltip>
                } */}
                {biggerThan700 && <div style={{ display: 'flex', margin: '8px 20px', flexDirection: 'column', height: '32px' }}>
                    <h6 style={{ margin: '0px' }}>{buildingName}</h6>
                    <p style={{ fontSize: '10px' }}>{diaryName}</p>
                </div>}
            </Stack>
        );
    else return <Box></Box>
}
