import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { Diff } from 'diff';
//import DiffViewer from '../../../Shared/DiffViewer/diffViewer'
//const diff = require('rich-text-diff');
//const RichDiff = require('react-rich-diff');
const Diff = require('diff');

interface CompareLogDiaryDialogProps {
    open: boolean;
    onClose: () => void;
    oldValue?: string;
    newValue?: string;
}
export default function CompareLogDiaryDialog(props: CompareLogDiaryDialogProps) {
    const { open, onClose, oldValue, newValue } = props;
    const { t } = useTranslation();
    const [input, setInput] = useState("");
    const [stat, setStat] = useState();


    useEffect(() => {

        /* let str = diff(oldValue ? oldValue : '', newValue ? newValue : '');

        console.log('old: ' + oldValue);
        console.log('new: ' + newValue);
        setInput(str.replace('</p<ins>><p</ins>><ins>', '</p><ins><p>')); // takový hack na opravu špatného zobrazení, protože se tam rozhazí tagy */
        let oldValParsed = getTextContentOnly((oldValue ? oldValue : ''));
        let newValParsed = getTextContentOnly((newValue ? newValue : ''));
        setInput(compareStrings(arrayToString(oldValParsed), arrayToString(newValParsed)));
    }, [oldValue, newValue]);

    const highlightSyntax = (str: string) => (
        <span
            style={{ display: 'inline' }}
            dangerouslySetInnerHTML={{
                __html: str
            }}
        />
    );

    function arrayToString(arr: string[]): string {
        let res = '';
        arr.forEach((v, i) => {
            res += v;
        });
        return res;
    }

    function compareStrings(string1: string, string2: string) {
        let results = Diff.diffChars(string1, string2);
        let output = "";
        results.forEach((item: any) => {
            if (item.removed) {
                output += `<span style="background-color:lightcoral">${item.value}</span>`;
            }
            else if (item.added) {
                output += `<span style="background-color:aquamarine">${item.value}</span>`;
            }
            else {
                output += `${item.value}`;
            }
        });
        return output;
    }

    const getTextContentOnly = (html: string) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const walker = document.createTreeWalker(
            doc.body,
            NodeFilter.SHOW_ALL,
            null
        );
        let texts: string[] = [];
        let node: any;
        while (node = walker.nextNode()) {
            if (node.tagName && node.tagName.toLowerCase() === 'p')
                texts.push('<br>');
            else if (node.nodeValue)
                texts.push(node.nodeValue);
        }
        return texts;
    }

    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="compare-dialog-title"
            aria-describedby="compare-dialog-description"
        >
            <DialogTitle id="compare-user-dialog-title" >
                <div >
                    <div style={{ marginRight: '40px', minWidth: '250px' }}>{t('log.compareTextNoformat')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <div style={{ display: 'flex' }}>
                        <span style={{ backgroundColor: 'white', marginRight: '10px' }}><div style={{ margin: '5px', fontWeight: 'bold' }}>{t('log.legenda')}</div></span>
                        <span style={{ backgroundColor: 'aquamarine', marginRight: '10px' }}><div style={{ margin: '5px' }}>{t('log.added')}</div></span>
                        <span style={{ backgroundColor: 'lightcoral' }}><div style={{ margin: '5px' }}>{t('log.removed')}</div></span>
                    </div>
                    <hr></hr>
                    <div style={{ minHeight: '200px' }}>
                        {/* <ReactDiffViewer
                            oldValue={oldValue}
                            newValue={newValue}
                            splitView={true}
                            showDiffOnly={false}
                            styles={{ diffContainer: { minHeight: '150px' } }}
                        /> */}
                        <div>
                            <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: input }} />
                        </div>
                    </div>
                </div>
                {/* <DiffViewer oldValue={oldValue ? oldValue : ''} newValue={newValue ? newValue : ''} /> */}
            </DialogContent>
        </Dialog>
    )
}
