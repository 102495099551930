import { AttachFile, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, Popover, styled, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { Marker } from 'react-image-marker';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { BeforeUploadEventArgs, FileInfo, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { CreateRequestImageBuildingRequest, FileParameter, RequestsDTO, VovClient } from '../../../../ApiOld/ApiServerVov';
import { useApi } from '../../../../ApiOld/Api/useApi';
import BuildingSelect from '../../../Shared/BuildingSelect';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';

export interface CreateRequestFromImageBuildingDialogProps {
    open: boolean;
    selectedValue: string;
    imageUrl?: string;
    selectedBuildingId?: number;
    onClose: (value: string) => void;
    todo: RequestsDTO | undefined;
    onRefresh: () => void;
    topMargin?: number;
}


export default function CreateRequestFromImageBuildingDialog(props: CreateRequestFromImageBuildingDialogProps) {
    const { onClose, selectedValue, open, todo, imageUrl, selectedBuildingId, onRefresh, topMargin } = props;
    const { t, i18n } = useTranslation();
    const optionsStates = [
        { label: t('singleTodo.new'), id: 0 },
        { label: t('singleTodo.active'), id: 1 },
        { label: t('singleTodo.resolved'), id: 2 },
    ];
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const [task, setTask] = useState<Partial<RequestsDTO> | undefined>(todo);
    const upload = useRef<UploaderComponent>(null);
    const [file, setFile] = useState('');
    const [fileData, setFileData] = useState<FileInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
        if (selectedBuildingId !== undefined && selectedBuildingId !== null && todo !== undefined) {
            if (todo !== undefined)
                setTask({ ...todo, buildingId: selectedBuildingId });
            // else setTask(new RequestsDTO({ buildingId: selectedBuildingId }));
        } else setTask(new RequestsDTO(todo));
    }, [todo]);
    useEffect(() => {
        setLoading(false);
    }, []);

    async function saveTodo(): Promise<boolean> {
        try {
            if (task !== null) {
                setLoading(true);
                if (fileData !== null) {
                    let fileParameter: FileParameter = { fileName: fileData.name, data: fileData.rawFile };
                    let t = await authorizedApi.createRequestImageBuilding(new CreateRequestImageBuildingRequest({ name: task?.name, buildingDiaryId: task?.buildingDiaryId, buildingId: task?.buildingId, dateTo: task?.dateTo, description: task?.description, imageUrl: imageUrl === undefined ? '' : imageUrl }));
                    await authorizedApi.createRequestImageBuildingComment(t.id, task?.description, fileParameter);

                    //let t = await (new APIVovInvestorHelper()).SaveRequest(task, '', fileParameter, imageUrl === undefined ? '' : imageUrl);
                    if (t) {
                        onRefresh();
                        //setTask({ ...task, id: t.id, rowVersion: t.rowVersion });
                        // setTask({ ...task, rowVersion: t.rowVersion });
                        //nesmí se volat dvakrát za sebou kvůli async chování setstate
                    }
                }
                else {
                    let taskSave = new RequestsDTO(task);
                    if (selectedBuildingId !== undefined && selectedBuildingId !== null)
                        taskSave = new RequestsDTO({ ...task, buildingId: selectedBuildingId });
                    let t = await authorizedApi.createRequestImageBuilding(new CreateRequestImageBuildingRequest({ name: task?.name, buildingDiaryId: task?.buildingDiaryId, buildingId: task?.buildingId, dateTo: task?.dateTo, description: task?.description, imageUrl: imageUrl === undefined ? '' : imageUrl }));
                    //let t = await (new APIVovInvestorHelper()).SaveRequest(taskSave, '', undefined, imageUrl === undefined ? '' : imageUrl);
                    if (t) {
                        onRefresh();
                        //setTask({ ...task, id: t.id, rowVersion: t.rowVersion });
                        // setTask({ ...task, rowVersion: t.rowVersion });
                        //nesmí se volat dvakrát za sebou kvůli async chování setstate
                    }
                }
                setLoading(false);
            }
            return true;
        }
        catch {
            setLoading(false);
            return false;
        }
    }

    function onSaveClicked() {

        let save = saveTodo().then((saved) => {
            if (saved) {
                setFile('');
                if (upload.current !== null)
                    upload.current!.clearAll();
                onClose("");
            }
        })
    }

    const handleKeyDown = (event: any) => {
        if (task !== undefined && task !== null && task.name !== null && task.name !== undefined && task.name !== '') {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && !(event.shiftKey) && charCode === 's') {
                // console.log("CTRL+S Pressed");
                event.preventDefault();
                saveTodo();
                setFile('');
                if (upload.current !== null)
                    upload.current!.clearAll();
                onClose("");

            }
            if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && charCode === 's') {
                // console.log("CTRL+SHIFT+S Pressed");
                event.preventDefault();
                saveTodo();
                setFile('');
                if (upload.current !== null)
                    upload.current!.clearAll();
                onClose("");
            }
        }
    }


    const [fullScreen, setFullScreen] = useState(false);
    function newProjectClosed() { setFile(''); onClose(""); }

    interface CustomMarker extends Marker {
        type: "success" | "error" | "warning";
        content: string;
        deviceId?: string | number;
    }
    async function uploadChanged(e: BeforeUploadEventArgs) {
        e.cancel = true;
        upload.current!.filesData.forEach(element => {
            setFileData(element);
            setFile(element.name);
        });
    }

    const onFileSelected = (e: any) => {
    }

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    if (task !== undefined) {
        return (
            <Dialog onKeyDown={handleKeyDown} fullWidth maxWidth="lg"
                fullScreen={fullScreen} open={open} onClose={onClose}
                sx={{ width: biggerThan600 ? '60%' : '100%', margin: 'auto', marginTop: topMargin ? topMargin + 'px' : '40px' }}>
                <DialogTitle height='40px' sx={{ zIndex: "28", backgroundColor: '#F5F5F5' }}>
                    <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                        <div style={{ float: 'left', display: 'flex' }}>
                            <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('investor.taskCreation')} </Typography>
                        </div>
                        <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                            {biggerThan600 && task!.date && <div>
                                <Typography variant='body1' sx={{ marginRight: '12px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString(i18n.language) : ""} </Typography>
                            </div>}
                            <div style={{ marginTop: '-14px' }}>
                                <IconButton onClick={newProjectClosed} >
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                {loading && <div style={{ margin: 'auto', minHeight: '400px', minWidth: '350px', display: 'block' }}>
                    {/* <div>
                        <Save sx={{ width: '30px', height: '30px', marginTop: '80px', marginBottom: '10px' }} />
                    </div> */}
                    <div style={{ fontSize: '20px', marginBottom: '10px', marginTop: '80px' }}>
                        {t('savingDots')}
                    </div>
                    <div>
                        <CircularProgress sx={{ opacity: '1', width: '16px', height: '16px', margin: 'auto', marginLeft: '25px' }} />
                    </div>
                </div>
                }
                {!loading && <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5' }} >
                    <ValidationGroup>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Validate name='name-todo-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                <TextField
                                    size='small' error={task !== null && task !== undefined && task.name === ''}
                                    sx={{ /* width: '95%', minWidth: '95%', */ margin: "20px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF', borderRadius: '20px' }}
                                    onChange={e => setTask({ ...task, name: e.target.value })}
                                    label={(t('Name'))} value={task.name} />
                            </Validate>

                            <TextField id="outlined-multiline-flexible"
                                multiline
                                size='small'
                                maxRows={6}
                                sx={{ margin: "20px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF', /* alignItems: "flex-start", */ borderRadius: '20px' }}
                                onChange={e => setTask({ ...task, description: e.target.value })}
                                label={t('Description')}
                                value={task.description}
                                inputProps={{
                                    sx: {
                                        minHeight: "150px",
                                        overflow: "auto",
                                    },
                                }}
                            />
                            <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '14px' }}>
                                <div style={{ width: 'fit-content', minWidth: '250px', marginLeft: '20px', marginTop: '14px', marginRight: '24px', backgroundColor: '#F5F5F5' }}>
                                    <BuildingSelect isMandatory isReadOnly={imageUrl !== undefined} onChange={(e) => { setTask({ ...task, buildingId: e ? e!.id : undefined }) }}
                                        selectedProjectId={selectedBuildingId !== undefined ? selectedBuildingId : (task !== null ? task.buildingId : undefined)} />
                                </div>
                                <div style={{ marginLeft: '20px', marginTop: '14px', marginRight: '16px', float: 'right', width: '280px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker

                                            label={t('Deadline')}
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            ampm={false}
                                            value={task.dateTo !== undefined ? task.dateTo! : null}
                                            onChange={(e, v) => {
                                                setTask({ ...task, dateTo: e !== null && e !== undefined ? e! : undefined });
                                            }}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'left', borderRadius: '20px' }} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            {imageUrl === undefined && <div style={{ marginLeft: '20px', marginTop: '16px', marginRight: '40px', backgroundColor: '#F5F5F5' }}>
                                <UploaderComponent
                                    multiple={false}
                                    maxFileSize={248888888888898}
                                    locale={i18n.language}
                                    selected={onFileSelected}
                                    showFileList={false}
                                    directoryUpload={false}
                                    autoUpload={true}
                                    ref={upload}
                                    beforeUpload={(e: BeforeUploadEventArgs) => { uploadChanged(e) }}
                                />
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '6px' }}>{file}</div>
                                    {file && <Button onClick={(e) => {
                                        upload.current!.clearAll();
                                        setFile('');
                                        setFileData(null);
                                    }} >x</Button>}
                                </div>
                            </div>}
                            <div style={{ height: '1px', marginTop: '4px', backgroundColor: '#F5F5F5' }}></div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: 'auto' }}>
                                    <Button onClick={() => { onSaveClicked() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>Vytvořit úkol</Button>
                                </div>

                            </div>
                        </div>
                    </ValidationGroup>
                </div >}
            </Dialog >
        );
    }
    else return (<div></div>);
};
