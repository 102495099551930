import { Check, Save } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import SplitButton from "../../Shared/SplitButton";
import { useTranslation } from "react-i18next";

export interface IInvoiceMenuButtonsProps {
    redirectToList: () => void;
    saveInvoice: () => void;
    saveDone: boolean;
    invoiceData: any;
    permissions?: any;
    saveDisabled?: boolean;
}

export default function InvoiceMenuButtons(props: IInvoiceMenuButtonsProps) {
    const { redirectToList, saveInvoice, saveDone, invoiceData, permissions, saveDisabled } = props;
    const { t } = useTranslation();
    return (
        <Stack spacing={2} direction={'row'} mr={2}>
            {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
            <Tooltip enterTouchDelay={0} title={saveDisabled ? t('Please confirm changes in the tables by pressing Enter or clicking outside the row being edited') : ''}>
                <div style={{ marginRight: '10px' }}>
                    <SplitButton
                        selectedIndexInput={0}
                        disabled={
                            saveDisabled ||
                            invoiceData === undefined ||
                            invoiceData === null ||
                            !permissions ||
                            !(
                                permissions.buildingBillsEdit ||
                                permissions.buildingBillsSetDateExpires ||
                                permissions.buildingBillsSetDateReceive ||
                                permissions.buildingBillsSetDateTaxable ||
                                permissions.buildingBillsSetFrom ||
                                permissions.buildingBillsSetIsApproved ||
                                permissions.buildingBillsSetIsDone ||
                                permissions.buildingBillsSetIsPosted ||
                                permissions.buildingBillsSetIsPostponed ||
                                permissions.buildingBillsSetName ||
                                permissions.buildingBillsSetPaymentValues ||
                                permissions.buildingBillsSetSubject ||
                                permissions.buildingBillsSetSupplier ||
                                permissions.buildingBillsSetTo ||
                                permissions.buildingBillsSetVariableSymbol
                            )
                        }
                        selectOnClick={true}
                        variant='contained'
                        options={[t('save'), t('saveAndClose')]}
                        startIconSplitButton={<Save />}
                        onMenuClick={(e) => {
                            if (e === 0) {
                                saveInvoice();
                            }
                            else if (e === 1) {
                                saveInvoice();
                                redirectToList();
                            }
                        }}
                    />
                </div>
            </Tooltip>
        </Stack>
    );
}