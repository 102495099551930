import { Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import NoRights from '../../Shared/Layouts/NoRights';
import SingleTodoSceneOld from './SingleTodoSceneOld';
import TodoChatSceneOld from './TodoChatSceneOld';
import TodoHistoryScene from './TodoHistorySceneOld';
import { useMediaPredicate } from 'react-media-hook';
import ReusableTabs from '../InvoiceComponents/ReusableTabs';
import { TabItem } from '@syncfusion/ej2-react-navigations';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { Attachment, AttachmentOutlined, Chat, GroupsOutlined, History, Info } from '@mui/icons-material';
import { useRequestsPermissionContext } from '../../../Contexts/Permissions/RequestsPermissionContext';
import { AddTodoRequest, TodoDTO, UpdateTodoRequest } from '../../../ApiOld/ApiServerVov';
import { TodoMainVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../../ApiOld/Api/useApi';
import TodoAssigneesSceneOld from './TodoAssigneesSceneOld';
import TodoMenuButtons from './TodoMenuButtons';
import TodoAttachmentMainScene from './TodoAttachmentMainScene';

interface SingleTodoTabSceneOldProps {
    selected?: number;
}

export default function SingleTodoTabSceneOld(props: SingleTodoTabSceneOldProps) {
    const { t, i18n } = useTranslation();
    const { selected } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [todo, setTodo] = useState<TodoDTO | undefined>();
    const [errorCode, setErrorCode] = useState<number>(0);

    const params = useParams();
    const navigate = useNavigate();

    const biggerThan1100 = useMediaPredicate("(min-width: 1100px)");

    const { permissions, reloadPermissions } = useRequestsPermissionContext();

    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();

    const { authorizedApi } = useApi(TodoMainVovClient);

    useEffect(() => {
        setBackMenuFunction(() => redirectToList);
    }, []);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions, permissions]);
    const refresh = useCallback(() => {
        if (params['id'] && !isNaN(parseInt(params['id']))) {
            if (parseInt(params['id']) > 0) {
                authorizedApi.getTodoDetail(parseInt(params['id'])).then(p => {
                    setTodo(p.data);
                    console.log('Data TODO', p);
                });
            }
            else {
                var n = new TodoDTO({ id: 0, state: 0, progress: 0, assignees: [], showInGantt: false, datetimeCreated: new Date(), origin: 0 });
                //@ts-ignore
                n.buildingDiaryId = null;
                //@ts-ignore
                n.wealthId = null;
                setTodo(n);
            }
        }
    }, [authorizedApi, params]);
    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        setMenuButtons(permissions && <TodoMenuButtons
            permissions={permissions}
            todoData={todo}
            saveDone={saveDone}
            setSaveDone={setSaveDone}
            error={errorCode}
            saveTodo={saveTodo}
            selectedTabIndex={selectedTabIndex}
        />);
    }, [permissions, saveDone, selectedTabIndex, todo, errorCode]);

    useEffect(() => {
        if (selected !== undefined)
            setSelectedTabIndex(selected);
    }, [selected]);


    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/todos');

    }

    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
    }, []);



    async function saveTodo(close: boolean) {
        if (todo !== undefined) {
            if (todo.id !== undefined && todo.id > 0) {
                let t = await authorizedApi.updateTodo(new UpdateTodoRequest({
                    data: new TodoDTO({
                        assignees: todo.assignees,
                        buildingDiaryId: todo.buildingDiaryId,
                        buildingId: todo.buildingId,
                        datetimeCreated: todo.datetimeCreated,
                        datetimeDeadline: todo.datetimeDeadline,
                        datetimeEnd: todo.datetimeEnd,
                        datetimeStart: todo.datetimeStart,
                        description: todo.description,
                        id: todo.id,
                        name: todo.name,
                        origin: todo.origin,
                        progress: todo.progress,
                        responsiblePersonId: todo.responsiblePersonId,
                        showInGantt: todo.showInGantt,
                        state: todo.state,
                        wealthId: todo.wealthId
                    })
                }));
                if (t) {
                    setTodo(new TodoDTO({ ...todo, id: t.id }));
                }
            }
            else {
                let t = await authorizedApi.addTodo(new AddTodoRequest({
                    data: new TodoDTO({
                        assignees: todo.assignees,
                        buildingDiaryId: todo.buildingDiaryId,
                        buildingId: todo.buildingId,
                        datetimeCreated: todo.datetimeCreated,
                        datetimeDeadline: todo.datetimeDeadline,
                        datetimeEnd: todo.datetimeEnd,
                        datetimeStart: todo.datetimeStart,
                        description: todo.description,
                        id: todo.id,
                        name: todo.name,
                        origin: todo.origin,
                        progress: todo.progress,
                        responsiblePersonId: todo.responsiblePersonId,
                        showInGantt: todo.showInGantt,
                        state: todo.state,
                        wealthId: todo.wealthId
                    })
                }));
                if (t) {
                    setTodo(new TodoDTO({ ...todo, id: t.id }));
                }
            }
            setSaveDone(true);
        }
    }

    const tabs = useMemo(() => permissions && [
        {
            icon: <Info style={{ width: biggerThan1100 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
            panel: <SingleTodoSceneOld onRefreshNeeded={() => refresh()} todo={todo} setTodo={setTodo} />
        },
        {
            icon: <GroupsOutlined style={{ width: biggerThan1100 ? '16px' : '24px' }} />,
            label: t('todo.assignees'),
            panel: <TodoAssigneesSceneOld todo={todo} setTodo={setTodo} />
        },
        permissions && {
            icon: <Chat style={{ width: biggerThan1100 ? '16px' : '24px' }} />,
            label: t('Chat'),
            panel: <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ background: 'white' }}>
                    <TodoChatSceneOld />
                </Box>
            </Box>
        },
        permissions && {
            icon: <AttachmentOutlined style={{ width: biggerThan1100 ? '16px' : '24px' }} />,
            label: t('Attachments'),
            panel: <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ background: 'white' }}>
                    <TodoAttachmentMainScene todoId={todo?.id ?? 0} />
                </Box>
            </Box>
        },
        permissions && {
            icon: <History style={{ width: biggerThan1100 ? '16px' : '24px' }} />,
            label: t('logOfChanges'),
            panel: <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ background: 'white' }}>
                    <TodoHistoryScene />
                </Box>
            </Box>
        },
    ], [biggerThan1100, params, permissions, t, todo]);

    if (permissions)
        return (
            <Box sx={{ m: 2, maxWidth: biggerThan1100 ? '100vw' : '92vw', height: '100vh', overflowY: biggerThan1100 ? 'inherit' : 'auto', p: 1 }}>
                <ReusableTabs
                    variant="scrollable"
                    tabs={tabs?.filter(Boolean) as any[]}
                    onSelectedTabChange={(index) => setSelectedTabIndex(index)}
                />
            </Box>
        );
    else
        return <div>{t('loading...')}</div>
}
