import { Download, Folder } from '@mui/icons-material';
import { Checkbox, IconButton, Typography } from '@mui/material';
import { DrawNodeEventArgs, FieldSettingsModel, NodeCheckEventArgs, NodeExpandEventArgs, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useRef, useState } from 'react';
import { FileDirectoryDTO } from '../../../ApiOld/ApiServerVov';
import saveBlob from '../../Shared/Files/SaveBlob';

interface InvestorDocumentsTreeProps {

    files?: FileDirectoryDTO[];
}
export default function InvestorDocumentsTree(props: InvestorDocumentsTreeProps) {
    const { files } = props;
    const treeViewRef = useRef<TreeViewComponent>(null);
    const [dataSource, setDataSource] = useState<FileDirectoryDTO[]>([]);


    useEffect(() => {

        setDataSource(files ?? []);
    }, [files]);

    useEffect(() => {
        // Use Syncfusion's expandAll API to programmatically expand nodes after rendering
        setTimeout(() => {
            if (treeViewRef.current) {
                treeViewRef.current.expandAll();
            }
        }, 200);
    }, [dataSource, treeViewRef]);

    let cssClass: string = "custom";
    function nodeFolderTemplate(data: any): JSX.Element {
        return (
            <div key={'key' + data.id}>
                {data.isDirectory && <div style={{ display: 'flex', paddingTop: '2px' }}>
                    <Folder />
                    <div style={{ marginLeft: '8px' }}>{data.name}</div>
                </div >}
                {!data.isDirectory && <div style={{ display: 'flex', paddingTop: '2px', color: 'black' }} onClick={(e) => { e.preventDefault(); e.stopPropagation() }} onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                    <IconButton onClick={(e) => { window.open(data.url, '_blank'); }}>
                        <Download />
                    </IconButton>
                    <div style={{ marginLeft: '8px' }}>{data.name}</div>
                </div>}
            </div>)
    }

    const findNode = (nodes: FileDirectoryDTO[], id: number): FileDirectoryDTO | null => {
        for (const node of nodes) {
            if (node.id === id) return node;
            if (node.children) {
                const found = findNode(node.children, id);
                if (found) return found;
            }
        }
        return null;
    };

    const fields: object = { dataSource: files, id: 'id', text: 'name', child: 'children', hasChildren: 'hasChildren' };

    return <TreeViewComponent ref={treeViewRef} cssClass={cssClass} nodeTemplate={nodeFolderTemplate} fields={fields} />

}