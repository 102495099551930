
import { AddCircleOutline, Check, CheckBox, NotInterested, Reply, Save } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SplitButton from '../../../Shared/SplitButton';
import KrosAdminUpperMenu from './KrosAdminUpperMenu';
import { APIVovKrosAdminHelper } from '../../../../ApiOld/ApiKrosAdminOldClient';
import { AdminApplicationDTO, AdminApplicationDataDTO } from '../../../../ApiOld/ApiServerVov';
import LanguagePicker from '../../../Shared/LanguagePicker';


interface KrosAdminApplicationDetailSceneProps {
}

export default function KrosAdminApplicationDetailScene(props: KrosAdminApplicationDetailSceneProps) {
    const { t, i18n } = useTranslation();
    const [saving, setSaving] = useState<boolean>(false);
    const [appData, setAppData] = useState<Partial<AdminApplicationDataDTO> | undefined>();
    /*  const [messageData, setMessageData] = useState<{ title: string, text: string }>(); */

    const theme = useTheme();
    const navigate = useNavigate();

    const params = useParams();
    useEffect(() => {
        if (params['id']) {
            if (!Number.isNaN(parseInt(params['id']))) {
                new APIVovKrosAdminHelper().GetApplicationDataKrosAdmin(parseInt(params['id'])).then(e => {
                    if (e)
                        setAppData(e.application);
                    else setAppData(undefined);
                });
            }
        }
        else setAppData(undefined);
    }, []);

    function saveApp() {
        // a ukládat změny?
        if (appData) {
            setSaving(true);
            new APIVovKrosAdminHelper().SaveApplicationDataKrosAdmin(new AdminApplicationDataDTO({
                id: appData.id, name: appData.name, email: appData.email, isActive: appData.isActive, isPaying: appData.isPaying, url: appData.url, key: appData.key, defaultLanguage: appData.defaultLanguage, idFakturoid: appData.idFakturoid, ico: appData.ico, dic: appData.dic, addressStreet: appData.addressStreet, addressCity: appData.addressCity, addressZipCode: appData.addressZipCode, addressState: appData.addressState
            })).then((d) => {
                setSaving(false);
            });
        }
    }

    const redirectToList = () => {
        navigate('/' + params['lang'] + '/kros/admin/applications')
    }
    if (appData)
        return (
            <div>

                <div style={{ display: 'block', padding: '32px', paddingTop: '0px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', marginTop: '8px' }}>
                        <div>
                            <IconButton color='primary' onClick={redirectToList}>
                                <Reply />
                            </IconButton>
                        </div>
                        {/* <div style={{ paddingTop: '12px', background: 'white', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px' }}>
                            <h6>Uživatelé - základní informace</h6>
                        </div> */}
                        <div style={{ marginLeft: 'auto', marginRight: '56px', paddingTop: '16px' }}>
                            <SplitButton selectedIndexInput={0} disabled={appData.name === undefined || appData.name === ''} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                                startIconSplitButton={<Save />}
                                onMenuClick={(e) => {
                                    if (e === 0) {
                                        saveApp();
                                    }
                                    else if (e === 1) {
                                        saveApp();
                                        redirectToList();

                                    }
                                }}></SplitButton>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>APLIKÁCIE</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Aktívne"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.isActive}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, isActive: e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Aktívne</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Platiaci"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.isPaying}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, isPaying: e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Platiaci</Typography>}
                                    labelPlacement="end"
                                />

                                <Validate name='url-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                    <TextField sx={{ margin: '10px 20px' }} label={'URL'}
                                        size='small'
                                        name='url'
                                        variant='outlined'
                                        value={appData.url}
                                        onChange={(e) => { setAppData({ ...appData, url: e.target.value }); }} />
                                </Validate>

                                <Validate name='key-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                    <TextField sx={{ margin: '10px 20px' }} label={'Kľúč'}
                                        size='small'
                                        name='url'
                                        variant='outlined'
                                        value={appData.key}
                                        onChange={(e) => { setAppData({ ...appData, key: e.target.value }); }} />
                                </Validate>
                                <FormControlLabel sx={{ margin: '10px 20px' }}
                                    value="Defaultný jazyk"
                                    onChange={(e) => { }}
                                    control={<LanguagePicker showText={false} onLanguageChange={(e) => setAppData({ ...appData, defaultLanguage: e })} />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Defaultný jazyk</Typography>}
                                    labelPlacement="end"
                                />

                                <TextField sx={{ margin: '10px 20px' }} label={'ID kontaktu na fakturoidu'}
                                    size='small'
                                    name='fakturoid'
                                    variant='outlined'
                                    value={appData.idFakturoid}
                                    onChange={(e) => { setAppData({ ...appData, idFakturoid: e.target.value }); }} />
                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>ÚDAJE O FIRME</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <Validate name='name-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                    <TextField sx={{ margin: '10px 20px' }} label={t('Name')}
                                        size='small'
                                        name='appName'
                                        variant='outlined'
                                        value={appData.name}
                                        onChange={(e) => { setAppData({ ...appData, name: e.target.value }) }} />
                                </Validate>
                                <TextField sx={{ margin: '10px 20px' }} label={'IČO'}
                                    size='small'
                                    name='ico'
                                    variant='outlined'
                                    value={appData.ico}
                                    onChange={(e) => { setAppData({ ...appData, ico: e.target.value }); }} />
                                <TextField sx={{ margin: '10px 20px' }} label={'DIČ'}
                                    size='small'
                                    name='dic'
                                    variant='outlined'
                                    value={appData.dic}
                                    onChange={(e) => { setAppData({ ...appData, dic: e.target.value }); }} />
                                <TextField sx={{ margin: '10px 20px' }} label={'E-mail'}
                                    size='small'
                                    name='email'
                                    variant='outlined'
                                    value={appData.email}
                                    onChange={(e) => { setAppData({ ...appData, email: e.target.value }); }} />
                                <TextField sx={{ margin: '10px 20px' }} label={'Ulica'}
                                    size='small'
                                    name='email'
                                    variant='outlined'
                                    value={appData.addressStreet}
                                    onChange={(e) => { setAppData({ ...appData, addressStreet: e.target.value }); }} />
                                <TextField sx={{ margin: '10px 20px' }} label={'Mesto'}
                                    size='small'
                                    name='city'
                                    variant='outlined'
                                    value={appData.addressCity}
                                    onChange={(e) => { setAppData({ ...appData, addressCity: e.target.value }); }} />
                                <TextField sx={{ margin: '10px 20px' }} label={'PSČ'}
                                    size='small'
                                    name='psc'
                                    variant='outlined'
                                    value={appData.addressZipCode}
                                    onChange={(e) => { setAppData({ ...appData, addressZipCode: e.target.value }); }} />
                                <TextField sx={{ margin: '10px 20px' }} label={'Krajina'}
                                    size='small'
                                    name='zeme'
                                    variant='outlined'
                                    value={appData.addressState}
                                    onChange={(e) => { setAppData({ ...appData, addressState: e.target.value }); }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    else return <div>loading ....</div>;
}