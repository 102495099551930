import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EmployeeGroupListDTO, UserGroupDTO } from "../../ApiOld/ApiServerVov";
import { useMediaPredicate } from "react-media-hook";
import { APIVovEmployeeHelper } from "../../ApiOld/ApiOldEmployeeClient";

export interface EmployeeGroupSelectProps {
    selectedGroupId?: number | null;
    onChange: (event: EmployeeGroupListDTO | null) => void;
    isMandatory?: boolean;
    readOnly?: boolean;
    alertFontSize?: number;
    minWidth?: number;
}
export default function EmployeeGroupSelect(props: EmployeeGroupSelectProps) {
    const { onChange, selectedGroupId, isMandatory, readOnly, alertFontSize, minWidth } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedGroup, setSelectedGroup] = React.useState<EmployeeGroupListDTO | null>(null);
    const [groups, setGroups] = React.useState<EmployeeGroupListDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    useEffect(() => {
        new APIVovEmployeeHelper().GetEmployeeGroups().then((data) => {
            setGroups(data);
        });

    }, []);
    useEffect(() => {
        if (groups !== undefined && groups !== null && groups.length > 0
            && selectedGroupId !== undefined &&
            selectedGroupId !== null && selectedGroupId > 0) {
            let q = groups.find(x => x.id == selectedGroupId);
            if (q !== undefined)
                setSelectedGroup(q);
        }

    }, [groups]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedGroup(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedGroup}
                options={groups}
                readOnly={readOnly}
                fullWidth={biggerThan700}
                sx={{ minWidth: minWidth ? minWidth : 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedGroup === null}  {...params} label={t('Group')}

                />}
            />
            {selectedGroup === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '2px', marginTop: '14px', color: theme.palette.error.main, fontSize: alertFontSize ? alertFontSize : 14, fontFamily: 'Roboto' }}>{t('FieldRequired')}</p>}
        </div>
    );
}
