import { Box, Grid, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import { useTranslation } from 'react-i18next';
import { GetManufacturerDataDetailResponse } from '../../../../ApiOld/Api/ApiServerVov';

interface ManufacturerMainDetailProps {
  manufacturerData: Partial<GetManufacturerDataDetailResponse>;
  setManufacturerData: (data: Partial<GetManufacturerDataDetailResponse> | null) => void;
}

export default function ManufacturerMainDetail(props: Readonly<ManufacturerMainDetailProps>) {
  const { manufacturerData, setManufacturerData } = props;
  const { t } = useTranslation();

  return (
    <Box>
      <ValidationGroup>
        <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0px', marginTop: '16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12} p={0}>
                <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                  <TextField label={t('name')} variant="outlined" fullWidth size='small'
                    name='name'
                    value={manufacturerData.name ?? ''}
                    onChange={(e) => { setManufacturerData({ ...manufacturerData, name: e.target.value }) }}
                    sx={{ my: 1 }}
                  />
                </Validate>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ValidationGroup>
    </Box>
  )
}
