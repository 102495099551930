

import { Typography, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetTodosForDiaryDTO, RequestsDTO } from '../../../../ApiOld/ApiServerVov';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import NoDataImageHolder from '../../../Shared/Layouts/NoDataImageHolder';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import InvestorTaskDetailDialog from '../../../Investor/Components/InvestorTaskDetailDialog';

interface TasksInDiaryWidgetProps {
    recordId: number;
    domain?: string;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    reloadData?: boolean;
}

export default function TasksInDiaryWidget(props: TasksInDiaryWidgetProps) {
    const { t, i18n } = useTranslation();
    const { recordId, domain, isInvestor, isSubdodavatel, reloadData } = props;
    const [todos, setTodos] = useState<GetTodosForDiaryDTO[]>([]);
    const [detailOpen, setDetailOpen] = useState(false);
    const [selectedDetailTodo, setSelectedDetailTodo] = useState<RequestsDTO | undefined>();
    const theme = useTheme();
    const navigate = useNavigate();
    useEffect(() => {
        loadData();
    }, [recordId]);
    useEffect(() => {
        if (reloadData === true) {
            loadData();
        }
    }, [reloadData]);
    function loadData() {
        setTodos([]);
        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetTodosForRecord(recordId).then((todosData) => {
            if (todosData) {
                setTodos(todosData);
            }
        });
    }
    function detailProjectClosed() { setDetailOpen(false); /* setSelectedDetailTodo(undefined); refreshData(); */ }
    function gridTemplate(props: GetTodosForDiaryDTO) {
        if (isInvestor === true) {
            return (<div>
                <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    if (props.id)
                        new APIVovInvestorHelper().GetRequest(props.id).then((r) => {
                            setSelectedDetailTodo(r);
                            setDetailOpen(true);
                        });
                }}>{props.name}</a>
            </div >);
        }
        else {
            return (<div>
                <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    // console.log(props.id);
                    window.open('https://' + domain + '.vimovsem.cz/admin/requests/edit/' + props.id, '_blank', 'noreferrer');
                }}>{props.name}</a>
            </div >);
        }
    }
    function resolversTemplate(props: GetTodosForDiaryDTO) {
        let users = props.resolvers!.map((item, index) => {
            return <div key={index.toString()} style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}><div style={{ backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '5px' }} ><Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{item.name}</Typography></div></div>
        })
        return (<div>
            {users}
        </div>);
    }
    const grid = useRef<GridComponent>(null);

    // if (todos)
    return (
        <div>

            {todos && todos.length > 0 && <GridComponent style={{ borderColor: 'transparent' }} id="grid-component-tasks-in-diary" allowSorting={true}
                ref={grid}
                filterSettings={standardFilterOptions}
                allowReordering={true} allowResizing={true} showColumnChooser={true}
                allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language}
                enablePersistence={true}
                resizeSettings={{ mode: 'Normal' }}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                allowFiltering={true}
                dataSource={todos}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} showInColumnChooser={false} />
                    <ColumnDirective field='name' template={gridTemplate} headerText={t('Name')} maxWidth='800' width='200' minWidth='30' />
                    <ColumnDirective field='assignedTo' headerText={t('Responsibility')} maxWidth='500' width='140' minWidth='30' />
                    <ColumnDirective field='resolvers' headerText={t('todo.assignees')} template={resolversTemplate} maxWidth='800' width='300' minWidth='200' />
                    <ColumnDirective field='deadline' width='60' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('Deadline')} />
                    <ColumnDirective field='created' width='60' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('Created')} />
                    <ColumnDirective field='createdBy' width='60' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('CreatedBy')} />

                </ColumnsDirective>
                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser]} />
            </GridComponent>}
            {(todos === undefined || todos === null || todos.length === 0) && <NoDataImageHolder title={t('todo.noRequests')} />}

            <InvestorTaskDetailDialog onRefresh={() => { loadData(); }} todo={selectedDetailTodo} open={detailOpen} selectedValue={t('investor.taskDetail')} onClose={detailProjectClosed} topMargin={140} />

        </div>);
    // else return <div></div>;
}