import { Close, Search } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddProductsToMaterialsRequest, ProductForDiaryDialogDTO, WarehouseVovClient } from '../../../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../../../ApiOld/Api/useApi';
import MuiDatagrid from '../../../Shared/MuiDatagrid';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

export interface ProductsForDiaryDialogProps {
    onClose: (event: any) => void;
    open: boolean;
    recordId?: number;
}
export default function ProductsForDiaryDialog(props: ProductsForDiaryDialogProps, ref: any) {
    const { onClose, open, recordId } = props;
    const { authorizedApi } = useApi(WarehouseVovClient);
    const [productsForDiaryDialog, setProductsForDiaryDialog] = useState<ProductForDiaryDialogDTO[]>();
    const [searchBackupProductsForDiaryDialog, setSearchBackupProductsForDiaryDialog] = useState<ProductForDiaryDialogDTO[]>();
    const [searchText, setSearchText] = useState<string | undefined>();
    const [searched, setSearched] = useState<string>('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
    const { t } = useTranslation();

    useEffect(() => {
        if (authorizedApi && recordId && open)
            authorizedApi.getProductsForDiaryDialog().then((r) => {
                setProductsForDiaryDialog(r?.productsForDiaryDialog ?? []);
                setSearchBackupProductsForDiaryDialog(r?.productsForDiaryDialog ?? []);
            });
        else if (!open) {
            setProductsForDiaryDialog(undefined);
            setSearchText(undefined);
            setSearched('');
        }
    }, [authorizedApi, open, recordId]);

    useEffect(() => {
        if (!open) {
            setSelectionModel([]);
        }
    }, [open]);

    const handleClose = (e: any) => {
        onClose(e);
    };

    useEffect(() => {
        if (searched !== '') {
            let searchedProducts = searchBackupProductsForDiaryDialog?.filter((x) => {
                return searchText?.toLowerCase() === '' ? x : (x.code?.toLowerCase().includes(searchText?.toLowerCase()!) ||
                    x.name?.toLowerCase().includes(searchText?.toLowerCase()!) ||
                    x.stockManufacturerName?.toLowerCase().includes(searchText?.toLowerCase()!) ||
                    x.stockProductCategoryName?.toLowerCase().includes(searchText?.toLowerCase()!) ||
                    x.measureUnit?.toLowerCase().includes(searchText?.toLowerCase()!));
            });
            setProductsForDiaryDialog(searchedProducts);
        }
        else if (searchBackupProductsForDiaryDialog) {
            setProductsForDiaryDialog(searchBackupProductsForDiaryDialog);
        }
    }, [searched]);

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleSearch();
        }
    }
    const handleSearch = () => {
        if (searchText !== undefined && searchText !== '') setSearched(searchText); else setSearched('');
    }

    const save = () => {
        if (productsForDiaryDialog && recordId) {
            authorizedApi.addProductsToMaterials(new AddProductsToMaterialsRequest({ diaryRecordsId: recordId, checkedProductsIds: selectionModel?.map(id => Number(id)) })).then((r) => {
                onClose(true);
            });
        }
    }

    const columns: GridColDef[] = [
        { field: 'code', headerName: t('code'), flex: 1, minWidth: 100, editable: false },
        { field: 'name', headerName: t('Name'), flex: 1, minWidth: 100, editable: false },
        { field: 'measureUnit', headerName: t('Unit'), flex: 1, minWidth: 100, editable: false },
        { field: 'stockManufacturerName', headerName: t('Manufacturer'), flex: 1, minWidth: 100, editable: false },
        { field: 'stockProductCategoryName', headerName: t('warehouses.productCategories'), flex: 1, minWidth: 100, editable: false },
    ];

    return (
        <Dialog style={{ marginTop: '60px' }}
            open={open}
            onClose={() => { handleClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="wealth-dialog-title" >
                <Box>
                    <Box sx={{ marginRight: '20px', minWidth: '400px' }}>{t('warehouses.products')}</Box>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose('') }}><Close /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#F5F5F5' }}>
                <Box sx={{ maxHeight: '800px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <TextField value={searchText} onKeyDown={handleKeyDown} onChange={(e) => { setSearchText(e.target.value) }}
                            placeholder={t('SearchDots')} variant='standard' sx={{ height: '20px', margin: '20px' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleSearch}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} size='small' />
                        {searchText !== '' && (productsForDiaryDialog === undefined || productsForDiaryDialog.length === 0) && <Box sx={{ marginLeft: '20px', marginTop: '24px' }}>{t('noRecords')}</Box>}
                    </Box>
                    <MuiDatagrid
                        columns={columns}
                        rows={productsForDiaryDialog ?? []}
                        editMode="row"
                        checkboxSelection
                        onRowSelectionModelChange={(ids) => {
                            setSelectionModel(ids);
                        }}
                    />
                    <Box sx={{ textAlign: 'center' }}>
                        <Button variant="contained" sx={{ marginTop: '40px', marginBottom: '10px' }} disabled={!selectionModel || selectionModel.length === 0}
                            onClick={save}>{t('diary.insertIntoDiary')}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

