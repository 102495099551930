import { Folder } from '@mui/icons-material';
import { Checkbox, Typography } from '@mui/material';
import { DrawNodeEventArgs, FieldSettingsModel, NodeCheckEventArgs, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useState } from 'react';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { FileDirectoryDTO } from '../../../../ApiOld/ApiServerVov';
import FileRecord from './FileRecord';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';
interface ChooseProjectFilesProps {
    recordId?: number;
    onFileSelected?: (id: number, isSelect: boolean) => void;
    files?: FileDirectoryDTO[];
}
export default function ChooseProjectFiles(props: ChooseProjectFilesProps) {
    const { recordId, onFileSelected, files } = props;
    const { permissions } = useDiaryTabsContext();

    let cssClass: string = "custom";
    function nodeTemplate(data: any): JSX.Element {
        if (data.isDirectory)
            return (
                <div style={{ display: 'flex', paddingTop: '2px' }}>


                    <Folder />
                    <Typography sx={{ marginLeft: '8px' }}>{data.name}</Typography>

                </div >

            )
        else {
            return <FileRecord id={data.id} isSelected={data.isSelected} name={data.name} onSelected={(id, selected) => {
                // let filesCopy = [...files];

                if (onFileSelected)
                    onFileSelected(id, selected);
                // fileOriginal.isSelected = selected;
                // setFiles(filesCopy);



            }} />;
        }
    }
    function nodeChecked(e: NodeCheckEventArgs) {
        var item = e.data[0];
        if (onFileSelected)
            onFileSelected(item.id as number, e.action === 'check' ? true : false);
        // let file = filesChanged.find(f => f.id === item.id);
        // // let fileOriginal = files.find(f => f.id === item.id);
        // if (file !== undefined) {
        //     setFilesChanged([...filesChanged.filter(f => f.id !== item.id)]);
        // }
        // else {
        //     // file.isSelected = selected;
        //     setFilesChanged([...filesChanged, new FileDirectoryDTO({ id: item.id as number, isSelected: e.action === 'check' ? true : false })]);
        // }
    }
    function drawNode(args: DrawNodeEventArgs) {
        let ele = args.node.querySelector('.e-checkbox-wrapper');
        if (ele && args.nodeData.isDirectory) {
            ele.setAttribute('style', 'display: none');
        }
        if (permissions === undefined || permissions?.deleteAttachments === undefined || !permissions.deleteAttachments)
            if (ele && args.nodeData.isSelected)
                ele.setAttribute('style', 'display: none');
        // ele[0].style.setPropery('display', 'none');
        // ele.classList.add('e-checkbox-disabled');
    }
    const fields: object = { dataSource: files, id: 'id', text: 'name', child: 'children', hasChildern: 'hasChildren', expanded: 'hasChildren', isChecked: 'isSelected' };
    if (recordId && files && files.length > 0)
        return <TreeViewComponent nodeChecked={nodeChecked} cssClass={cssClass} showCheckBox nodeTemplate={nodeTemplate} drawNode={drawNode} fields={fields} />
    else
        return <div></div>;
}