
import { AddCircleOutline, Check, CheckBox, NotInterested, Reply, Save } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SplitButtonNoPush from '../../Shared/SplitButtonNoPush';
import SplitButton from '../../Shared/SplitButton';
import { StatisticsKrosAdminDTO } from '../../../ApiOld/ApiServerVov';
import { APIVovKrosAdminHelper } from '../../../ApiOld/ApiKrosAdminOldClient';


interface KrosAdminStatisticsSceneProps {
}

export default function KrosAdminStatisticsScene(props: KrosAdminStatisticsSceneProps) {
    const { t, i18n } = useTranslation();
    const [stats, setStats] = useState<StatisticsKrosAdminDTO>();
    /*  const [messageData, setMessageData] = useState<{ title: string, text: string }>(); */

    const theme = useTheme();
    // const { diaryId } = props;
    useEffect(() => {
        setStats(undefined);
        new APIVovKrosAdminHelper().GetStatisticsKrosAdmin().then((v) => { if (v) setStats(v.data) });
    }, []);

    if (stats)
        return (
            <ValidationGroup>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {/* <div style={{ position: 'fixed', left: '40px', top: '112px', zIndex: 30 }}>
                        <IconButton onClick={redirectToList}>
                            <Reply />
                        </IconButton>
                    </div> */}
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '16px' }}>
                        <h6>Objem dat:</h6>
                        <div>{stats.dataAmount}</div>
                        <h6>Vytvořená dema za 30 dní:</h6>
                        <div>{stats.demosCount}</div>
                        <h6>Platící firmy:</h6>
                        <div>{stats.payingApps}</div>
                        <h6>Aktivní firmy:</h6>
                        <div>{stats.activeApps}</div>
                        <h6>Firmy:</h6>
                        <div>{stats.apps}</div>
                        <h6>Aktivní projekty:</h6>
                        <div>{stats.activeProjects}</div>
                        <h6>Projekty:</h6>
                        <div>{stats.projects}</div>
                        <h6>Aktivní majetek:</h6>
                        <div>{stats.activeWealth}</div>
                        <h6>Majetek:</h6>
                        <div>{stats.wealth}</div>
                        <h6>Aktivní požadavky:</h6>
                        <div>{stats.activeRequests}</div>
                        <h6>Požadavky:</h6>
                        <div>{stats.requests}</div>
                        <h6>Stavební deníky:</h6>
                        <div>{stats.diaries}</div>
                        <h6>Investoři:</h6>
                        <div>{stats.investors}</div>
                        <h6>Uživatelé:</h6>
                        <div>{stats.users}</div>
                        <h6>Aktivní zaměstnanci:</h6>
                        <div>{stats.activeEmployees}</div>
                        <h6>Zaměstnanci:</h6>
                        <div>{stats.employees}</div>
                        <h6>Uživatelé a aktivní zaměstnanci:</h6>
                        <div>{stats.activeUsersAndEmployees}</div>
                        <h6>Zaměstnanců dnes v práci:</h6>
                        <div>{stats.employeesAtWork}</div>
                        <h6>Faktury:</h6>
                        <div>{stats.invoices}</div>
                    </div>

                </div>

            </ValidationGroup >
        );
    else return <div style={{ marginLeft: '64px', marginTop: '64px' }}><CircularProgress /></div>
}