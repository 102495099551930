import { Box, CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { ValidationGroup } from 'mui-validate';
import { TodoDTO } from '../../../ApiOld/ApiServerVov';
import { useNavigate, useParams } from 'react-router';
import delay from '../../Shared/Functions/Delay';
import './SingleTodoSceneOld.css';
import SingleTodoAssigneesSceneOld from './SingleTodoAssigneesSceneOld';


export interface TodoAssigneesSceneOldProps {
    todo: TodoDTO | undefined;
    setTodo: React.Dispatch<React.SetStateAction<TodoDTO | undefined>>;
}

export default function TodoAssigneesSceneOld(props: TodoAssigneesSceneOldProps) {
    const { todo, setTodo } = props;
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();

    const biggerThan1100 = useMediaPredicate("(min-width: 1100px)");

    const params = useParams();

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    if (todo !== undefined) {
        return (
            <ValidationGroup>
                <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <h4>{t('todo.assignees')}</h4>
                    <div style={{ margin: '10px' }}>
                        {todo.buildingId && <SingleTodoAssigneesSceneOld buildingId={todo.buildingId} selectedAssignees={todo.assignees ? todo.assignees : []}
                            onChange={(sel) => {
                                setTodo(new TodoDTO({ ...todo, assignees: sel }));
                            }}
                        />}
                    </div>
                </Box>
            </ValidationGroup>
        );
    }
    else return (<div><CircularProgress /></div>);
};
