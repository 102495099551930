import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BuildingsPermissionProvider } from '../../../Contexts/Permissions/BuildingsPermissionContext';
import ProjectList from '../../Shared/ProjectList';
import ProjectsList from './ProjectsList';
import ProjectDetail from './ProjectDetail';

interface ProjectsProps {
    createProject?: boolean;
}

export default function Projects(props: ProjectsProps) {
    var params = useParams();
    const [buildingId, setBuildingId] = useState<number | undefined>();

    useEffect(() => {
        if (props.createProject) {
            setBuildingId(0);
        }
        else if (params.id) {
            setBuildingId(parseInt(params.id));
        }
        else {
            setBuildingId(undefined);
        }
    }, [params, props.createProject]);

    return <BuildingsPermissionProvider><div>
        {!buildingId && buildingId !== 0 && <ProjectsList />}
        {(buildingId || buildingId === 0) && <ProjectDetail buildingId={buildingId} />}
    </div></BuildingsPermissionProvider>;
}