import { Button, IconButton, Tooltip, Grid, Typography, CircularProgress, Checkbox, Menu, MenuItem, Box, useTheme } from '@mui/material';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WindowScroller } from 'react-virtualized';
import { useMediaPredicate } from 'react-media-hook';
import { AddAPhoto, AutoFixHighOutlined, Delete, Flaky, Update } from '@mui/icons-material';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import { ImageCategoryDTO, ImageDiaryDTO, SetImagesShowToInvestorRequest, VovClient } from '../../../../ApiOld/ApiServerVov';
import i18n from '../../../../Localization/i18nInitializer';
import { load } from '@syncfusion/ej2-react-grids';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { useApi } from '../../../../ApiOld/Api/useApi';
import RenameImageDialog from '../Dialogs/RenameImageDialog';

interface ProjectPhotosTilesSceneProps {
    width?: number;
    canDelete?: boolean;
    images: ImageDiaryDTO[];
    buildingId: number;
    selectedImagesIds: number[];
    loading: boolean;
    allSelect: boolean;
    category: ImageCategoryDTO | undefined;

    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    onDelete?: (value: ImageDiaryDTO) => void;
    onSelect: (ids: number[] | undefined) => void;
    onImageClick: (index: number | undefined) => void;
    onNameChange: (value: string, id: number) => void;
    setImages: (data: ImageDiaryDTO[]) => void;
    setAllSelect: (value: boolean) => void;
    onCategoryChange: () => void;
    onShowToInvestorChange: (value: ImageDiaryDTO, checked: boolean) => void;
}

export default function ProjectPhotosTilesScene(props: ProjectPhotosTilesSceneProps) {
    const { images, canDelete, onDelete, buildingId, onImageClick, onNameChange, setImages, selectedImagesIds, allSelect, setAllSelect, onCategoryChange, onShowToInvestorChange, category } = props;
    const [toDelete, setToDelete] = useState<ImageDiaryDTO | undefined>();
    const [renameImage, setRenameImage] = useState<ImageDiaryDTO>();
    const [renameOpen, setRenameOpen] = useState<boolean>(false);
    //const [selected, setSelected] = React.useState<ImageCategoryDTO>();
    const selectedIdsSet = useMemo(() => new Set(selectedImagesIds), [selectedImagesIds]);
    const { t } = useTranslation();
    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        if (images && images.length > 0 && selectedImagesIds.length === 1) {
            const section = document.querySelector('#image_' + selectedImagesIds[0].toString());
            if (section) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
        }
    }, [selectedImagesIds]);

    function handleDelete(image: ImageDiaryDTO) {
        if (image && permissions && permissions.buildingsEdit) {
            if (onDelete) onDelete(image);
        }
    }

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent, data: ImageDiaryDTO) => {
        event.preventDefault();
        event.stopPropagation();
        setRenameImage(data);
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleSelect = useCallback(
        (imageId: number, checked: boolean) => {
            if (checked) {
                props.onSelect([...Array.from(selectedIdsSet), imageId]);
            } else {
                props.onSelect(selectedImagesIds.filter(id => id !== imageId));
            }
        },
        [props.onSelect, selectedImagesIds]
    );

    const [disableOuterTooltip, setDisableOuterTooltip] = useState(false);

    const renderImageTile = useCallback(
        (image: ImageDiaryDTO, index: number) => {
            return (
                <Grid
                    item
                    key={'key' + image.id}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={4}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {/* Outer Tooltip for image.name */}
                    <Tooltip
                        title={disableOuterTooltip ? '' : image.name} // Disable tooltip dynamically
                        placement="top"
                        arrow
                    >
                        <div
                            className="parent"
                            id={'image_' + image.id}
                            style={{
                                padding: '8px',
                                position: 'relative',
                                background: selectedIdsSet.has(image.id!) ? 'lightgray' : 'white',
                                borderRadius: '10px',
                            }}
                            onContextMenu={(e) => {

                                if (category?.fromDiary)
                                    return;
                                e.preventDefault();
                                e.stopPropagation();
                                setRenameImage(image);
                                setContextMenu({
                                    mouseX: e.clientX + 2,
                                    mouseY: e.clientY - 6,
                                });
                            }}
                        >
                            <Checkbox
                                color="primary"
                                sx={{ position: 'absolute', top: '8px', left: '5px' }}
                                checked={selectedIdsSet.has(image.id!)}
                                onChange={(e, checked) => handleSelect(image.id!, checked)}
                            />

                            {/* Inner Tooltip for ShowToInvestor */}
                            {!category?.fromDiary && <Tooltip
                                title={t('buildings.showToInvestors')}
                                placement="top"
                                arrow
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '8px',
                                        right: '5px',
                                    }}
                                    onMouseEnter={() => setDisableOuterTooltip(true)} // Disable outer tooltip
                                    onMouseLeave={() => setDisableOuterTooltip(false)} // Re-enable outer tooltip
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={image.showToInvestor}
                                        onChange={(e, checked) => onShowToInvestorChange(image, checked)}
                                    />
                                </div>
                            </Tooltip>}

                            <img
                                onClick={() => onImageClick && onImageClick(index)}
                                src={image.thumbnailUrl}
                                height={260}
                                width={280}
                                loading="lazy"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    borderRadius: '10px',
                                    border: '1px solid lightgray',
                                    cursor: 'pointer',
                                }}
                            />
                            <Grid container spacing={1} style={{ padding: '4px' }}>
                                <Grid item xs={6}>
                                    <Typography variant="caption">
                                        {image.created ? image.created.toLocaleDateString(i18n.language) : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">{image.creator ?? ''}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Tooltip>
                </Grid>
            );
        },
        [disableOuterTooltip, onShowToInvestorChange, onImageClick, selectedIdsSet, t]
    );


    if (props.loading)
        return <CircularProgress />
    else
        return (
            <div style={{ maxHeight: '600px' }}>
                <div style={{ color: allSelect ? '#FFC600' : 'black', margin: '4px', marginLeft: '16px', cursor: 'pointer' }} onClick={() => {
                    props.onSelect(!allSelect ? images.map(x => x.id!) : []);
                    setAllSelect(!allSelect);
                }}><Box><Flaky color='inherit' />{t('selectUnselectAll')}</Box>
                </div>
                <WindowScroller>
                    {({ height, isScrolling, onChildScroll, scrollTop, registerChild, width }) => (
                        //@ts-ignore
                        <div ref={registerChild}>
                            <Grid container spacing={2} style={{ padding: '16px' }}>
                                {images.map(renderImageTile)}
                            </Grid>
                        </div>
                    )}
                </WindowScroller>

                {!category?.fromDiary && <Menu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (renameImage) {
                                setRenameOpen(true);
                            }
                        }}
                    >
                        {t('rename')}
                    </MenuItem>
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onCategoryChange();
                        }}
                    >
                        {t('buildings.moveToFolder')}
                    </MenuItem>
                </Menu>}

                <DeleteDialog data={toDelete} onDeleteAgree={(data) => {
                    handleDelete(data);
                    setToDelete(undefined);
                }} onClose={() => {
                    setToDelete(undefined);
                }} open={toDelete !== undefined} />
                <RenameImageDialog oldName={renameImage && renameImage.name ? renameImage.name : ''} open={renameOpen} onClose={() => { setRenameImage(undefined); setRenameOpen(false); setContextMenu(null); }} imageId={renameImage ? renameImage.id : undefined} onConfirm={(id, name) => {
                    onNameChange(name, id);
                }} />
            </div>
        );
}