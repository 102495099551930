import { BookOnlineOutlined, ConstructionOutlined, Dashboard, FolderOutlined, Group, Home, PrecisionManufacturingOutlined, QueryStatsOutlined, SettingsOutlined, Task } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from '@mui/material';
import * as React from 'react';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { StavarioModule } from './StavarioModule';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

export interface IUserLayoutMobileMenuProps {
    showMobileMenu: boolean;
    setShowMobileMenu: (show: boolean) => void;
}

export default function UserLayoutMobileMenu(props: IUserLayoutMobileMenuProps) {
    const { showMobileMenu, setShowMobileMenu } = props;
    const { activeModule, setActiveModule, modulesVisibility } = useUserLayoutContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isProjectActive = React.useMemo(() => activeModule === StavarioModule.ProjectDetail || activeModule === StavarioModule.ProjectList, [activeModule]);
    const isDiaryActive = React.useMemo(() => activeModule === StavarioModule.DiariesList || activeModule === StavarioModule.DiaryDetail || activeModule === StavarioModule.DiaryRecordsList || activeModule === StavarioModule.DiaryRecordDetail, [activeModule]);
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const handleClose = () => {
        setShowMobileMenu(false);
        setAnchorEl(null);
    };
    return (
        <Menu
            anchorEl={anchorEl}
            open={showMobileMenu}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/dashboard/employees'; }}>
                <ListItemIcon>
                    <Dashboard />
                </ListItemIcon>
                <ListItemText primary={t('Dashboard')} />
            </MenuItem>
            <MenuItem sx={{ background: isProjectActive ? theme.palette.primary.main : 'inherit' }} onClick={() => { setActiveModule(StavarioModule.ProjectList); handleClose(); navigate('projects'); }}>
                <ListItemIcon>
                    <ConstructionOutlined />
                </ListItemIcon>
                <ListItemText primary="Projekty" />
            </MenuItem>
            {modulesVisibility?.diary && <MenuItem sx={{ background: isDiaryActive ? theme.palette.primary.main : 'inherit' }} onClick={() => { setActiveModule(StavarioModule.DiaryRecordsList); handleClose(); navigate('diary/diaryvov'); }}>
                <ListItemIcon>
                    <BookOnlineOutlined />
                </ListItemIcon>
                <ListItemText primary="Stavební deník" />
            </MenuItem>}
            {modulesVisibility?.employees && < MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/employees'; }}>
                <ListItemIcon>
                    <Group />
                </ListItemIcon>
                <ListItemText primary={t('employees')} />
            </MenuItem>}
            {modulesVisibility?.wealth && <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/wealth'; }}>
                <ListItemIcon>
                    <PrecisionManufacturingOutlined />
                </ListItemIcon>
                <ListItemText primary={t('Majetek')} />
            </MenuItem>}
            {modulesVisibility?.requests && <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/requests'; }}>
                <ListItemIcon>
                    <Task />
                </ListItemIcon>
                <ListItemText primary={t('todo.tasks')} />
            </MenuItem>}
            {modulesVisibility?.warehouse && <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/stock-warehouses'; }}>
                <ListItemIcon>
                    <Home />
                </ListItemIcon>
                <ListItemText primary={t('dashboard.warehouse')} />
            </MenuItem>}
            {modulesVisibility?.reports && <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/building-hours/'; }}>
                <ListItemIcon>
                    <QueryStatsOutlined />
                </ListItemIcon>
                <ListItemText primary={t('menu.reports')} />
            </MenuItem>}
            {modulesVisibility?.documents && <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/document-templates'; }}>
                <ListItemIcon>
                    <FolderOutlined />
                </ListItemIcon>
                <ListItemText primary={t('Documents')} />
            </MenuItem>}
            {modulesVisibility?.settings && <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + '.vimovsem.cz/admin/settings'; }}>
                <ListItemIcon>
                    <SettingsOutlined />
                </ListItemIcon>
                <ListItemText primary={t('Settings')} />
            </MenuItem>}
        </Menu >
    );
}
