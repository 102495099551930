import { APIOldBuildingsClient } from "../../../ApiOld/ApiOldBuildingsClient";
import { APIVovHelper } from "../../../ApiOld/ApiOldClient";
import { APIVovEmployeeHelper } from "../../../ApiOld/ApiOldEmployeeClient";
import { ApiOldInvoicesClient } from "../../../ApiOld/ApiOldInvoicesClient";
import { APIOldWealthClient } from "../../../ApiOld/ApiOldWealthClient";
import { APIVovSubcontractorHelper } from "../../../ApiOld/ApiSubcontractorOldClient";
import { TrashType } from "./TrashType";

export default async function RemoveFromTrashServer(trashType: TrashType, id: number[], isSubdodavatel: boolean): Promise<boolean> {

    switch (trashType) {
        case TrashType.Projects:
            return await RemoveProjectFromTrash(id);
        case TrashType.Diaries:
            return await RemoveDiaryFromTrash(id);
        case TrashType.DailyRecords:
            return await RemoveDailyRecordsFromTrash(id, isSubdodavatel);
        case TrashType.Wealths:
            return await RemoveWealthsFromTrash(id);
        case TrashType.Invoices:
            return await RemoveInvoicesFromTrash(id);
        case TrashType.Todos:
            return await RemoveTodosFromTrash(id);
        case TrashType.Employees:
            return await RemoveEmployeesFromTrash(id);
        default:
            return false;
    }

}

async function RemoveProjectFromTrash(id: number[]): Promise<boolean> {
    return await (new APIOldBuildingsClient().MoveProjectsFromTrash(id));
}
async function RemoveDiaryFromTrash(id: number[]): Promise<boolean> {
    return await (new APIVovHelper().MoveDiariesFromTrash(id));
}
async function RemoveDailyRecordsFromTrash(id: number[], isSubdodavatel: boolean): Promise<boolean> {
    if (isSubdodavatel)
        return await (new APIVovSubcontractorHelper().MoveDailyRecordsFromTrash(id))
    else
        return await (new APIVovHelper().MoveDailyRecordsFromTrash(id));
}
async function RemoveWealthsFromTrash(id: number[]): Promise<boolean> {
    return await (new APIOldWealthClient().MoveWealthsFromTrash(id));
}

async function RemoveInvoicesFromTrash(id: number[]): Promise<boolean> {
    return await (new ApiOldInvoicesClient().MoveInvoicesFromTrash(id));
}

async function RemoveTodosFromTrash(ids: number[]): Promise<boolean> {
    return await (new APIVovHelper().MoveTodosFromThrash(ids));
}

async function RemoveEmployeesFromTrash(id: number[]): Promise<boolean> {
    return await (new APIVovEmployeeHelper().MoveEmployeesFromTrash(id));
}
