import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Validate, ValidationGroup } from 'mui-validate';
import BasicUpload from '../../Shared/Files/BasicUploadComponent';

interface InvestorUploadPhotoDialogProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    buildingId: number;
}
export default function InvestorUploadPhotoDialog(props: InvestorUploadPhotoDialogProps) {
    const { open, onClose, buildingId } = props;

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [uploading, setUploading] = React.useState<boolean>(false);


    const { t } = useTranslation();

    useEffect(() => {
        setRefresh(false);

    }, []);

    return (
        <Dialog
            open={open}
            sx={{ marginTop: '100px' }}
            onClose={() => { onClose(refresh) }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="upload-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('files.addPhotos')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose(refresh); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>

                <Grid item xs={4} sx={{ marginTop: '-35px', marginRight: '32px' }}>
                    <BasicUpload isBuilding={true} isSubdodavatel={false} isInvestor={true} uploadStarted={() => { setUploading(true) }} uploadCompleted={() => { setUploading(false) }} recordId={buildingId} onlyImages={true} onNeedRefresh={() => { setRefresh(true); }} />
                </Grid>
                {!uploading && <Button onClick={() => { onClose(refresh) }} style={{
                    marginLeft: 'auto', marginRight: '10px', border: '1px solid', borderColor: 'lightgray'

                }} aria-describedby='btnOk'>
                    <Typography color='#FFC600'>OK</Typography>
                </Button>}
            </DialogContent>
        </Dialog>
    )
}
