import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { EmployeeGroupsDTO, GetWealthDetailsResponse, WealthVovClient } from '../ApiOld/Api/ApiServerVov';
import { useApi } from '../ApiOld/Api/useApi';

interface WealthsContextProps {
    wealthDetailData?: GetWealthDetailsResponse;
    setWealthDetailData?: React.Dispatch<React.SetStateAction<GetWealthDetailsResponse | undefined>>;
    wealthId?: number;
    setWealthId?: React.Dispatch<React.SetStateAction<number | undefined>>;
    employeeGroups?: EmployeeGroupsDTO[];
}

const WealthsContext = createContext<WealthsContextProps>({});

export const useWealthsContext = () => useContext(WealthsContext);

interface WealthsProviderProps {
    children: ReactNode;
}

export const WealthsProvider: React.FC<WealthsProviderProps> = ({ children }) => {
    const [wealthDetailData, setWealthDetailData] = useState<GetWealthDetailsResponse | undefined>(undefined);
    const [wealthId, setWealthId] = useState<number | undefined>(undefined);
    const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroupsDTO[] | []>([]);
    const { authorizedApi } = useApi(WealthVovClient);

    const reloadData = useCallback(async () => {
        if (wealthId) {
            try {
                const data = await authorizedApi.getWealthDetails(wealthId);
                setWealthDetailData(data);
            } catch (error) {
                console.error("Failed to load wealth details:", error);
            }
        }
    }, [authorizedApi, wealthId]);

    const reloadGroups = useCallback(async () => {
        const groups = await authorizedApi.getEmployeeGroupsForAdminUser();
        setEmployeeGroups(groups.employeeGroups ?? []);
    }, [authorizedApi]);
    useEffect(() => { reloadGroups(); }, [reloadGroups]);
    useEffect(() => { reloadData() }, [reloadData]);

    return (
        <WealthsContext.Provider value={{ wealthDetailData, setWealthDetailData, setWealthId, wealthId, employeeGroups }}>
            {children}
        </WealthsContext.Provider>
    );
};