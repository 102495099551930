import { ArrowDropDown, ArrowRight, Close, Folder, FolderSpecial, Restore } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { FileDirectoryDeleteDTO, RestoreDeletedProjectFilesRequest, VovClient } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import './ProjectDocumentTreeScene.css'
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { useApi } from '../../../../ApiOld/Api/useApi';

interface ProjectDocumentTreeThrashSceneProps {
    projectId: number;
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function ProjectDocumentTreeThrashScene(props: ProjectDocumentTreeThrashSceneProps) {
    const { projectId, open, onClose } = props;
    const [files, setFiles] = React.useState<FileDirectoryDeleteDTO[] | null>(null);
    const [selectedFiles, setSelectedFiles] = React.useState<number[]>([]);
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        if (open)
            authorizedApi?.getDeletedProjectFilesToRestore(projectId).then(p => {
                setFiles(p.files ?? []);
            });
    }, [open]);

    useEffect(() => {
        if (!open)
            setSelectedFiles([]);
    }, [open]);

    const FileTreeView = ({ files }) => (
        <div style={{ maxHeight: '90%', minHeight: '120px', overflow: 'auto' }}>
            {files !== null &&
                files.map((file, index) => (
                    <FileNode key={'key' + index} file={file} setFiles={setFiles} index={index} level={1} />
                ))}
        </div>
    );

    const FileNode = (props) => {
        const { file, setFiles, index, level } = props;

        const toggleExpanded = (files: FileDirectoryDeleteDTO[], fileId: number): FileDirectoryDeleteDTO[] => {
            return files.map((item) => {
                if (item.id === fileId) {
                    return new FileDirectoryDeleteDTO({ ...item, isExpanded: !item.isExpanded });
                } else if (item.children && item.children.length > 0) {
                    return new FileDirectoryDeleteDTO({ ...item, children: toggleExpanded(item.children, fileId) });
                }
                return item;
            });
        };

        const handleToggleExpand = (e: any, fileId: number) => {
            e.preventDefault(); e.stopPropagation();
            setFiles((prev) => (prev ? toggleExpanded(prev, fileId) : prev));
        };

        const hasChildren = () => {
            return file && file.children && file.children.length > 0;
        }

        if (file)
            return (
                <div key={'key' + file.id} onClick={(e) => { /* e.preventDefault(); e.stopPropagation(); */ }} >
                    <div style={{
                        display: 'flex',
                        border: '0px solid',
                        borderColor: 'white',
                        cursor: 'pointer',
                    }}
                        className={`selectable ${file.isSelected ? 'selected' : ''}`} >
                        {hasChildren() && <div style={{ color: 'black' }}><Button size='small' onClick={(e) => handleToggleExpand(e, file.id)}>
                            {!file.isExpanded && <ArrowRight htmlColor='black' />}
                            {file.isExpanded && <ArrowDropDown htmlColor='black' />}
                        </Button></div>}
                        {!hasChildren() && <div style={{ width: '64px', background: 'white' }}>

                        </div>}
                        <Box>
                            {file.isDeleted && <Checkbox checked={selectedFiles.includes(file.id)} onChange={(e, checked) => {
                                console.log('prev wtf', e, file, checked);
                                e.preventDefault(); e.stopPropagation();

                                if (selectedFiles.includes(file.id))
                                    setSelectedFiles(selectedFiles.filter(p => p !== file.id));
                                else
                                    setSelectedFiles([...selectedFiles, file.id]);

                            }} />}
                            {/* {!file.isDeleted && <div style={{ width: '32px' }} />} */}
                        </Box>
                        <div style={{ display: 'flex', marginTop: '6px', paddingTop: '2px', cursor: 'context-menu'/* , marginLeft: '-16px' */ }}>
                            {file.isDirectory ? <Folder color='primary' /> : <FolderSpecial color='primary' />}
                            <Typography sx={{ marginLeft: '8px' }}>{file.name}</Typography>
                        </div >

                    </div>
                    {file.isExpanded && (
                        <div style={{ marginLeft: 20 + 'px' }}>
                            {file.children &&
                                file.children.map((child, i) => (
                                    <FileNode key={i} file={child} setFiles={setFiles} index={i} level={level + 1} />
                                ))}
                        </div>
                    )}
                </div>
            );
        else
            return <div></div>
    };

    if (files && files.length > 0)
        return <Dialog maxWidth="lg"
            open={open} onClose={() => onClose(false)}
            sx={{ margin: 'auto' }}>
            <DialogTitle sx={{ zIndex: "28", backgroundColor: '#F5F5F5' }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('files.restoreFolders')} </Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                        <div style={{ marginTop: '-14px', display: 'flex' }}>
                            <Button disabled={selectedFiles.length === 0} variant='contained' startIcon={<Restore />} onClick={() => {
                                if (selectedFiles.length > 0) {
                                    authorizedApi?.restoreDeletedProjectFiles(new RestoreDeletedProjectFilesRequest({ projectId: projectId, filesIds: selectedFiles })).then(p => {
                                        onClose(true);
                                    });
                                }
                            }} sx={{ marginTop: '8px' }}>
                                {t('refresh')}
                            </Button>
                            <IconButton onClick={() => onClose(false)} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <FileTreeView files={files} />
            </DialogContent>
            {/* <CircularProgressDialog open={createFolderDialogOpen} onClose={() => setCreateFolderDialogOpen(true)} title={t('creatingFolderStructure')} /> */}
            {/* <UploadSnackbar onClose={() => { setUploadingFiles(undefined) }} isOpen={uploadingFiles !== undefined} files={uploadingFiles} /> */}
        </Dialog>;
    else
        return <div></div>;
}
