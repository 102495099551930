import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ImageDialogProps {
    open: boolean;
    onClose: () => void;
    imageUrl: string | null;
}

export const ImageDialog: React.FC<ImageDialogProps> = ({ open, onClose, imageUrl }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent style={{ textAlign: 'center', position: 'relative' }}>
                <IconButton
                    onClick={onClose}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    <CloseIcon />
                </IconButton>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt="Full-size preview"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '90vh',
                            objectFit: 'contain', // Ensures the image fits within the dialog
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
