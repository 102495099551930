import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertTitle, Box, Button, Card, Slide, Snackbar, TextField } from "@mui/material";
import "./LoginScene.css";
import Passwordbox from "../Shared/Passwordbox";
//@ts-ignore
import jsPDF from 'jspdf';
import LanguagePicker from "../Shared/LanguagePicker";
import { APIVovHelper } from "../../ApiOld/ApiOldClient";
import { ApiException } from "../../ApiOld/ApiServerVov";
import { useLoginAdminStore } from "../../Contexts/LoginAdminZContext";

interface LoginOldVovProps {
    onLogin?(): void;
}

export default function LoginOldVov(props: LoginOldVovProps) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    let [userName, setUserName] = useState("");
    let [password, setPassword] = useState("");
    let [appUri, setAppUri] = useState("");
    const [openLoginError, setOpenLoginError] = React.useState(false);
    const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        if (e.animationName === "mui-auto-fill") {
            stateSetter(autofilled);
        }

        if (e.animationName === "mui-auto-fill-cancel") {
            stateSetter(autofilled);
        }
    };
    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
        setAppUri(params['domain']!);
    }, []);
    const { load } = useLoginAdminStore();
    const CallOldApiWithLogin = async (e: any) => {
        try {
            console.log('start auth');
            let a = await (new APIVovHelper().Login(appUri, userName, password));
            if (a.userName && a.id)
                load(a.userName, a.id);
            if (props.onLogin)
                props.onLogin();
            else
                navigate({
                    pathname: 'userlayout/projects',
                });

        }
        catch (ex) {
            if (ex instanceof ApiException) {
                setOpenLoginError(true);
            }
            else {

            }
        }
    }


    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const onTextChange = (event: any) => {
        setUserName(event.currentTarget.value);
    };
    const onPassChange = (event: string) => {
        setPassword(event);
    };
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };
    const [userNameHasValue, setUserNameHasValue] = React.useState(false);

    return (

        <Box display='flex' alignItems={'center'} justifyContent={'center'} height={'100vh'} width={'100%'}>
            <Card sx={{ minWidth: '400px' }}>
                <Box display='flex' alignItems={'center'} flexDirection='column' sx={{ p: 4 }} >
                    <img
                        width="200px"
                        height="54px"
                        src="https://stavariokluc.vimovsem.cz/images/login-logo-stavario.svg"
                        alt="stavario"
                    ></img>
                    <TextField
                        fullWidth
                        inputProps={{
                            onAnimationStart: makeAnimationStartHandler(setUserNameHasValue)
                        }}
                        InputLabelProps={{
                            shrink: userNameHasValue || userName.length > 0
                        }}
                        sx={{ mt: 4 }}
                        id="outlined-basic"
                        label={t('login.enterEmail')}
                        size='small'
                        value={userName}
                        onChange={onTextChange}
                        variant="outlined" />
                    <Passwordbox

                        fullWidth
                        size='small'
                        sx={{ mt: 4 }}
                        onEnter={() => { CallOldApiWithLogin(undefined); }}
                        password={password}
                        onPasswordChange={onPassChange}></Passwordbox>
                    <Button
                        fullWidth
                        sx={{ mt: 4 }}
                        onClick={(e) => { CallOldApiWithLogin(undefined); }} variant="contained" type="submit">
                        {t('login.sign')}
                    </Button>
                    <p style={{ marginTop: '26px' }}>
                        {t('login.forgot')} <a onClick={(e) => {
                            navigate(
                                { pathname: '/' + i18n.language + '/' + params['domain']! + '/resetpassword', },
                                { state: { email: userName } }
                            );
                        }} style={{ color: '#ffc107', textAlign: 'center', cursor: 'pointer' }}>{t('login.password')}</a>
                    </p>
                    <LanguagePicker showText={false} onLanguageChange={(e) => { navigate('/' + e + '/' + appUri) }} />
                </Box>
                <div>
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('login.loginFailed')}</AlertTitle>
                            <span>{t('login.loginFailedDetail')}</span>
                        </Alert>
                    </Snackbar>
                </div>
            </Card>

        </Box >
    )



}