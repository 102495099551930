import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, TextareaAutosize, Typography } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BuildingStateEnum, GetBuildingDataDetailResponse } from '../../../ApiOld/ApiServerVov';
import ProjectPosition from './GoogleMap/ProjectPosition';
import CellTowerIcon from '@mui/icons-material/CellTower';
import RequestEyetowerDialog from './Components/RequestEyetowerDialog';
import { useBuildingsPermissionContext } from '../../../Contexts/Permissions/BuildingsPermissionContext';

interface ProjectMainDetailProps {
  projectData: Partial<GetBuildingDataDetailResponse>;
  setProjectData: (data: Partial<GetBuildingDataDetailResponse> | null) => void;

}

export default function ProjectMainDetail(props: Readonly<ProjectMainDetailProps>) {
  const { projectData, setProjectData } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { permissions, reloadPermissions } = useBuildingsPermissionContext();

  useEffect(() => {
    reloadPermissions();
  }, [reloadPermissions]);

  return (
    <Box>
      <ValidationGroup>
        <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '0px', marginTop: '16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} p={0} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                  <TextField label={t('buildings.buildingName')} variant="outlined" fullWidth size='small'
                    name='name'
                    value={projectData.name}
                    onChange={(e) => { setProjectData({ ...projectData, name: e.target.value }) }}
                    sx={{ my: 1 }}
                  />
                </Validate>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={3}>
                    <Validate name='code-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                      <TextField label={t('buildings.buildingCode')} variant="outlined" fullWidth size='small'
                        name='code'
                        value={projectData.code}
                        onChange={(e) => { setProjectData({ ...projectData, code: e.target.value }); }}
                        sx={{ my: 1 }}
                      />
                    </Validate>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField value={Number(projectData.isActive)} sx={{ lineHeight: '1rem', my: 1 }} onChange={(e) => { setProjectData({ ...projectData, isActive: Boolean(e.target.value) }) }} variant="outlined" fullWidth label={t('active')} select size='small' >
                      <MenuItem value={1}>{t('yesL')}</MenuItem>
                      <MenuItem value={0}>{t('noL')}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField sx={{ lineHeight: '1rem', my: 1 }} value={Number(projectData.showToEmployees)} onChange={(e) => { setProjectData({ ...projectData, showToEmployees: Boolean(e.target.value) }) }} variant="outlined" fullWidth label={t('showToEmployees')} select size='small' >
                      <MenuItem sx={{ height: '20px' }} value={1}>{t('yesL')}</MenuItem>
                      <MenuItem sx={{ height: '20px' }} value={0}>{t('noL')}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField label={t('hourlyRate')} variant="outlined" fullWidth size='small'
                      name='hourlyRate'
                      type="number"
                      value={projectData.hourlyRate}
                      onChange={(e) => { setProjectData({ ...projectData, hourlyRate: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                      sx={{ my: 1 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={3}>
                    <TextField label={t('buildings.contractNumber')} variant="outlined" fullWidth size='small'
                      name='contractNumber'
                      value={projectData.contractNumber}
                      onChange={(e) => { setProjectData({ ...projectData, contractNumber: e.target.value }) }}
                      sx={{ my: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormControl sx={{ my: 1, width: '100%' }} size="small">
                      <InputLabel id="demo-select-small-label">{t('buildings.projectState')}</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={projectData.state}
                        label={t('buildings.projectState')}
                        onChange={(e) => { setProjectData({ ...projectData, state: e.target.value as BuildingStateEnum }) }}
                      >
                        <MenuItem value={undefined}>--</MenuItem>
                        <MenuItem value={BuildingStateEnum.Preparing}>{t('buildings.preparing')}</MenuItem>
                        <MenuItem value={BuildingStateEnum.Realization}>{t('buildings.realization')}</MenuItem>
                        <MenuItem value={BuildingStateEnum.Done}>{t('buildings.completed')}</MenuItem>
                        <MenuItem value={BuildingStateEnum.Cancelled}>{t('buildings.cancelled')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField label={t('buildings.houseType')} variant="outlined" fullWidth size='small'
                      name='houseType'
                      value={projectData.houseType}
                      onChange={(e) => { setProjectData({ ...projectData, houseType: e.target.value }) }}
                      sx={{ my: 1 }}
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <TextField label={t('Investor')} variant="outlined" fullWidth size='small'
                      name='client'
                      value={projectData.client}
                      onChange={(e) => { setProjectData({ ...projectData, client: e.target.value }) }}
                      sx={{ my: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField label={t('Architect')} variant="outlined" fullWidth size='small'
                      name='architect'
                      value={projectData.architect}
                      onChange={(e) => { setProjectData({ ...projectData, architect: e.target.value }) }}
                      sx={{ my: 1 }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <InputLabel sx={{ margin: '0px 8px -10px 8px' }} htmlFor="address-textarea">{t('address')}</InputLabel>
                    <TextareaAutosize style={{ width: '100%', minHeight: '50px', maxHeight: '80px', borderRadius: '4px', borderColor: 'lightgray', margin: '8px 0px', resize: 'none' }}

                      minRows={3} maxRows={6}
                      name='address'
                      value={projectData?.address1}
                      onChange={(e) => { setProjectData({ ...projectData, address1: e.target.value }) }}
                    />
                  </Grid>
                  {projectData && projectData.id === 0 && <Grid item lg={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={6}>
                        <FormControlLabel
                          sx={{ marginLeft: '16px' }}
                          value="end"
                          control={<Checkbox
                            checked={projectData.createDiary}
                            onChange={(e, checked) => { setProjectData({ ...projectData, createDiary: checked }) }}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                          label={<Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{t('buildings.createDiary')}</Typography>}
                          labelPlacement="end"
                        />

                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControlLabel
                          sx={{ marginLeft: '16px' }}
                          value="end"
                          control={<Checkbox
                            checked={projectData.createWarehouse}
                            onChange={(e, checked) => { setProjectData({ ...projectData, createWarehouse: checked }) }}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                          label={<Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{t('buildings.createWarehouse')}</Typography>}
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                  </Grid>}
                </Grid>

              </Grid>
              <Grid item xs={12} lg={6}>
                <ProjectPosition
                  projectData={projectData}
                  setProjectData={setProjectData}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </ValidationGroup>
    </Box>
  )
}
