import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import InvoiceList from './InvoiceList';
import InvoiceDetail from './InvoiceDetail';
import { BuildingBillsPermissionProvider } from '../../../Contexts/Permissions/BuildingBillsPermissionContext';

interface InvoicesProps {
    type: 'list' | 'detail' | 'detailBuilding' | 'new';
}

export default function Invoices({ type }: InvoicesProps) {
    const params = useParams();
    const [invoiceId, setInvoiceId] = useState<number | undefined>();
    const [buildingId, setBuildingId] = useState<number | undefined>();

    useEffect(() => {
        if (params.buildingId) {
            setBuildingId(parseInt(params.buildingId));
        } else {
            setBuildingId(undefined);
        }
        if (params.invoiceId) {
            setInvoiceId(parseInt(params.invoiceId));
        } else {
            setInvoiceId(undefined);
        }
    }, [params]);
    return (
        <BuildingBillsPermissionProvider>
            {type === 'list' && <InvoiceList buildingId={buildingId} />}
            {type === 'detail' && <InvoiceDetail fromProject={false} invoiceId={invoiceId} />}
            {type === 'detailBuilding' && <InvoiceDetail fromProject={true} invoiceId={invoiceId} />}
            {type === 'new' && <InvoiceDetail fromProject={buildingId !== undefined} invoiceId={0} />}
        </BuildingBillsPermissionProvider>
    )
}