
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Search, Sort, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, Page } from '@syncfusion/ej2-react-grids';
import { Button, useTheme } from '@mui/material';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';

import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { WealthDTO } from '../../../ApiOld/ApiServerVov';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { LocalShipping } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useApi } from '../../../ApiOld/Api/useApi';
import { WealthVovClient } from '../../../ApiOld/Api/ApiServerVov';

interface WealthListProps {

}

function WealthList(props: WealthListProps) {
    InitSfGridLocale();

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Majetku</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [wealth, setWealths] = useState<WealthDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const grid = useRef<GridComponent>(null);
    const { authorizedApi } = useApi(WealthVovClient);
    const { setMenuButtons, setBackMenuFunction } = useUserLayoutContext();
    const refresh = useCallback(async () => {
        const machines = await authorizedApi.getWealthsForAdminUser(false);
        setWealths(machines.wealths ?? []);


    }, [authorizedApi]);

    useEffect(() => {
        refresh();
    }, [refresh]);
    useEffect(() => {
        setMenuButtons(<Button>vbejce</Button>)
    }, [setMenuButtons]);
    useEffect(() => {
        setBackMenuFunction(undefined);
    }, [setBackMenuFunction]);


    function gotoDetail(id: number) {
        navigate({
            pathname: `/${i18n.language}/${params['domain']!}/userlayout/wealthdetail/${id}`,
        },);
    }
    function GridTemplate(props: WealthDTO) {
        const [hover, setHover] = useState(false);
        return (<div style={{ cursor: 'pointer' }} onClick={(e) => { if (props.id) gotoDetail(props.id); }} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <a style={{
                color: theme.palette.text.primary, textDecoration: hover ? 'underline' : 'none',
                fontWeight: props.wealthState !== undefined && props.wealthState === "rented" ? 'bold' : 'normal',

            }}

            >{props.name}</a>
        </div >);
    }

    function StateTemplate(props: WealthDTO) {
        const color = props.wealthState === 'available' ? '#00BFA5' : 'red';
        return <span style={{ color, fontWeight: 'bold' }}>{props.wealthState === 'available' ? t('wealth.available') : t('wealth.rented')}</span>;
    }


    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    // function refresh() {
    //     new APIOldWealthClient().GetWealthsForAdminUser(false).then((r) => { if (r.wealths) setWealths(r.wealths); if (grid.current) grid.current.refresh(); });
    // }

    if (wealth !== null)
        return (
            <div>
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
                    <UpperMenu moduleName={t('wealth.wealthList')}
                        gridType={GridType.Todos} gridId='grid-component-wealth-list-new'
                        canAdd
                        canTrash
                        onThrashClicked={() => { setTrashOpen(true); }}
                        canDelete
                        onDelete={(e) => { new APIOldWealthClient().deleteWealths(e as number[]).then((e) => { if (e) refresh(); }) }}
                        onCreate={() => {
                            navigate(
                                {
                                    pathname: `/${i18n.language}/${params['domain']!}/userlayout/wealthdetail/0`,
                                },
                            );
                        }}
                        canChangeEyeView={false}
                        grid={grid} canChangeGroup canToolbarGrid
                        menuName={t('View')}
                        icon={<LocalShipping />}
                        canExcelExport
                    />
                    <GridComponent ref={grid} id="grid-component-wealth-list-new" allowSorting={true}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language} enablePersistence={false}
                        recordDoubleClick={(e) => { gotoDetail(e.rowData.id) }}
                        filterSettings={filterOptions}
                        allowPaging={true}
                        style={{ backgroundColor: '#F5F5F5' }}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        toolbarClick={toolbarClickExcel}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={true} groupSettings={groupOptions}
                        dataSource={wealth}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width='60' toolTip='checkbox' />
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} toolTip='' showInColumnChooser={false} />
                            <ColumnDirective field='code' headerText={t('code')} width='100' toolTip={t('code')} />
                            <ColumnDirective field='wealthState' headerText={t('state')} template={StateTemplate} valueAccessor={(field: any, wealth: any) => {
                                return wealth[field] === 'available' ? t('wealth.available') : t('wealth.rented');
                            }} />
                            <ColumnDirective template={GridTemplate} field='name' headerText={t('Name')} toolTip={t('Name')} />
                            <ColumnDirective field='serialNumber' headerText={t('SerialNumber')} toolTip={t('Name')} />
                            <ColumnDirective field='responsibleEmployee' headerText={t('ResponsibleEmployee')} toolTip={t('Name')} />
                            <ColumnDirective field='buildingName' headerText={t('buildings.buildingName')} toolTip={t('Name')} />
                            <ColumnDirective field='stockName' headerText={t('WarehouseName')} toolTip={t('Name')} />
                            <ColumnDirective field='isActive' type='boolean' editType='booleanedit' displayAsCheckBox headerText={t('active')} toolTip={t('Active')} />
                            <ColumnDirective field='showToEmployee' type='boolean' editType='booleanedit' displayAsCheckBox headerText={t('showToEmployees')} />

                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                    </GridComponent>
                    <TrashGridDialog trashType={TrashType.Wealths} open={trashOpen} onClose={(e) => { console.log(e); setTrashOpen(false); if (e) refresh(); }} />
                </div>

            </div>
        );
    return <div>{t('noRecords')}</div>;
};
export default WealthList;