import React from "react";
import { CopyCutPasteType } from "../../../../ApiOld/ApiServerVov";


// Create the context
export const CopyPasteContext = React.createContext<{
    copyPasteId: number | null;
    setCopyPasteId: React.Dispatch<React.SetStateAction<number | null>>;
    copyPasteFileIds: number[] | null;
    setCopyPasteFileIds: React.Dispatch<React.SetStateAction<number[] | null>>;
    copyCutType: number | null;
    setCopyCutType: React.Dispatch<React.SetStateAction<CopyCutPasteType | null>>;
} | null>(null);

// Create a provider component
export const CopyPasteProvider = ({ children }) => {
    const [copyPasteId, setCopyPasteId] = React.useState<number | null>(null);
    const [copyPasteFileIds, setCopyPasteFileIds] = React.useState<number[] | null>(null);
    const [copyCutType, setCopyCutType] = React.useState<CopyCutPasteType | null>(null);

    return (
        <CopyPasteContext.Provider value={{ copyPasteId, setCopyPasteId, copyPasteFileIds, setCopyPasteFileIds, copyCutType, setCopyCutType }}>
            {children}
        </CopyPasteContext.Provider>
    );
};