import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Autocomplete, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Validate, ValidationGroup } from 'mui-validate';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { AddImageCategoryRequest, ImageCategoryDTO, RenameCategoryBuildingRequest, RenameImagesBuildingRequest, SetImagesCategoryRequest, VovClient } from '../../../../ApiOld/ApiServerVov';

interface MoveToCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    imageIds: number[];
    categories: ImageCategoryDTO[];
}
export default function MoveToCategoryDialog(props: MoveToCategoryDialogProps) {
    const { open, onClose, onConfirm, imageIds, categories } = props;

    const [category, setCategory] = React.useState<ImageCategoryDTO | null>(null);
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
    const [openLoginError, setOpenLoginError] = React.useState(false);

    const { authorizedApi } = useApi(VovClient);
    const autoC = useRef<HTMLDivElement>(null);

    /* useEffect(() => {
        console.log(oldName, 'oldName');
        setName(oldName);
    }, [oldName]); */

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    const { t } = useTranslation();
    return (
        <Dialog
            disablePortal
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="move-dialog-title"
            aria-describedby="move-dialog-description"
        >
            <DialogTitle id="cmove-category-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '300px' }}>{t('buildings.moveToFolder')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setCategory(null); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ minHeight: '400px' }}>
                    <ValidationGroup>
                        <Validate name='category-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]} >
                            <Autocomplete
                                ref={autoC}
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                options={categories}
                                getOptionKey={(option) => option.id!}
                                getOptionLabel={(option) => option.name!}
                                onChange={(e, value) => { setCategory(value); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{ margin: '12px', width: '526px' }}
                                        label={t("buildings.chooseFolder")}
                                    //onSelect={handleInput}
                                    />
                                )}
                            />
                        </Validate>
                    </ValidationGroup>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                    <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled || !category} onClick={() => {
                        if (category) {
                            setButtonDisabled(true);
                            authorizedApi.setImagesCategory(new SetImagesCategoryRequest({ imageIds: imageIds, categoryId: category.id })).then((r) => {
                                setButtonDisabled(false);
                                if (r.ok) {
                                    if (onConfirm)
                                        onConfirm();

                                    setCategory(null);
                                    onClose();
                                }
                                else {
                                    setOpenLoginError(true);
                                }
                            });
                        }
                    }}>{t('save')}</Button>
                </div>
                <div className="move-form-popup">
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('buildings.errorMoveToFolder')}</AlertTitle>
                            {t('buildings.errorMoveToFolderDesc')}
                        </Alert>
                    </Snackbar>
                </div>
            </DialogContent>
        </Dialog>
    )
}
