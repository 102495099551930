import { ColumnsDirective, GridComponent, Group, Sort, Filter, Search, Toolbar, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, ExcelExport, } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { WealthHistoryDTO } from '../../../ApiOld/ApiServerVov';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import { Box } from '@mui/material';
import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { useDiaryTabsContext } from '../../../Contexts/Providers/DiaryTabsProvider';

InitSfGridLocale();
interface ProjectsListProps {
}

export default function WealthHistory(props: ProjectsListProps) {

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };


    const [wealthHistory, setWealthHistory] = useState<WealthHistoryDTO[] | undefined>();
    const { t, i18n } = useTranslation();
    const { permissions, refreshPermissions } = useDiaryTabsContext();
    const params = useParams();

    useEffect(() => {
        if (permissions === null)
            refreshPermissions(false);
    }, [permissions, refreshPermissions]);

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const grid = useRef<GridComponent>(null);

    useEffect(() => {
        if (wealthHistory === null || wealthHistory === undefined)
            refresh();
        else setWealthHistory(undefined);
    }, []);

    function refresh() {
        new APIOldWealthClient().GetWealthHistory(params['id'] ? parseInt(params['id']) : 0).then((r) => {
            setWealthHistory(r);
        });
    }

    function deviationTemplate(props) {
        return (
            <span style={{ color: props.deviation === '0 min' ? 'black' : 'red' }}>
                {props.deviation === '' ? t('MissingData') : props.deviation}
            </span>
        );
    }

    if (wealthHistory)
        return <Box sx={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: '10px', overflow: 'hidden', padding: '20px' }}>

            <UpperMenu moduleName=''
                gridType={GridType.Invoices}
                canChangeEyeView={false}
                grid={grid} gridId='grid-component-wealth-new' canChangeGroup canToolbarGrid
                menuName={t('View')}
                backgroundColor='#FFFFFF'
                canExcelExport
            />
            <GridComponent ref={grid} id="grid-component-wealth-new" allowSorting={true}
                allowReordering={true} allowResizing={true} showColumnChooser={true}
                allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language} enablePersistence={true}
                filterSettings={filterOptions}
                allowPaging={true}
                style={{ backgroundColor: '#FFFFFF' }}
                pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                toolbarClick={toolbarClickExcel}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                allowFiltering={true} groupSettings={groupOptions}
                dataSource={wealthHistory}>
                <ColumnsDirective>
                    <ColumnDirective field='responsibleEmployee' headerText={t('ResponsibleEmployee')} width={300} />
                    <ColumnDirective field='building' headerText={t('AtProject')} width={250} />
                    <ColumnDirective field='warehouse' headerText={t('AtWarehouse')} width={250} />
                    <ColumnDirective field='from' type='datetime' format='dd.MM.yyyy HH:mm:ss' headerText={t('from')} width={180} />
                    <ColumnDirective field='to' type='datetime' format='dd.MM.yyyy HH:mm:ss' headerText={t('to')} width={180} />
                    <ColumnDirective field='deviation' headerText={t('TimeDeviation')} template={deviationTemplate} width={180} />
                </ColumnsDirective>
                <Inject services={[Sort, Group, Filter, Search, Toolbar, Reorder, Resize, ColumnChooser, Page, ExcelExport]} />
            </GridComponent>
        </Box>

    return <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '200px', overflow: 'hidden' }}>{t('loading...')}</Box>;
}