import { Box, InputLabel, TextareaAutosize, TextField } from '@mui/material'
import React from 'react'
import { CustomFieldDataType, CustomFieldDto } from '../../ApiOld/Api/ApiServerVov'
import ClickableCheckbox from '../Shared/ClickableCheckbox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IntegerInput from './Inputs/IntegerInput';
import DecimalInput from './Inputs/DecimalInput';
import CustomFieldAutocomplete from './Inputs/CustomFieldAutocomplete';

interface CustomFieldProps {
  customField: CustomFieldDto;
  value: any;
  onValueChange: (value: any) => void;
}


export default function CustomField(props: Readonly<CustomFieldProps>) {
  const { customField, value, onValueChange } = props;
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  console.log(customField.dataType);

  if (customField.dataType === CustomFieldDataType.String || customField.dataType === undefined)
    return (
      <TextField sx={{ mt: 2 }} fullWidth size='small' label={customField.name} value={value} onChange={(e) => { onValueChange(e.currentTarget.value) }} />
    )
  else if (customField.dataType === CustomFieldDataType.Integer)
    return (
      <IntegerInput label={customField.name ?? ''} value={value} onChange={(val) => { onValueChange(val) }} />
    )
  else if (customField.dataType === CustomFieldDataType.Decimal)
    return (
      <DecimalInput label={customField.name ?? ''} value={value} onChange={(val) => { onValueChange(val) }} />
    )
  else if (customField.dataType === CustomFieldDataType.Boolean)
    return (

      <ClickableCheckbox label={customField.name} boxSX={{ mt: '10px' }} checked={value === 'true'} onChange={(e, ch) => { onValueChange(ch ? 'true' : 'false') }} />
    )
  else if (customField.dataType === CustomFieldDataType.DateTime)
    return (
      <Box sx={{ mt: 4 }}>
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
          <DatePicker
            inputFormat="DD.MM.YYYY"
            value={value ? new Date(value) : null}
            onChange={(e) => {
              if (e)
                onValueChange(e);
            }}
            renderInput={(params) => <TextField fullWidth variant='outlined' size='small' {...params} label={customField.name} />} />
        </LocalizationProvider>
      </Box>
    )
  else if (customField.dataType === CustomFieldDataType.Enum)
    return <CustomFieldAutocomplete value={value} onValueChange={onValueChange} customField={customField} />
  else if (customField.dataType === CustomFieldDataType.Paragraph)
    return (
      <Box sx={{ mt: 2 }}>
        <InputLabel htmlFor="customParagraph-textarea">{customField.name}</InputLabel>
        <TextareaAutosize name='customParagraph' value={value} onChange={(e) => {
          if (e)
            onValueChange(e.currentTarget.value);
        }} style={{ width: '100%' }} />
      </Box>
    )
  else return <Box>{t('UnsupportedDataType')}</Box>
}
