import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridRowId,
    GridRowModel,
    GridSlots,
    GridRowSelectionModel,
} from '@mui/x-data-grid';
import { ApiOldInvoicesClient } from '../../../ApiOld/ApiOldInvoicesClient';
import { BuildingDTO, InvoiceItem, TaxrateDTO } from '../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { csCZ } from '@mui/x-data-grid/locales';
import { SolveProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from '../../../Contexts/LoginOldContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import MuiDatagrid from '../../Shared/MuiDatagrid';
import { Stack } from '@mui/material';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

interface InvoiceItemsProps {
    invoiceItems: InvoiceItem[] | undefined;
    saveInvoiceItems: (invoiceItems: InvoiceItem[]) => void;
    permissions: any;
    onEditModeChange: (isEditing: boolean) => void;
}

export default function InvoiceItems(props: InvoiceItemsProps) {
    const { invoiceItems, saveInvoiceItems, permissions, onEditModeChange } = props;
    const { t } = useTranslation();
    const [isSaveTriggered, setIsSaveTriggered] = useState(false);
    const [projects, setProjects] = useState<BuildingDTO[]>([]);
    const [taxRates, setTaxRates] = useState<TaxrateDTO[]>([]);
    const [rows, setRows] = useState(invoiceItems || []);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const globalContext = useContext(StavarioOldGlobalContext);
    const setGlobal = useContext(StavarioOldUpdateContext)!;

    const reloadData = useCallback(() => {
        new ApiOldInvoicesClient().GetTaxrates().then((d) => {
            setTaxRates(d);
        });
        if (invoiceItems) setRows(invoiceItems);
        SolveProjectsContext(globalContext, setGlobal).then((res) => {
            setProjects(res);
        });
    }, [globalContext, invoiceItems, setGlobal]);

    useEffect(() => {
        reloadData();
    }, [invoiceItems, reloadData]);

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            let id;
            do {
                id = Math.floor(Math.random() * 1000000)
            } while (rows.some((row) => row.id === id));

            setRows((oldRows) => [...oldRows, { id, name: "", priceWithoutVat: 0, isNew: true }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Stack sx={{ width: '100%', alignItems: 'center', padding: 1.5, borderBottom: '1px solid lightgray' }} spacing={2} direction={'row-reverse'}>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={
                            !selectionModel ||
                            selectionModel.length === 0 ||
                            !permissions ||
                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetRelItemValues)
                        }
                    >
                        {t('Delete')}
                    </Button>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<AddIcon />}
                        onClick={handleClick}
                        disabled={
                            !permissions ||
                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetRelItemValues)
                        }
                    >
                        {t('Add')}
                    </Button>
                </Stack>
            </GridToolbarContainer>
        );
    }

    const handleDeleteClick = useCallback(() => {
        const selectedIDs = new Set(selectionModel);
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id !== undefined && selectedIDs.has(row.id)
                    ? { ...row, isDeleted: true } as InvoiceItem
                    : row
            )
        );
        setIsSaveTriggered(true);
    }, [selectionModel]);

    useEffect(() => {
        if (isSaveTriggered) {
            saveInvoiceItems([...rows]);
            setIsSaveTriggered(false);
        }
    }, [rows, isSaveTriggered, saveInvoiceItems]);

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isUpdated: true } as InvoiceItem;
        setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
        setIsSaveTriggered(true);
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);

        const isEditing = Object.values(newRowModesModel).some(
            (rowMode) => rowMode.mode === 'edit'
        );
        onEditModeChange(isEditing);
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('Name'),
            editable: !!permissions && (permissions.buildingBillsEdit || permissions.buildingBillsSetRelItemValues),
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'projectId',
            headerName: t('Projekt'),
            editable: !!permissions && (permissions.buildingBillsEdit || permissions.buildingBillsSetRelItemValues),
            type: 'singleSelect',
            flex: 1,
            minWidth: 150,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: projects.map((project) => ({ code: project.id, name: project.name })),
        },
        {
            field: 'priceWithoutVat',
            headerName: t('withoutVat'),
            type: 'number',
            minWidth: 150,
            editable: !!permissions && (permissions.buildingBillsEdit || permissions.buildingBillsSetRelItemValues),
        },
        {
            field: 'taxratesId',
            headerName: t('VatRate'),
            editable: !!permissions && (permissions.buildingBillsEdit || permissions.buildingBillsSetRelItemValues),
            align: 'right',
            headerAlign: 'right',
            type: 'singleSelect',
            minWidth: 200,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: taxRates.map((taxRate) => ({ code: taxRate.id, name: `${t('Vat')} ${taxRate.value}%` })),
        },
    ];

    const totalPriceWithoutVat = rows.reduce((total, row) => total + (row.priceWithoutVat ?? 0), 0);

    return (
        <Box>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                {`${t('totalWithoutVat')} ${totalPriceWithoutVat.toFixed(2)} Kč`}
            </div>
            <MuiDatagrid
                columns={columns}
                rows={rows.filter((row) => !row.isDeleted)}
                editMode="row"
                checkboxSelection
                rowModesModel={rowModesModel}
                onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                }}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </Box>
    );
}
