import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdminUsersDTO, BuildingDTO } from "../../ApiOld/ApiServerVov";
import { useMediaPredicate } from "react-media-hook";
import { APIVovHelper } from "../../ApiOld/ApiOldClient";

export interface AdminUserSelectProps {
    selectedUserId?: number | null;
    onChange: (event: AdminUsersDTO | null) => void;
    isMandatory?: boolean;
    isSubdodavatel?: boolean;
    label?: string;
    disabled?: boolean;
}
export default function AdminUserSelect(props: AdminUserSelectProps) {
    const { onChange, selectedUserId, isMandatory, isSubdodavatel, label, disabled } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedUser, setSelectedUser] = React.useState<AdminUsersDTO | null>(null);
    const [users, setUsers] = React.useState<AdminUsersDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    /*  const handleChange = (event: SelectChangeEvent<typeof selectedProject>) => {
         if (event.target.value !== undefined) {
             setSelectedProject(event.target.value as (ProjectDTO | null));
 
             onChange(event.target.value as (ProjectDTO | null));
         }
 
     }; */
    useEffect(() => {
        if (isSubdodavatel !== true)
            new APIVovHelper().GetApplicationUsers().then((res) => {
                setUsers(res)
            });

    }, []);
    useEffect(() => {
        if (users !== undefined && users !== null && users.length > 0
            && selectedUserId !== undefined &&
            selectedUserId !== null && selectedUserId > 0) {
            let q = users.find(x => x.id == selectedUserId);
            if (q !== undefined)
                setSelectedUser(q);
        }

    }, [users, selectedUserId]);
    return (
        <div>

            <Autocomplete
                disablePortal
                size='small'
                id="combo-box-demo"
                onChange={(e, v) => {
                    setSelectedUser(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedUser}
                options={users}
                disabled={disabled}
                fullWidth={biggerThan700}
                renderInput={(params) => <TextField {...params} error={isMandatory === true && selectedUser === null} label={label} />}
            />
            {selectedUser === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}