import { Box, Button, Checkbox, Grid, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyCutPasteType, DeleteImageCategoryBuildingRequest, FileDirectoryDTO, FileParameter, ImageCategoryDTO, ImageDiaryDTO, VovClient } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import './ProjectDocumentTreeScene.css'
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { Add, Folder, FolderSpecial } from '@mui/icons-material';
import AddImageCategoryDialog from '../Dialogs/AddImageCategoryDialog';
import { ContainsFolder } from '../../../Shared/Functions/ContainsFolder';
import { UploadFileDC, useUploadFileStore } from '../../../../Contexts/UploadFileContext';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import { useApi } from '../../../../ApiOld/Api/useApi';
import RenameCategoryDialog from '../Dialogs/RenameCategoryDialog';

interface ProjectPhotosCategorySceneProps {
    categories: ImageCategoryDTO[];
    buildingName: string | undefined;
    buildingId: number;
    onSelect: (data: any) => void;
    onRefresh: () => void;
}

export default function ProjectPhotosCategoryScene(props: ProjectPhotosCategorySceneProps) {
    const { onSelect, onRefresh, categories, buildingName, buildingId } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [selected, setSelected] = React.useState<ImageCategoryDTO>();
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const [renameCategory, setRenameCategory] = useState<ImageCategoryDTO>();
    const [renameOpen, setRenameOpen] = useState<boolean>(false);
    const { authorizedApi } = useApi(VovClient);

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();
    const { uploadingFiles, addFile, uploadPhotos } = useUploadFileStore();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    /*  useEffect(() => {
         if (!selected && categories && categories.length > 0)
             setSelected(categories[0]);
     }, [categories]); */

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent, data: ImageCategoryDTO) => {
        event.preventDefault();
        event.stopPropagation();
        onSelect(data);
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    useEffect(() => {
    }, [selected]);


    function handleCategoryClick(category: ImageCategoryDTO): void {
        setSelected(category);
        if (onSelect) onSelect(category);
    }

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [deleteData, setDeleteData] = useState<ImageCategoryDTO>();
    const [isDragging, setIsDragging] = useState(false);
    const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback(
        async (event: React.DragEvent<HTMLDivElement>, categoryId: number | undefined) => {
            event.preventDefault();
            event.stopPropagation();
            setIsDragging(false);

            const { files, types } = event.dataTransfer;
            if (files.length > 0) {
                let hasFolder = ContainsFolder(event);

                if (hasFolder) {
                    return;
                }
                else {
                    for (let i = 0; i < files.length; i++) {
                        addFile(new UploadFileDC(files[i], false, false, buildingId, true, '', categoryId));
                    }
                    uploadPhotos();
                }
            }
        },
        [buildingId]
    );

    const handleAddCategory = () => {
        handleCloseContextMenu();
        setCreateOpen(true);
    };

    const handleDeleteCategory = (category: ImageCategoryDTO) => {
        handleCloseContextMenu();
        setDeleteData(category);
        setDeleteDialogOpen(true);
    };

    const onDelete = async (category: ImageCategoryDTO) => {
        if (permissions && permissions.buildingsEdit) {
            await authorizedApi.deleteImageCategoryBuilding(new DeleteImageCategoryBuildingRequest({ categoryId: category.id! }));
            setDeleteDialogOpen(false);
            setSelected(undefined);
            if (onSelect) onSelect(undefined);
            onRefresh();
        }
    }

    if (categories)
        return <Grid item xs={12}>
            <Typography sx={{ marginLeft: '24px', marginBottom: '-16px', fontWeight: 'bold' }}>{t('buildings.folders')}</Typography>
            <Box style={{ margin: '16px', padding: '24px', border: '1px solid lightgray', borderRadius: '10px' }}>
                <Grid container sx={{ cursor: 'pointer' }} onClick={() => { setSelected(undefined); onSelect(undefined); }}>
                    <Grid item>
                        <Typography sx={{ margin: '8px', fontSize: '18px' }}>{buildingName}</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ cursor: 'pointer', justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold', margin: '8px', marginTop: '16px', fontSize: '14px' }}>{t('files.folderName')}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={!permissions || !permissions.buildingsEdit} sx={{ marginLeft: 'auto', marginTop: '8px' }} onClick={() => setCreateOpen(true)} >
                            <Add />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="column" sx={{ marginTop: '4px' }}>
                    {categories.map((category, index) => (
                        <Grid
                            item
                            key={index}
                            onClick={() => {
                                handleCloseContextMenu();
                                handleCategoryClick(category);
                            }}
                            style={{
                                /* border: isDragging ? '2px dashed' : '1px dashed',
                                borderColor: isDragging ? theme.palette.text.primary : 'black', */
                                cursor: 'pointer',
                                backgroundColor: selected && selected.id === category.id ? 'lightblue' : 'transparent',
                                padding: '10px',
                                borderRadius: '5px',
                                display: 'flex'
                            }}
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e) => handleDrop(e, category.id)}

                            onContextMenu={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelected(category);
                                if (onSelect) onSelect(category);
                                handleContextMenu(e, category);
                            }}
                        >
                            {category.fromDiary ? <FolderSpecial color='primary' /> : <Folder color='primary' />} <Typography sx={{ marginLeft: '6px', paddingTop: '2px' }}>{category.name}</Typography>
                        </Grid>
                    ))}
                </Grid>

                <Menu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAddCategory();
                        }}
                    >
                        {t('Add')}
                    </MenuItem>
                    <MenuItem
                        disabled={(selected && !selected.id) || !permissions || !permissions.buildingsEdit}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDeleteCategory(selected!);
                        }}
                    >
                        {t('Delete')}
                    </MenuItem>
                    <MenuItem
                        disabled={(selected && !selected.id) || !permissions || !permissions.buildingsEdit}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setRenameCategory(selected);
                        }}
                    >
                        {t('rename')}
                    </MenuItem>
                </Menu>
                <AddImageCategoryDialog open={createOpen} onClose={() => { setCreateOpen(false) }} buildingId={buildingId} onCreated={() => onRefresh()} />
                <DeleteDialog onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} data={deleteData} onDeleteAgree={event => onDelete(event)} />


                {renameCategory && <RenameCategoryDialog oldName={renameCategory && renameCategory.name ? renameCategory.name : ''} open={renameCategory !== undefined} onClose={() => { setRenameCategory(undefined); setContextMenu(null); }} categoryId={renameCategory.id} onConfirm={(id, name) => {
                    onRefresh();
                }} />}
            </Box>
        </Grid>
    else
        return <div></div>;
}
