import { WealthsProvider } from "../../../Contexts/WealthsContext";
import WealthTabs from "./WealthTabs";

export default function WealthTabsContext() {
    return (
        <WealthsProvider>
            <WealthTabs />
        </WealthsProvider>
    );

}