import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import ApplicationCustomFields from './ApplicationCustomFields';
import { useTranslation } from 'react-i18next';

interface ApplicationCustomFieldsDialogProps {
  open: boolean;
  onClose: (refresh: boolean) => void;
}

export default function ApplicationCustomFieldsDialog(props: Readonly<ApplicationCustomFieldsDialogProps>) {
  const { open, onClose } = props
  const { t } = useTranslation();
  const [needRefresh, setNeedRefresh] = React.useState(false);
  return (
    <Dialog open={open} onClose={() => { onClose(needRefresh) }}>
      <DialogTitle>{t('FieldsSettings')}</DialogTitle>
      <DialogContent>
        <ApplicationCustomFields />
      </DialogContent>
    </Dialog>
  )
}
