import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useMediaPredicate } from "react-media-hook";

export interface TabItem {
    icon: React.ReactElement;
    label: string;
    disabled?: boolean;
    onClick?: () => void;
}

interface StyledTabsProps {
    variant?: 'standard' | 'scrollable' | 'fullWidth' | undefined;
    tabs: TabItem[];
    selectedTabIndex: number;
    onTabChange: (index: number) => void;
}

const StyledTabs: React.FC<StyledTabsProps> = ({ variant, tabs, selectedTabIndex, onTabChange }) => {
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    return (
        <Box sx={{ width: '100%', marginTop: '20px', backgroundColor: '#f5f5f5', zIndex: 5000, height: '40px' }}>
            <Tabs
                variant={variant === undefined ? biggerThan700 ? 'standard' : 'scrollable' : variant}
                value={selectedTabIndex}
                onChange={(e, n) => onTabChange(n)}
                aria-label="reusable tabs"
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{
                    "& button": {
                        color: 'black',
                        minHeight: '40px',
                    },
                    "& button.Mui-selected": {
                        color: '#11181f',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '10px 10px 0px 0px',
                    },
                }}
                scrollButtons={true}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        disabled={tab.disabled}
                        key={index}
                        icon={tab.icon}
                        label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '0px' }}>{tab.label}</div>}
                        iconPosition='start'
                        {...tabProps(index)}
                        onClick={tab.onClick}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

StyledTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
    onTabChange: PropTypes.func.isRequired,
};

const tabProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

export default StyledTabs;
