import { Button, Card, Link, Stack, Tooltip } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, RecordDoubleClickEventArgs, Page } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InitSfGridLocale from '../../Localization/SfGridLanguageInitializer';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import './TodoScene.css';
import { TodoGanttDTO, TodoListDTO, VovClient } from '../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../ApiOld/ApiToDoOldHelper';
import UpperMenu from '../Shared/Layouts/UpperMenu';
import { GridType } from '../../Api/UsersMicroserviceClient';
import TodoGanttOld from './ToDoOld/TodoGanttOld';
import { useNavigate, useParams } from 'react-router-dom';
import { TrashType } from '../Shared/Trash/TrashType';
import TrashGridDialog from '../Shared/Trash/TrashGridDialog';
import { useApi } from '../../ApiOld/Api/useApi';
import GridComponentStatefull from '../Shared/Datagrid/GridComponentStatefull';
import { useUserLayoutContext } from '../../Contexts/Providers/UserLayoutMenuProvider';
import { useRequestsPermissionContext } from '../../Contexts/Permissions/RequestsPermissionContext';
import { AddCircleOutline, MapOutlined, Segment, List } from '@mui/icons-material';

InitSfGridLocale();


interface TodoScenePropsOld extends WithLoadingProps {
    buildingId?: number;
}

function TodoSceneOld(props: TodoScenePropsOld) {
    const { fireLoading, stopLoading, buildingId } = props;
    const params = useParams();
    const [data, setData] = useState<TodoListDTO[]>();
    const [ganttData, setGanttData] = useState<TodoGanttDTO[]>();
    const [newOpen, setNewOpen] = useState(false);
    const [selectedView, setSelectedView] = useState<number>(1);
    const [selectedTodo, setSelectedTodo] = useState<TodoListDTO | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { t, i18n } = useTranslation();
    const grid = useRef<GridComponent>(null);
    const navigate = useNavigate();

    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();

    const { authorizedApi } = useApi(VovClient);
    const { permissions, reloadPermissions } = useRequestsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {

        refreshData();
        // console.log('loading top');
    }, []);
    useEffect(() => {
        setMenuButtons(
            <Stack direction='row' mr={2} alignItems={'center'} spacing={2}>
                {selectedView === 1 && <Button size='small' sx={{ mr: 2 }} onClick={(e) => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos-gantt')} variant='contained'><Segment /></Button>}
                <Button size='small' sx={{ mr: 2 }} onClick={(e) => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos-map')} variant='contained'><MapOutlined /></Button>
                {permissions && permissions.requestsCreate && <Button
                    size='small'
                    sx={{ mr: 2 }}
                    startIcon={<AddCircleOutline />}
                    onClick={(e) => createTodo(e)} variant='contained'>{t('Add')}</Button>}
            </Stack>
        );
        setBackMenuFunction(undefined);

        // console.log('loading top');
    }, [permissions, setMenuButtons, setBackMenuFunction, t, selectedView, setSelectedView, i18n.language, params['domain']]);

    /* useEffect(() => {, 
        if (selectedTodo !== undefined) {
            setNewOpen(true);
        }
    }, [selectedTodo]); */

    /* useEffect(() => {
        if (params['todoId'])
            refreshData(parseInt(params['todoId']));
        else
            refreshData();
    }, [params]); */

    useEffect(() => {
        /* if (selectedView === 2)
            new APITodoOldHelper().GetGanttTodos().then((result) => {
                setGanttData(result);
            }); */
    }, [selectedView]);

    useEffect(() => {
        if (refresh) {
            refreshData();
            setRefresh(false);
        }
    }, [refresh]);
    let groupOptions = {

        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    function gridTemplate(props: TodoListDTO) {
        return (<div>
            <Link component="button" onClick={event => goToTodo(event, props)} >{props.name}</Link>
        </div>);
    }

    function gridAssigneesTemplate(props: TodoListDTO) {
        return (<div>
            <Tooltip title={props.assignees} >
                <div>
                    {props.assignees}
                </div>
            </Tooltip>
        </div>);
    }

    function gridStateTemplate(props: TodoListDTO) {
        return (<div>
            <Tooltip title={props.assignees} >
                <div>
                    {props.state === 0 && <div> {t('singleTodo.new')} </div>}
                    {props.state === 1 && <div> {t('singleTodo.active')} </div>}
                    {props.state === 2 && <div> {t('singleTodo.resolved')} </div>}
                </div>
            </Tooltip>
        </div>);
    }

    const goToTodo = (event: any, param: TodoListDTO) => {
        navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/todos/tododetail/' + param.id);
    };
    function refreshData() {
        fireLoading!();
        if (buildingId) {
            authorizedApi!.getTodosForBuilding(buildingId).then((result) => {
                setData(result.todos);
                stopLoading!();
            })
        }
        else
            new APITodoOldHelper().GetTodos().then((result) => {
                setData(result);
                /* if (selectedId && result) {
                    setSelectedTodo(result.find(p => p.id === selectedId));
                } */
                console.log('loading top');
                stopLoading!();
            });
    }



    function toolbarClick1(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    function deleteTodos(event: any) {
        var rowsSelected = grid.current!.getSelectedRecords();
        let result: number[] = rowsSelected.map(a => (a as TodoListDTO).id!);
        new APITodoOldHelper().DeleteTodos(result).then(e => setRefresh(true));
    }
    function createTodo(event: any) {
        /* let todo: TodoListDTO = new TodoListDTO({ id: 0, name: "", state: 0 });
        setSelectedTodo(todo); */
        navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/todos/tododetail/0');
    }

    function onViewChange(e: number) {
        setSelectedView(e);
    }

    function newProjectClosed() { setNewOpen(false); setSelectedTodo(undefined); }


    return (
        <div>
            <Card sx={{ margin: 3, padding: 3, height: '100%' }} elevation={3}>
                {selectedView === 1 && <GridComponentStatefull<TodoListDTO>
                    data={data ?? []}
                    templateColumns={[
                        { field: 'name', template: gridTemplate },
                        { field: 'assignees', template: gridAssigneesTemplate },
                        { field: 'state', template: gridStateTemplate },
                    ]}
                    moduleName={t('todo.tasks')}
                    rowHeight={30}
                    canDelete
                    canTrash
                    onCreate={createTodo}
                    onDelete={deleteTodos}
                    onThrashClicked={() => { setTrashOpen(true); }}
                    //onViewChange={onViewChange}
                    ref={grid} id='grid-component-todo-old' menuName={t('View')}
                    gridType={GridType.Todos}
                    canChangeGroup={selectedView === 1} canToolbarGrid={selectedView === 1}
                    canExcelExport={selectedView === 1}
                    allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={false}
                    recordClick={(e: any) => {
                        if (e.column.editType !== 'booleanedit') {
                            goToTodo(e, e.rowData as TodoListDTO);
                        }
                    }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    allowPaging={true}
                    filterSettings={filterOptions} toolbarClick={toolbarClick1}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions} searchLabel={t('SearchInTodo')}
                    columnsXX={[
                        { width: '50', maxWidth: '50', textAlign: 'Right', visible: true, showInColumnChooser: false, type: 'checkbox' },
                        { field: 'id', width: '46', headerText: 'ID', textAlign: 'Right', visible: false, showInColumnChooser: false },
                        { field: 'name', headerText: t('Name'), width: '100', minWidth: '100', template: gridTemplate },
                        { field: 'buildingName', headerText: t('Projekt'), width: '100', minWidth: '100' },
                        { field: 'responsiblePerson', headerText: t('Responsibility'), width: '100', minWidth: '100' },
                        { field: 'creatorName', headerText: t('CreatedBy'), width: '100', minWidth: '100' },
                        { field: 'assignees', template: gridAssigneesTemplate, headerText: t('todo.assignees'), width: '100', minWidth: '100' },
                        { field: 'state', template: gridStateTemplate, width: '80', headerText: t('state') },
                        { field: 'dateTimeCreated', width: '160', type: 'dateTime', format: 'dd.MM.yyyy HH:mm', headerText: t('Created') },
                        { field: 'dateTimeDeadline', width: '160', type: 'dateTime', format: 'dd.MM.yyyy HH:mm', headerText: t('Deadline') },
                    ]}>
                    <Inject services={[Page, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser]} />
                </GridComponentStatefull>}
                {selectedView === 2 && <TodoGanttOld tasksGantt={ganttData} />}
                {/*  {selectedView === 3 && <KanbanStavarioOld onRefresh={refreshData} tasks={data} />}
                {selectedView === 4 && data &&
                    <ImageMarkerMapOld tasks={data} onRefreshData={(e) => { setData(e) }}></ImageMarkerMapOld>} */}

                <TrashGridDialog isSubdodavatel={false} trashType={TrashType.Todos} open={trashOpen} onClose={(e) => { console.log(e); setTrashOpen(false); if (e) refreshData(); }} />
            </Card>
        </div >
    );
};
export default withLoading(TodoSceneOld);

