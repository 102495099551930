import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, IconButton } from '@mui/material';
import { Close, Fullscreen } from '@mui/icons-material';

interface DialogBaseProps extends React.PropsWithChildren {
    open: boolean;
    onClose: (data?: any) => void;
    title: React.ReactNode;
    showCloseButton?: boolean;
    minWidth?: string | number;
    fullWidth?: boolean;
    buttonLabel?: string;
    onButtonClick?: () => void;
    dialogActions?: React.ReactNode;
    fullScreen?: boolean;
}

const DialogBase: React.FC<DialogBaseProps> = ({ open, onClose, title, children, showCloseButton, minWidth, fullWidth, buttonLabel, onButtonClick, dialogActions, fullScreen }) => {


    return (
        <Dialog fullWidth={fullWidth} PaperProps={{ sx: { minWidth: minWidth } }} open={open} onClose={onClose} fullScreen={fullScreen}>
            <DialogTitle>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='h5'>{title}</Typography>
                    {onButtonClick && <Button variant='outlined' onClick={onButtonClick}>{!buttonLabel ? 'Button' : buttonLabel}</Button>}
                    {showCloseButton && <IconButton onClick={() => onClose()}><Close /></IconButton>}
                </Box>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            {dialogActions && <DialogActions>
                {dialogActions}
            </DialogActions>}
        </Dialog>
    );
};

export default DialogBase;