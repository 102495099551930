import React, { useEffect, useState } from 'react'
import { APIOldBuildingsClient } from '../../../ApiOld/ApiOldBuildingsClient';
import { AiImagesDescriptionDto } from '../../../ApiOld/ApiServerVov';
import { ColumnChooser, ColumnDirective, ColumnsDirective, ExcelExport, Filter, GridComponent, Group, Inject, Page, Reorder, Resize, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { useTranslation } from 'react-i18next';
import { Photo } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import AiRecognitionPhotosDialog from './AiRecognitionPhotosDialog';
import LoginOldVov from '../../Forms/LoginOldScene';

export default function AiRecognitionList() {
    const [aiImageDescriptions, setAiImageDescriptions] = useState<AiImagesDescriptionDto[]>([]);
    const [aiImageDescription, setAiImageDescription] = useState<AiImagesDescriptionDto | null>(null);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    useEffect(() => {
        if (loggedIn)
            new APIOldBuildingsClient().GetAiImageDescriptions().then((res) => {
                if (res !== undefined)
                    setAiImageDescriptions(res);
            }).catch(() => setLoggedIn(false));
    }, [loggedIn]);
    useEffect(() => {
        let token = localStorage.getItem('old_bearer');
        if (token !== null && token !== undefined)
            setLoggedIn(true);
    }, []);
    function cellTemplatePhotos(props: AiImagesDescriptionDto) {
        if (props.urls !== undefined && props.urls.length > 0)
            return (<IconButton onClick={() => {
                setAiImageDescription(props);
            }}><Photo color='action' /></IconButton>);
        else return (<div></div>);

    }
    return (
        <div>
            {loggedIn && <div>
                <AiRecognitionPhotosDialog open={aiImageDescription !== null} onClose={() => { setAiImageDescription(null) }} urls={aiImageDescription?.urls || undefined} />
                <GridComponent

                    id="grid-component-ai" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    // recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                    //     toDiaryDetail(e.rowData as GetDiariesForUserDTO)
                    // }}
                    //actionComplete={(e) => { console.log(e) }}
                    // filterSettings={filterOptions}
                    allowPaging={true}
                    onChange={() => { console.log('grid changed'); }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    // toolbarClick={toolbarClickExcel}
                    //  columnDataStateChange={(e) => { console.log(e) }}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true}
                    // groupSettings={groupOptions}
                    dataSource={aiImageDescriptions}>
                    <ColumnsDirective>
                        {/* <ColumnDirective type='checkbox' width='40' /> */}
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} showInColumnChooser={false} />
                        <ColumnDirective field='hasPhotos' type='boolean' template={cellTemplatePhotos} headerText={t('dashboard.photos')} width='46' />
                        <ColumnDirective field='date' type='string' valueAccessor={(e, t) => { console.log((t as any).date.toLocaleDateString('cs')); return (t as any).date.toLocaleDateString('cs'); }} headerText='Datum' width='120' />
                        <ColumnDirective field='applicationsName' headerText='Společnost' width='60' />
                        <ColumnDirective field='buildingName' headerText='Stavba' width='160' />
                        <ColumnDirective field='workCategory' headerText='Kategorie' width='260' />
                        <ColumnDirective field='workCategoryNext' headerText='1 měsíc' width='260' />
                        <ColumnDirective field='workCategoryAfterNext' headerText='3 měsíce' width='260' />
                        <ColumnDirective field='generatedDescription' headerText='Popis' width='380' />

                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent>
            </div>}
            {!loggedIn && <LoginOldVov onLogin={() => { setLoggedIn(true) }} />}
        </div>
        // <div>{aiImageDescriptions.map((aiImageDescription) => {
        //     return 

        //     <div style={{ display: 'flex' }}>
        //         <div style={{ width: '100px', margin: '8px' }}>{aiImageDescription.id}</div>
        //         <div style={{ width: '100px', margin: '8px' }}>{aiImageDescription.date?.toLocaleDateString('cs')}</div>
        //         <div style={{ width: '100px', margin: '8px' }}>{aiImageDescription.applicationsName}</div>
        //         <div style={{ width: '100px', margin: '8px' }}>{aiImageDescription.buildingName}</div>
        //         <div style={{ width: '200px', margin: '8px' }}>{aiImageDescription.workCategory}</div>
        //         <div style={{ width: '400px', margin: '8px' }}>{aiImageDescription.generatedDescription}</div>
        //     </div>;
        // })}</div>
    )
}
