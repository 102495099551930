
import { BookOutlined, CarRental, DocumentScanner, History, Info, MoneyOff, Newspaper, Task, Link } from '@mui/icons-material';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';
import WealthDetail from './WealthDetail';
import WealthExpenditures from './WealthExpenditures';
import WealthHistory from './WealthHistory';
import StyledTabs from '../../Shared/Tabs/StyledTabs';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useWealthsContext, WealthsProvider } from '../../../Contexts/WealthsContext';
import { useApi } from '../../../ApiOld/Api/useApi';
import { WealthVovClient } from '../../../ApiOld/Api/ApiServerVov';
import WealthTabsContext from './WealthTabsContext';

interface WealthTabsProps {

}

function WealthTabs(props: WealthTabsProps) {
    const params = useParams();
    const [value, setValue] = React.useState(0);
    // const [wealthId, setWealth] = React.useState(0);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const param = useParams();
    const { setBackMenuFunction } = useUserLayoutContext();
    const { wealthId, setWealthId } = useWealthsContext();

    useEffect(() => {
        let num = parseInt(param['id']!);
        if (!isNaN(num) && setWealthId !== undefined)
            setWealthId(num);
    }, [setWealthId, param]);

    function navigateToBorrowWealth() {
        navigate(
            {
                pathname: `/${i18n.language}/${params['domain']!}/userlayout/rental/new/${params['id']}`,
            },
        );
    }
    useEffect(() => {
        setBackMenuFunction(() => () => {
            navigate({
                pathname: `/${i18n.language}/${params['domain']!}/userlayout/wealths`,
            })
        });
    }, [setBackMenuFunction, navigate, i18n.language, params]);

    const biggerThan700 = useMediaQuery('(min-width:1100px)');

    const tabs = [
        {
            icon: <Info style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
        },
        {
            icon: <History style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('singleTodo.history'),
        },
        {
            icon: <MoneyOff style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('Expenses'),
        },
        {
            icon: <Link style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('BorrowWealth'),
            onClick: navigateToBorrowWealth,
        },
    ];
    useEffect(() => {
        let num = parseInt(param['id']!);
        if (!isNaN(num))
            setWealthId?.(num);
    }, [param, setWealthId]);

    return (
        <div style={{ backgroundColor: '#f5f5f5', margin: '24px' }}>

            <Box sx={{ width: '100%', zIndex: 5000 }}>
                <StyledTabs
                    tabs={tabs}
                    selectedTabIndex={value}
                    onTabChange={setValue}
                />

                <TabPanel value={value} index={0}>
                    <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', borderRadius: '10px', borderTopLeftRadius: '0px', backgroundColor: '#FFFFFF' }}>
                        <WealthDetail />
                    </Box>
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                Dokumenty nehotovo
                </Box>
                </TabPanel> */}
                <TabPanel value={value} index={1}>
                    <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%' }}>
                        <WealthHistory />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#FFFFFF', borderRadius: '10px' }}>
                        <WealthExpenditures />
                    </Box>
                </TabPanel>
            </Box>
        </div>
    );
};
export default WealthTabs;