import { CheckBox, Close, Info, Search, TheaterComedy } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { WealthForDiaryDialogDTO } from '../../../ApiOld/ApiServerVov';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';

export interface WealthForDiaryDialogProps {
    onClose: (event: any) => void;
    open: boolean;
    recordId?: number;
    isSubdodavatel?: boolean;
}
export default function WealthForDiaryDialog(props: WealthForDiaryDialogProps, ref: any) {
    const { onClose, open, recordId, isSubdodavatel } = props;

    const [wealthsForDiaryDialog, setWealthsForDiaryDialog] = React.useState<WealthForDiaryDialogDTO[]>();
    const [searchBackupwealthsForDiaryDialog, setSearchBackupwealthsForDiaryDialog] = React.useState<WealthForDiaryDialogDTO[]>();
    const [searchText, setSearchText] = React.useState<string | undefined>();
    const [searched, setSearched] = React.useState<string>('');

    useEffect(() => {
        if (recordId && open)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetWealthsForDiaryDialog(recordId).then((data) => {
                setWealthsForDiaryDialog(data);
                setSearchBackupwealthsForDiaryDialog(data);
            });
        else if (!open) {
            setWealthsForDiaryDialog(undefined);
            setSearchText(undefined);
            setSearched('');
        }
    }, [open, recordId]);

    useEffect(() => {
        if (!open) {
            checkedWealthIds.current = [];
        }
    }, [open]);

    const handleClose = (e: any) => {
        onClose(e);
    };

    useEffect(() => {
        if (searched !== '') {
            let searchedWealths = searchBackupwealthsForDiaryDialog?.filter((x) => {
                return searchText?.toLowerCase() === '' ? x : x.name?.toLowerCase().includes(searchText?.toLowerCase()!);
            });
            setWealthsForDiaryDialog(searchedWealths);
        }
        else if (searchBackupwealthsForDiaryDialog) {
            setWealthsForDiaryDialog(searchBackupwealthsForDiaryDialog);
        }
    }, [searched]);

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleSearch();
        }
    }
    const handleSearch = () => {
        if (searchText !== undefined && searchText !== '') setSearched(searchText); else setSearched('');
    }

    const checkedWealthIds = useRef<number[]>([]);

    const handleUpdateCheckedWealthIds = (wealthId?: number) => {

        if (wealthId) {
            if (checkedWealthIds.current.includes(wealthId)) {
                checkedWealthIds.current = checkedWealthIds.current.filter(id => id !== wealthId);
            } else {
                checkedWealthIds.current = [...checkedWealthIds.current, wealthId];
            }
        }
    }

    const save = () => {
        if (wealthsForDiaryDialog && recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).AddWealthsToMechanisms(recordId, checkedWealthIds.current).then((r) => {
                onClose(true);
            });
        }
    }

    const { t } = useTranslation();

    return (
        <Dialog style={{ marginTop: '60px' }}
            open={open}
            onClose={() => { handleClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="wealth-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '400px' }}>{t('diary.machines')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose('') }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ background: '#F5F5F5' }}>
                <div style={{ maxHeight: '800px' }}>
                    <div style={{ display: 'flex' }}>
                        <TextField value={searchText} onKeyDown={handleKeyDown} onChange={(e) => { setSearchText(e.target.value) }}
                            placeholder={t('SearchDots')} variant='standard' sx={{ height: '20px', margin: '20px' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleSearch}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} size='small' />
                        {searchText !== '' && (wealthsForDiaryDialog === undefined || wealthsForDiaryDialog.length === 0) && <div style={{ marginLeft: '20px', marginTop: '24px' }}>{t('noRecords')}</div>}
                    </div>
                    {wealthsForDiaryDialog && wealthsForDiaryDialog.map((wealthItem) => {
                        return <div style={{ minWidth: '168px', borderRadius: '10px', margin: '4px', boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px' }}>
                            <FormControlLabel sx={{ marginLeft: '10px' }}
                                value="end"
                                onChange={() => handleUpdateCheckedWealthIds(wealthItem.id)}
                                control={
                                    <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }}
                                        checkedIcon={<CheckBox color='secondary' />}
                                    />}
                                label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{wealthItem.name}</Typography>}
                                labelPlacement="end"
                            /></div>;
                    })}
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained" sx={{ marginTop: '40px', marginBottom: '10px' }}
                            onClick={save}>{t('diary.insertIntoDiary')}</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

