import { DocumentScanner } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { BuildingInfoDTO, GetDocumentsForInvestorAndBuildingDTO } from '../../../ApiOld/ApiServerVov';
import InvestorDocumentsTree from './InvestorDocumentsTree';
import { useDiaryTabsContext } from '../../../Contexts/Providers/DiaryTabsProvider';

interface InvestorDocumentsSceneProps {
    buildingId?: number;
}
function InvestorDocumentsScene(props: InvestorDocumentsSceneProps) {
    const { buildingId } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [buildings, setBuildings] = useState<GetDocumentsForInvestorAndBuildingDTO[]>([]);
    const { t, i18n } = useTranslation();
    const [newOpen, setNewOpen] = useState(false);
    const { investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();
    useEffect(() => {
        //loadBuildings();

        if (!investorPermissions)
            refreshInvestorPermissions();
    }, []);

    /* useEffect(() => {
        if (!investorPermissions)
            refreshInvestorPermissions();
    }); */

    useEffect(() => {
        loadBuildings();
    }, [buildingId]);

    function setDefaultState() {
        setBuildings([]);
    }

    function loadBuildings() {
        //setDefaultState();
        // fireLoading!();
        console.log('refresh');
        new APIVovInvestorHelper().GetInvestorDocumentByBuilding(buildingId).then((data) => {
            if (data.filesBuildings)
                setBuildings(data.filesBuildings);
            else
                setDefaultState();
        }).catch(() => { setDefaultState() });
    }

    if (!investorPermissions)
        return (<div>{t('loading...')}</div>);
    else if (investorPermissions.investorDocumentsList !== true)
        return (<div>{t('noViewingPermissions')}</div>);
    return (<div><div style={{ height: '78px', backgroundColor: '#F2F2F2', border: '1px solid', borderColor: '#C3C3C3', marginBottom: '12px' }}>
        <div style={{ backgroundColor: '#F2F2F2' }}>
            <div style={{ float: 'left' }}>
                <div style={{ height: '78px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginLeft: '20px' }}>
                        <DocumentScanner />
                    </div>
                    {biggerThan700 && <Typography sx={{ fontWeight: 'bold', marginLeft: '12px' }}>{t('Documents')}</Typography>}
                </div>
            </div>

        </div>
    </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 0.5, flexWrap: 'wrap' }}>
            {
                buildings.map((item, i) => {
                    return (<InvestorDocumentsTree key={'key' + i} files={item.files} />);
                })
            }
        </div>


    </div>);
}
export default InvestorDocumentsScene;