

export const isValidHtml = (input: string): boolean => {
    try {
        // Use DOMParser to parse the input string as HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(input, "text/html");

        // Check for parser errors
        if (doc.querySelector("parsererror")) {
            return false;
        }

        // Re-serialize the parsed HTML to compare with the input
        const serialized = doc.body.innerHTML.trim();

        // Check if the parsed and original strings match
        return serialized === input.trim();
    } catch (error) {
        return false;
    }
};