import React, { useEffect } from "react";
import { Autocomplete, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InvestorRoleDTO, UserRoleDTO } from "../../ApiOld/ApiServerVov";
import { useMediaPredicate } from "react-media-hook";
import { useApi } from "../../ApiOld/Api/useApi";
import { UserVovClient } from "../../ApiOld/Api/ApiServerVov";

export interface InvestorRoleSelectProps {
    selectedRoleId?: number | null;
    onChange: (event: UserRoleDTO | null) => void;
    isMandatory?: boolean;
    alertFontSize?: number;
}
export default function InvestorRoleSelect(props: InvestorRoleSelectProps) {
    const { onChange, selectedRoleId, isMandatory, alertFontSize } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { authorizedApi } = useApi(UserVovClient);

    const [selectedRole, setSelectedRole] = React.useState<InvestorRoleDTO | null>(null);
    const [roles, setRoles] = React.useState<InvestorRoleDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    useEffect(() => {
        authorizedApi.getInvestorRoles().then((res) => {
            setRoles(res.roles ?? []);
        });

    }, []);
    useEffect(() => {
        if (roles !== undefined && roles !== null && roles.length > 0
            && selectedRoleId !== undefined &&
            selectedRoleId !== null && selectedRoleId > 0) {
            let q = roles.find(x => x.id == selectedRoleId);
            if (q !== undefined)
                setSelectedRole(q);
        }

    }, [roles]);
    return (
        <div>

            <Autocomplete
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedRole(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedRole}
                options={roles}

                fullWidth={biggerThan700}
                sx={{ minWidth: 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedRole === null}  {...params} label={t('role')}

                />}
            />
            {selectedRole === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '2px', marginTop: '14px', color: theme.palette.error.main, fontSize: alertFontSize ? alertFontSize : 14, fontFamily: 'Roboto' }}>{t('FieldRequired')}</p>}
        </div>
    );
}