import { Box, Tab, Tabs, IconButton, Button } from "@mui/material";
import { AccountBalanceOutlined, BookOnlineOutlined, Check, Info, LocalAtm, LocalAtmOutlined, MenuBook, NewspaperOutlined, Reply, Save, SettingsOutlined, Task } from '@mui/icons-material';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import tabProps from '../../Shared/Tabs/TabProps';
import SplitButton from "../../Shared/SplitButton";
import delay from '../../Shared/Functions/Delay';
import { AssignedEmployeeDTO, AssignedUserDTO, BuildingNoteDTO, GetBuildingDataDetailResponse } from "../../../ApiOld/ApiServerVov";
import { useNavigate } from 'react-router-dom';
import { APIOldBuildingsClient } from "../../../ApiOld/ApiOldBuildingsClient";
import ProjectDocumentsScene from "./Components/ProjectDocumentsScene";
import { useMediaPredicate } from "react-media-hook";
import { groupBy } from "../../Shared/GroupBy";
import ProjectPhotosScene from "./Components/ProjectPhotosScene";
import BuildingCustomFields from "../../CustomFields/BuildingCustomFields";
import ProjectMainDetail from "./ProjectMainDetail";
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ProjectPeople from "./ProjectPeople";
import ProjectNotes from "./ProjectNotes";
import DataArrayIcon from '@mui/icons-material/DataArray';
import StyledTabs, { TabItem } from "../../Shared/Tabs/StyledTabs";
import InvoiceList from "../InvoiceComponents/InvoiceList";
import { useBuildingsPermissionContext } from "../../../Contexts/Permissions/BuildingsPermissionContext";
import { BuildingBillsPermissionProvider } from "../../../Contexts/Permissions/BuildingBillsPermissionContext";
import { useUserLayoutContext } from "../../../Contexts/Providers/UserLayoutMenuProvider";
import ProjectMenuButtons from "./ProjectMenuButtons";
import ProjectInfoDialog from "./Dialogs/ProjectInfoDialog";
import ReusableTabs from "../InvoiceComponents/ReusableTabs";
import ProjectInvestors from "./ProjectInvestors";
import CreateInvestorDialog from "./Dialogs/CreateInvestorDialog";
import TodoSceneOld from "../ToDoSceneOld";
import ProjectDocumentTreeThrashScene from "./Components/ProjectDocumentTreeThrashScene";


// 1. useLoadScript v parent nebo v child?
// 2. ukladani checkboxu nejde
// 3. projectList layout nevypada jako v deniku
// 4. udelat fotky


interface ProjectDetailProps {
    buildingId?: number;
}

export default function ProjectDetail(props: ProjectDetailProps) {

    const [projectData, setProjectData] = useState<Partial<GetBuildingDataDetailResponse> | null>(null);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveCustomFieldsTriggered, setSaveCustomFieldsTriggered] = useState(false);
    const [settingsCustomFieldsTriggered, setSettingsCustomFieldsTriggered] = useState(false);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<number>(0);

    const [groupedUsers, setGroupedUsers] = useState<[string, AssignedUserDTO[]][]>([]);
    const [groupedEmployees, setGroupedEmployees] = useState<[string, AssignedEmployeeDTO[]][]>([]);

    const [infoOpen, setInfoOpen] = useState(false);
    const [createInvestorOpen, setCreateInvestorOpen] = useState(false);
    const [thrashOpen, setThrashOpen] = useState(false);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    const params = useParams();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions, permissions]);

    const handleSaveCustomFields = () => {
        setSaveCustomFieldsTriggered(true);
        delay(100).then((d) => { setSaveCustomFieldsTriggered(false); });
    };
    const handleSetupCustomFields = () => {
        setSettingsCustomFieldsTriggered(true);
        delay(100).then((d) => { setSettingsCustomFieldsTriggered(false); });
    };
    const addNote = () => {
        setProjectData({ ...projectData, noteList: [...projectData!.noteList!, new BuildingNoteDTO({ id: (-1) * projectData!.noteList!.length, text: '', name: 'Nová poznámka' })] });
    };

    const addInvestor = () => {
        setCreateInvestorOpen(true);
    }

    useEffect(() => {
        reloadData();

    }, [props.buildingId]);
    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();
    useEffect(() => {
        setBackMenuFunction(() => () => { redirectToList() })
        console.log('projectData', projectData, props);
        reloadData();

    }, []);

    const reloadData = () => {
        if (props.buildingId) {
            console.log('reloadData2', props);
            new APIOldBuildingsClient().GetBuildingDataDetail(props.buildingId).then((d) => {
                setProjectData(d);
                setTitle(d.name ?? '');
            });
        }
        else {
            setProjectData(new GetBuildingDataDetailResponse({ assignedUsers: [], assignedEmployees: [], id: 0, isActive: true, showToEmployees: true, gpsX1: 50.634302, gpsY1: 13.820344, gpsDiff1: 100, gpsX2: 1, gpsX3: 1, gpsX4: 1, gpsY2: 1, gpsY3: 1, gpsY4: 1, gpsDiff2: 1, gpsDiff3: 1, gpsDiff4: 1, createDiary: true }));
        }
    }

    useEffect(() => {
        setMenuButtons(projectData && permissions && <ProjectMenuButtons
            handleSetupCustomFields={handleSetupCustomFields}
            permissions={permissions}
            projectData={projectData}
            saveDone={saveDone}
            setSaveDone={setSaveDone}
            error={errorCode}
            saveProject={saveProject}
            selectedTabIndex={selectedTabIndex}
            onAddNote={addNote}
            onCreateInvestor={addInvestor}
            onThrash={() => setThrashOpen(true)}
        />);
    }, [projectData, permissions, saveDone, selectedTabIndex]);

    useEffect(() => {
        if (saveDone === true)
            delay(5000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    useEffect(() => {
        if (projectData !== undefined && projectData !== null) {
            if (projectData.assignedUsers) {
                const data = groupBy(projectData.assignedUsers, i => i.group === undefined ? "" : i.group);

                let tmp: any[] = [];
                for (const b in data) {
                    tmp?.push([b, data[b]])
                }

                setGroupedUsers(tmp.sort((a, b) => {
                    if (a[0] < b[0]) {
                        return -1;
                    }
                    if (a[0] > b[0]) {
                        return 1;
                    }
                    return 0;
                }));
            }
            else setGroupedUsers([]);

            if (projectData.assignedEmployees) {
                const data = groupBy(projectData.assignedEmployees, i => i.group === undefined ? "" : i.group);

                let tmp: any[] = [];
                for (const b in data) {
                    tmp?.push([b, data[b]])
                }

                setGroupedEmployees(tmp.sort((a, b) => {
                    if (a[0] < b[0]) {
                        return -1;
                    }
                    if (a[0] > b[0]) {
                        return 1;
                    }
                    return 0;
                }));
            }
            else setGroupedEmployees([]);
        }
        else {
            setGroupedEmployees([]);
            setGroupedUsers([]);
        }
    }, [projectData]);

    function saveProject(close: boolean) {
        handleSaveCustomFields();
        setSaveDone(false);
        if (projectData && permissions && (projectData.id! > 0 ? permissions.buildingsEdit : permissions.buildingsCreate))
            new APIOldBuildingsClient().SaveBuilding(projectData).then((d) => {
                if (d.ok) {
                    if (!props.buildingId)
                        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/projectDetail/' + d.id);
                    setProjectData({ ...projectData, id: d.id });
                    setSaveDone(true);
                    setErrorCode(0);

                    if (close)
                        redirectToList();
                }
                else {
                    setErrorCode(d.errorCode ? d.errorCode : 0);
                    setSaveDone(true);
                    setInfoOpen(true);
                }
            }).catch((e) => {
                setSaveDone(true);
            });
        else setSaveDone(true);
    }

    const errorMessages = [
        '',
        t('buildings.buildingWithSameNameExists'),
        t('error') + ". " + t('sales.contactSupport'),
        t('error') + ". " + t('sales.contactSupport')
    ];

    const [refreshDocuments, setRefreshDocuments] = useState(false);

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/projects')
    }
    const tabs = useMemo(() => projectData && [
        {
            icon: <Info style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
            panel: <ProjectMainDetail projectData={projectData} setProjectData={setProjectData} />
        },
        params['id'] && {
            icon: <DataArrayIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('CustomFields'),
            panel: <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ background: 'white' }}>
                    {projectData?.id && <BuildingCustomFields settingsTriggered={settingsCustomFieldsTriggered} saveTriggered={saveCustomFieldsTriggered} buildingId={projectData.id} />}
                </Box>
            </Box>
        },
        params['id'] && {
            icon: <GroupsOutlinedIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('PeopleInTheProject'),
            disabled: permissions ? !permissions.buildingsAssign : true,
            panel: <ProjectPeople groupedUsers={groupedUsers} groupedEmployees={groupedEmployees} projectData={projectData} setProjectData={setProjectData} />
        },
        params['id'] && {
            icon: <AccountBalanceOutlined style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('investors'),
            disabled: false/* permissions ? !permissions.buildingsEdit : true */,
            panel: <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <div style={{ marginTop: '16px' }}>
                        <ProjectInvestors projectData={projectData} setProjectData={setProjectData} />
                    </div>
                </Box>
            </div>
        },
        params['id'] && {
            icon: <ArticleOutlinedIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('notes'),
            disabled: permissions ? !permissions.buildingsNotes : true,
            panel: <ProjectNotes projectData={projectData} setProjectData={setProjectData} />
        },
        params['id'] && {
            icon: <PhotoSizeSelectActualOutlinedIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('photos'),
            disabled: permissions ? !permissions.buildingsPhotos : true,
            panel: <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <div style={{ marginTop: '16px' }}>
                        <ProjectPhotosScene buildingId={projectData.id!} buildingName={projectData.name!} />
                    </div>
                </Box>
            </div>
        },
        params['id'] && {
            icon: <DescriptionOutlinedIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('Documents'),
            disabled: permissions ? !permissions.buildingsDocuments : true,
            panel: <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <div style={{ marginTop: '16px' }}>
                        {projectData.id && <ProjectDocumentsScene refreshG={refreshDocuments} setRefreshG={setRefreshDocuments} projectId={projectData.id} />}
                    </div>
                </Box>
            </div>
        },
        params['id'] && {
            icon: <LocalAtmOutlinedIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('invoices.Invoices'),
            disabled: permissions ? !permissions.buildingsBills : true,
            panel: <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <div style={{ marginTop: '16px' }}>
                        <BuildingBillsPermissionProvider>
                            <InvoiceList buildingId={projectData.id} />
                        </BuildingBillsPermissionProvider>
                    </div>
                </Box>
            </div>
        },
        params['id'] && {
            icon: <Task style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('todo.tasks'),
            disabled: false/* permissions ? !permissions.buildingsEdit : true */,
            panel: <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <div style={{ marginTop: '16px' }}>
                        <TodoSceneOld buildingId={projectData.id} />
                    </div>
                </Box>
            </div>
        },
        params['id'] && {
            icon: <BookOnlineOutlined style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('menu.constructionDiary'),
            disabled: false/* permissions ? !permissions.buildingsEdit : true */,
            onClick: () => { navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/diary/diaryvov/' + projectData.name) },
            panel: <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <div style={{ marginTop: '16px' }}>
                        DENÍK
                    </div>
                </Box>
            </div>
        },
    ], [biggerThan700, groupedEmployees, groupedUsers, params, permissions, projectData, saveCustomFieldsTriggered, settingsCustomFieldsTriggered, t, refreshDocuments, setRefreshDocuments]);

    if (projectData)
        return (
            <Box sx={{ m: 2, maxWidth: biggerThan700 ? '100vw' : '92vw', height: '100vh', overflowY: biggerThan700 ? 'inherit' : 'auto', p: 1 }}>
                <ReusableTabs
                    variant="scrollable"
                    tabs={tabs?.filter(Boolean) as TabItem[]}
                    onSelectedTabChange={(index) => setSelectedTabIndex(index)}
                />
                <ProjectInfoDialog open={infoOpen} onClose={() => setInfoOpen(false)} text={errorMessages[errorCode]} />
                <CreateInvestorDialog open={createInvestorOpen} onClose={(ref) => { setCreateInvestorOpen(false); if (ref) reloadData(); }} buildingId={props.buildingId} />
                {props.buildingId && <ProjectDocumentTreeThrashScene projectId={props.buildingId} open={thrashOpen} onClose={(refresh) => {
                    setThrashOpen(false);
                    if (refresh) {
                        setRefreshDocuments(true);
                    }
                }} />}
            </Box>
        );
    return <div>{t('loading...')}</div>

}