import { RemoveRedEyeSharp, VisibilityOff } from "@mui/icons-material";
import { IconButton, SxProps, TextField, Theme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface PasswordboxProps {
    password: string;
    onEnter?: () => void;
    onPasswordChange: (event: any) => void;
    size?: "small" | "medium";
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
}

export default function Passwordbox(props: Readonly<PasswordboxProps>) {

    const { password, onPasswordChange, onEnter, size, fullWidth, sx } = props;
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (onEnter)
                onEnter();
        }
    }
    const onPassChange = (event: any) => {
        onPasswordChange(event!.currentTarget!.value);
    };
    const { t } = useTranslation();
    const [passwordHasValue, setPasswordHasValue] = React.useState(false);
    let [passwordMode, setPasswordMode] = useState("password");
    const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        if (e.animationName === "mui-auto-fill") {
            stateSetter(autofilled);
        }

        if (e.animationName === "mui-auto-fill-cancel") {
            stateSetter(autofilled);
        }
    };
    return (
        <TextField
            sx={sx}
            fullWidth={fullWidth}
            size={size ? size : "medium"}
            type={passwordMode}
            inputProps={{
                onAnimationStart: makeAnimationStartHandler(setPasswordHasValue)
            }}
            InputLabelProps={{
                shrink: passwordHasValue || props.password.length > 0
            }}
            variant="outlined"
            value={props.password}
            onChange={onPassChange}
            id="outlined-password-input"
            label={t('login.enterPassword')}
            onKeyDown={handleKeyPress}
            InputProps={{
                endAdornment:
                    <IconButton sx={{ mt: '-2px' }} onClick={() => {
                        if (passwordMode === 'password')
                            setPasswordMode("text");
                        else setPasswordMode("password");
                    }}> {
                            passwordMode === 'password' ?
                                <RemoveRedEyeSharp fontSize={'small'} /> : <VisibilityOff fontSize={'small'} />
                        }</IconButton>
            }}

        />
    );
}