import React from 'react';
import TextField from '@mui/material/TextField';

interface IntegerInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
}

const DecimalInput = (props: Readonly<IntegerInputProps>) => {
  const { value, onChange, label } = props;

  // Handler to restrict input to numbers only
  const handleKeyPress = (event) => {
    const charCode = event.charCode;

    // Allow numbers and one decimal point only
    if (
      (charCode >= 48 && charCode <= 57) || // numeric (0-9)
      charCode === 46 // decimal point (.)
    ) {
      return;
    }

    // Prevent other input
    event.preventDefault();
  };

  // Handler to validate the input value
  const handleChange = (event) => {
    const inputValue = event.target.value;

    // Validate the input to be either an integer or a decimal number
    const validNumberRegex = /^\d*\.?\d*$/;

    if (validNumberRegex.test(inputValue)) {
      onChange(inputValue);
    }
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      size='small'
      sx={{ mt: 2 }}
      fullWidth
      value={value}
      onChange={handleChange}
      inputProps={{
        inputMode: 'decimal', // Show decimal keypad on mobile
        pattern: '[0-9]*' // Use pattern for basic validation
      }}
      onKeyPress={handleKeyPress}
    />
  );
};

export default DecimalInput;