import * as React from 'react';
import DialogBase from '../../Shared/DialogBase';
import TasksImageMap from './TasksImageMap';
import { TodoDTO, TodoListDTO } from '../../../ApiOld/Api/ApiServerVov';
import { useTranslation } from 'react-i18next';

export interface ITasksImageMapDialogProps {
    open: boolean;
    onClose: (refresh: boolean, todo: TodoListDTO | null) => void;
    todo: TodoDTO;
}

export default function TasksImageMapDialog(props: ITasksImageMapDialogProps) {
    const { t } = useTranslation();
    return (
        <DialogBase fullScreen title={t('SelectLocation')} open={props.open} onClose={() => props.onClose(false, null)}>
            <TasksImageMap externalTodo={props.todo} onSaved={(todo) => { props.onClose(true, todo ?? null) }} />
        </DialogBase>
    );
}
