import * as React from 'react';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import LanguagePicker from '../LanguagePicker';
import { useLocation, useNavigate, useParams } from 'react-router';
import { PeopleOutline, Dashboard, FileOpenOutlined, LogoutOutlined } from '@mui/icons-material';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useTranslation } from 'react-i18next';

export interface ILayoutUserMenuProps {
    anchorEl: null | HTMLElement;
    handleClose: () => void;
    onChangeView: () => void;
    isUppside?: boolean;
}

export default function LayoutUserMenu(props: ILayoutUserMenuProps) {
    const { anchorEl, handleClose, onChangeView, isUppside } = props;
    const navigate = useNavigate();
    const { logout } = useUserLayoutContext();
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem >
                <Box pr={2} ml={-2}>
                    <LanguagePicker showText onLanguageChange={(e) => { navigate('/' + e + location.pathname.substring(location.pathname.indexOf('/', 1))); handleClose(); }} />
                </Box>
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + ".vimovsem.cz/admin/admin-user-account/"; }}>
                <Stack mx={0.5} direction={'row'} alignItems={'center'} spacing={1} my={.5} >
                    <PeopleOutline />
                    <Typography fontSize={13} fontWeight={'bold'}>{t('Profile')}</Typography>
                </Stack>
            </MenuItem>
            {!isUppside && <MenuItem onClick={() => { onChangeView(); handleClose(); }}>
                <Stack mx={0.5} direction={'row'} alignItems={'center'} spacing={1} my={.5} >
                    <Dashboard />
                    <Typography fontSize={13} fontWeight={'bold'}>{t('ChangeView')}</Typography>
                </Stack>
            </MenuItem>}
            <MenuItem onClick={() => { handleClose(); window.location.href = 'https://' + params['domain'] + ".vimovsem.cz/admin/my-files/"; }}>
                <Stack mx={0.5} direction={'row'} alignItems={'center'} spacing={1} my={.5}>
                    <FileOpenOutlined />
                    <Typography fontSize={13} fontWeight={'bold'}>{t('MyFiles')}</Typography>
                </Stack>
            </MenuItem>

            {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
            <MenuItem onClick={logout}>
                <Stack mx={0.5} direction={'row'} alignItems={'center'} my={.5} spacing={1}>
                    <LogoutOutlined />
                    <Typography fontSize={13} fontWeight={'bold'}>{t('Logout')}</Typography>
                </Stack></MenuItem>
        </Menu>
    );
}
