import React, { useCallback, useEffect } from 'react'
import { CreateCustomFieldForApplicationRequest, CustomFieldDataType, CustomFieldDto, CustomFieldOptionDto, CustomFieldsVovClient, UpdateCustomFieldForApplicationRequest } from '../../ApiOld/Api/ApiServerVov';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import CustomFieldDataTypeSelect from './CustomFieldDataTypeSelect';
import { useApi } from '../../ApiOld/Api/useApi';
import CustomField from './CustomField';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
interface AddUpdateApplicationCustomFieldDialogProps {
  open: boolean;
  onClose: (needRefresh: boolean) => void;
  data: CustomFieldDto | null;
}

export default function AddUpdateApplicationCustomFieldDialog(props: Readonly<AddUpdateApplicationCustomFieldDialogProps>) {
  const { t } = useTranslation();
  const { open, onClose, data } = props;
  const { authorizedApi } = useApi(CustomFieldsVovClient);
  const [needRefresh, setNeedRefresh] = React.useState(false);
  const [localData, setLocalData] = React.useState<CustomFieldDto>({ ...data, dataType: data?.dataType ?? CustomFieldDataType.String } as CustomFieldDto);
  const [enumOptions, setEnumOptions] = React.useState<CustomFieldOptionDto[]>([]);
  const [deletedEnumOptionIds, setDeletedEnumOptionIds] = React.useState<number[]>([]);

  useEffect(() => {
    if (data) {
      setLocalData(data);
      if (data.dataType === CustomFieldDataType.Enum && data.id) {
        authorizedApi.getCustomFieldOptionsForEnum(data.id).then((options) => {
          setEnumOptions(options.customFieldOptions ?? []);
        });
      }
    }
  }, [data, authorizedApi]);

  const createOrUpdate = useCallback(async () => {
    if (localData.id) {
      await authorizedApi.updateCustomFieldForApplication(new UpdateCustomFieldForApplicationRequest({
        id: localData.id,
        name: localData.name,
        dataType: localData.dataType,
        defaultValue: localData.defaultValue,
        newEnumOptions: enumOptions.length > 0 ? enumOptions.filter(x => x.id! < 0).map(x => x.value ?? '') : undefined,
        deletedEnumOptions: deletedEnumOptionIds,
        updatedEnumOptions: enumOptions.filter(x => x.id! > 0),
      }));
    } else {
      await authorizedApi.createCustomFieldForApplication(new CreateCustomFieldForApplicationRequest({ ...localData, enumOptions: enumOptions.length > 0 ? enumOptions.map(x => x.value ?? '') : undefined }));
    }
    setNeedRefresh(true);
    onClose(needRefresh);
  }, [authorizedApi, localData, enumOptions, deletedEnumOptionIds, onClose]);

  const [newId, setNewId] = React.useState<number>(-1);

  return (
    <Dialog open={open} onClose={() => { onClose(needRefresh) }}>
      <DialogTitle>{t('EditCustomField')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField fullWidth size='small' sx={{ mt: 2 }} value={localData.name} label={t('name')} onChange={(e) => { setLocalData({ ...localData, name: e.currentTarget.value } as CustomFieldDto) }}></TextField>
          </Grid>
          <Grid item xs={12}>
            <CustomFieldDataTypeSelect sx={{ mt: 2, "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } }} dataType={localData.dataType ?? CustomFieldDataType.String} onChange={(dataType) => { setLocalData({ ...localData, dataType: dataType } as CustomFieldDto) }} />
          </Grid>
          <Grid item xs={12}>
            <CustomField customField={localData} value={localData.defaultValue} onValueChange={(val) => { setLocalData({ ...localData, defaultValue: val } as CustomFieldDto) }} />
          </Grid>
          {localData.dataType === CustomFieldDataType.Enum && <Grid item xs={12}>
            <Button onClick={() => { setEnumOptions(prev => { return [...prev, new CustomFieldOptionDto({ value: '', id: newId })] }); setNewId(prev => prev - 1); }}>{t('AddItem')}</Button>
            {enumOptions.map((option, index) => {
              return <TextField
                size='small'
                fullWidth
                key={index}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => {
                        setEnumOptions(prev => {
                          const newOptions = [...prev];
                          newOptions.splice(index, 1);
                          return newOptions;
                        });
                        if (option.id)
                          setDeletedEnumOptionIds(prev => [...prev, option.id!]);
                      }}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mt: 2 }}
                value={option?.value}
                onChange={(e) => {
                  setEnumOptions(prev => {
                    const changed = prev.find(x => x.id === option.id);
                    if (changed && e.target)
                      changed.value = e.target.value;
                    return [...prev];
                  });
                }}></TextField>
            })}
          </Grid>}
          <Button sx={{ mt: 2 }} variant='contained' onClick={createOrUpdate}>{t('save')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
