import { CheckBox, Close, Info, Search, TheaterComedy } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { EmployeeGroupDTO } from '../../../ApiOld/ApiServerVov';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';

export interface EmployeesWithGroupsDialogProps {
    onClose: (event: any) => void;
    open: boolean;
    recordId?: number;
    date?: string;
    buildingName?: string;
    isSubdodavatel?: boolean;
}
export default function EmployeesWithGroupsDialog(props: EmployeesWithGroupsDialogProps, ref: any) {
    const { onClose, open, recordId, buildingName, date, isSubdodavatel } = props;
    const [employees, setEmployees] = React.useState<EmployeeGroupDTO[]>();
    const [searchBackupEmployees, setSearchBackupEmployees] = React.useState<EmployeeGroupDTO[]>();
    const [searchText, setSearchText] = React.useState<string | undefined>();
    const [searched, setSearched] = React.useState<string>('');
    const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    const handleClose = (e: any) => {
        onClose(e);
    };
    useEffect(() => {
        if (recordId && open)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetEmployeesWithGroups(recordId).then((data) => {
                setEmployees(data);
                setSearchBackupEmployees(data);
            });
        else if (!open) {
            setEmployees(undefined);
            setSearchText(undefined);
            setSearched('');
        }
    }, [open, recordId]);
    useEffect(() => {
        if (searched !== '') {
            let searchedEmployees = searchBackupEmployees?.filter(x => x.employees?.find(y => y.name && y.name.toLowerCase().includes(searchText?.toLowerCase()!)) !== undefined);
            setEmployees(searchedEmployees);
        }
        else if (searchBackupEmployees) {
            setEmployees(searchBackupEmployees);
        }
    }, [searched]);

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            // console.log(searchText);
            handleSearch();
        }
    }
    const handleSearch = () => {
        if (searchText !== undefined && searchText !== '') setSearched(searchText); else setSearched('');
    }
    function changeSelectionOnGroup(group: EmployeeGroupDTO, checked: boolean) {
        if (employees) {
            let groupsNew = [...employees];
            let currentGroup = groupsNew.find(x => x.id === group.id);
            if (currentGroup) {
                currentGroup.isSelected = checked;
                if (currentGroup.employees)
                    currentGroup.employees!.forEach(x => x.isSelected = checked);
                setEmployees(groupsNew);
            }

        }

    }
    function changeSelectionOnUser(employee: EmployeeGroupDTO, checked: boolean) {
        if (employees) {
            let groupsNew = [...employees];
            for (let p of groupsNew) {
                if (p.employees)
                    for (let i of p.employees) {
                        if (i.id === employee.id) {
                            i.isSelected = checked;
                            break;
                        }
                    }
            }
            setEmployees(groupsNew);


        }
    }
    const { t } = useTranslation();
    return (
        <Dialog style={{ marginTop: '100px' }}
            open={open}
            onClose={() => { handleClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '400px' }}>{t('diary.selectEmployeesToInsert')}</div>
                    <IconButton sx={{
                        width: '30px', height: '30px', right: '20px', top: '18px',
                        float: 'right', position: 'absolute'
                    }}
                        onClick={() => { onClose('') }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ background: '#F5F5F5' }}>
                <div style={{ maxHeight: '800px' }}>
                    <div style={{ display: 'flex' }}>
                        <TextField value={searchText} onKeyDown={handleKeyDown} onChange={(e) => { setSearchText(e.target.value) }}
                            placeholder={t('SearchDots')} variant='standard' sx={{ height: '20px', margin: '20px' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleSearch}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} size='small' />
                        {searchText !== '' && (employees === undefined || employees.length === 0) && <div style={{ marginLeft: '20px', marginTop: '24px' }}>{t('noRecords')}</div>}

                    </div>
                    {date && buildingName && <div style={{ fontSize: '12px' }}><Info style={{ width: '16px', height: '16px', marginTop: '-4px' }} /> {t('diary.PinnedUserWasAt')} {<span style={{ fontWeight: 'bold' }}>{date}</span>} {t('diary.OnTheSiteNamed')} {<span style={{ fontWeight: 'bold' }}>{buildingName}</span>}</div>}
                    {employees && employees.map((group, i) => {
                        return <div key={i}>
                            <div style={{ marginTop: '10px' }}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={group.isSelected} onChange={(e, checked) => { changeSelectionOnGroup(group, checked); }} />}
                                    label={<Typography sx={{ fontSize: '20px', marginLeft: '10px' }}>{group.name !== null && group.name !== undefined ? group.name : t('diary.employeesNoGroup')}</Typography>}
                                    labelPlacement="end"
                                />
                            </div>
                            {/* <Checkbox checked={group.isSelected} onChange={(e, checked) => { changeSelectionOnGroup(group, checked); }}>{group.name}</Checkbox></div> */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{group.employees && group.employees.map((emp, i) => {
                                return <div key={i} style={{
                                    background: emp.isSelected ? theme.palette.primary.main : 'white',
                                    minWidth: '168px', borderRadius: '10px', margin: '4px', boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px'
                                }}> <FormControlLabel
                                        value="end"
                                        sx={{ marginLeft: '10px' }}
                                        control={<Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={emp.isSelected}
                                            onChange={(e, checked) => { changeSelectionOnUser(emp, checked); }} checkedIcon={<CheckBox color='secondary' />} />}
                                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{emp.code}{emp.code ? '  ' : ''}{emp.name}</Typography>}
                                        labelPlacement="end"
                                    /></div>;
                            })}</div>

                        </div>;

                    })}
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained"
                            sx={{ marginTop: '40px', marginBottom: '10px' }}

                            onClick={() => {
                                onClose(employees);
                            }
                            }>{t('diary.insertIntoDiary')}</Button>
                    </div>
                </div>
            </DialogContent>

        </Dialog>);
}