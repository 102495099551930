import { Check, Close, RestoreOutlined, Save, SettingsOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Stack } from '@mui/material';
import * as React from 'react';
import SplitButton from '../../Shared/SplitButton';
import { useTranslation } from 'react-i18next';
import { GetBuildingDataDetailResponse } from '../../../ApiOld/ApiServerVov';
import { useBuildingsPermissionContext } from '../../../Contexts/Permissions/BuildingsPermissionContext';

export interface IProjectMenuButtonsProps {
    saveProject: (close: boolean) => void;
    saveDone: boolean;
    setSaveDone: (e: boolean) => void;
    error: number;
    handleSetupCustomFields: () => void;
    onAddNote: () => void;
    onCreateInvestor: () => void;
    onThrash: () => void;
    selectedTabIndex: number;
    permissions?: any;
    projectData: Partial<GetBuildingDataDetailResponse> | null;
}

export default function ProjectMenuButtons(props: IProjectMenuButtonsProps) {
    const { saveProject, saveDone, error, handleSetupCustomFields, onAddNote, onCreateInvestor, selectedTabIndex, permissions, projectData, onThrash } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        if (saveDone)
            setSaving(false);
    }, [saveDone]);

    return (
        <Stack spacing={2} direction={'row'} mr={2}>
            {selectedTabIndex === 1 && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={handleSetupCustomFields} startIcon={<SettingsOutlined />} variant='contained' size='small'>{t('FieldsSettings')}</Button>}
            {selectedTabIndex === 3 && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={onCreateInvestor} startIcon={<SettingsOutlined />} variant='contained' size='small'>{t('createInvestor')}</Button>}
            {selectedTabIndex === 4 && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={onAddNote} startIcon={<SettingsOutlined />} variant='contained' size='small'>{t('addNote')}</Button>}
            {selectedTabIndex === 6 && permissions.buildingsDocuments && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={onThrash} startIcon={<RestoreOutlined />} variant='contained' size='small'>{t('files.restoreFolders')}</Button>}
            {saveDone && !saving && !error && <Check color='success' />}
            {saveDone && !saving && error !== 0 && <Close color='error' />}
            {!saving && <SplitButton selectedIndexInput={0} disabled={projectData === undefined || projectData === null || !permissions?.buildingsEdit || !projectData.name || projectData.name.length < 1 || !projectData.code || projectData.code.length < 1 || projectData.gpsX1 === undefined || projectData.gpsY1 === undefined || projectData.gpsDiff1 === undefined} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                startIconSplitButton={<Save />}

                onMenuClick={(e) => {
                    if (e === 0) {
                        setSaving(true);
                        saveProject(false);
                    }
                    else if (e === 1) {
                        setSaving(true);
                        saveProject(true);
                    }
                }}></SplitButton>}
            {saving && <CircularProgress color='primary' />}
        </Stack>
    );
}
