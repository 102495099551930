import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

interface WarehouseInfoDialogProps {
    open: boolean;
    onClose: () => void;
    text: string;
}
export default function WarehouseInfoDialog(props: Readonly<WarehouseInfoDialogProps>) {
    const { open, onClose, text } = props;

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="info-dialog-title"
            aria-describedby="info-dialog-description"
        >
            <DialogTitle id="info-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('error')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ minHeight: '120px' }}>
                    <Typography variant='body1'>{text}</Typography>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                    <Button variant='contained' sx={{ margin: 'auto' }} onClick={() => {
                        onClose();
                    }}>{t('close')}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
