import { Box, Checkbox, CircularProgress, FormControlLabel, Link, Tooltip } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, RecordDoubleClickEventArgs } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToDoAssigneeDTO, UserType } from '../../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../../ApiOld/ApiToDoOldHelper';


interface SingleTodoAssigneesSceneOldProps {
    buildingId: number;
    selectedAssignees: ToDoAssigneeDTO[];
    onChange: (sel: ToDoAssigneeDTO[]) => void;
}

function SingleTodoAssigneesSceneOld(props: SingleTodoAssigneesSceneOldProps) {
    const { buildingId, selectedAssignees, onChange } = props;
    const params = useParams();
    const [data, setData] = useState<ToDoAssigneeDTO[]>();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        refreshData();
    }, [buildingId]);


    function refreshData() {
        if (buildingId)
            new APITodoOldHelper().GetTodoAllPossibleAssignees(buildingId).then((result) => {
                setData(result);
            });
        else setData([]);
    }

    if (data)
        return (
            <div>
                <div style={{ display: "flex", flexDirection: 'column', margin: '0px' }}>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px', width: '95%' }}>
                        <h6>{t('users')}</h6>
                        <Box>
                            {data.some(p => p.type === UserType.Admin) && data.filter(p => p.type === UserType.Admin).map((adminUser) => (
                                <FormControlLabel
                                    key={adminUser.id}
                                    control={
                                        <Checkbox
                                            id={adminUser.id?.toString()}
                                            checked={selectedAssignees.some(p => p.id == adminUser.id && p.type == UserType.Admin)}
                                            onChange={(e, ch) => {
                                                if (ch === true) {
                                                    if (!selectedAssignees.some(p => p.id === adminUser.id && p.type === UserType.Admin))
                                                        onChange([...selectedAssignees, adminUser])
                                                }
                                                else onChange(selectedAssignees.filter(p => !(p.id === adminUser.id && p.type === UserType.Admin)))
                                            }}
                                        />
                                    }
                                    label={adminUser.name}
                                />
                            ))}
                        </Box>
                    </div>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px', width: '95%' }}>
                        <h6>{t('employees')}</h6>
                        <Box>
                            {data.some(p => p.type === UserType.Employee) && data.filter(p => p.type === UserType.Employee).map((employee) => (
                                <FormControlLabel
                                    key={employee.id}
                                    control={
                                        <Checkbox
                                            id={employee.id?.toString()}
                                            checked={selectedAssignees.some(p => p.id == employee.id && p.type == UserType.Employee)}
                                            onChange={(e, ch) => {
                                                if (ch === true) {
                                                    if (!selectedAssignees.some(p => (p.id === employee.id && p.type === UserType.Employee)))
                                                        onChange([...selectedAssignees, employee])
                                                }
                                                else onChange(selectedAssignees.filter(p => !(p.id === employee.id && p.type === UserType.Employee)))
                                            }}
                                        />
                                    }
                                    label={employee.name}
                                />
                            ))}
                        </Box>
                    </div>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px', width: '95%' }}>
                        <h6>{t('investors')}</h6>
                        <Box>
                            {data.some(p => p.type === UserType.Investor) && data.filter(p => p.type === UserType.Investor).map((investor) => (
                                <FormControlLabel
                                    key={investor.id}
                                    control={
                                        <Checkbox
                                            id={investor.id?.toString()}
                                            checked={selectedAssignees.some(p => p.id == investor.id && p.type == UserType.Investor)}
                                            onChange={(e, ch) => {
                                                if (ch === true) {
                                                    if (!data.some(p => p.id === investor.id && p.type === UserType.Investor))
                                                        onChange([...selectedAssignees, investor])
                                                }
                                                else onChange(selectedAssignees.filter(p => !(p.id === investor.id && p.type === UserType.Investor)))
                                            }}
                                        />
                                    }
                                    label={investor.name}
                                />
                            ))}
                        </Box>
                    </div>
                </div>
            </div >
        );
    else return <div><CircularProgress /></div>;
};
export default SingleTodoAssigneesSceneOld;

