import { Clear, DoneAll } from '@mui/icons-material';
import { Box, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Tooltip, useTheme } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BuildingEmployeeDTO, EmployeeDetailDTO } from '../../../../ApiOld/ApiServerVov';

export interface EmployeeBuildingsSceneProps {
    employee: EmployeeDetailDTO | undefined;
    setEmployee: (value: EmployeeDetailDTO | undefined) => void;
}


export default function EmployeeBuildingsScene(props: EmployeeBuildingsSceneProps) {
    const { employee, setEmployee } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();

    const checkAll = useCallback((isAssigned: boolean) => {
        if (!employee) return;

        const updatedAssignedUsers: BuildingEmployeeDTO[] = employee.buildings!.map((p) =>
            new BuildingEmployeeDTO({ id: p.id, isAssigned: isAssigned, name: p.name })
        );
        setEmployee(new EmployeeDetailDTO({ ...employee, buildings: updatedAssignedUsers }));
    }, [setEmployee]);

    if (employee !== undefined && employee.buildings !== undefined) {
        return (
            <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <h4>{t('visibleForUsers')}</h4><div style={{ boxShadow: '1px 3px 3px 3px lightgray', margin: '16px', padding: '8px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex' }}>
                            <Tooltip title={t('chooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                                    <DoneAll sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('unchooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                                    <Clear sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            employee.buildings.map((item2, ii) => {
                                return (<div key={ii} style={{ margin: '10px', marginLeft: '20px' }}> <FormControlLabel
                                    key={item2.id}
                                    control={
                                        <Checkbox
                                            id={item2.id?.toString()}
                                            checked={item2.isAssigned}
                                            onChange={(e) => {
                                                if (!employee) return;

                                                const updatedAssignedUsers: BuildingEmployeeDTO[] = employee.buildings!.map((p) =>
                                                    p.id === item2.id ? new BuildingEmployeeDTO({ id: p.id, isAssigned: !p.isAssigned, name: p.name }) : p
                                                );
                                                setEmployee(new EmployeeDetailDTO({ ...employee, buildings: updatedAssignedUsers }));
                                            }}
                                        />
                                    }
                                    label={item2.name}
                                /> </div>);
                            })
                        }
                    </div>
                </div>
            </Box>
        );
    }
    else return (<div></div>);
};
