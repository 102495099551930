import { CalendarMonth, Clear, Close, CloseFullscreen, Fullscreen } from '@mui/icons-material';
import { Box, Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import withLoading, { WithLoadingProps } from '../../Shared/Loader';
import { GetMaterialsForRecordTableDTO } from '../../../ApiOld/ApiServerVov';
import { useParams } from 'react-router';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as RNG from 'react-date-range';
import cs from 'date-fns/locale/cs';
import sk from 'date-fns/locale/sk';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-US';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser } from '@syncfusion/ej2-react-grids';
import standardFilterOptions from '../../Shared/Grids/StandardFilterOptions';

export interface DiaryMaterialsExportExcelDialogProps extends WithLoadingProps {
    open: boolean;
    currentDate: Date;
    diaryId: number | undefined;
    onClose: (refresh: boolean) => void;
    isSubdodavatel?: boolean;
}


//
function DiaryMaterialsExportExcelDialog(props: DiaryMaterialsExportExcelDialogProps) {

    const { onClose, diaryId, open, isSubdodavatel, fireLoading, stopLoading, currentDate } = props;
    const { t, i18n } = useTranslation();
    const [diary, setDiary] = useState<number | undefined>(undefined);
    const [materials, setMaterials] = React.useState<GetMaterialsForRecordTableDTO[]>([]);
    const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);

    const [dateRange, setDateRange] = React.useState<RNG.Range[]>([
        {
            startDate: new Date((new Date()).valueOf()),
            endDate: new Date((new Date()).valueOf()),
            key: 'selection'
        }
    ]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    const params = useParams();

    useEffect(() => {
        stopLoading!();
        setDiary(diaryId);

        if (open === true)
            loadData();
    }, []);

    useEffect(() => {
        stopLoading!();
        setDiary(diaryId);
        if (open === true)
            loadData();
    }, [diaryId]);

    useEffect(() => {
        stopLoading!();
        if (open === true)
            loadData();
    }, [open]);

    useEffect(() => {
        setDateRange([
            {
                startDate: new Date(currentDate.valueOf()),
                endDate: new Date(currentDate.valueOf()),
                key: 'selection'
            }
        ])
    }, [currentDate]);

    useEffect(() => {
        stopLoading!();

        if (open === true)
            loadData();
    }, [dateRange]);

    const loadData = () => {
        if (diaryId !== undefined && dateRange && dateRange![0].startDate && dateRange![0].endDate) {
            let start = new Date(
                Date.UTC(
                    dateRange![0].startDate.getFullYear(),
                    dateRange![0].startDate.getMonth(),
                    dateRange![0].startDate.getDate(),
                    dateRange![0].startDate.getHours(),
                    dateRange![0].startDate.getMinutes(),
                    dateRange![0].startDate.getSeconds(),
                    dateRange![0].startDate.getMilliseconds()
                )
            );
            let end = new Date(
                Date.UTC(
                    dateRange![0].endDate.getFullYear(),
                    dateRange![0].endDate.getMonth(),
                    dateRange![0].endDate.getDate(),
                    dateRange![0].endDate.getHours(),
                    dateRange![0].endDate.getMinutes(),
                    dateRange![0].endDate.getSeconds(),
                    dateRange![0].endDate.getMilliseconds()
                )
            );
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetMaterialsForRecordsExcel(diaryId, start, end).then((materials) => {
                if (materials)
                    setMaterials(materials);
                else setMaterials([]);
            });
        }
        else setMaterials([]);
    }

    const [fullScreen, setFullScreen] = useState(false);
    function onClosed() { onClose(false); }
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    function setDefaultDate() {
        setDateRange([
            {
                startDate: undefined,
                endDate: undefined,
                key: 'selection'
            }
        ]);
    }

    const onPrintClick = () => {
        if (grid.current !== undefined)
            grid.current!.excelExport();
    }

    const grid = useRef<GridComponent>(null);
    return (
        <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={open} onClose={() => { onClosed() }} sx={{ marginTop: '80px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "255", backgroundColor: '#F5F5F5' }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('diary.exportExcelAll')}{' - '}{t('diary.materials')}</Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => {
                                setFullScreen(!fullScreen);
                            }} >
                                {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                            </IconButton>
                            <IconButton onClick={onClosed} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            <div style={{ display: 'flex-inline', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: '#F5F5F5', minHeight: '600px' }} >
                <div style={{ display: 'flex', margin: '20px' }}>
                    <div style={{ margin: '20px', width: '65%' }}>
                        <Box sx={{ width: '100%', height: '500px', margin: '16px 8px' }}>
                            <GridComponent style={{ borderColor: 'transparent', height: '500px' }} id="grid-component-materials" allowSorting={true}
                                ref={grid}
                                height={400}
                                // dataBound={(e) => { if (grid.current) grid.current.autoFitColumns([]); }}
                                allowReordering={true} allowResizing={true} showColumnChooser={true}
                                allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                                locale={i18n.language}
                                enablePersistence={false}
                                filterSettings={standardFilterOptions}
                                resizeSettings={{ mode: 'Normal' }}
                                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                                allowFiltering={false}
                                dataSource={materials}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} showInColumnChooser={false} />
                                    <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='200' minWidth='200' allowEditing={false} />
                                    <ColumnDirective field='amount' editType='numericedit' maxWidth='400' textAlign='Right'
                                        headerText={t('Amount')} width='100' format='N2' allowEditing={false} />
                                    <ColumnDirective field='unit' headerText='Jednotka' maxWidth='400' width='200' minWidth='200' allowEditing={false} />
                                    <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing={false} headerText={t('diary.sharedEntry')} width='40' minWidth='200' />
                                    <ColumnDirective field='adminCreatorName' headerText={t('CreatedBy')} allowEditing={false} maxWidth='800' width='300' minWidth='200' />
                                    <ColumnDirective field='diaryDate' type='datetime' format='dd.MM.yyyy' allowEditing={false} headerText={t('diary.diaryDate')} maxWidth='800' width='200' minWidth='200' />
                                    <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='200' minWidth='200' />
                                    <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='200' minWidth='200' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser]} />
                            </GridComponent>
                        </Box>
                    </div>
                    <div style={{ display: 'flex', margin: '5px', borderRight: '1px solid black' }}>
                    </div>
                    <div style={{ margin: '20px', width: '30%' }}>
                        <div style={{ display: 'flex', margin: '5px', borderBottom: '1px solid' }}>
                            <Typography sx={{ fontWeight: 'bold', minWidth: '80px', marginRight: '10px', marginLeft: "auto" }}>
                                {dateRange[0]!.startDate === undefined ? '               ' : dateRange[0]!.startDate === dateRange[0]!.endDate ? dateRange[0]!.startDate?.toLocaleDateString(i18n.language) : dateRange[0]!.startDate?.toLocaleDateString(i18n.language) + ' - ' + dateRange[0]!.endDate?.toLocaleDateString(i18n.language)}
                            </Typography>
                            {
                                dateRange[0]!.startDate && <IconButton sx={{ marginTop: '-8px' }} onClick={() => {
                                    setDefaultDate();
                                }}>
                                    <Clear />
                                </IconButton>
                            }
                            <IconButton sx={{ marginTop: '-8px' }} onClick={() => {
                                setIsCalendarOpen(true);
                            }}>
                                <CalendarMonth />
                            </IconButton>
                        </div>

                        <div style={{ margin: 'auto' }}>
                            <Button onClick={() => { onPrintClick() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>{t('Export')}</Button>
                        </div>
                    </div>
                </div>
                <Dialog open={isCalendarOpen} onClose={() => { setIsCalendarOpen(false); }}>
                    <div>
                        <RNG.DateRange editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={dateRange} onChange={item => { setDateRange([item.selection]); }} locale={i18n.language == 'cs' ? cs : (i18n.language == 'pl' ? pl : (i18n.language == 'sk' ? sk : en))} />
                        <div style={{ display: 'flex', margin: '5px', borderRight: '1px solid black' }}>
                            <Button onClick={() => { setIsCalendarOpen(false); }} style={{
                                marginLeft: 'auto', marginRight: '10px', border: '1px solid', borderColor: 'lightgray'

                            }} aria-describedby='btnOk'>
                                <Typography color='#FFC600'>OK</Typography>
                            </Button>
                            <Button onClick={() => { setIsCalendarOpen(false); }} style={{ marginRight: 'auto', border: '1px solid', borderColor: 'lightgray' }} aria-describedby='btnOk'>
                                <Typography color='#FFC600'>{t('Cancel')}</Typography>
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div >
        </Dialog >
    );
};

export default withLoading(DiaryMaterialsExportExcelDialog);
