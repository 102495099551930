
import React from 'react';
import { CustomFieldDataType } from '../../ApiOld/Api/ApiServerVov';
import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';
import { AutocompleteProps } from '@react-google-maps/api';
import { customDataFieldsCaptions } from './CustomDataFieldsCaption';
import { useTranslation } from 'react-i18next';

interface CustomFieldDataTypeSelectProps {
  dataType: CustomFieldDataType;
  onChange: (dataType: CustomFieldDataType) => void;
  sx: SxProps<Theme>;
}

export default function CustomFieldDataTypeSelect(props: Readonly<CustomFieldDataTypeSelectProps>) {
  const { dataType, onChange, sx } = props;
  const { t } = useTranslation();
  return (

    <Autocomplete
      sx={sx}
      size='small'
      options={customDataFieldsCaptions}
      getOptionLabel={(option) => t(option.label)}
      value={customDataFieldsCaptions.find((option) => option.value === dataType) || null}
      onChange={(event, newValue) => onChange(newValue?.value || CustomFieldDataType.String)}
      renderInput={(params) => <TextField {...params} label="Datový typ vlastního pole" />}
    />
  );
}
