import { useEffect, useRef, useState } from "react";
import { Typography, Box, TextField, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TodoAttachmentAutocompleteSearchBarProps {
    list: any[];
    onSelected: (selected: any) => void;
    clear: boolean;
}

function TodoAttachmentAutocompleteSearchBar(props: TodoAttachmentAutocompleteSearchBarProps) {
    const { list, onSelected, clear } = props;
    const [input, setInput] = useState("");
    const { t, i18n } = useTranslation();
    const autoC = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (clear && autoC.current) {
            const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            // @ts-ignore
            if (ele) ele.click();
        }
    }, [clear]);

    const handleInput = (e) => {
        console.log(e.target.value);
        setInput(e.target.value.toLowerCase());
    };

    return (
        <Box
            className="App"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                margin: '8px'
            }}
        >
            <Autocomplete
                ref={autoC}
                disablePortal
                size="small"
                id="combo-box-demo"
                options={list.map((item) => item)}
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => { onSelected(value); }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("files.searchFiles")}
                        onSelect={handleInput}
                    />
                )}
            />
        </Box>
    );
}

export default TodoAttachmentAutocompleteSearchBar;