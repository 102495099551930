import * as React from 'react';
import { Avatar, useTheme } from '@mui/material';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';

export interface IUserAvatarProps {
    handleAvatarClick: (event: React.MouseEvent<HTMLElement>) => void;
    isUppside?: boolean;
}

export default function UserAvatar(props: IUserAvatarProps) {
    const { handleAvatarClick, isUppside } = props;
    const { initials } = useUserLayoutContext();
    const theme = useTheme();
    return (
        <Avatar
            onClick={handleAvatarClick}
            sx={{
                width: isUppside ? 40 : 26, height: isUppside ? 40 : 26, margin: 'auto',
                cursor: 'pointer',
                marginBottom: isUppside ? undefined : '10px',
                bgcolor: isUppside ? theme.palette.primary.main : 'white',
                color: '#404040', fontSize: 'small', '&:hover': {
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }
            }} variant='circular' color='error'>{initials}</Avatar>
    );
}
