import { useTheme } from "@emotion/react";
import { Download, FormatListBulleted, Tv } from "@mui/icons-material";
import { Box, Tooltip, CircularProgress, Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../ApiOld/Api/useApi";
import { ImageDiaryDTO, VovClient, DeleteImagesBuildingRequest, SetImagesShowToInvestorRequest, ConversationFileDto } from "../../../ApiOld/ApiServerVov";
import { useBuildingsPermissionContext } from "../../../Contexts/Permissions/BuildingsPermissionContext";
import ImageGallery from "../../Shared/FileViewers/ImageGallery";
import MessageDialog from "../../Shared/MessageDialog";
import ProjectPhotoAutocompleteSearchBar from "../ProjectComponents/Components/ProjectPhotoAutocompleteSearchBar";
import ProjectPhotosListScene from "../ProjectComponents/Components/ProjectPhotosListScene";
import ProjectPhotosTilesScene from "../ProjectComponents/Components/ProjectPhotosTilesScene";
import MoveToCategoryDialog from "../ProjectComponents/Dialogs/MoveToCategoryDialog";
import { GetFilesByIdForTodoZipRequest, TodoMainVovClient } from "../../../ApiOld/Api/ApiServerVov";
import { useRequestsPermissionContext } from "../../../Contexts/Permissions/RequestsPermissionContext";
import TodoAttachmentScene from "./TodoAttachmentListScene";
import TodoAttachmentTilesScene from "./TodoAttachmentTilesScene";
import TodoAttachmentListScene from "./TodoAttachmentListScene";
import TodoAttachmentAutocompleteSearchBar from "./Components/TodoAttachmentAutocompleteSearchBar";


interface TodoAttachmentMainSceneProps {
    todoId: number;
}

export default function TodoAttachmentMainScene(props: TodoAttachmentMainSceneProps) {
    const { todoId } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>();
    const [viewList, setViewList] = useState<boolean>(true);
    const [messageOpen, setMessageOpen] = useState<boolean>(false);
    const [files, setFiles] = useState<ConversationFileDto[]>();

    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    const { authorizedApi } = useApi(TodoMainVovClient);

    const { permissions, reloadPermissions } = useRequestsPermissionContext();

    useEffect(() => {
        authorizedApi.getTodosAttachmentsList(todoId).then((r) => {
            setFiles(r.conversationFiles ?? []);
        });
    }, []);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const [clearSearch, setClearSearch] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);

    const [selectAll, setSelectAll] = useState<boolean>(false);
    if (files)
        return <Grid item xs={12} lg={12}>
            <Box style={{ margin: '16px', padding: '8px', border: '1px solid lightgray', borderRadius: '10px' }}>
                <Grid container spacing={2}>
                    {!viewList && <Grid item xs={12} md={6} lg={6}>
                        <TodoAttachmentAutocompleteSearchBar clear={clearSearch} list={files} onSelected={(e) => { setSelectedIds(e ? [e.id!] : []) }} />
                    </Grid>}
                    <Grid item xs={'auto'} md={viewList ? 6 : 3} lg={viewList ? 6 : 3} style={{ display: 'flex', justifyContent: 'left', padding: '16px', marginTop: '12px', gap: 8 }}>
                        <Button size="small" color={'secondary'} sx={{ marginLeft: '8px' }} onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (selectedIds.length > 0 && todoId !== undefined && !downloading) {
                                if (selectedIds.length === 1) {
                                    var file = files?.find(f => f.id === selectedIds[0]);

                                    if (file) {
                                        const anchor = document.createElement('a');
                                        anchor.href = file.fileUrl!;
                                        anchor.download = file.fileName ?? 'file.zip'; // Suggests a file name for the download
                                        document.body.appendChild(anchor); // Append to the DOM to trigger download
                                        anchor.click(); // Trigger click to download
                                        document.body.removeChild(anchor); // Clean up the DOM
                                    }
                                }
                                else {
                                    setDownloading(true);
                                    authorizedApi.getFilesByIdForTodoZip(new GetFilesByIdForTodoZipRequest({ todoId: todoId, fileIds: selectedIds, apiAdress: '' })).then(() => {
                                    }).catch((e) => {
                                        console.log(e);
                                    });
                                    setDownloading(false);
                                    setMessageOpen(true);
                                }
                            }
                        }}>
                            {!downloading && <Tooltip title={t('downloadSelected')}>
                                <Download color={/* viewList ? */ /* 'primary' */ undefined} />
                            </Tooltip>}
                            {downloading && <CircularProgress size='24px' />}
                        </Button>
                    </Grid>
                    <Grid item xs={'auto'} md={viewList ? 6 : 3} lg={viewList ? 6 : 3} style={{ display: 'flex', justifyContent: 'right', padding: '16px', marginTop: '8px', gap: 8 }}>
                        <div style={{ cursor: 'pointer' }} onClick={() => { setViewList(true); }}>
                            <Tooltip title={t('files.list')}>
                                <FormatListBulleted color={viewList ? 'primary' : undefined} />
                            </Tooltip>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => { setViewList(false); }}>
                            <Tooltip title={t('files.tiles')}>
                                <Tv color={viewList ? undefined : 'primary'} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                {!viewList && <div style={{ overflow: 'auto' }}>
                    {files && <TodoAttachmentTilesScene allSelect={selectAll} setAllSelect={(c) => setSelectAll(c)} onImageClick={(index) => setSelectedImageIndex(index)} files={files} selectedIds={selectedIds} onSelect={(e) => { setSelectedIds(e ?? []); }} />}
                </div>}
                {viewList && <div>
                    {files && <TodoAttachmentListScene onImageClick={(index) => setSelectedImageIndex(index)} files={files} selectedIds={selectedIds} onSelect={(e) => { setSelectedIds(e ?? []); }} />}
                </div>}
            </Box>

            <ImageGallery<ConversationFileDto>
                canSaveChanged={false}
                canCreateTask
                files={files ?? []}
                selected={selectedImageIndex}
                open={selectedImageIndex !== undefined}
                onClose={() => { setSelectedImageIndex(undefined); }}
                newRequestButton={true}
            />

            <MessageDialog onClose={() => setMessageOpen(false)} text={t('downloadImagesZipText')} title={t('downloadImagesZipHeader')} open={messageOpen} />
        </Grid>
    else
        return <div></div>;
}
