import { ChangeEventArgs, HtmlEditor, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Image as RTImage } from "@syncfusion/ej2-react-richtexteditor";
import i18n from "../../../../../Localization/i18nInitializer";
import { Box } from "@mui/material";
import { GetDiaryPermissionsInvestorResponse } from "../../../../../ApiOld/ApiServerVov";

interface AnotherNotesWidgetSceneProps {
    notes: string | undefined;
    setNotes: (notes: string) => void;
    isInvestor: boolean | undefined;
    investorPerm: GetDiaryPermissionsInvestorResponse | undefined | null;
    setDataToSaveChanged: (dataToSaveChanged: boolean) => void;
}

export default function AnotherNotesWidgetScene(props: AnotherNotesWidgetSceneProps) {
    const { notes, setNotes, isInvestor, investorPerm, setDataToSaveChanged } = props;
    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }
    return (
        <Box sx={{ marginX: '40px', background: 'white', padding: '30px', borderRadius: '15px', minHeight: 500 }}>
            {(!isInvestor || (isInvestor && investorPerm && investorPerm.buildingDiaryRecordsEdit === true)) && <RichTextEditorComponent toolbarSettings={toolbarSettings} saveInterval={100} height={400} locale={i18n.language} value={notes} change={(eve: ChangeEventArgs) => {
                if (notes !== eve.value) {
                    setNotes(eve.value);
                    setDataToSaveChanged(true);
                }
            }} >
                <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>}
            {(isInvestor && investorPerm && investorPerm.buildingDiaryRecordsEdit !== true) && <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: notes ? notes : "" }} />}
        </Box>
    );
}