import React, { createContext, useCallback, useContext, useState, ReactNode } from 'react';
import { GetBuildingsPermissionsResponse, GetRequestsPermissionsResponse, VovClient } from '../../ApiOld/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';
import { TodoMainVovClient } from '../../ApiOld/Api/ApiServerVov';

interface RequestsPermissionContextProps {
    permissions: GetRequestsPermissionsResponse | null;
    reloadPermissions: () => Promise<void>;
}

const RequestsPermissionContext = createContext<RequestsPermissionContextProps>({
    permissions: null,
    reloadPermissions: async () => { },
});

export const useRequestsPermissionContext = () => useContext(RequestsPermissionContext);


interface RequestsPermissionProviderProps {
    children: ReactNode;
}

export const RequestsPermissionProvider: React.FC<RequestsPermissionProviderProps> = ({ children }) => {
    // const { permissions, reloadPermissions } = useReloadPermissions();
    const [permissions, setPermissions] = useState<GetRequestsPermissionsResponse | null>(null);
    const { authorizedApi } = useApi(TodoMainVovClient);
    const reloadPermissions = useCallback(async (): Promise<void> => {
        console.log('loading permissions');
        const permissions = await authorizedApi.getRequestsPermissions();
        setPermissions(permissions);
        console.log('loaded permissions');
    }, [authorizedApi]);
    return (
        <RequestsPermissionContext.Provider value={{ permissions, reloadPermissions }}>
            {children}
        </RequestsPermissionContext.Provider>
    );
};