import { Check, Close, Save, SettingsOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Stack } from '@mui/material';
import * as React from 'react';
import SplitButton from '../../Shared/SplitButton';
import { useTranslation } from 'react-i18next';
import { GetBuildingDataDetailResponse, GetRequestsPermissionsResponse, TodoDTO } from '../../../ApiOld/ApiServerVov';
import { useCallback } from 'react';

export interface ITodoMenuButtonsProps {
    saveTodo: (close: boolean) => void;
    saveDone: boolean;
    setSaveDone: (e: boolean) => void;
    error: number;
    selectedTabIndex: number;
    permissions?: GetRequestsPermissionsResponse | null;
    todoData: TodoDTO | undefined;
}

export default function TodoMenuButtons(props: ITodoMenuButtonsProps) {
    const { saveTodo, saveDone, error, selectedTabIndex, permissions, todoData } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        if (saveDone)
            setSaving(false);
    }, [saveDone]);

    const showSaveButton = useCallback(() => {
        return selectedTabIndex === 0 || selectedTabIndex === 1;
    }, [selectedTabIndex]);

    return (
        <Stack spacing={2} direction={'row'} mr={2}>
            {/* {selectedTabIndex === 1 && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={handleSetupCustomFields} startIcon={<SettingsOutlined />} variant='contained' size='small'>{t('FieldsSettings')}</Button>}
            {selectedTabIndex === 3 && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={onAddNote} startIcon={<SettingsOutlined />} variant='contained' size='small'>{t('addNote')}</Button>}
            {selectedTabIndex === 7 && <Button disabled={!permissions || permissions.buildingsEdit !== true} onClick={onCreateInvestor} startIcon={<SettingsOutlined />} variant='contained' size='small'>{t('createInvestor')}</Button>} */}
            {saveDone && !saving && !error && <Check color='success' />}
            {saveDone && !saving && error !== 0 && <Close color='error' />}
            {!saving && showSaveButton() && <SplitButton selectedIndexInput={0} disabled={!todoData || !permissions?.requestsEdit || !todoData.name || todoData.name.length < 1} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                startIconSplitButton={<Save />}

                onMenuClick={(e) => {
                    if (e === 0) {
                        setSaving(true);
                        saveTodo(false);
                    }
                    else if (e === 1) {
                        setSaving(true);
                        saveTodo(true);
                    }
                }}></SplitButton>}
            {saving && <CircularProgress color='primary' />}
        </Stack>
    );
}
