import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BuildingFileDTO, CopyCutPasteType } from '../../../../ApiOld/ApiServerVov';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Article, PictureAsPdf, TableView, Image, Description } from '@mui/icons-material';
import { getFileExtension } from '../../../Shared/Functions/GetFileExtension';
import { truncateFileName } from '../../../Shared/TruncateFileName';
import { CopyPasteContext } from '../Contexts/CopyPasteContext';
import { useTranslation } from 'react-i18next';
import { APIOldBuildingsClient } from '../../../../ApiOld/ApiOldBuildingsClient';

interface ProjectDocumentFileItemProps {
    doc: BuildingFileDTO;
    setSelectedDocumentFiles: (items: BuildingFileDTO[]) => void;
    selectedDocumentFiles: BuildingFileDTO[];
    setUploading: (uploading: boolean) => void;
    onRefresh: () => void;
    openDeleteDialog: () => void;
    scrollToItem: boolean;
}

function ProjectDocumentFileItem(props: ProjectDocumentFileItemProps) {
    const { doc, setSelectedDocumentFiles, selectedDocumentFiles, onRefresh, setUploading, openDeleteDialog } = props;
    const ref = useRef<HTMLDivElement>(null);

    const contextValue = useContext(CopyPasteContext);

    const { t } = useTranslation();

    useEffect(() => {
        if (props.scrollToItem && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props.scrollToItem]);


    const handleContextMenuFile = (event: React.MouseEvent, file: BuildingFileDTO) => {
        event.preventDefault();

        if (!selectedDocumentFiles.includes(file))
            setSelectedDocumentFiles([...selectedDocumentFiles, file]);

        setContextMenuFile(
            contextMenuFile === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    };

    function openOrDownloadFile(url, filename) {
        const isFileOpenSupported = window.open(url, '_blank');

        if (!isFileOpenSupported) {
            // If opening in a new tab is not supported, trigger a download
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            try {
                document.body.removeChild(link);
            } catch (e) {
                console.log(e);
            }
        }
    }

    function getIcon(props: string, key: string): ReactJSXElement | undefined {
        switch (getFileExtension(props)) {
            case '.pdf':
                return (<PictureAsPdf key={key + 'icon'} color='primary' sx={{ width: '90px', height: '90px' }} />)
            case '.xlsx':
            case '.xls':
                return <TableView key={key + 'icon'} color='primary' sx={{ width: '100px', height: '100px' }} />;
            case '.docx':
            case '.doc':
                return <Article key={key + 'icon'} color='primary' sx={{ width: '100px', height: '100px' }} />;
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.tiff':
                return <Image key={key + 'icon'} color='primary' sx={{ width: '100px', height: '100px' }} />;
            default: return <Description sx={{ width: '100px', height: '100px' }} />;
        }
    }

    const [contextMenuFile, setContextMenuFile] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);



    const handleCloseContextMenuFile = () => {
        setContextMenuFile(null);
    };

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    const handlePasteFiles = useCallback(() => {
        if (contextValue !== null && contextValue.copyPasteFileIds && doc && doc.id && contextValue.copyCutType !== undefined && contextValue.copyCutType !== null) {
            setUploading(true);
            new APIOldBuildingsClient().CopyCutPasteProjectFile(undefined, doc.id, contextValue.copyCutType as CopyCutPasteType, contextValue.copyPasteFileIds).then(p => {
                onRefresh();
                setUploading(false);
            });
        }
    }, [onRefresh, contextValue, doc]);

    return <div ref={ref} onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); handleContextMenuFile(e, doc!) }} key={doc.id} style={{ margin: '5px', background: selectedDocumentFiles.includes(doc!) ? 'lightgray' : '', borderRadius: '5px' }} onDoubleClick={() => { openOrDownloadFile(doc?.file, doc?.name) }}
        onClick={(e) => {
            /* if (selectedDocumentFiles.some(p => p === doc))
                setSelectedDocumentFiles(selectedDocumentFiles.filter(p => p !== doc));
            else { */
            if (e.ctrlKey)
                setSelectedDocumentFiles([...selectedDocumentFiles, doc!]);
            else
                setSelectedDocumentFiles([doc!]);
            /* } */
        }}>
        <IconButton sx={{ width: '100px', height: '100px' }} >
            {getIcon(doc && doc.name ? doc.name : '', doc.id + '')}
            {/* <Description sx={{ width: '100px', height: '100px' }} /> */}
        </IconButton>
        <div style={{ textAlign: 'center', maxWidth: '90px', wordWrap: 'break-word' }}>
            {doc && doc.name ? truncateFileName(doc.name, 32) : ''}
        </div>
        <Menu
            open={contextMenuFile !== null}
            onClose={handleCloseContextMenuFile}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenuFile !== null
                    ? { top: contextMenuFile.mouseY, left: contextMenuFile.mouseX }
                    : undefined
            }
        >
            <MenuItem disabled={selectedDocumentFiles.length === 0} onClick={(e) => {
                e.preventDefault(); e.stopPropagation();
                contextValue?.setCopyPasteFileIds(selectedDocumentFiles.map(p => p.id!).filter(onlyUnique));
                contextValue?.setCopyCutType(CopyCutPasteType.CopyFile);
                handleCloseContextMenuFile();
            }}>{t('files.copyFile')}
            </MenuItem>
            <MenuItem disabled={selectedDocumentFiles.length === 0} onClick={(e) => {
                e.preventDefault(); e.stopPropagation();
                contextValue?.setCopyPasteFileIds(selectedDocumentFiles.map(p => p.id!).filter(onlyUnique));
                contextValue?.setCopyCutType(CopyCutPasteType.CutFile);
                handleCloseContextMenuFile();
            }}>{t('files.cutFile')}
            </MenuItem>
            <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); openDeleteDialog(); handleCloseContextMenuFile(); }}>{t('files.deleteFile')}</MenuItem>
            <MenuItem disabled={contextValue === null || contextValue.copyPasteFileIds === null || contextValue.copyPasteFileIds.length === 0} onClick={(e) => {
                e.preventDefault(); e.stopPropagation();
                handlePasteFiles();
                handleCloseContextMenuFile();
            }}>{t('files.pasteFile')}</MenuItem>

        </Menu>
    </div>;
}

export default ProjectDocumentFileItem
