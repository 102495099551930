import { AttachFile, Check, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, Reply, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogTitle, FormControlLabel, Grid, IconButton, makeStyles, Popover, Slider, styled, Tab, Tabs, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { BuildingDTO, TodoListDTO, UserResponsibleDTO } from '../../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../../ApiOld/ApiToDoOldHelper';
import { useNavigate, useParams } from 'react-router';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import AdminUserBuildingSelect from '../../Shared/AdminUserBuildingSelect';
import AdminUserSelect from '../../Shared/AdminUserSelect';
import SplitButton from '../../Shared/SplitButton';
import delay from '../../Shared/Functions/Delay';
import BuildingSelect from '../../Shared/BuildingSelect';
import BuildingDiarySelect from '../../Shared/BuildingDiarySelect';
import WealthSelect from '../../Shared/WealthSelect';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './SingleTodoSceneOld.css';
import dayjs, { Dayjs } from "dayjs";
import SingleTodoAssigneesSceneOld from './SingleTodoAssigneesSceneOld';
import { TodoStates } from './TodoStates';
import { TodoDTO } from '../../../ApiOld/Api/ApiServerVov';
import TasksImageMapDialog from '../Tasks/TasksImageMapDialog';


export interface SingleTodoSceneOldProps {
    todo: TodoDTO | undefined;
    setTodo: React.Dispatch<React.SetStateAction<TodoDTO | undefined>>;
    onRefreshNeeded?: () => void;
}

export default function SingleTodoSceneOld(props: SingleTodoSceneOldProps) {
    const { todo, setTodo, onRefreshNeeded } = props;
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();

    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    const params = useParams();
    const optionsStates = TodoStates(t);

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    const showGanttOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    };
    const [showMap, setShowMap] = useState(false);
    if (todo !== undefined) {
        return (
            <ValidationGroup>

                <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <TasksImageMapDialog todo={todo} open={showMap} onClose={(refreshNeeded, todoIn) => {
                        setTodo({ ...todo, mapLeft: todoIn?.mapLeft, mapTop: todoIn?.mapTop, buildingTodoMapId: todoIn?.buildingTodoMapId } as TodoDTO);
                        setShowMap(false);
                        if (refreshNeeded) onRefreshNeeded?.();
                    }} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h4>{t('buildings.basicInformation')}</h4>
                                {todo.datetimeCreated && <Box>
                                    {t('Created') + ': ' + (todo.datetimeCreated as any).toLocaleString(undefined, options)}
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Validate name='name-todo-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                    <TextField id="standard-basic"
                                        error={!todo.name}
                                        helperText={''}
                                        size="small"
                                        sx={{ minWidth: biggerThan700 ? '350px' : '150px', margin: "10px", marginTop: "6px", marginBottom: '6px' }}
                                        onChange={e => setTodo(new TodoDTO({ ...todo, name: e.target.value }))}
                                        label={(t('singleTodo.taskName'))} value={todo.name} variant="outlined" />
                                </Validate>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <Autocomplete
                                    disablePortal

                                    disableClearable
                                    id="combo-box-demo"
                                    size="small"
                                    onChange={(e, v) => {
                                        setTodo(new TodoDTO({ ...todo, state: (v !== undefined && v !== null ? v!.id : undefined) }));
                                    }}
                                    value={optionsStates.find((opt) => opt.id === todo.state)}
                                    options={optionsStates}
                                    //sx={{ margin: "8px" }}
                                    renderInput={(params) => <TextField
                                        error={false}
                                        helperText={''}
                                        {...params}
                                        label={t('state')} />}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <AdminUserSelect isMandatory selectedUserId={todo.responsiblePersonId} onChange={(res) => {
                                    setTodo(new TodoDTO({ ...todo, responsiblePersonId: res ? res.id : undefined }));
                                }} label={t('todo.handles')} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop: '0px' }}>
                                <p style={{ zIndex: 4, marginBottom: '-10px', background: 'white', width: '45px', marginLeft: '10px', paddingLeft: '4px', fontSize: '10px', fontWeight: 'bold', color: 'rgba(0,0,0,0.6)' }}>{todo.description !== '' && t('Description')}</p>

                                <TextareaAutosize aria-label="empty textarea"
                                    placeholder={t('Description')}
                                    minRows={3}
                                    value={todo.description === null ? undefined : todo.description}
                                    onChange={(e) => { setTodo(new TodoDTO({ ...todo, description: e.target.value })) }}
                                    style={{ width: '100%', minHeight: '50px', maxHeight: '80px', borderRadius: '12px', borderColor: 'lightgray', margin: '8px 0px', resize: 'none', marginTop: todo.description === '' ? '20px' : '0px', padding: '8px', boxSizing: 'border-box' }} />
                            </Box>
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={0}>

                            <BuildingSelect isMandatory onChange={async (e) => {
                                if (e) {
                                    setTodo(new TodoDTO({ ...todo, buildingId: e.id }));
                                }
                                else setTodo(new TodoDTO({ ...todo, buildingId: undefined }));
                            }} selectedProjectId={todo.buildingId} label={t('Projekt')} />
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={0}>

                            {todo.buildingId && <BuildingDiarySelect buildingId={todo.buildingId} onChange={async (e) => {
                                if (e) {
                                    setTodo(new TodoDTO({ ...todo, buildingDiaryId: e.id }));
                                }
                                else setTodo(new TodoDTO({ ...todo, buildingDiaryId: undefined }));
                            }} selectedDiaryId={todo.buildingDiaryId} />}
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={0}>
                            <WealthSelect onChange={async (e) => {
                                if (e) {
                                    setTodo(new TodoDTO({ ...todo, wealthId: e.id }));
                                }
                                else setTodo(new TodoDTO({ ...todo, wealthId: undefined }));
                            }} selectedWealthId={todo.wealthId} />
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={'6px'}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-show-investors"
                                options={showGanttOptions}
                                size="small"
                                disableClearable
                                value={todo.showInGantt ? showGanttOptions[0] : showGanttOptions[1]}
                                onChange={(e, v) => { if (v) { setTodo(new TodoDTO({ ...todo, showInGantt: v.id == 0 ? true : false })) } }}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label={'Gantt'} />}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={'6px'}>
                            <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label={t('todo.startDate')}
                                    inputFormat="DD.MM.YYYY hh:mm"
                                    value={todo.datetimeStart ? dayjs(todo.datetimeStart) : null}
                                    onChange={(e, v) => {
                                        setTodo(new TodoDTO({ ...todo, datetimeStart: e === null ? undefined : e.toDate() }));
                                    }}

                                    renderInput={
                                        (params) => <TextField label=''
                                            sx={{ width: '100%' }}

                                            size='small' {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={'6px'}>
                            <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label={t('todo.endDate')}
                                    inputFormat="DD.MM.YYYY hh:mm"
                                    value={todo.datetimeEnd ? dayjs(todo.datetimeEnd) : null}
                                    onChange={(e, v) => {
                                        setTodo(new TodoDTO({ ...todo, datetimeEnd: e === null ? undefined : e.toDate() }));
                                    }}

                                    renderInput={
                                        (params) => <TextField label=''
                                            sx={{ width: '100%' }}
                                            size='small' {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={'6px'}>
                            <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label={t('todo.deadline')}
                                    inputFormat="DD.MM.YYYY hh:mm"
                                    value={todo.datetimeDeadline ? dayjs(todo.datetimeDeadline) : null}
                                    onChange={(e, v) => {
                                        setTodo(new TodoDTO({ ...todo, datetimeDeadline: e === null ? undefined : e.toDate() }));
                                    }}

                                    renderInput={
                                        (params) => <TextField label=''
                                            sx={{ width: '100%' }}
                                            size='small' {...params} />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={'6px'}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', margin: '10px', marginTop: '0px', marginLeft: '0px', marginRight: '0px',
                                borderRadius: '14px',
                                border: '1px solid lightgray',
                                px: '4px',
                                boxSizing: 'border-box'
                            }}>
                                <p style={{ marginTop: '-10px', marginBottom: '0px', background: 'white', width: '45px', marginLeft: '4px', paddingLeft: '4px', fontSize: '10px', fontWeight: 'bold', color: 'rgba(0,0,0,0.6)' }}>{t('todo.progress')}</p>

                                <Slider step={1} sx={{
                                    '& .MuiSlider-thumb': {
                                        height: '10px', width: '10px'
                                    }, height: '2px', width: 'calc(100% - 20px)', marginLeft: '10px', marginRight: '10px', marginTop: '-4px'
                                }} value={todo.progress} aria-label="Default" valueLabelDisplay="auto" onChange={(e, v) => { setTodo(new TodoDTO({ ...todo, progress: v as number })) }} />
                            </Box>
                            {/* <Typography>{t('todo.progress')}</Typography>
                            <Slider step={1} sx={{ width: '100%', marginLeft: '4px' }} value={todo.progress} aria-label="Default" valueLabelDisplay="auto" onChange={(e, v) => { setTodo(new TodoDTO({ ...todo, progress: v as number })) }} /> */}
                        </Grid>
                        <Grid item md={4} sm={12} padding={'10px'} paddingTop={'6px'}>
                            {todo.buildingTodoMapId && todo.buildingTodoMapId > 0 && <Button onClick={() => {
                                setShowMap(true);
                            }} >{t('HasMap')}</Button>}
                            {(!todo.buildingTodoMapId || !(todo.buildingTodoMapId > 0)) && <Button onClick={() => {
                                setShowMap(true);
                            }} >{t('NoMap')}</Button>}
                        </Grid>
                    </Grid>
                </Box>
            </ValidationGroup>
        );
    }
    else return (<div><CircularProgress /></div>);
};
