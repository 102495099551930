import ManufacturerDetail from './ManufacturerDetail';
import ManufacturersList from './ManufacturersList';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface ManufacturersProps {
    type: 'list' | 'detail' | 'new';
}

export default function Manufacturers({ type }: Readonly<ManufacturersProps>) {
    const params = useParams();
    const [manufacturerId, setManufacturerId] = useState<number | undefined>();

    useEffect(() => {
        if (params.manufacturerId) {
            setManufacturerId(parseInt(params.manufacturerId));
        } else {
            setManufacturerId(undefined);
        }
    }, [params]);

    return (
        <Box>
            {type === 'list' && <ManufacturersList />}
            {type === 'detail' && <ManufacturerDetail manufacturerId={manufacturerId} />}
            {type === 'new' && <ManufacturerDetail manufacturerId={undefined} />}
        </Box>
    )
}