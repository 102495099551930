import { Check, Close, Save } from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/material';
import * as React from 'react';
import SplitButton from '../../Shared/SplitButton';
import { useTranslation } from 'react-i18next';
import { GetWarehouseDataDetailResponse } from '../../../ApiOld/Api/ApiServerVov';

export interface WarehouseMenuButtonsProps {
    warehouseData: Partial<GetWarehouseDataDetailResponse> | null;
    saveDone: boolean;
    error: number;
    saveWarehouse: (close: boolean) => void;
}

export default function WarehouseMenuButtons(props: Readonly<WarehouseMenuButtonsProps>) {
    const { warehouseData, saveDone, error, saveWarehouse } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        if (saveDone)
            setSaving(false);
    }, [saveDone]);

    return (
        <Stack spacing={2} direction={'row'} mr={2}>
            {saveDone && !saving && !error && <Check color='success' />}
            {saveDone && !saving && error !== 0 && <Close color='error' />}
            {!saving && <SplitButton selectedIndexInput={0} disabled={!warehouseData?.name || warehouseData.name.length < 1} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                startIconSplitButton={<Save />}

                onMenuClick={(e) => {
                    if (e === 0) {
                        setSaving(true);
                        saveWarehouse(false);
                    }
                    else if (e === 1) {
                        setSaving(true);
                        saveWarehouse(true);
                    }
                }}></SplitButton>}
            {saving && <CircularProgress color='primary' />}
        </Stack>
    );
}
