export default function getFilesFromDropEvent(dataTransferItems: any) {
    function traverseFileTreePromise(item: any, path = ''): Promise<any> {
        return new Promise((resolve) => {
            if (item.isFile) {
                item.file((file: any) => {
                    file.filepath = path + file.name;
                    files.push(file);
                    resolve(file);
                });
            } else if (item.isDirectory) {
                let dirInfo = {
                    filepath: path + item.name + "/",
                    isEmpty: true,
                };
                folders.push(dirInfo);

                let dirReader = item.createReader();
                dirReader.readEntries((entries: any) => {
                    if (entries.length === 0) {
                        resolve(dirInfo);
                    } else {
                        dirInfo.isEmpty = false;

                        let entriesPromises: Promise<any>[] = [];
                        for (let entr of entries) {
                            entriesPromises.push(traverseFileTreePromise(entr, path + item.name + "/"));
                        }
                        Promise.all(entriesPromises).then(() => {
                            resolve(dirInfo);
                        });
                    }
                });
            }
        });
    }

    let files: any[] = [];
    let folders: any[] = [];
    return new Promise((resolve, reject) => {
        let entriesPromises: Promise<any>[] = [];
        for (let it of dataTransferItems) {
            entriesPromises.push(traverseFileTreePromise(it.webkitGetAsEntry()));
        }
        Promise.all(entriesPromises)
            .then(() => {
                resolve({ files: files, folders: folders });
            });
    });
}