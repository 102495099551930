import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'

interface AiRecognitionPhotosDialogProps {
    open: boolean;
    onClose: () => void;
    urls?: string[];
}
export default function AiRecognitionPhotosDialog(props: AiRecognitionPhotosDialogProps) {
    const { open, onClose, urls } = props;
    return (
        <Dialog

            maxWidth="xl"
            open={open}
            sx={{ marginTop: '80px' }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Rozpoznané fotografie
            </DialogTitle>
            <DialogContent>
                {urls && urls.map((url, index) => {
                    return <img key={index} src={url} alt={url} style={{ width: '25%' }} />
                })}
            </DialogContent>
        </Dialog>
    )
}
