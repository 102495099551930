
import { LocalShipping, Note, TableView } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, EditSettingsModel, GridComponent, IEditCell, Inject, ToolbarItems, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit, CheckBoxChangeEventArgs } from '@syncfusion/ej2-react-grids';
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetMachinesForRecordTableDTO, SaveMachinesTableReqDTO, WealthForDiaryDialogDTO } from '../../../../ApiOld/ApiServerVov';
import { useDiaryMachinesCopyStore } from '../../../../Contexts/DiaryMachinesCopyZContext';
import InitSfGridLocale from '../../../../Localization/SfGridLanguageInitializer';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import { TabPanel } from '../../../Shared/Tabs/TabPanel';
import tabProps from '../../../Shared/Tabs/TabProps';
import WealthForDiaryDialog from '../../Wealth/WealthForDiaryDialog';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';

InitSfGridLocale();

interface MachinesCopyWidgetProps {
    buildingDiaryRecordId: number | undefined;
    machinesNote?: string;
    machinesInput?: GetMachinesForRecordTableDTO[];
    noteChanged?: (t: string) => void;
    onSelectionChanged?: (sel: number[]) => void;
    saveChanged?: boolean;
    isCopying: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
}

export default function MachinesCopyWidget(props: MachinesCopyWidgetProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, machinesInput, machinesNote, noteChanged, saveChanged, isCopying, isInvestor, isSubdodavatel, onSelectionChanged } = props;
    const [machines, setMachines] = useState<GetMachinesForRecordTableDTO[]>();
    const [machinesNoteLocal, setMachinesNoteLocal] = useState<string | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [copying, setCopying] = useState<boolean>(false);
    const [isWealthForDiaryDialogOpen, setIsWealthForDiaryDialogOpen] = useState<boolean>(false);

    const saving = useRef<boolean>(false);

    const { permissions } = useDiaryTabsContext();
    const theme = useTheme();
    const { machinesStore } = useDiaryMachinesCopyStore();

    function reloadData() {
        if (isCopying) {
            setMachines(machinesStore);
            saving.current = false;
        }
        else {
            if (buildingDiaryRecordId !== undefined)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetMachinesForRecord(buildingDiaryRecordId, false).then((d) => {
                    if (d !== undefined) {
                        setMachines(d);
                        if (grid.current)
                            grid.current!.dataSource = d;
                    }
                    saving.current = false;
                });
            else
                saving.current = false;
        }
    }
    useEffect(() => {
        setMachines(machinesStore);
    }, [machinesStore]);
    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        setCopying(isCopying);
    }, [isCopying]);
    useEffect(() => {
        setMachinesNoteLocal(machinesNote);
    }, [machinesNote]);
    useEffect(() => {

        reloadData();
    }, [buildingDiaryRecordId]);
    useEffect(() => {
        if (machinesInput)
            setMachines(machinesInput);
    }, [machinesInput]);
    const grid = useRef<GridComponent>(null);

    const loaded = useRef(false);

    const numericParams: IEditCell = {
        params: {
            decimals: 2,
            format: "N2",
            validateDecimalOnType: true,
            locale: i18n.language

        }
    };
    return (
        <div style={{ background: 'white' }}>
            <Box >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => {
                        setSelectedTabIndex(n);
                    }} aria-label="basic tabs example">
                        <Tab label={t('table')} {...tabProps(0)} />
                        {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <GridComponent style={{ borderColor: 'transparent' }} id="grid-component-machines" allowSorting={true}
                        allowPaging={true}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        ref={grid}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language}
                        enablePersistence={false}
                        filterSettings={standardFilterOptions}
                        resizeSettings={{ mode: 'Normal' }}
                        allowFiltering={false}
                        dataSource={machines}
                        checkBoxChange={(e: CheckBoxChangeEventArgs) => { if (onSelectionChanged) onSelectionChanged(e.selectedRowIndexes ? e.selectedRowIndexes : []); }}
                    >
                        <ColumnsDirective>
                            <ColumnDirective headerText='' type='checkbox' width='50' field='selected' />
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} showInColumnChooser={false} />
                            <ColumnDirective field='code' headerText={t('code')} maxWidth='150' width='150' minWidth='100' />
                            <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='400' minWidth='200' />
                            <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='30' />
                            {/* <ColumnDirective field='employeeFullName' headerText="xxx zodpovedny zamestnanec" allowEditing={false} maxWidth='800' width='300' minWidth='200' /> */}
                            <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='300' minWidth='200' />
                            {/* <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='300' minWidth='200' /> */}
                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                    </GridComponent>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div style={{ margin: '-10px -24px' }}>
                        <RichTextEditorComponent actionBegin={(e) => { }} height={400} locale={i18n.language} value={machinesNoteLocal} change={(eve: ChangeEventArgs) => {
                            if (isSubdodavatel !== true) {
                                setMachinesNoteLocal(eve.value);
                                if (noteChanged)
                                    noteChanged(eve.value);
                            }
                        }} >
                            <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </div>
                </TabPanel>
            </Box>
        </div>);
}