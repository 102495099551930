import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Checkbox, Stack, Tooltip } from '@mui/material';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Group, Filter, Search, Toolbar, Reorder, Resize, ColumnChooser, Page, ExcelExport } from '@syncfusion/ej2-react-grids';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import CreateInvoice from './CreateInvoice';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { BuildingBillsForInvoicesPageDTO, DeleteInvoicesRequest } from '../../../ApiOld/ApiServerVov';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import { useBuildingBillsPermissionContext } from '../../../Contexts/Permissions/BuildingBillsPermissionContext';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { Grading } from '@mui/icons-material';
import { useApi } from '../../../ApiOld/Api/useApi';
import { DownloadBillPaymentsRequest, DownloadInvoiceFilesRequest, InvoiceVovClient, UpdateInvoiceFromListRequest } from '../../../ApiOld/Api/ApiServerVov';
import saveBlob from '../../Shared/Files/SaveBlob';

InitSfGridLocale();

export default function InvoiceList(props: { buildingId?: number }) {
    const { buildingId } = props;
    const { permissions, reloadPermissions } = useBuildingBillsPermissionContext();
    const { authorizedApi } = useApi(InvoiceVovClient);
    const [invoices, setInvoices] = useState<BuildingBillsForInvoicesPageDTO[] | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);
    const [newOpen, setNewOpen] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const grid = useRef<GridComponent>(null);

    useEffect(() => {
        if (!permissions) reloadPermissions();
    }, [reloadPermissions]);

    const refresh = useCallback(() => {
        if (buildingId) {
            authorizedApi.getInvoicesBuilding(buildingId, false).then((e) => {
                if (e.bills) setInvoices(e.bills);
            });
        } else {
            authorizedApi.invoices(false).then((e) => {
                if (e.buildingBillsForInvoicesPage) setInvoices(e.buildingBillsForInvoicesPage);
            });
        }
    }, [authorizedApi, buildingId]);

    async function updateInvoicePropertyFromList(id: number | undefined, field: keyof BuildingBillsForInvoicesPageDTO, e: any) {
        if (grid.current) {
            let rowDetails = grid.current.getRowInfo(e.currentTarget);
            if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                const rowData = rowDetails.rowData as BuildingBillsForInvoicesPageDTO;
                (rowData[field] as boolean) = e.target.checked;
                grid.current.updateRow(rowDetails.rowIndex, rowData);
                await authorizedApi.updateInvoiceFromList(new UpdateInvoiceFromListRequest({
                    invoiceId: id!,
                    property: field,
                    value: e.target.checked,
                })).then(e => {
                    if (e.ok) grid.current!.refresh();
                });

            }
        }
    }

    const renderCheckboxTemplate = (
        props: BuildingBillsForInvoicesPageDTO,
        field: keyof BuildingBillsForInvoicesPageDTO
    ) => {
        const getTooltipText = () => {
            switch (field) {
                case 'isDone':
                    return t(props[field] ? 'MarkAsUnpaid' : 'MarkAsPaid');
                case 'isApproved':
                    return t(props[field] ? 'MarkAsPending' : 'MarkAsDone');
                case 'isPostponed':
                    return t(props[field] ? 'UnmarkAsPostponed' : 'MarkAsPostponed');
                case 'isPosted':
                    return t(props[field] ? 'UnmarkAsRecognized' : 'MarkAsRecognized');
                default:
                    return '';
            }
        };

        return (
            <Tooltip title={getTooltipText()}>
                <Checkbox
                    color='secondary'
                    sx={{
                        height: '20px',
                        width: '20px',
                    }}
                    checked={!!props[field]}
                    onChange={async (e) => {
                        updateInvoicePropertyFromList(props.id, field, e);
                    }}
                />
            </Tooltip>
        );
    };

    function GridTemplate(props: BuildingBillsForInvoicesPageDTO) {
        const [hover, setHover] = useState(false);
        if (permissions && permissions.buildingBillsDetail)
            return (<div style={{ cursor: 'pointer' }} onClick={() => {
                navigateToDetail(props);
            }} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <a style={{ color: '#5762D5', textDecoration: 'underline', fontWeight: 'bold' }}>{props.name!}</a>
            </div>);
        else return <div>{props.name!}</div>
    }

    const toolbarClickExcel = (props: any) => {
        if (grid.current && props.item.id.toString().endsWith('excelexport')) {
            grid.current!.excelExport();
        }
    };

    function navigateToDetail(data: BuildingBillsForInvoicesPageDTO) {
        if (permissions && permissions.buildingBillsDetail)
            navigate(
                { pathname: '/' + i18n.language + '/' + params['domain']! + (buildingId ? '/userlayout/invoiceDetailBuilding/' + data.id!.toString() : '/userlayout/invoiceDetail/' + data.id!.toString()) },
                { state: { id: data.id, inputRecords: invoices } }
            );
    }

    const downloadPayments = useCallback(async () => {
        const rowsData = grid.current?.getSelectedRecords();
        if (rowsData) {
            const ids = rowsData.map((row: any) => row.id) as number[]
            await authorizedApi.downloadBillPayments(new DownloadBillPaymentsRequest({ invoiceIds: ids })).then((e) => {
                if (e) {
                    saveBlob(e.data, 'platby.zip');
                }
            })
        }
    }, [authorizedApi]);

    const downloadInvoiceFiles = useCallback(async () => {
        const rowsData = grid.current?.getSelectedRecords();
        if (rowsData) {
            const ids = rowsData.map((row: any) => row.id) as number[]
            await authorizedApi.downloadInvoiceFiles(new DownloadInvoiceFilesRequest({ invoiceIds: ids })).then((e) => {
                if (e) {
                    saveBlob(e.data, 'invoices.zip');
                }
            })


        }
    }, [authorizedApi]);

    useEffect(() => {
        if (!invoices && permissions && permissions.buildingBillsList) refresh();
    }, [invoices, refresh, buildingId, permissions]);

    if (invoices && permissions && permissions.buildingBillsList) {
        return (
            <Box sx={{ m: '30px', borderRadius: '16px', boxShadow: buildingId ? 'none' : '4px 4px 16px 4px lightgray', overflow: 'hidden' }}>
                <Stack direction='row-reverse' spacing={2} sx={{ background: 'white', padding: 1 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => downloadPayments()}
                    >
                        {t('DownloadPaymentOrders')}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => downloadInvoiceFiles()}
                    >
                        {t('DownloadInvoices')}
                    </Button>
                </Stack>
                <UpperMenu
                    moduleName={t('Faktury')}
                    gridType={GridType.Invoices}
                    icon={<Grading />}
                    canAdd={!!permissions?.buildingBillsCreate}
                    canTrash
                    onCreate={() => {
                        if (permissions?.buildingBillsCreate) {
                            navigate(`/${i18n.language}/${params['domain']}/userlayout/invoices/new`);
                        }
                    }}
                    canDelete={!!permissions?.buildingBillsDelete}
                    onDelete={(ids) => {
                        if (permissions?.buildingBillsDelete) {
                            authorizedApi.deleteInvoices(new DeleteInvoicesRequest({ invoicesIds: ids as number[] })).then((success) => {
                                if (success) refresh();
                            });
                        }
                    }}
                    onThrashClicked={() => setTrashOpen(true)}
                    grid={grid} gridId='grid-component-invoices'
                    canChangeGroup
                    canToolbarGrid
                    menuName={t('View')}
                    canExcelExport
                />
                <GridComponent
                    ref={grid}
                    id="grid-component-invoices"
                    allowSorting
                    allowReordering
                    allowResizing
                    showColumnChooser
                    allowExcelExport
                    allowMultiSorting
                    allowGrouping={false}
                    locale={i18n.language}
                    enablePersistence
                    recordDoubleClick={(e) => {
                        if (permissions?.buildingBillsDetail) navigateToDetail(e.rowData as BuildingBillsForInvoicesPageDTO);
                    }}
                    filterSettings={{ type: 'Excel', ignoreAccent: true }}
                    allowPaging
                    style={{ backgroundColor: '#F5F5F5' }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering
                    groupSettings={{ showDropArea: true, captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>' }}
                    dataSource={invoices}
                >
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width={60} />
                        <ColumnDirective field='name' template={GridTemplate} headerText={t('Name')} width={300} />
                        <ColumnDirective field='project' headerText={t('Projekt')} width={250} />
                        <ColumnDirective field='dateTaxable' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} headerText={t('invoices.chargeableEvent')} width={180} />
                        <ColumnDirective field='dateExpire' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} headerText={t('date_expire')} width={180} />
                        <ColumnDirective field='isApproved' headerText={t('is_done')} textAlign='Center' headerTextAlign='Center' width={150} template={(props) => renderCheckboxTemplate(props, 'isApproved')} />
                        <ColumnDirective field='isDone' headerText={t('is_approved')} textAlign='Center' headerTextAlign='Center' width={130} template={(props) => renderCheckboxTemplate(props, 'isDone')} />
                        <ColumnDirective field='isPostponed' headerText={t('is_postponed')} textAlign='Center' headerTextAlign='Center' width={130} template={(props) => renderCheckboxTemplate(props, 'isPostponed')} />
                        <ColumnDirective field='isPosted' headerText={t('is_posted')} textAlign='Center' headerTextAlign='Center' width={150} template={(props) => renderCheckboxTemplate(props, 'isPosted')} />
                        <ColumnDirective field='priceWithoutVat' headerText={t('invoices.amountExcludingVAT')} textAlign='Center' headerTextAlign='Center' template={(props) => <span>{props.priceWithoutVat.toFixed(2)} Kč</span>} />
                        <ColumnDirective field='priceWithVat' headerText={t('invoices.amountIncludingVAT')} textAlign='Center' headerTextAlign='Center' template={(props) => <span>{props.priceWithVat.toFixed(2)} Kč</span>} />
                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, Reorder, Resize, ColumnChooser, Page, ExcelExport]} />
                </GridComponent>
                <CreateInvoice open={newOpen} onClose={() => { setNewOpen(false); refresh(); }} />
                <TrashGridDialog trashType={TrashType.Invoices} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refresh(); }} />
            </Box>
        );
    } else if (permissions && !permissions.buildingBillsList) {
        return <div>{t('noViewingPermissions')}</div>;
    } else {
        return <div>{t('noRecords')}</div>;
    }
}
