import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { StavarioModule } from '../../Components/Shared/Layouts/StavarioModule';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetModulesVisibilityResponse } from '../../ApiOld/Api/ApiServerVov';


interface UserLayoutContextProps {
    menuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeModule: StavarioModule;
    setActiveModule: React.Dispatch<React.SetStateAction<StavarioModule>>;
    isActiveModuleHasAd: boolean;
    backMenuFunction?: () => void;
    setBackMenuFunction: (fn: (() => void) | undefined) => void;
    menuButtons: React.ReactNode | null;
    setMenuButtons: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
    initials: string | null;
    setInitials: (initials: string) => void;
    logout: () => void;
    title?: string | null;
    setTitle: (title: string) => void;
    hideTopBar: boolean;
    setHideTopBar: (hide: boolean) => void;
    isSubdodavatel: boolean;
    modulesVisibility: GetModulesVisibilityResponse | null;
    setModulesVisibility: React.Dispatch<React.SetStateAction<GetModulesVisibilityResponse | null>>;
}

const UserLayoutContext = createContext<UserLayoutContextProps | undefined>(undefined);

export const UserLayoutProvider: React.FC<React.PropsWithChildren<{ isSubdodavatel: boolean }>> = ({ children, isSubdodavatel }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeModule, setActiveModule] = useState<StavarioModule>(StavarioModule.ProjectList);
    const [backMenuFunction, setBackMenuFunction] = useState<() => void>();
    const [menuButtons, setMenuButtons] = useState<React.ReactNode | null>(null);
    const [initials, setInitials] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [hideTopBar, setHideTopBar] = useState<boolean>(false);
    const [modulesVisibility, setModulesVisibility] = useState<GetModulesVisibilityResponse | null>(null);
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const path = location.pathname;
        console.log('tady', path);
        setTitle(null);
        if (path.toLowerCase().includes('projects')) {
            console.log('projects');
            setActiveModule(StavarioModule.ProjectList);
        }
        else if (path.toLowerCase().includes('projectdetail')) {
            console.log('projectdetail');
            setActiveModule(StavarioModule.ProjectDetail);
        } else if (path.toLowerCase().includes('diaryvov') && !path.toLowerCase().includes('diaryvovdetail')) {
            console.log('diaryvov', 'modul bez reklam');
            setActiveModule(StavarioModule.DiaryRecordsList);
        }
        else if (path.toLowerCase().includes('diaryvovdetail')) {
            console.log('diaryvovdetail', 'modul bez reklam');
            setActiveModule(StavarioModule.DiaryRecordDetail);
        }
        else if (path.toLowerCase().includes('diaries')) {
            console.log('diaries', 'modul bez reklam');
            setActiveModule(StavarioModule.DiariesList);
        }
        else if (path.toLowerCase().includes('diarydetail')) {
            // console.log('diaryvovdetail', 'modul bez reklam');
            setActiveModule(StavarioModule.DiaryDetail);
        }
        else if (path.toLowerCase().includes('wealthdetail')) {
            // console.log('diaryvovdetail', 'modul bez reklam');
            setActiveModule(StavarioModule.WealthDetail);
        }
        else if (path.toLowerCase().includes('wealths')) {
            // console.log('diaryvovdetail', 'modul bez reklam');
            setActiveModule(StavarioModule.WealthList);
        }
        else if (path.toLowerCase().includes('todos-map')) {
            // console.log('diaryvovdetail', 'modul bez reklam');
            setActiveModule(StavarioModule.TodosMap);
        }
        else if (path.toLowerCase().includes('warehouse')) {
            console.log('warehouses');
            setActiveModule(StavarioModule.WarehouseList);
        }
        else if (path.toLowerCase().includes('todos')) {
            console.log('todos');
            setActiveModule(StavarioModule.TodoList);
        }
        if (path.toLowerCase().includes('manufacture')) {
            console.log('manufacturers');
            setActiveModule(StavarioModule.ManufacturerList);
        }
        if (path.toLowerCase().includes('productCateg')) {
            console.log('productCategories');
            setActiveModule(StavarioModule.ProductCategoryList);
        }
        else if (path.toLowerCase().includes('product')) {
            console.log('products');
            setActiveModule(StavarioModule.ProductList);
        }
    }, [location.pathname]);
    const isActiveModuleHasAd = useMemo(() => {
        console.log(activeModule, 'changing');
        return activeModule === StavarioModule.ProjectList || activeModule === StavarioModule.DiaryRecordsList || activeModule === StavarioModule.ProjectDetail;
    }, [activeModule]);
    const logout = () => {
        localStorage.clear();
        navigate('/' + params['lang'] + '/' + params['domain']);
    };
    useEffect(() => {
        console.log('activeModule', activeModule);
    }, [activeModule]);
    return (
        <UserLayoutContext.Provider value={{ modulesVisibility, setModulesVisibility, menuOpen, setMenuOpen, activeModule, setActiveModule, isActiveModuleHasAd, backMenuFunction, setBackMenuFunction, menuButtons, setMenuButtons, initials, setInitials, logout, title, setTitle, hideTopBar, setHideTopBar, isSubdodavatel }}>
            {children}
        </UserLayoutContext.Provider>
    );
};

export const useUserLayoutContext = () => {
    const context = useContext(UserLayoutContext);
    if (context === undefined) {
        throw new Error('useUserLayoutContext must be used within a UserLayoutProvider');
    }
    return context;
};