
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Search, Sort, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, Page } from '@syncfusion/ej2-react-grids';
import { Button, Card, Switch, useTheme } from '@mui/material';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import { EmployeeListDTO, WealthDTO } from '../../../ApiOld/ApiServerVov';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { AddCircleOutline, Engineering, LocalShipping } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { APIVovEmployeeHelper } from '../../../ApiOld/ApiOldEmployeeClient';
import GridComponentStatefull from '../../Shared/Datagrid/GridComponentStatefull';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useEmployeesPermissionContext } from '../../../Contexts/Permissions/EmployeesPermissionContext';

interface EmployeesListProps {

}

function EmployeesList(props: EmployeesListProps) {
    InitSfGridLocale();

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Zaměstnanců</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [employees, setEmployees] = useState<EmployeeListDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const grid = useRef<GridComponent>(null);

    const { permissions, reloadPermissions } = useEmployeesPermissionContext();

    useEffect(() => {
        if (permissions === null) {
            reloadPermissions();
        }
    }, [reloadPermissions, permissions]);


    useEffect(() => {
        refreshData();
    }, []);

    const { setBackMenuFunction, setMenuButtons, setTitle } = useUserLayoutContext();

    useEffect(() => {
        setBackMenuFunction(undefined);
        setMenuButtons(<Button
            size='small'
            sx={{ mr: 2 }}
            startIcon={<AddCircleOutline />}
            variant='contained' onClick={() => {
                /* if (permissions ? permissions.buildingsCreate : false)
                    navigate({ pathname: '/' + i18n.language + '/' + params['domain']! + '/userlayout/projectDetail/' }); */
            }
            }>{t('Add')}</Button>);
        refreshData();
    }, [setBackMenuFunction, setMenuButtons, permissions, i18n.language, navigate, t]);


    function refreshData() {
        new APIVovEmployeeHelper().GetEmployeesList().then((d) => {
            setEmployees(d); /* if (grid.current) grid.current.refresh(); */
        });
    }
    function gotoDetail(id: number) {
        navigate('employee/' + id.toString());
    }
    function gridNameTemplate(props: EmployeeListDTO) {
        return (<div /* style={{ cursor: 'pointer' }} */>
            {props.firstname + " " + props.lastname}
        </div >);
    }

    function gridPhoneTemplate(props: EmployeeListDTO) {
        return (<div>
            <span style={{ fontSize: 'smaller', color: 'gray' }}>{props.phonePrefix}</span>{" " + props.phoneNumber}
        </div >);
    }


    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    function gridIsActiveTemplate(props: EmployeeListDTO) {
        return (<div>
            <Switch size='small' checked={props.isActive}
                onChange={(e, checked) => {
                    e.preventDefault(); e.stopPropagation();
                    changeIsActive(e);
                }}
            />
        </div >);
    }

    const changeIsActive = (args: any) => {
        if (grid.current) {
            let rowDetails = grid.current.getRowInfo(args.currentTarget);
            if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                (rowDetails.rowData as EmployeeListDTO).isActive = args.target.checked;

                grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource
                grid.current.refresh();

                new APIVovEmployeeHelper().SetIsEmployeeActive((rowDetails.rowData as EmployeeListDTO).id!, args.target.checked);
            }
        }
    }

    if (employees !== null)
        return (
            <div>
                <Card sx={{ margin: 3, padding: 3, height: '100%' }} elevation={3}>
                    <GridComponentStatefull<EmployeeListDTO>
                        icon={<Engineering />}
                        data={employees ?? []}
                        templateColumns={[
                            { field: 'name', template: gridNameTemplate },
                            { field: 'phone', template: gridPhoneTemplate },
                            { field: 'isActive', template: gridIsActiveTemplate },
                        ]}
                        moduleName={t('employees')}
                        rowHeight={30}
                        canDelete
                        canTrash
                        onCreate={() => { /* navigate('employee/0'); */ }}
                        onDelete={(e) => { new APIVovEmployeeHelper().DeleteEmployees(e as number[]).then((e) => { if (e) refreshData(); }) }}
                        onThrashClicked={() => { setTrashOpen(true); }}
                        ref={grid} id='grid-component-employees-statefull' menuName={t('View')}
                        gridType={GridType.Employees}
                        canChangeGroup canToolbarGrid
                        canExcelExport
                        allowSorting={true}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language} enablePersistence={false}
                        recordClick={(e: any) => {
                            if (e.column.editType !== 'booleanedit') {
                                gotoDetail(e.rowData.id);
                            }
                        }}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        allowPaging={true}
                        filterSettings={filterOptions} toolbarClick={toolbarClickExcel}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={true} groupSettings={groupOptions} searchLabel={t('SearchInEmployees')}
                        columnsXX={[
                            { width: '50', maxWidth: '50', textAlign: 'Right', visible: true, showInColumnChooser: false, type: 'checkbox' },
                            { field: 'id', width: '46', headerText: 'ID', textAlign: 'Right', visible: false, showInColumnChooser: false },
                            { field: 'code', headerText: t('code'), width: '100', minWidth: '100' },
                            { field: 'name', headerText: t('Name'), width: '100', minWidth: '100' },
                            { field: 'groupName', headerText: t('employee.group'), width: '100', minWidth: '100' },
                            { field: 'phone', headerText: t('employee.phone'), width: '100', minWidth: '100' },
                            { field: 'wage', headerText: t('employee.wage'), width: '100', minWidth: '100' },
                            { field: 'isActive', headerText: t('active'), width: '100', minWidth: '100' }
                        ]}>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                    </GridComponentStatefull>
                    <TrashGridDialog trashType={TrashType.Employees} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refreshData(); }} />
                </Card>
            </div>
        );
    return <div>{t('noRecords')}</div>;
};
export default EmployeesList;