import React, { createContext, useCallback, useContext, useState, ReactNode } from 'react';
import { GetEmployeesPermissionsResponse } from '../../ApiOld/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';
import { EmployeesVovClient, TodoMainVovClient } from '../../ApiOld/Api/ApiServerVov';

interface EmployeesPermissionContextProps {
    permissions: GetEmployeesPermissionsResponse | null;
    reloadPermissions: () => Promise<void>;
}

const EmployeesPermissionContext = createContext<EmployeesPermissionContextProps>({
    permissions: null,
    reloadPermissions: async () => { },
});

export const useEmployeesPermissionContext = () => useContext(EmployeesPermissionContext);


interface EmployeesPermissionProviderProps {
    children: ReactNode;
}

export const EmployeesPermissionProvider: React.FC<EmployeesPermissionProviderProps> = ({ children }) => {
    // const { permissions, reloadPermissions } = useReloadPermissions();
    const [permissions, setPermissions] = useState<GetEmployeesPermissionsResponse | null>(null);
    const { authorizedApi } = useApi(EmployeesVovClient);
    const reloadPermissions = useCallback(async (): Promise<void> => {
        const permissions = await authorizedApi.getEmployeesPermissions();
        setPermissions(permissions);
    }, [authorizedApi]);
    return (
        <EmployeesPermissionContext.Provider value={{ permissions, reloadPermissions }}>
            {children}
        </EmployeesPermissionContext.Provider>
    );
};