import { Close, RemoveFromQueueOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page, RowSelectEventArgs } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetTrashColumns from './GetTrashColumns';
import GetTrashItems from './GetTrashItems';
import RemoveFromTrashServer from './RemoveFromTrashServer';
import { TrashItemModel } from './TrashItemModel';
import { TrashType } from './TrashType';


interface TrashGridDialogProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    trashType: TrashType;
    isSubdodavatel?: boolean;
}

export default function TrashGridDialog(props: TrashGridDialogProps) {
    const { open, onClose, trashType, isSubdodavatel } = props;
    const [items, setItems] = useState<any[] | undefined>(undefined);
    const [columns, setColumns] = useState<TrashItemModel[] | null>(null);
    const [cantReactive, setCantReactive] = useState<boolean>(true);
    const [openError, setOpenError] = React.useState(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (open) {
            setColumns(GetTrashColumns(trashType));
            // console.log('columns', GetTrashColumns(trashType));

        }
    }, [trashType, open]);
    useEffect(() => {
        if (open) {
            reloadData();
        }
    }, [columns]);
    function reloadData() {
        GetTrashItems(trashType, isSubdodavatel === undefined ? false : isSubdodavatel).then((items) => {
            // console.log('items', items);
            setItems(items);
        });
    }
    function rowSelected(e: RowSelectEventArgs) {
        setCantReactive(false);
    }
    function rowDeselected(e: RowSelectEventArgs) {
        setCantReactive(true);
    }
    function removeFromTrash() {
        if (grid.current) {
            const selectedRows = grid.current.getSelectedRecords();
            // console.log('selectedRows', selectedRows);
            let result = selectedRows.map(a => {
                type ObjectKey = keyof typeof a;
                const myVar = 'id' as ObjectKey;
                return a[myVar];
            });

            // console.log('result', result);
            let ids = ((result as any) as number[]);
            RemoveFromTrashServer(trashType, ids, isSubdodavatel === undefined ? false : isSubdodavatel).then((result) => {
                if (result) onClose(true);
                else setOpenError(true);
            });
        }
    }

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const grid = React.useRef<GridComponent>(null);
    return (<Dialog
        open={open}
        maxWidth="xl"
        onClose={() => { onClose(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {t('Trash')}
            <IconButton sx={{ width: '30px', height: '30px', border: '1px solid', marginRight: '16px', marginTop: '6px', float: 'right' }}
                onClick={() => { onClose(false) }}><Close /></IconButton>
        </DialogTitle>
        <DialogContent>
            <div>
                <div><Button variant='contained' startIcon={<RemoveFromQueueOutlined />} onClick={removeFromTrash} disabled={cantReactive}>{t('refresh')}</Button></div>
                <div>
                    {columns && items && <GridComponent id="grid-component-trash" allowSorting={true}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language} enablePersistence={false}
                        ref={grid}
                        allowPaging={true}
                        style={{ backgroundColor: '#F5F5F5' }}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        rowSelected={rowSelected}
                        rowDeselected={rowDeselected}
                        //  columnDataStateChange={(e) => { console.log(e) }}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={true}
                        dataSource={items}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width='30' />
                            {columns.map((column) => {
                                if (column.field === 'date') return <ColumnDirective field={column.field} type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} headerText={t(column.humanName)} width='100' />;
                                else return <ColumnDirective field={column.field} headerText={t(column.humanName)} width='100' />;
                            })}

                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                    </GridComponent>}
                </div>
            </div >
            <div className="Auth-form-popup">
                <Snackbar
                    TransitionComponent={TransitionLeft}
                    open={openError}
                    autoHideDuration={5000}
                    onClose={() => { setOpenError(false); }}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                    <Alert onClose={() => { setOpenError(false); }} severity="warning">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {t('diary.recoverError')}
                    </Alert>
                </Snackbar>
            </div>
        </DialogContent>
    </Dialog>)
}