import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Validate, ValidationGroup } from 'mui-validate';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { AddImageCategoryRequest, VovClient } from '../../../../ApiOld/ApiServerVov';

interface AddImageCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    onCreated?: (id: number, name: string) => void,
    buildingId?: number;
}
export default function AddImageCategoryDialog(props: AddImageCategoryDialogProps) {
    const { open, onClose, onCreated, buildingId } = props;

    const [name, setName] = React.useState<string>();
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
    const [openLoginError, setOpenLoginError] = React.useState(false);

    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
    }, []);

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="create-category-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('files.createFolder')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div>
                        <Validate name='name-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                            <TextField sx={{ margin: '12px', width: '526px' }} label={t('name')}
                                size='small'
                                name='userName'
                                variant='outlined'
                                value={name}
                                error={!name}
                                onChange={(e) => { setName(e.target.value) }} />
                        </Validate>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled || name === undefined || name === ''} onClick={() => {
                                if (name) {
                                    setButtonDisabled(true);
                                    authorizedApi.addImageCategory(new AddImageCategoryRequest({ name: name, buildingId: buildingId })).then((r) => {
                                        setButtonDisabled(false);
                                        if (r.ok) {
                                            if (onCreated)
                                                onCreated(r.id!, name);

                                            setName(undefined);
                                            onClose();
                                        }
                                        else {
                                            setOpenLoginError(true);
                                        }
                                    });
                                }
                            }}>{t('save')}</Button>
                        </div>
                    </div>
                </ValidationGroup>
                <div className="create-form-popup">
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('files.categoryAlreadyExists')}</AlertTitle>
                            {t('files.unableToCreateCat')}
                        </Alert>
                    </Snackbar>
                </div>
            </DialogContent>
        </Dialog>
    )
}
