import { Box } from "@mui/material";
import { Info } from '@mui/icons-material';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import delay from '../../../Shared/Functions/Delay';
import { useNavigate } from 'react-router-dom';
import { useMediaPredicate } from "react-media-hook";
import { TabItem } from "../../../Shared/Tabs/StyledTabs";
import { useUserLayoutContext } from "../../../../Contexts/Providers/UserLayoutMenuProvider";
import ReusableTabs from "../../InvoiceComponents/ReusableTabs";
import { useApi } from "../../../../ApiOld/Api/useApi";
import ProductCategoryMainDetail from "./ProductCategoryMainDetail";
import ProductCategoryMenuButtons from "./ProductCategoryMenuButtons";
import WarehouseInfoDialog from "../Dialogs/WarehouseInfoDialog";
import { GetProductCategoryDataDetailResponse, SaveProductCategoryRequest, WarehouseVovClient } from "../../../../ApiOld/Api/ApiServerVov";

interface ProductCategoryDetailProps {
    productCategoryId?: number;
}

export default function ProductCategoryDetail(props: Readonly<ProductCategoryDetailProps>) {
    const { productCategoryId } = props;
    const { authorizedApi } = useApi(WarehouseVovClient);
    const [productCategoryData, setProductCategoryData] = useState<Partial<GetProductCategoryDataDetailResponse> | null>(null);
    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<number>(0);
    const [infoOpen, setInfoOpen] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const params = useParams();

    useEffect(() => {
        setBackMenuFunction(() => () => { redirectToList() })
        if (productCategoryId) {
            authorizedApi.getProductCategoryDetail(productCategoryId).then((d) => {
                setProductCategoryData(d);
                setTitle(d.name ?? '');
            });
        }
        else {
            setProductCategoryData(new GetProductCategoryDataDetailResponse({ id: 0 }));
        }
    }, []);

    useEffect(() => {
        if (productCategoryId) {
            authorizedApi.getProductCategoryDetail(productCategoryId).then((d) => {
                setProductCategoryData(d);
            });
        }
        else {
            setProductCategoryData(new GetProductCategoryDataDetailResponse({ id: 0 }));
        }
    }, [authorizedApi, productCategoryId]);

    useEffect(() => {
        if (saveDone === true)
            delay(5000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    useEffect(() => {
        setMenuButtons(productCategoryData && <ProductCategoryMenuButtons
            productCategoryData={productCategoryData}
            saveDone={saveDone}
            error={errorCode}
            saveProductCategory={saveProductCategory}
        />);
    }, [productCategoryData, saveDone, setMenuButtons, errorCode]);

    function saveProductCategory(close: boolean) {
        if (productCategoryData)
            authorizedApi.saveProductCategory(new SaveProductCategoryRequest(productCategoryData)).then((d) => {
                if (d.ok) {
                    if (!props.productCategoryId)
                        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/productCategoryDetail/' + d.id);
                    setProductCategoryData({ ...productCategoryData, id: d.id });
                    setSaveDone(true);
                    setErrorCode(0);

                    if (close)
                        redirectToList();
                }
                else {
                    setErrorCode(d.errorCode ? d.errorCode : 0);
                    setSaveDone(true);
                    setInfoOpen(true);
                }
            });
    }

    const errorMessages = [
        '',
        t('warehouses.productCategoryWithSameNameExists'),
        t('error') + ". " + t('sales.contactSupport'),
        t('error') + ". " + t('sales.contactSupport')
    ];

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/productCategories')
    }

    const tabs = useMemo(() => productCategoryData && [
        {
            icon: <Info style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
            panel: <ProductCategoryMainDetail productCategoryData={productCategoryData} setProductCategoryData={setProductCategoryData} />
        }
    ], [biggerThan700, productCategoryData, t]);

    if (productCategoryData)
        return (
            <Box sx={{ m: 2, maxWidth: biggerThan700 ? '100vw' : '92vw', height: '100vh', overflowY: biggerThan700 ? 'inherit' : 'auto', p: 1 }}>
                <ReusableTabs
                    variant="scrollable"
                    tabs={tabs?.filter(Boolean) as TabItem[]}
                    onSelectedTabChange={(index) => setSelectedTabIndex(index)}
                />
                <WarehouseInfoDialog open={infoOpen} onClose={() => setInfoOpen(false)} text={errorMessages[errorCode]} />
            </Box>
        );
    return <Box>{t('loading...')}</Box>

}