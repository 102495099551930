import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import * as React from 'react';
import { GetGptDiaryDescriptionOneDayFromPhotosRequest, GetGptDiaryDescriptionsFromPhotosRequest, GptVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../../ApiOld/Api/useApi';

export interface IAutoCompleteDiaryOneDayProps {
    diaryRecordId: number;
    open: boolean;
    onClosed: (data: string | null) => void;
}

export default function AutoCompleteDiaryOneDay(props: IAutoCompleteDiaryOneDayProps) {
    const { authorizedApi } = useApi(GptVovClient);
    const { diaryRecordId, onClosed, open } = props;
    const [loadedText, setLoadedText] = React.useState<string | null>(null);
    const fillDiary = React.useCallback(async () => {
        setLoadedText(null);
        authorizedApi.writeDiaryForRecord(new GetGptDiaryDescriptionOneDayFromPhotosRequest({
            diaryRecordId: diaryRecordId
        })).then((response) => {
            setLoadedText(response.dailyRecord ?? '');
        });
        // setOpenApiInfo(true);
        // onClosed();
        // }
    }, [authorizedApi, diaryRecordId]);
    React.useEffect(() => {
        if (open) {
            fillDiary();
        }
    }, [fillDiary, open])
    return (
        <Dialog open={open} onClose={() => { onClosed(loadedText) }} sx={{ marginTop: '80px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "255", backgroundColor: '#F5F5F5' }}>
                <Typography fontWeight='bold' mt={-0.5}>AI psaní stavebního deníku</Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    {loadedText !== null ? <div dangerouslySetInnerHTML={{ __html: loadedText }}></div> : <CircularProgress />}
                </Box>
            </DialogContent>
        </Dialog>
    );
}
