import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
interface ReallyCloseGptDialogProps {
    open: boolean;
    handleClose: (reallyClose: boolean) => void;
}

export default function ReallyCloseGptDialog(props: ReallyCloseGptDialogProps) {
    const { open, handleClose } = props;
    const { t } = useTranslation()
    return (
        <Dialog
            open={open}
            onClose={() => { handleClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('reallyCloseAIHelper')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('reallyCloseAIHelperDesc')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose(false) }}>{t('noL')}</Button>
                <Button onClick={() => { handleClose(true) }} autoFocus>
                    {t('yesL')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
