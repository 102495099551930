import { useEffect, useRef, useState } from "react";
import { Typography, Box, TextField, Autocomplete, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AssignedUserDTO } from "../../../../../ApiOld/ApiServerVov";
import { Close } from "@mui/icons-material";

interface ProjectSearchBarProps {
    onSearch: (search: string | undefined) => void;
}

function ProjectSearchBar(props: ProjectSearchBarProps) {
    const { onSearch } = props;
    const [input, setInput] = useState<string>();
    const { t, i18n } = useTranslation();
    const autoC = useRef<HTMLDivElement>(null);

    useEffect(() => {
        onSearch(input);
    }, [input]);

    const handleInput = (e) => {
        console.log(e.target.value);
        setInput(e.target.value.toLowerCase());
    };

    return (
        <Box
            className="App"
            sx={{
                display: "flex",
                /* flexDirection: "column", */
                justifyContent: "space-between",
                margin: '8px'
            }}
        >
            <TextField fullWidth size="small" value={input} label={t("SearchDots")} onChange={handleInput} />
            <IconButton onClick={() => { setInput('') }} sx={{ marginTop: '-4px' }}>
                <Close />
            </IconButton>
        </Box>
    );
}

export default ProjectSearchBar;