import { ArrowDropDown, ArrowRight, Clear, DoneAll, Folder, FolderSpecial, InsertDriveFile } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { AssignedUserDTO, CopyCutPasteType, FileDirectoryDTO, FileParameter, GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import ProjectSearchBar from './SearchBars/ProjectSearchBar';

interface ProjectPeopleAdminUsersComponentProps {
    groupedUsers: [string, AssignedUserDTO[]][];
    users: AssignedUserDTO[];
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectPeopleAdminUsersComponent(props: ProjectPeopleAdminUsersComponentProps) {
    const { groupedUsers, users, setProjectData } = props;
    const [search, setSearch] = useState<String>();
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const checkAllAdminUser = useCallback((group: string | undefined, isAssigned: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                p.group === group ? new AssignedUserDTO({ id: p.id, isAssigned: isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);



    const checkAll = useCallback((enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: enabled, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);

    return <Grid item xs={12} lg={4}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: '24px', marginBottom: '0px', fontWeight: 'bold' }}>{t('buildings.assignedUsers')}
            </Typography>

            <Box sx={{ marginTop: '0px', marginLeft: '16px' }}>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        </Box>
        <Box sx={{ padding: '0px 8px 0px 8px' }}>
            <ProjectSearchBar onSearch={setSearch} />
        </Box>
        {groupedUsers.map((adminUser, i) => {
            return (
                <Box key={'key' + i} style={{ margin: '16px', padding: '8px', border: '1px solid lightgray', borderRadius: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', margin: '8px', fontSize: '14px' }}>{adminUser[0].length > 0 ? adminUser[0] : t('nonAssigned')}</Typography>
                        <div style={{ display: 'flex' }}>
                            <Tooltip title={t('chooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAllAdminUser(adminUser[0], true); }}>
                                    <DoneAll sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('unchooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAllAdminUser(adminUser[0], false); }}>
                                    <Clear sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            adminUser[1].map((item2, i) => {
                                if (search && item2.name!.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                                    return null;
                                }
                                return (<div key={item2.id} style={{ margin: '0px', marginLeft: '20px' }}> <FormControlLabel

                                    control={
                                        <Checkbox
                                            id={item2.id?.toString()}
                                            checked={item2.isAssigned}
                                            onChange={(e) => {
                                                setProjectData((prev) => {
                                                    if (!prev) {
                                                        return prev;
                                                    }

                                                    const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                                                        item2.id === p.id ? new AssignedUserDTO({ id: p.id, isAssigned: !p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                                    );

                                                    return {
                                                        ...prev,
                                                        assignedUsers: updatedAssignedUsers,
                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                });
                                            }}
                                        />
                                    }
                                    label={item2.name}
                                /> </div>);
                            })
                        }
                    </div>
                </Box>
            );
        })}
    </Grid>;
}
