import { createTheme, inputLabelClasses, Theme } from "@mui/material";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { maxHeaderSize } from "http";

export default function createThemeStavario(): Theme {
    return createTheme({
        typography: {
            fontFamily: "Roboto",
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700
        },
        palette: {
            primary: {
                main: '#FFC600',
                light: '#F5F5F5',
                dark: 'orange'
            },
            success: {
                main: '#19CB40',
            },
            action: {},
            info: {
                main: '#A5A5A5'
            },
            text: { primary: '#404040' },
            secondary: {
                light: '#F2F2F2',
                main: '#404040',
                contrastText: '#FFFFFF',
            }
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#FFC600",
                        color: "black",
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        maxHeight: '20px'
                    }
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        maxHeight: '30px'
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: '16px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
                    }
                }
            },

            // Add TextField border radius override
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '20px', // Change this value to the desired borderRadius

                        },
                        lineHeight: '1rem'



                    },
                    inputSizeSmall: {
                        fontSize: '13px',
                        padding: '8px 8px 4px 12px',
                        maxHeight: '36px'
                    },


                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    sizeSmall: {
                        fontSize: '11px', // Change this value to the desired font size
                        fontWeight: 'bold',
                        [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)

                            marginTop: '2px',
                            fontSize: '13px',
                            fontWeight: 'bold'
                        }
                    },
                },
            },
            // Add Button corner radius override
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '12px', // Set a different corner radius for Buttons here
                    }
                }
            },

        }
    })
}