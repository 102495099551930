import * as React from 'react';
import DialogBase from '../../../Shared/DialogBase';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { TodoMainVovClient, UploadImageToProjectTodosMapResponse } from '../../../../ApiOld/Api/ApiServerVov';
import { Box, Button, CircularProgress, IconButton, Stack, TextField } from '@mui/material';
import BuildingSelect from '../../../Shared/BuildingSelect';
import { BuildingDTO } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { RemoveCircle } from '@mui/icons-material';


export interface INewImageMapDialogProps {
    open: boolean;
    onClose: (needRefresh: boolean) => void;
    // buildingId: number;
}

export default function NewImageMapDialog(props: INewImageMapDialogProps) {
    const { open, onClose } = props;
    const [mapName, setMapName] = React.useState('');
    const [selectedBuilding, setSelectedBuilding] = React.useState<BuildingDTO | null>(null);
    const [file, setFile] = React.useState<File | null>(null);
    const { authorizedApi } = useApi(TodoMainVovClient);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [saving, setSaving] = React.useState(false);
    const { t } = useTranslation();
    const upload = React.useCallback(async () => {
        setSaving(true);
        const res = await authorizedApi.uploadImageToProjectTodosMap({ fileName: file?.name ?? '', data: file }, selectedBuilding?.id, mapName);
        setSaving(false);
        onClose(true);
    }, [authorizedApi, selectedBuilding, file, mapName, onClose]);
    return (
        <DialogBase dialogActions={
            <Button onClick={upload} disabled={file === null || mapName === ''}>hotovo</Button>
        } open={open} onClose={onClose} title='Nová mapa'>
            {!saving && <Stack mt={1} minWidth='250px' spacing={2}>
                <TextField
                    size='small'
                    label={t('name')}
                    value={mapName}
                    onChange={(e) => setMapName(e.currentTarget.value)} />
                <BuildingSelect
                    onChange={(e) => { setSelectedBuilding(e) }} />
                {file === null && <Button onClick={() => {
                    inputRef.current?.click();
                }} variant='contained' size='small' >{t('upload')}</Button>}
                {file !== null && <Stack alignItems='center' direction='row'>
                    {file.name}
                    <IconButton onClick={() => { setFile(null); }}>
                        <RemoveCircle color='error' />
                    </IconButton>
                </Stack>}
                <input
                    type='file'
                    ref={inputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => { setFile(e.currentTarget.files?.[0] ?? null); e.target.files = null; }} />
            </Stack>}
            {saving && <Box minHeight={200}><CircularProgress /></Box>}
        </DialogBase>
    );
}
