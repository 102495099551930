import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { TextField, Typography, Checkbox, Button } from '@mui/material';
import { DateTimePicker, DatePicker, TimePicker } from '@mui/x-date-pickers';
import LocalizationProviderWithLanguages from '../../Shared/Localization/LocalizationProviderWithLanguages';
import { useTranslation } from 'react-i18next';

interface Field {
    key: string;
    label: string;
    type: 'text' | 'number' | 'boolean' | 'datetime' | 'date' | 'time';
    isEditable: boolean;
}

interface DiaryMobileListProps<T> {
    data: T[];
    fields: Field[];
    headerFields: (keyof T)[];
    onDataChange?: (updatedData: T[]) => void;
}

export default function DiaryMobileList<T extends { id: string | number, isDeleted: boolean | undefined }>(props: Readonly<DiaryMobileListProps<T>>) {
    const { t } = useTranslation();
    const [openStates, setOpenStates] = React.useState<Record<string | number, boolean>>({});
    const [items, setItems] = React.useState(props.data);

    React.useEffect(() => {
        setItems(props.data);
    }, [props.data]);

    const handleClick = (id: string | number) => {
        setOpenStates((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleFieldChange = (id: string | number, field: Field, value: any) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, [field.key]: value } : item
        );
        setItems(updatedItems);
        props.onDataChange?.(updatedItems);
    };

    const handleDelete = (id: string | number) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, isDeleted: true } : item
        );
        setItems(updatedItems);
        props.onDataChange?.(updatedItems);
    };

    const renderField = (item, field, isHeader = false) => {
        const isEditable = field.isEditable || isHeader;
        const value = item[field.key];

        switch (field.type) {
            case 'number':
                return (
                    <TextField
                        type="number"
                        value={parseFloat(value)}
                        onChange={(e) => handleFieldChange(item.id, field, e.target.value === '' ? null : parseFloat(e.target.value))}
                        label={t(field.label)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!isEditable}
                    />
                );
            case 'datetime':
                return (
                    <LocalizationProviderWithLanguages>
                        <DateTimePicker
                            disabled={!isEditable}
                            value={value || null}
                            onChange={(newValue) => handleFieldChange(item.id, field, newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label={t(field.label)} size="small" fullWidth disabled={!isEditable} />
                            )}
                        />
                    </LocalizationProviderWithLanguages>
                );
            case 'date':
                return (
                    <LocalizationProviderWithLanguages>
                        <DatePicker
                            disabled={!isEditable}
                            value={value || null}
                            onChange={(newValue) => handleFieldChange(item.id, field, newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label={t(field.label)} size="small" fullWidth disabled={!isEditable} />
                            )}
                        />
                    </LocalizationProviderWithLanguages>
                );
            case 'time':
                return (
                    <LocalizationProviderWithLanguages>
                        <TimePicker
                            disabled={!isEditable}
                            value={value || null}
                            onChange={(newValue) => handleFieldChange(item.id, field, newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label={t(field.label)} size="small" fullWidth disabled={!isEditable} />
                            )}
                        />
                    </LocalizationProviderWithLanguages>
                );
            case 'boolean':
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            checked={!!value}
                            onChange={(e) => handleFieldChange(item.id, field, e.target.checked)}
                            disabled={!isEditable}
                        />
                        <Typography variant="body2" style={{ marginLeft: '0.5rem' }}>
                            {t(field.label)}
                        </Typography>
                    </div>
                );
            default:
                return (
                    <TextField
                        type="text"
                        value={value || ""}
                        onChange={(e) => handleFieldChange(item.id, field, e.target.value)}
                        label={t(field.label)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!isEditable}
                    />
                );
        }
    };

    const renderHeader = (item: T) => (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            {props.headerFields.map(fieldKey => {
                const field = props.fields.find((f) => f.key === fieldKey);
                return field ? (
                    <div
                        key={fieldKey as string}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderField(item, field, true)}
                    </div>
                ) : null;
            })}
        </div>
    );

    const nonHeaderFields = props.fields.filter(field => !props.headerFields.includes(field.key as keyof T));

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
            {items.filter(x => !x.isDeleted).map((item) => (
                <div key={(item as { id: string | number }).id}>
                    <ListItemButton onClick={() => handleClick((item as { id: string | number }).id)}>
                        <ListItemText
                            primary={<Typography variant="body1">{renderHeader(item)}</Typography>}
                        />
                        <IconButton edge="end" onClick={(e) => { e.stopPropagation(); handleClick((item as { id: string | number }).id); }}>
                            {openStates[(item as { id: string | number }).id] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </ListItemButton>
                    <Collapse in={openStates[(item as { id: string | number }).id]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {nonHeaderFields.map((field) => (
                                <ListItemButton sx={{ pl: 4 }} key={field.key}>
                                    <ListItemText primary={renderField(item, field)} />
                                </ListItemButton>
                            ))}
                        </List>
                        <Button
                            sx={{ mb: 2, ml: 4 }}
                            variant='contained'
                            size='small'
                            onClick={() => handleDelete(item.id)}
                        >
                            {t('Delete')}
                        </Button>
                    </Collapse>
                </div>
            ))}
        </List>
    );
}
