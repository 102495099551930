import React from 'react'
import { useApi } from '../../../ApiOld/Api/useApi';
import { CustomFieldDto, CustomFieldOptionDto, CustomFieldsVovClient } from '../../../ApiOld/Api/ApiServerVov';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
interface CustomFieldAutocompleteProps {
  customField: CustomFieldDto;
  value: any;
  onValueChange: (value: any) => void;
}

export default function CustomFieldAutocomplete(props: Readonly<CustomFieldAutocompleteProps>) {
  const { customField, value, onValueChange } = props;
  const { authorizedApi } = useApi(CustomFieldsVovClient);
  const [options, setOptions] = React.useState<CustomFieldOptionDto[]>([]); // [string, string][
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);
  const getOptions = React.useCallback(async () => {
    if (!customField.id) return;
    setLoadingOptions(true);
    const customFieldOptions = await authorizedApi.getCustomFieldOptionsForEnum(customField.id);
    setOptions(customFieldOptions.customFieldOptions ?? []);
    setLoadingOptions(false);

  }, [authorizedApi, customField.id]);
  React.useEffect(() => {
    getOptions();
  }, [getOptions]);
  React.useEffect(() => {
    console.log(value, options, selectedId);
    const localValue = options.find((option) => option.id == value);
    console.log(localValue);
    if (localValue?.id) {
      if (localValue.id !== selectedId)
        setSelectedId(localValue.id);
    } else {
      setSelectedId(null);
    }
  }, [value, options, selectedId]);
  return (
    <Box sx={{ mt: 4 }}>{
      options.length > 0 &&
      <Autocomplete
        options={options}
        size='small'
        getOptionLabel={(option) => option.value ?? ''}
        value={options.find((option) => option.id == selectedId) || null}
        onChange={(event, newValue) => { setSelectedId(newValue?.id ?? null); onValueChange(newValue?.id ?? null) }}
        renderInput={(params) => <TextField variant='outlined' {...params} label={customField.name} />}
      />}
      {options.length === 0 && loadingOptions && <CircularProgress />}
    </Box>
  )
}
