import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Validate, ValidationGroup } from 'mui-validate';
import { APIVovKrosAdminHelper } from '../../../../ApiOld/ApiKrosAdminOldClient';
import { AdminUsersDTO, ApplicationListDTO } from '../../../../ApiOld/ApiServerVov';
import ApplicationSelectKrosAdmin from './ApplicationSelectKrosAdmin';

interface CreateUserDialogKrosAdminProps {
    open: boolean;
    onClose: () => void;
    onCreated?: (id: number, name: string) => void
}
export default function CreateUserDialogKrosAdmin(props: CreateUserDialogKrosAdminProps) {
    const { open, onClose, onCreated } = props;

    const [name, setName] = React.useState<string>();
    const [email, setEmail] = React.useState<string>();
    const [app, setApp] = React.useState<number>();
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
    const [openLoginError, setOpenLoginError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    function isEmail(email: string): boolean {
        const regexp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        return regexp.test(email);
    }
    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="create-user-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('createUser')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); setEmail(undefined); setApp(undefined); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div>
                        <Validate name='name-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                            <TextField sx={{ margin: '12px', width: '526px' }} label={t('z')}
                                size='small'
                                name='userName'
                                variant='outlined'
                                value={name}
                                error={!name}
                                onChange={(e) => { setName(e.target.value) }} />
                        </Validate>
                        <Validate name='email-signup-validate' initialValidation={'noisy'} regex={[/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('FieldRequired')]}>
                            <TextField
                                id="outlined-basic"
                                sx={{ margin: '12px', width: '526px' }}
                                label={'Email'}
                                size='small'
                                variant='outlined'
                                value={email}
                                error={!email || !isEmail(email)}
                                onChange={(e) => { setEmail(e.target.value) }} />
                        </Validate>
                        <div style={{ width: '526px', margin: '12px' }}>
                            <ApplicationSelectKrosAdmin onChange={(e) => { console.log('zmena:', e); setApp(e?.id) }} />
                        </div>
                        {/* </Validate> */}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled || name === undefined || name === '' || email === undefined || isEmail(email) === false || app === undefined} onClick={() => {
                                if (name && email && isEmail(email) && app) {
                                    setButtonDisabled(true);
                                    new APIVovKrosAdminHelper().SaveUserData(new AdminUsersDTO({ name: name, email: email, applicationId: app })).then((ok) => {
                                        if (ok && ok.ok) {
                                            if (onCreated)
                                                onCreated(ok.id!, name);
                                            setName(undefined);
                                            setEmail(undefined);
                                            setApp(undefined);
                                            onClose();
                                        }
                                        else {
                                            setOpenLoginError(true);
                                            setErrorMessage(ok && ok.message ? ok.message : '');
                                        }
                                        setButtonDisabled(false);
                                    });
                                }
                            }}>{t('save')}</Button>
                        </div>
                    </div>
                </ValidationGroup>
                <div className="create-form-popup">
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('unableToCreateSub')}</AlertTitle>
                            <span>{errorMessage}</span>
                        </Alert>
                    </Snackbar>
                </div>
            </DialogContent>
        </Dialog>
    )
}
