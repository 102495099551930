import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';


interface SplitLoadingButtonProps {
    options: string[];
    loading: boolean;
    size: 'small' | 'medium' | 'large';
    variant: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
    startIcon?: React.ReactNode;
    onMenuItemClick: (event: any, index: number) => void;
}
export default function SplitLoadingButton(props: SplitLoadingButtonProps) {
    const { loading, size, variant, disabled, options, startIcon, onMenuItemClick } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const { t } = useTranslation();

    useEffect(() => {
    }, [loading]);

    const handleToggle = () => {
        if (!loading && !disabled)
            setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        //setSelectedIndex(index);
        setOpen(false);
        onMenuItemClick(event, index);
    };

    return <React.Fragment>
        <ButtonGroup sx={{ borderRadius: '12px' }} variant={variant} ref={anchorRef} size={size} disabled={disabled}>
            <LoadingButton
                onClick={(e) => onMenuItemClick(e, selectedIndex)}
                loading={loading}
                disabled={loading || disabled}
                startIcon={startIcon}
                variant={variant}
            >
                <span>{biggerThan700 && options[selectedIndex]}</span>
            </LoadingButton>
            <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="menu"
                sx={{ width: '36px', borderLeft: '1px solid black', minWidth: '36px !important' }}
                onClick={handleToggle}
                disabled={loading || disabled}
            >
                <ArrowDropDown />
            </Button>
        </ButtonGroup>
        <Popper
            sx={{
                zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                                {options.map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                        disabled={disabled}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>

    </React.Fragment>;
}