import React, { createContext, useCallback, useContext, useState, ReactNode } from 'react';
import { GetBillsPermissionsResponse, GetBuildingsPermissionsResponse, VovClient } from '../../ApiOld/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';
import { InvoiceVovClient } from '../../ApiOld/Api/ApiServerVov';

interface BuildingBillsPermissionContextProps {
    permissions: GetBillsPermissionsResponse | null;
    reloadPermissions: () => Promise<void>;
}

const BuildingBillsPermissionContext = createContext<BuildingBillsPermissionContextProps>({
    permissions: null,
    reloadPermissions: async () => { },
});

export const useBuildingBillsPermissionContext = () => useContext(BuildingBillsPermissionContext);

const useReloadPermissions = () => {

    const { authorizedApi } = useApi(InvoiceVovClient);
    const [permissions, setPermissions] = useState<GetBillsPermissionsResponse | null>(null);

    const reloadPermissions = useCallback(async (): Promise<void> => {
        const permissions = await authorizedApi.getBillsPermissions();
        setPermissions(permissions);
    }, [authorizedApi]);

    return { permissions, reloadPermissions };
};

interface BuildingBillsPermissionProviderProps {
    children: ReactNode;
}

export const BuildingBillsPermissionProvider: React.FC<BuildingBillsPermissionProviderProps> = ({ children }) => {
    const { permissions, reloadPermissions } = useReloadPermissions();

    return (
        <BuildingBillsPermissionContext.Provider value={{ permissions, reloadPermissions }}>
            {children}
        </BuildingBillsPermissionContext.Provider>
    );
};