import { Button, Card, Link, Stack, Tooltip } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, RecordDoubleClickEventArgs, Page } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InitSfGridLocale from '../../Localization/SfGridLanguageInitializer';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import './TodoScene.css';
import { TodoGanttDTO, TodoListDTO, VovClient } from '../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../ApiOld/ApiToDoOldHelper';
import UpperMenu from '../Shared/Layouts/UpperMenu';
import { GridType } from '../../Api/UsersMicroserviceClient';
import TodoGanttOld from './ToDoOld/TodoGanttOld';
import { useNavigate, useParams } from 'react-router-dom';
import { TrashType } from '../Shared/Trash/TrashType';
import TrashGridDialog from '../Shared/Trash/TrashGridDialog';
import { useApi } from '../../ApiOld/Api/useApi';
import GridComponentStatefull from '../Shared/Datagrid/GridComponentStatefull';
import { useUserLayoutContext } from '../../Contexts/Providers/UserLayoutMenuProvider';
import { useRequestsPermissionContext } from '../../Contexts/Permissions/RequestsPermissionContext';
import { AddCircleOutline, MapOutlined, Segment, List } from '@mui/icons-material';

InitSfGridLocale();


interface ToDoSceneGanttOldProps extends WithLoadingProps {
    buildingId?: number;
}

function ToDoSceneGanttOld(props: ToDoSceneGanttOldProps) {
    const { fireLoading, stopLoading, buildingId } = props;
    const params = useParams();
    const [ganttData, setGanttData] = useState<TodoGanttDTO[]>([]);
    const [newOpen, setNewOpen] = useState(false);
    const [trashOpen, setTrashOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();

    const { authorizedApi } = useApi(VovClient);
    const { permissions, reloadPermissions } = useRequestsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {

        refreshData();
    }, []);
    useEffect(() => {
        setMenuButtons(
            <Stack direction='row' mr={2} alignItems={'center'} spacing={2}>
                <Button size='small' sx={{ mr: 2 }} onClick={(e) => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos')} variant='contained'><List /></Button>
                <Button size='small' sx={{ mr: 2 }} onClick={(e) => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos-map')} variant='contained'><MapOutlined /></Button>
                {permissions && permissions.requestsCreate && <Button
                    size='small'
                    sx={{ mr: 2 }}
                    startIcon={<AddCircleOutline />}
                    onClick={(e) => createTodo(e)} variant='contained'>{t('Add')}</Button>}
            </Stack>
        );
        setBackMenuFunction(undefined);

        // console.log('loading top');
    }, [permissions, setMenuButtons, setBackMenuFunction, t, i18n.language, params['domain']]);

    useEffect(() => {
        if (refresh) {
            refreshData();
            setRefresh(false);
        }
    }, [refresh]);

    const goToTodo = (event: any, param: TodoListDTO) => {
        navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/todos/tododetail/' + param.id);
    };

    function refreshData() {
        //fireLoading!();
        new APITodoOldHelper().GetGanttTodos().then((result) => {
            setGanttData(result);
            stopLoading!();
        });
    }

    function createTodo(event: any) {
        navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/todos/tododetail/0');
    }

    return (
        <div>
            <Card sx={{ margin: 3, padding: 3, height: '100%' }} elevation={3}>
                <TodoGanttOld tasksGantt={ganttData} />

                <TrashGridDialog isSubdodavatel={false} trashType={TrashType.Todos} open={trashOpen} onClose={(e) => { console.log(e); setTrashOpen(false); if (e) refreshData(); }} />
            </Card>
        </div >
    );
};
export default withLoading(ToDoSceneGanttOld);

