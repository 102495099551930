import axios, { AxiosInstance } from "axios";
import delay from "../Components/Shared/Functions/Delay";
import { APIVovSubcontractorHelper } from "./ApiSubcontractorOldClient";
import { VovClient } from "./ApiServerVov";

export class SingletonRefreshClientSubcontractorOld {
    public static isRefreshing: boolean;
    private constructor() {
    }
    public static setRefresh() {
        this.isRefreshing = true;
    }
    public static completeRefresh() {
        this.isRefreshing = false;
    }

}
export class ApiSubcontractorOldBaseHelper {

    protected GetAdressedAuthRefreshableClient(): VovClient {
        return new VovClient(this.GetApiVovAddress(), this.GetAuthClient());
    }
    protected GetApiVovAddress(): string {
        console.log(process.env.REACT_APP_VOV_API_URL);
        if (process.env.REACT_APP_VOV_API_URL === 'NOT')
            return '';
        else return process.env.REACT_APP_VOV_API_URL!;

    }

    public GetClientToAuth(): VovClient {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                let culture = localStorage.getItem('i18nextLng');
                if (culture !== undefined && culture !== null)
                    config.headers = {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'accept-language': culture,
                        'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                else config.headers = {

                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                try {
                    if (error.response.headers['is-token-expired'] === 'true') {
                        console.log('refresh token', error);
                        originalRequest._retry = true;
                        await new APIVovSubcontractorHelper().RefreshSubcontractorToken();
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
                        return axiosApiInstance(originalRequest);
                    }
                    else throw new Error('unathorized but refresh token not expires');
                }
                catch (e) {
                    throw new Error('unable to refresh token');
                }
            }
            return Promise.reject(error);
        });
        return new VovClient(this.GetApiVovAddress(), axiosApiInstance);
    }
    protected GetAuthClient(): AxiosInstance {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                let token = localStorage.getItem('subcontractor_token_old');
                let culture = localStorage.getItem('i18nextLng');
                if (culture !== undefined && culture !== null)
                    config.headers = {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'accept-language': culture,
                        'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                else config.headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                console.log('401.1', window.location.href);
                var items = window.location.href.split("/");
                localStorage.removeItem('subcontractor_token_old');
                window.location.href = window.location.origin + '/' + items[3] + '/' + items[4] + '/' + items[5];
            }
            /* if (error.response.status === 401 && !originalRequest._retry) {
                try {
                    if (error.response.headers['is-token-expired'] === 'true') {
                        if (!SingletonRefreshClientSubcontractorOld.isRefreshing) {
                            originalRequest._retry = true;
                            await new APIVovSubcontractorHelper().RefreshSubcontractorToken();
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
                        }
                        else delay(400);

                        return axiosApiInstance(originalRequest);
                    }
                    else throw new Error('unathorized but refresh token not expires');
                }
                catch (e) {
                    console.log('error refresh', e);
                    // throw new Error('unable to refresh token');
                }
            } */
            return Promise.reject(error);
        });
        return axiosApiInstance;
    }
}