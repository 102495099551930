import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { DeleteEmployeeSignatureRequest, DeleteEmployeeSignatureResponse, DeleteEmployeesRequest, EmployeeDTO, EmployeeDeletedDTO, EmployeeDetailDTO, EmployeeGroupListDTO, EmployeeListDTO, FileParameter, GetSignatureForEmployeeResponse, MoveEmployeesFromTrashRequest, SaveEmployeeRequest, SaveEmployeeResponse, SaveEmployeeSignatureResponse, SetIsEmployeeActiveRequest } from "./ApiServerVov";

export class APIVovEmployeeHelper extends ApiOldBaseHelper {
    public async GetEmployeesForAdminUser(): Promise<EmployeeDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeesForAdminUser()).employees;
    }

    public async GetEmployeesList(): Promise<EmployeeListDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeesList()).employees!;
    } 

    public async SetIsEmployeeActive(id: number, active: boolean): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().setIsEmployeeActive(new SetIsEmployeeActiveRequest({ employeeId: id, active: active}))).ok!;
    }

    public async GetDeletedEmployees(): Promise<EmployeeDeletedDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getDeletedEmployees()).employees!;
    }

    public async MoveEmployeesFromTrash(ids: number[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().moveEmployeesFromTrash(new MoveEmployeesFromTrashRequest({ deletedEmployeeIds: ids }))).ok!;
    }

    public async DeleteEmployees(ids: number[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().deleteEmployees(new DeleteEmployeesRequest({ deletedEmployeeIds: ids }))).ok!;
    }

    public async GetEmployeeDetail(id: number): Promise<EmployeeDetailDTO> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeeDetail(id)).employee!;
    }

    public async GetSignatureForEmployee(id: number): Promise<GetSignatureForEmployeeResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getSignatureForEmployee(id));
    }

    public async SaveEmployeeSignature(id: number, file: FileParameter): Promise<SaveEmployeeSignatureResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveEmployeeSignature(id, file));
    }

    public async DeleteEmployeeSignature(id: number): Promise<DeleteEmployeeSignatureResponse> {
        return (await this.GetAdressedAuthRefreshableClient().deleteEmployeeSignature(new DeleteEmployeeSignatureRequest({ employeeId: id })));
    }

    public async SaveEmployee(employee: EmployeeDetailDTO): Promise<SaveEmployeeResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveEmployee(new SaveEmployeeRequest({ employee: employee })));
    }

    public async GetEmployeeGroups(): Promise<EmployeeGroupListDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeeGroups()).groups!;
    }

}