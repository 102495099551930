import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';
import { useTranslation } from 'react-i18next';

export default function ExternalEntryPoint() {
    const params = useParams();
    const navigate = useNavigate();
    const { load } = useLoginAdminStore();
    const { t } = useTranslation();
    const getRouteForParameter = (param: string) => {
        switch (param) {
            case 'diary': return 'diary/diaryvov';
            case 'projects': return 'projects';
            default: return param;
        }
    }
    useEffect(() => {
        if (params['token'] && params['adminUserId'] && params['lang'] && params['domain'] && params['route']) {
            new APIVovHelper().LoginErp(params['token'], parseInt(params['adminUserId']), params['domain']).then((e) => {
                if (e.userName)
                    load(e.userName, parseInt(params['adminUserId']!));
                navigate('/' + params['lang'] + '/' + params['domain'] + '/userlayout/' + getRouteForParameter(params['route']!));
            })
        }
    }, [load, navigate, params, t]);
    return (<div>
        {t('diary.loggingIntoNewVersion')}
    </div>);

}
