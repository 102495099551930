import { AccessTime } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
export function TimeEditTemplate(props: any) {
    const [localVal, setLocalVal] = useState('');
    const [localValTime, setLocalValTime] = useState('');
    const [localTime, setLocalTime] = useState<Date | null>(null);

    useEffect(() => {
        //console.log(props.time);
        setLocalTime(props.time);
        setLocalValTime(new Date(props.time).getHours() + ':' + new Date(props.time).getMinutes());
        if (props.time)
            setLocalVal(props.time);

    }, []);
    return (<div>
        {localTime &&
            <div>
                <TextField sx={{ minWidth: '100px' }} type='time' value={localValTime} onChange={(e) => {
                    setLocalValTime(e.currentTarget!.value);
                    var pole = e.currentTarget!.value.split(":");
                    var d = new Date(localTime.getFullYear(), localTime.getMonth(), localTime.getDay(), parseInt(pole[0]), parseInt(pole[1]));
                    setLocalTime(d);
                    setLocalVal(d.toString());
                }}></TextField>
                <TextField
                    value={localVal}

                    onChange={(e) => { setLocalVal(e.currentTarget.value) }} sx={{ maxWidth: '0px', display: 'none' }}

                    size='small' name='time' />
            </div>}
        {/* <IconButton onClick={(e) => {
            if (localTime) {
                console.log(localTime);
                // 
                // var pole = e.currentTarget!.value.split(":");
                var d = new Date(localTime.getFullYear(), localTime.getMonth(), localTime.getDay(), 13, 22);
                setLocalTime(d);
                setLocalVal(d.toString());
                setLocalValTime('13:22');
            }
            //ts-ignore
            // text.current!.blur();

        }} style={{ marginLeft: '12px' }}><AccessTime /></IconButton> */}
    </div >);
};
export function TimeTemplate(props: any) {
    // console.log(props);
    if (props.time)
        return <div>
            {new Date(props.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            <IconButton disabled style={{ marginLeft: '12px' }}><AccessTime /></IconButton>
        </div>;

};