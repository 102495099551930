import { Autocomplete, Box, FormControl, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Popover, Select, Slider, styled, Switch, Tab, Tabs, TextareaAutosize, TextField, TextFieldProps, Tooltip, Typography, useTheme } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate, useParams } from 'react-router-dom';
import { EmployeeDetailDTO, EmploymentEnum, HealthInsuranceEnum, InvalidityEnum, MaritalStatusEnum, WorkTimeEnum } from '../../../../ApiOld/ApiServerVov';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export interface EmployeePersonalDetailSceneProps {
    employee: EmployeeDetailDTO | undefined;
    setEmployee: (value: EmployeeDetailDTO | undefined) => void;
}


export default function EmployeePersonalDetailScene(props: EmployeePersonalDetailSceneProps) {
    const { employee, setEmployee } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();

    /* useEffect(() => {
        console.log('employee', employee);
    }, [employee]); */

    const optionsStates = [
        { label: t('singleTodo.new'), id: 0 },
        { label: t('singleTodo.active'), id: 1 },
        { label: t('singleTodo.resolved'), id: 2 },
    ];
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");


    const showGanttOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    };

    if (employee !== undefined) {
        return (
            <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ background: 'white', p: 2, mb: '20px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <h4>{t('additionalInformation')}</h4>
                    <div style={{ margin: '10px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField label={t('employee.alternativeEmail')} variant="outlined" fullWidth size='small'
                                    name='title'
                                    value={employee.emailAlt}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, emailAlt: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.accountNumber')} variant="outlined" fullWidth size='small'
                                    name='accountNumber'
                                    value={employee.accountNumber}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, accountNumber: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.SWIFTBC')} variant="outlined" fullWidth size='small'
                                    name='swiftBc'
                                    value={employee.swiftBc}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, swiftBc: e.target.value })) }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="marital-state-label">{t('employee.maritalStatus')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="marital-state-label"
                                        id="marital-state"
                                        value={employee.maritalStatus}
                                        label={t('employee.maritalStatus')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, maritalStatus: e.target.value as MaritalStatusEnum })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={1}>{t('employee.single')}</MenuItem>
                                        <MenuItem value={2}>{t('employee.married')}</MenuItem>
                                        <MenuItem value={3}>{t('employee.divorced')}</MenuItem>
                                        <MenuItem value={4}>{t('employee.widowed')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.childrenCount')} variant="outlined" fullWidth size='small' type='number'
                                    name='childrenCount'
                                    value={employee.childrenCount}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, childrenCount: parseInt(e.target.value) })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={t('employee.birthDate')}
                                        inputFormat="DD.MM.YYYY"
                                        value={employee.birthDate ? employee.birthDate : null}
                                        onChange={(newValue) => {
                                            setEmployee(new EmployeeDetailDTO({ ...employee, birthDate: newValue !== null ? newValue : undefined }));
                                        }}
                                        renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField label={t('employee.birthNumber')} variant="outlined" fullWidth size='small'
                                    name='birthNumber'
                                    value={employee.birthNumber}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, birthNumber: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.birthPlace')} variant="outlined" fullWidth size='small'
                                    name='birthPlace'
                                    value={employee.birthPlace}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, birthPlace: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.driverLicenseNumber')} variant="outlined" fullWidth size='small'
                                    name='drivingLicenceNumber'
                                    value={employee.driverLicenseNumber}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, driverLicenseNumber: e.target.value })) }}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </Box>
                <Box sx={{ background: 'white', p: 2, mb: '20px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <h4>{t('employee.permanentAddress')}</h4>
                    <div style={{ margin: '10px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField label={t('employee.streetAndNumber')} variant="outlined" fullWidth size='small'
                                    name='streetAndNumber'
                                    value={employee.addressPermanentStreet1}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressPermanentStreet1: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.addresDetail')} variant="outlined" fullWidth size='small'
                                    name='addresDetail'
                                    value={employee.addressPermanentStreet2}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressPermanentStreet2: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.city')} variant="outlined" fullWidth size='small'
                                    name='city'
                                    value={employee.addressPermanentCity}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressPermanentCity: e.target.value })) }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField label={t('employee.zip')} variant="outlined" fullWidth size='small'
                                    name='zip'
                                    value={employee.addressPermanentZip}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressPermanentZip: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.state')} variant="outlined" fullWidth size='small'
                                    name='state'
                                    value={employee.addressPermanentCountry}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressPermanentCountry: e.target.value })) }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                <Box sx={{ background: 'white', p: 2, mb: '20px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <h4>{t('employee.correspondentAddress')}</h4>
                    <div style={{ margin: '10px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField label={t('employee.streetAndNumber')} variant="outlined" fullWidth size='small'
                                    name='streetAndNumber2'
                                    value={employee.addressCorrespondentStreet1}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressCorrespondentStreet1: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.addresDetail')} variant="outlined" fullWidth size='small'
                                    name='addresDetail2'
                                    value={employee.addressCorrespondentStreet2}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressCorrespondentStreet2: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.city')} variant="outlined" fullWidth size='small'
                                    name='city'
                                    value={employee.addressCorrespondentCity}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressCorrespondentCity: e.target.value })) }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField label={t('employee.zip')} variant="outlined" fullWidth size='small'
                                    name='zip'
                                    value={employee.addressCorrespondentZip}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressCorrespondentZip: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.state')} variant="outlined" fullWidth size='small'
                                    name='state'
                                    value={employee.addressCorrespondentCountry}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, addressCorrespondentCountry: e.target.value })) }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                <Box sx={{ background: 'white', p: 2, mb: '20px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                    <h4>{t('employee.jobAndOtherInfo')}</h4>
                    <div style={{ margin: '10px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="typeOfEmployment-label">{t('employee.typeOfEmployment')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="typeOfEmployment-label"
                                        id="typeOfEmployment"
                                        value={employee.employment}
                                        label={t('employee.typeOfEmployment')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, employment: e.target.value as EmploymentEnum })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={1}>{t('employee.forFixedPeriod')}</MenuItem>
                                        <MenuItem value={2}>{t('employee.forUnfixedPeriod')}</MenuItem>
                                        <MenuItem value={3}>{t('employee.agreementOnWorkPreformance')}</MenuItem>
                                        <MenuItem value={4}>{t('employee.agreementOnWorkActivity')}</MenuItem>
                                        <MenuItem value={5}>{t('employee.fixedTermMainEmploymentRealtionship')}</MenuItem>
                                        <MenuItem value={6}>{t('employee.unfixedTermMainEmploymentRealtionship')}</MenuItem>
                                        <MenuItem value={7}>{t('DPP')}</MenuItem>
                                        <MenuItem value={8}>{t('DPČ')}</MenuItem>
                                        <MenuItem value={9}>{t('OSVČ')}</MenuItem>
                                        <MenuItem value={10}>{'HPP na dobu určitou + DPP'}</MenuItem>
                                        <MenuItem value={11}>{'HPP na dobu neurčitou + DPP'}</MenuItem>
                                        <MenuItem value={12}>{'DPP + DPČ'}</MenuItem>
                                        <MenuItem value={13}>{'OSVČ + DPP'}</MenuItem>
                                        <MenuItem value={14}>{'HPP na dobu určitou + DPP + OSVČ'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="workingHours-label">{t('employee.workingHours')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="workingHours-label"
                                        id="workingHours"
                                        value={employee.workTime}
                                        label={t('employee.workingHours')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, workTime: e.target.value as WorkTimeEnum })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={1}>{t('employee.halfTimeJob')}</MenuItem>
                                        <MenuItem value={2}>{t('employee.flexibleWorkingHours')}</MenuItem>
                                        <MenuItem value={3}>{t('employee.sharedWorkPlace')}</MenuItem>
                                        <MenuItem value={4}>{t('employee.homeOffice')}</MenuItem>
                                        <MenuItem value={5}>{t('employee.workingHoursAccount')}</MenuItem>
                                        <MenuItem value={6}>{t('employee.compressedWorkWeek')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="disability-label">{t('employee.disability')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="disability-label"
                                        id="disability"
                                        value={employee.invalidity}
                                        label={t('employee.disability')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, invalidity: e.target.value as InvalidityEnum })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={1}>{t('employee.none')}</MenuItem>
                                        <MenuItem value={2}>{t('employee.partialDisabilityFirstOrSecondDegree')}</MenuItem>
                                        <MenuItem value={3}>{t('employee.fullDisabilityThirdDegree')}</MenuItem>
                                        <MenuItem value={4}>{t('employee.holderOfDisabledCard')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="insuranceCompany-label">{t('employee.insuranceCompany')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="insuranceCompany-label"
                                        id="insuranceCompany"
                                        value={employee.insurance}
                                        label={t('employee.insuranceCompany')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, insurance: e.target.value as HealthInsuranceEnum })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={111}>VZP - Všeobecná zdravotní pojišťovna</MenuItem>
                                        <MenuItem value={201}>VoZP - Vojenská zdravotní pojišťovna</MenuItem>
                                        <MenuItem value={205}>ČPZP - Česká průmyslová zdravotní pojišťovna</MenuItem>
                                        <MenuItem value={207}>OZP - Oborová zdravotní pojišťovna</MenuItem>
                                        <MenuItem value={209}>ZPŠ - Zaměstnanecká pojišťovna Škoda</MenuItem>
                                        <MenuItem value={211}>ZPMV - Zdravotní pojišťovna ministervśtva vnitra ČR</MenuItem>
                                        <MenuItem value={213}>RBP - Revírní bratrská pokladna, zdrav. pojišťovna</MenuItem>
                                        <MenuItem value={500}>VSZP - Všeobecná zdravotná poisťovna</MenuItem>
                                        <MenuItem value={501}>DÔVERA - Zdravotná poisťovňa Dôvera</MenuItem>
                                        <MenuItem value={502}>UNION - Zdravotná poisťovňa Union</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControlLabel control={<Switch checked={employee.creditForTaxPayer}
                                    onChange={(e, checked) => {
                                        setEmployee(new EmployeeDetailDTO({ ...employee, creditForTaxPayer: checked }));
                                    }}
                                />} label={t('employee.discountForTaxpayer')} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Switch checked={employee.studentUnder26}
                                    onChange={(e, checked) => {
                                        setEmployee(new EmployeeDetailDTO({ ...employee, studentUnder26: checked }));
                                    }}
                                />} label={t('employee.studentUnder26')} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('employee.citizenship')} variant="outlined" fullWidth size='small'
                                    name='state'
                                    value={employee.citizenship}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, citizenship: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="clothesSize-label">{t('employee.clothesSize')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="clothesSize-label"
                                        id="clothesSize"
                                        value={employee.clothesSize}
                                        label={t('employee.clothesSize')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, clothesSize: Number(e.target.value) })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={-2}>XXXXXL</MenuItem>
                                        <MenuItem value={-1}>XXXXL</MenuItem>
                                        <MenuItem value={0}>XXXL</MenuItem>
                                        <MenuItem value={1}>XXL</MenuItem>
                                        <MenuItem value={2}>XL</MenuItem>
                                        <MenuItem value={3}>L</MenuItem>
                                        <MenuItem value={4}>M</MenuItem>
                                        <MenuItem value={5}>S</MenuItem>
                                        <MenuItem value={6}>SX</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="secondaryClothesSize-label">{t('employee.secondaryClothesSize')}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        labelId="secondaryClothesSize-label"
                                        id="secondaryClothesSize"
                                        value={employee.clothesSizeSecondary}
                                        label={t('employee.secondaryClothesSize')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, clothesSizeSecondary: Number(e.target.value) })) }}
                                    >
                                        <MenuItem value={undefined}>-</MenuItem>
                                        <MenuItem value={1}>48</MenuItem>
                                        <MenuItem value={2}>50</MenuItem>
                                        <MenuItem value={3}>52</MenuItem>
                                        <MenuItem value={4}>54</MenuItem>
                                        <MenuItem value={5}>56</MenuItem>
                                        <MenuItem value={6}>58</MenuItem>
                                        <MenuItem value={7}>60</MenuItem>
                                        <MenuItem value={8}>62</MenuItem>
                                        <MenuItem value={9}>64</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label={t('employee.shoeSize')} variant="outlined" fullWidth size='small' type='number'
                                    name='shoeSize'
                                    inputProps={{
                                        step: "0.5"
                                    }}
                                    value={employee.shoesSize}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, shoesSize: e.target.value })) }}
                                />
                            </Grid>


                        </Grid>
                    </div>
                </Box>

            </Box>
        );
    }
    else return (<div></div>);
};
