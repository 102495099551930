import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { BuildingBillsForInvoicesPageDTO, CreateInvoiceRequest, CreateInvoiceResponse, DeleteInvoicesRequest, GetInvoiceItemsResponse, InvoiceFileDTO, InvoiceItem, MoveInvoicesFromTrashRequest, SaveInvoiceFilesRequest, SaveInvoiceItemsRequest, SaveInvoiceRequest, SaveWholeInvoiceRequest, SaveWholeInvoiceResponse, TaxrateDTO } from "./ApiServerVov";
import { GetInvoiceDataDetailResponse } from "./ApiServerVov";

export class ApiOldInvoicesClient extends ApiOldBaseHelper {

    public async GetBuildingBillsForInvoicesListPage(deleted: boolean): Promise<BuildingBillsForInvoicesPageDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().invoices(deleted)).buildingBillsForInvoicesPage!;
    }

    public async GetInvoiceDataDetail(invoiceId: number): Promise<GetInvoiceDataDetailResponse> {
        return (await this.GetAdressedAuthRefreshableClient().invoiceDetail(invoiceId))!;
    }

    public async deleteInvoices(invoicesIds: number[]): Promise<boolean> {
        return ((await this.GetAdressedAuthRefreshableClient().deleteInvoices(new DeleteInvoicesRequest({ invoicesIds: invoicesIds }))).ok!);
    }

    public async MoveInvoicesFromTrash(invoicesIds: number[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().moveInvoicesFromTrash(new MoveInvoicesFromTrashRequest({ deletedInvoicesIds: invoicesIds }))).ok!;
    }

    public async CreateInvoice(invoiceName: string): Promise<CreateInvoiceResponse> {
        return (await this.GetAdressedAuthRefreshableClient().createInvoice(new CreateInvoiceRequest({
            invoiceName
        })));
    }

    public async GetInvoiceItems(invoiceId: number): Promise<GetInvoiceItemsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().invoiceItems(invoiceId));
    }

    public async SaveWholeInvoice(invoice: Partial<GetInvoiceDataDetailResponse>, invoiceItems: InvoiceItem[], invoiceFiles: InvoiceFileDTO[]): Promise<SaveWholeInvoiceResponse> {
        return ((await this.GetAdressedAuthRefreshableClient().saveWholeInvoice(new SaveWholeInvoiceRequest(
            {
                saveInvoiceItemsRequest: new SaveInvoiceItemsRequest({
                    invoiceId: invoice.id!,
                    invoiceItems
                }),
                saveInvoiceRequest: new SaveInvoiceRequest({
                    id: invoice.id,
                    name: invoice.name,
                    isDone: invoice.isDone,
                    isApproved: invoice.isApproved,
                    isPostponed: invoice.isPostponed,
                    isPosted: invoice.isPosted,
                    dateTaxable: invoice.dateTaxable,
                    dateExpire: invoice.dateExpire,
                    adminUserFrom: invoice.adminUserFrom,
                    adminUserTo: invoice.adminUserTo,
                    supplier: invoice.supplier,
                    dateReceive: invoice.dateReceive,
                    variableSymbol: invoice.variableSymbol,
                    subject: invoice.subject,
                    paymentPayerBankNumber: invoice.paymentPayerBankNumber,
                    paymentPayerBankCode: invoice.paymentPayerBankCode,
                    paymentRecipientBankNumber: invoice.paymentRecipientBankNumber,
                    paymentRecipientBankCode: invoice.paymentRecipientBankCode,
                    paymentDueDate: invoice.paymentDueDate,
                    paymentRecipientName: invoice.paymentRecipientName,
                    paymentMessage: invoice.paymentMessage,
                    paymentVariableSymbol: invoice.paymentVariableSymbol,
                    paymentConstantSymbol: invoice.paymentConstantSymbol,
                    paymentSpecificSymbol: invoice.paymentSpecificSymbol,
                    commentAdd: invoice.commentAdd,
                }),
                saveInvoiceFilesRequest: new SaveInvoiceFilesRequest({
                    invoiceId: invoice.id!,
                    files: invoiceFiles
                }),
            }))))
    }

    public async GetTaxrates(): Promise<TaxrateDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().taxrates()).taxrates!;
    }

    public async GetBillsBuildingList(buildingId: number, deleted: boolean): Promise<BuildingBillsForInvoicesPageDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getInvoicesBuilding(buildingId, deleted)).bills!;
    }
}