import { Box } from "@mui/material";
import { Info } from '@mui/icons-material';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import delay from '../../../Shared/Functions/Delay';
import { useNavigate } from 'react-router-dom';
import { useMediaPredicate } from "react-media-hook";
import { TabItem } from "../../../Shared/Tabs/StyledTabs";
import { useUserLayoutContext } from "../../../../Contexts/Providers/UserLayoutMenuProvider";
import ReusableTabs from "../../InvoiceComponents/ReusableTabs";
import { useApi } from "../../../../ApiOld/Api/useApi";
import ManufacturerMainDetail from "./ManufacturerMainDetail";
import ManufacturerMenuButtons from "./ManufacturerMenuButtons";
import WarehouseInfoDialog from "../Dialogs/WarehouseInfoDialog";
import { GetManufacturerDataDetailResponse, SaveManufacturerRequest, WarehouseVovClient } from "../../../../ApiOld/Api/ApiServerVov";

interface ManufacturerDetailProps {
    manufacturerId?: number;
}

export default function ManufacturerDetail(props: Readonly<ManufacturerDetailProps>) {
    const { manufacturerId } = props;
    const { authorizedApi } = useApi(WarehouseVovClient);
    const [manufacturerData, setManufacturerData] = useState<Partial<GetManufacturerDataDetailResponse> | null>(null);
    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<number>(0);
    const [infoOpen, setInfoOpen] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const params = useParams();

    useEffect(() => {
        setBackMenuFunction(() => () => { redirectToList() })
        if (manufacturerId) {
            authorizedApi.getManufacturerDetail(manufacturerId).then((d) => {
                setManufacturerData(d);
                setTitle(d.name ?? '');
            });
        }
        else {
            setManufacturerData(new GetManufacturerDataDetailResponse({ id: 0 }));
        }
    }, []);

    useEffect(() => {
        if (manufacturerId) {
            authorizedApi.getManufacturerDetail(manufacturerId).then((d) => {
                setManufacturerData(d);
            });
        }
        else {
            setManufacturerData(new GetManufacturerDataDetailResponse({ id: 0 }));
        }
    }, [authorizedApi, manufacturerId]);

    useEffect(() => {
        if (saveDone === true)
            delay(5000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    useEffect(() => {
        setMenuButtons(manufacturerData && <ManufacturerMenuButtons
            manufacturerData={manufacturerData}
            saveDone={saveDone}
            error={errorCode}
            saveManufacturer={saveManufacturer}
        />);
    }, [manufacturerData, saveDone, setMenuButtons, errorCode]);

    function saveManufacturer(close: boolean) {
        if (manufacturerData)
            authorizedApi.saveManufacturer(new SaveManufacturerRequest(manufacturerData)).then((d) => {
                if (d.ok) {
                    if (!props.manufacturerId)
                        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/manufacturerDetail/' + d.id);
                    setManufacturerData({ ...manufacturerData, id: d.id });
                    setSaveDone(true);
                    setErrorCode(0);

                    if (close)
                        redirectToList();
                }
                else {
                    setErrorCode(d.errorCode ? d.errorCode : 0);
                    setSaveDone(true);
                    setInfoOpen(true);
                }
            });
    }

    const errorMessages = [
        '',
        t('warehouses.manufacturerWithSameNameExists'),
        t('error') + ". " + t('sales.contactSupport'),
        t('error') + ". " + t('sales.contactSupport')
    ];

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/manufacturers')
    }

    const tabs = useMemo(() => manufacturerData && [
        {
            icon: <Info style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
            panel: <ManufacturerMainDetail manufacturerData={manufacturerData} setManufacturerData={setManufacturerData} />
        }
    ], [biggerThan700, manufacturerData, t]);

    if (manufacturerData)
        return (
            <Box sx={{ m: 2, maxWidth: biggerThan700 ? '100vw' : '92vw', height: '100vh', overflowY: biggerThan700 ? 'inherit' : 'auto', p: 1 }}>
                <ReusableTabs
                    variant="scrollable"
                    tabs={tabs?.filter(Boolean) as TabItem[]}
                    onSelectedTabChange={(index) => setSelectedTabIndex(index)}
                />
                <WarehouseInfoDialog open={infoOpen} onClose={() => setInfoOpen(false)} text={errorMessages[errorCode]} />
            </Box>
        );
    return <Box>{t('loading...')}</Box>

}