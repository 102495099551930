import { Clear, DoneAll } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { AssignedEmployeeDTO, AssignedUserDTO, GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import ProjectSearchBar from './SearchBars/ProjectSearchBar';

interface ProjectPeopleEmployeesComponentProps {
    groupedEmployees: [string, AssignedEmployeeDTO[]][];
    employees: AssignedEmployeeDTO[];
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectPeopleEmployeesComponent(props: ProjectPeopleEmployeesComponentProps) {
    const { groupedEmployees, employees, setProjectData } = props;
    const [search, setSearch] = useState<String>();
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const checkAllEmployees = useCallback((group: string | undefined, isAssigned: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedEmps: AssignedEmployeeDTO[] = prev.assignedEmployees!.map((p) =>
                p.group === group ? new AssignedEmployeeDTO({ id: p.id, isAssigned: isAssigned, code: p.code, firstName: p.firstName, lastName: p.lastName, group: p.group, groupId: p.groupId }) : p
            );

            return {
                ...prev,
                assignedEmployees: updatedAssignedEmps,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);



    const checkAll = useCallback((enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedEmps: AssignedEmployeeDTO[] = prev.assignedEmployees!.map((p) =>
                new AssignedEmployeeDTO({ id: p.id, isAssigned: enabled, code: p.code, firstName: p.firstName, lastName: p.lastName, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedEmployees: updatedAssignedEmps,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);

    return <Grid item xs={12} lg={4}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: '24px', fontWeight: 'bold' }}>{t('employees')}</Typography>

            <Box sx={{ marginTop: '0px', marginLeft: '16px' }}>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        </Box>
        <Box sx={{ padding: '0px 8px 0px 8px' }}>
            <ProjectSearchBar onSearch={setSearch} />
        </Box>
        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '0px', margin: '16px', paddingBottom: '8px' }}>
            {groupedEmployees.map((employee, i) => {
                return (<div key={'key' + i}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ margin: '8px', marginLeft: '24px', fontWeight: 'bold', fontSize: '14px' }}>{employee[0].length > 0 ? employee[0] : t('nonAssigned')}</Typography>
                        <div style={{ display: 'flex' }}>
                            <Tooltip title={t('chooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAllEmployees(employee[0], true); }}>
                                    <DoneAll sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('unchooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAllEmployees(employee[0], false); }}>
                                    <Clear sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            employee[1].map((emp, i) => {
                                if (search && !emp.code?.toLowerCase().includes(search.toLowerCase()) && !emp.firstName?.toLowerCase().includes(search.toLowerCase()) && !emp.lastName?.toLowerCase().includes(search.toLowerCase())) {
                                    return;
                                }

                                return (<div key={'key' + i} style={{ margin: '0px', marginLeft: '32px' }}><FormControlLabel
                                    control={
                                        <Checkbox
                                            id={emp.id?.toString()}
                                            checked={emp.isAssigned}
                                            onChange={(e) => {
                                                setProjectData((prev) => {
                                                    if (!prev) {
                                                        return prev;
                                                    }

                                                    const updatedAssignedEmps: AssignedEmployeeDTO[] = prev.assignedEmployees!.map((p) =>
                                                        emp.id === p.id ? new AssignedEmployeeDTO({ id: p.id, isAssigned: !p.isAssigned, code: p.code, firstName: p.firstName, lastName: p.lastName, group: p.group, groupId: p.groupId }) : p
                                                    );

                                                    return {
                                                        ...prev,
                                                        assignedEmployees: updatedAssignedEmps,
                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                });
                                            }}
                                        />
                                    }
                                    label={emp.code + ' ' + emp.firstName + ' ' + emp.lastName}
                                /> </div>);
                            })
                        }
                    </div>
                </div>);
            })}
        </Box>
    </Grid>;
}
