import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { L10n } from '@syncfusion/ej2-base';
//import ReactPanZoom from 'react-image-pan-zoom-rotate';
import delay from '../Functions/Delay';

interface ImageEditorDialogProps {
    imageUri: string;
    open: boolean;
    onClose: (event: any) => void;
}
L10n.load({
    'de-DE': {
        'image-editor': {
            'Browse': 'Durchsuche',
            'Crop': 'Ernte',
            'ZoomIn': 'Hineinzoomen',
            'ZoomOut': 'Rauszoomen',
            'Transform': 'Verwandeln',
            'Annotation': 'Anmerkung',
            'Text': 'Text hinzufügen',
            'Pen': 'Stift',
            'Reset': 'Zurücksetzen',
            'Save': 'Speichern',
            'Select': 'Auswählen',
            'RotateLeft': 'Nach links drehen',
            'RotateRight': 'Drehe nach rechts',
            'HorizontalFlip': 'Horizontaler Flip',
            'VerticalFlip': 'Vertikaler Flip',
            'OK': 'OK',
            'Cancel': 'Absagen',
            'FillColor': 'Füllfarbe',
            'StrokeColor': 'Strichfarbe',
            'StrokeWidth': 'Strichbreite',
            'FontFamily': 'Schriftfamilie',
            'FontStyle': 'Schriftstil',
            'FontSize': 'Schriftgröße',
            'FontColor': 'Schriftfarbe',
            'Pan': 'Pfanne',
            'Move': 'Bewegen',
            'Custom': 'Brauch',
            'Square': 'Quadrat',
            'Circle': 'Kreis',
            'Rectangle': 'Rechteck',
            'Line': 'Linie',
            'Default': 'Standard',
            'Bold': 'Fett gedruckt',
            'Italic': 'Kursiv',
            'BoldItalic': 'Fett Kursiv',
        }
    }
});
export default function ImageEditorDialog(props: ImageEditorDialogProps) {
    const { imageUri, onClose, open } = props;
    const img = useRef<ImageEditorComponent>(null);
    const [rotation, setRotation] = useState<number>(0);
    const { t } = useTranslation();
    useEffect(() => {
        // var btnCrop = document.getElementsByClassName('tui-image-editor-newline tui-image-editor-range-wrap');
        // console.log(btnCrop);
        // delay(2000).then(() => {
        //     try {
        //         btnCrop.item(3)?.removeChild(btnCrop.item(2)?.childNodes.item(1)!);
        //         // btnCrop.item(3)?.removeChild(btnCrop.item(3)?.childNodes.item(3)!);

        //         // btnCrop.item(3)?.removeChild(btnCrop.item(3)?.childNodes.item(4)!);
        //         btnCrop.item(3)?.removeChild(btnCrop.item(3)?.childNodes.item(2)!);
        //     }
        //     catch (e) { }
        // });
        // (btnCrop[0] as HTMLLabelElement).outerText = 'otočení';
        // (btnCrop[1] as HTMLLabelElement).outerText = 'otočení';
        // console.log(btnCrop.item(0));
        // console.log(btnCrop.item(1) as labe);
        // var e1 = btnCrop.item(0);
        // var e2 = btnCrop.item(0);
        // console.log(e1);
        // console.log(e2);

        // if (e1 !== null && e2 !== null) {
        //     if (e1.parentElement !== null)
        //         e1.parentElement.removeChild(e1);
        //     if (e2.parentElement !== null)
        //         e2.parentElement.removeChild(e2);
        //     // document.removeChild(e1);

        // }
        // (btnCrop.item(1) as HTMLLabelElement).outerText = "Otočení";
    }, [open]);
    function loadImage() {
        console.log(img.current);
        if (img.current !== null)
            img.current!.open("https://localhost:7054/DiaryCommon/GetBase64FromUri/dc57b1e_image_60012110.png");
        // setBase64(base64D);

    }
    useEffect(() => {
        loadImage();
    }, [imageUri]);
    return <Dialog
        open={open}
        maxWidth='xl'
        PaperProps={{
            sx: {
                width: "1050px",
                maxHeight: 1200,
                height: '800px'
            }
        }}
        sx={{ marginTop: '20px', zIndex: 10000 }}
        onClose={() => { onClose(null) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Upravit fotografii"}
        </DialogTitle>
        <DialogContent>
            <div style={{ width: '100%', height: '80%' }}>
                <Button onClick={(e) => {
                    setRotation(rotation + 90);
                }} >{t('save')}</Button>
                <img style={{ transform: `rotate(${rotation}deg)` }} src={imageUri} />
            </div>
        </DialogContent>
    </Dialog>
}