import { Box, FormLabel, Grid, Icon, IconButton, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { BuildingNoteDTO, DeleteBuildingNoteRequest, VovClient } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { Inject } from '@syncfusion/ej2-react-grids';
import { RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { ChangeEventArgs, HtmlEditor, QuickToolbar, Toolbar, Link } from '@syncfusion/ej2-richtexteditor';
import { Delete, Edit, Padding } from '@mui/icons-material';
import { useApi } from '../../../../ApiOld/Api/useApi';
import RenameNoteDialog from '../Dialogs/RenameNoteDialog';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';

interface ProjectNotesItemProps {
    note: BuildingNoteDTO;
    toolbarSettings: object;
    onNoteChange: (note: BuildingNoteDTO) => void;
    onDelete: (id: number) => void;
}

export default function ProjectNotesItem(props: ProjectNotesItemProps) {
    const { note, toolbarSettings, onNoteChange, onDelete } = props;
    const { t, i18n } = useTranslation();
    const { authorizedApi } = useApi(VovClient);
    const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const theme = useTheme();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    /* const checkAll = useCallback((enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: enabled, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]); */

    return <Grid item xs={12} lg={6}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize={12} sx={{ marginLeft: '24px', marginBottom: '0px', fontWeight: 'bold' }}>{t('buildings.assignedUsers')}
            </Typography>

            <Box sx={{ marginTop: '0px', marginLeft: '16px' }}>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        </Box> */}
        <Box style={{ margin: '16px', border: '1px solid lightgray', borderRadius: '10px', marginTop: '0px' }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel sx={{ marginLeft: '24px', marginBottom: '0px', fontWeight: 'bold', marginTop: '8px' }}>{note.name}</FormLabel>
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                    <IconButton size='small' color="secondary" onClick={(e) => {
                        setOpenRenameDialog(true);
                    }}>
                        <Edit />
                    </IconButton>
                    <IconButton size='small' color="secondary" onClick={(e) => {
                        setDeleteDialogOpen(true)
                    }}>
                        <Delete />
                    </IconButton>
                </Box>
            </Box>

            <RichTextEditorComponent readonly={!permissions || !permissions.buildingsEdit} style={{ borderWidth: 0, borderRadius: '10px', overflow: 'hidden', marginBottom: '-1px' }} locale={i18n.language} value={note.text} change={(eve: ChangeEventArgs) => {
                console.log(eve.value, new BuildingNoteDTO({ ...note, text: eve.value }));
                onNoteChange(new BuildingNoteDTO({ ...note, text: eve.value }));
            }} toolbarSettings={toolbarSettings}>
                <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>

            <RenameNoteDialog open={openRenameDialog} onClose={() => { setOpenRenameDialog(false); }} onConfirm={(name) => {
                onNoteChange(new BuildingNoteDTO({ ...note, name: name }));
            }} oldName={note.name!} />

            <DeleteDialog onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} data={undefined} onDeleteAgree={() => {
                if (note.id && note.id > 0)
                    authorizedApi.deleteBuildingNote(new DeleteBuildingNoteRequest({ id: note.id })).then((r) => {
                        if (r.ok) {
                            onDelete(note.id!);
                        }
                    });
                else
                    onDelete(note.id!);
            }} />
        </Box>
    </Grid>;
}
