import React, { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import { enUS, cs, sk, pl, fr, de, ru, uk } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // Main CSS file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

interface DateRangeSelection {
    startDate: Date;
    endDate: Date;
    key: string;
}

interface LocalizedDateRangePickerProps {
    onDateRangeChange: (range: { startDate: Date; endDate: Date }) => void;
}

const LocalizedDateRangePicker: React.FC<LocalizedDateRangePickerProps> = ({ onDateRangeChange }) => {
    const [range, setRange] = useState<DateRangeSelection>({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const { i18n } = useTranslation();
    const calendarRef = useRef<HTMLDivElement>(null);

    // Close calendar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                !calendarRef.current.contains(event.target as Node)
            ) {
                setIsCalendarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Handler to update the selected range
    const handleSelect = (ranges: any) => {
        const selectedRange = ranges.selection;
        setRange({
            startDate: selectedRange.startDate || new Date(),
            endDate: selectedRange.endDate || new Date(),
            key: selectedRange.key,
        });

        // Call the parent's callback function to send the range back
        onDateRangeChange({
            startDate: selectedRange.startDate || new Date(),
            endDate: selectedRange.endDate || new Date(),
        });
    };

    // Toggle calendar visibility
    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const getLang = () => {
        switch (i18n.language) {
            case "cs":
                return cs;
            case "sk":
                return sk;
            case "pl":
                return pl;
            case "fr":
                return fr;
            case "de":
                return de;
            case "ru":
                return ru;
            case "ua":
                return uk;
            case "en":
                return enUS;
            default:
                return cs;
        }
    };

    return (
        <div ref={calendarRef}>
            {isCalendarOpen && (
                <DateRange
                    ranges={[range]}
                    onChange={handleSelect}
                    locale={getLang()}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                />
            )}
            <IconButton onClick={toggleCalendar}>
                <CalendarMonth />
            </IconButton>
        </div>
    );
};

export default LocalizedDateRangePicker;
