import { Box, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AssignedEmployeeDTO, AssignedUserDTO, GetBuildingDataDetailResponse } from "../../../ApiOld/ApiServerVov";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useBuildingsPermissionContext } from "../../../Contexts/Permissions/BuildingsPermissionContext";
import ProjectPeopleNotificationsComponent from "./Components/ProjectPeopleNotificationsComponent";
import ProjectPeopleAdminUsersComponent from "./Components/ProjectPeopleAdminUsersComponent";
import ProjectPeopleEmployeesComponent from "./Components/ProjectPeopleEmployeesComponent";

interface ProjectPeopleProps {
    groupedUsers: [string, AssignedUserDTO[]][];
    groupedEmployees: [string, AssignedEmployeeDTO[]][];
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectPeople(props: ProjectPeopleProps) {
    const { t } = useTranslation();
    const { groupedUsers, groupedEmployees, projectData, setProjectData } = props;

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    return (
        projectData === null ? <div>{t('Loading')}</div> :
            <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ marginTop: '8px' }}>
                    <Grid container spacing={1} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                        <ProjectPeopleAdminUsersComponent groupedUsers={groupedUsers} setProjectData={setProjectData} users={projectData.assignedUsers ? projectData.assignedUsers : []} />
                        <ProjectPeopleNotificationsComponent projectData={projectData} setProjectData={setProjectData} />
                        <ProjectPeopleEmployeesComponent groupedEmployees={groupedEmployees} setProjectData={setProjectData} employees={projectData.assignedEmployees ? projectData.assignedEmployees : []} />
                    </Grid>
                </Box>
            </Box>
    );
}