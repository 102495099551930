export function getFileExtension(filename: string): string {
    const lastDotIndex = filename.lastIndexOf('.');

    if (lastDotIndex === -1) {
        // No dot (.) found in the filename
        return '';
    }

    const extension = filename.slice(lastDotIndex);
    return extension.toLowerCase(); // Convert to lowercase if needed
}