import * as React from 'react';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { ImageMapDto, TodoMainVovClient } from '../../../../ApiOld/Api/ApiServerVov';
import { Autocomplete, Box, keyframes, TextField } from '@mui/material';
import { AutocompleteProps } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

export interface IImageMapSelectProps {
  buildingId?: number;
  selectedMap: ImageMapDto | null;
  onChange: (map: ImageMapDto | null) => void;
  initMapId?: number;

}

export default function ImageMapSelect(props: IImageMapSelectProps) {
  const { t } = useTranslation();
  const { authorizedApi } = useApi(TodoMainVovClient);
  const [maps, setMaps] = React.useState<ImageMapDto[]>([]);
  const [visibleMaps, setVisibleMaps] = React.useState<ImageMapDto[]>([]);
  const refreshMaps = React.useCallback(async () => {
    const res = await authorizedApi.tasksMapsList();
    console.log('building id from map select', props.buildingId);
    if (props.buildingId)
      setMaps(res.imageMaps?.filter(x => x.buildingId === props.buildingId) ?? []);
    else
      setMaps(res.imageMaps ?? []);
  }, [authorizedApi, props.buildingId]);
  React.useEffect(() => {
    refreshMaps();
  }, [refreshMaps]);
  React.useEffect(() => {
    if (props.initMapId) {
      const map = maps.find(x => x.id === props.initMapId);
      if (map) {
        props.onChange(map);
      }
    }
  }, [props, maps]);
  React.useEffect(() => {
    console.log('fakticky', props.buildingId);
  }, [props]);
  React.useEffect(() => {
    setVisibleMaps(props.buildingId ? maps.filter(x => x.buildingId === props.buildingId) : maps);
  }, [maps, props.buildingId]);
  const flicker = keyframes`
  0%, 60% {
    border-color: #FFC600; /* Yellow */
  }
  60%, 80% {
    border-color: #000000; /* Black */
  }
  80%, 100% {
    border-color: #FFC600; /* Yellow */
  }
`;
  const flicker2 = keyframes`
    0% {
      border-color: #FFC600; /* Base color */
    }
    10% {
      border-color: #E6B200;
    }
    20% {
      border-color: #CC9E00;
    }
    30% {
      border-color: #B28B00;
    }
    40% {
      border-color: #997700;
    }
    50% {
      border-color: #806300;
    }
    60% {
      border-color: #664F00;
    }
    70% {
      border-color: #4C3B00;
    }
    80% {
      border-color: #332800;
    }
    90% {
      border-color: #1A1400;
    }
    100% {
      border-color: #000000; /* Black */
    }
  `;
  return (
    <Autocomplete
      sx={{
        minWidth: 200,
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          animation: props.selectedMap === null ? `${flicker} 1s infinite` : 'none',
        },
      }}
      options={visibleMaps}
      getOptionLabel={(option) =>
        option.buildingId
          ? `${option.blobName} (${option.buildingName})`
          : option.blobName ?? ''
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={props.selectedMap === null ? t('ChooseMap') : t('Map')}
        />
      )}
      value={props.selectedMap}
      onChange={(e, v) => {
        props.onChange(v);
      }}
    />

  );
}
