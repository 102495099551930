
import { useTranslation } from 'react-i18next';



import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface ReallyLockUnlockDialogProps {
    open: boolean;
    onClose: (value: boolean) => void;
    locked: boolean;
}

export default function ReallyLockUnlockDialog(props: ReallyLockUnlockDialogProps) {
    const { open, onClose, locked } = props;
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => { onClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('diary.reallyLock')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {!locked && t('diary.confirmLockItem')/* 'Opravdu chcete zamknout? Odemknout záznam poté může jen uživatel se speciálním oprávněním.' */}
                        {locked && t('diary.reallyUnlock')/* 'Opravdu chcete odemknout? Záznam můžete kdykoli znovu zamknout.' */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onClose(false) }}>{t('noL')}</Button>
                    <Button onClick={() => { onClose(true); }} autoFocus>
                        {t('yesL')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
}