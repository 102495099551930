import { Check, Close, Save } from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GetProductCategoryDataDetailResponse } from '../../../../ApiOld/Api/ApiServerVov';
import SplitButton from '../../../Shared/SplitButton';

export interface ProductCategoryMenuButtonsProps {
    productCategoryData: Partial<GetProductCategoryDataDetailResponse> | null;
    saveDone: boolean;
    error: number;
    saveProductCategory: (close: boolean) => void;
}

export default function ProductCategoryMenuButtons(props: Readonly<ProductCategoryMenuButtonsProps>) {
    const { productCategoryData, saveDone, error, saveProductCategory } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        if (saveDone)
            setSaving(false);
    }, [saveDone]);

    return (
        <Stack spacing={2} direction={'row'} mr={2}>
            {saveDone && !saving && !error && <Check color='success' />}
            {saveDone && !saving && error !== 0 && <Close color='error' />}
            {!saving && <SplitButton selectedIndexInput={0} disabled={!productCategoryData?.name || productCategoryData.name.length < 1} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                startIconSplitButton={<Save />}

                onMenuClick={(e) => {
                    if (e === 0) {
                        setSaving(true);
                        saveProductCategory(false);
                    }
                    else if (e === 1) {
                        setSaving(true);
                        saveProductCategory(true);
                    }
                }}></SplitButton>}
            {saving && <CircularProgress color='primary' />}
        </Stack>
    );
}
