import React from 'react';
import TextField from '@mui/material/TextField';

interface IntegerInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
}

const IntegerInput = (props: Readonly<IntegerInputProps>) => {
  const { value, onChange, label } = props;

  // Handler to restrict input to numbers only
  const handleKeyPress = (event) => {
    const charCode = event.charCode;

    // Allow numbers only
    if (charCode >= 48 && charCode <= 57) {
      return;
    }

    // Prevent other input
    event.preventDefault();
  };

  // Handler to validate the input value
  const handleChange = (event) => {
    // const inputValue = event.target.value;

    // // Validate the input to be an integer
    // const validNumberRegex = /^\d*$/;

    // if (validNumberRegex.test(inputValue)) {
    onChange(event.target.value);
    // }
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      size='small'
      sx={{ mt: 2 }}
      label={label}
      value={value}
      onChange={handleChange}
      inputProps={{
        inputMode: 'numeric', // Show numeric keypad on mobile
        pattern: '[0-9]*' // Use pattern for basic validation
      }}
      onKeyPress={handleKeyPress}
    />
  );
};

export default IntegerInput;
