import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

interface ReusableTabsProps {
    tabs: any
    tabsMarginX?: number | undefined
    variant?: 'standard' | 'scrollable' | 'fullWidth' | undefined;
    onSelectedTabChange?: (index: number) => void;
}

const ReusableTabs = (props: ReusableTabsProps) => {
    const { tabs, variant, tabsMarginX, onSelectedTabChange } = props;
    const [value, setValue] = useState(0);
    const filteredTabs = tabs.filter(Boolean);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (onSelectedTabChange) {
            onSelectedTabChange(newValue);
        }
    };

    return (
        <Box sx={{ width: '100%', marginTop: '0px', backgroundColor: '#f5f5f5', zIndex: 5000, height: '40px' }}>
            <Tabs
                variant={variant}
                value={value}
                onChange={handleChange}
                aria-label='resuable tabs'
                allowScrollButtonsMobile
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{
                    marginX: tabsMarginX,
                    "& button": {
                        color: 'black',
                        minHeight: '40px',
                        fontWeight: 'normal',
                    },
                    "& button.Mui-selected": {
                        color: '#11181f',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '10px 10px 0px 0px',
                        fontWeight: 'bold',
                    },
                    ".MuiTabs-scrollButtons.Mui-disabled": {
                        opacity: 0.3,
                    }
                }}
                scrollButtons={true}
            >
                {filteredTabs.map((tab, index) => (
                    <Tab
                        disabled={tab.disabled}
                        key={'key' + index}
                        icon={tab.icon}
                        label={<span style={{ whiteSpace: 'nowrap' }}>{tab.label}</span>}
                        {...(tab.icon ? { iconPosition: 'start' } : {})}
                        onClick={tab.onClick}
                    />
                ))}
            </Tabs>
            {filteredTabs.map((tab, index) => (
                <Box
                    role="tabpanel"
                    hidden={value !== index}
                    key={index}
                    id={`tabpanel-${index}`}
                    aria-labelledby={`tab-${index}`}
                    sx={{ mt: -1, marginX: 0 }}
                >
                    {value === index &&
                        tab.panel
                    }
                </Box>
            ))}
        </Box>
    );
};

export default ReusableTabs;