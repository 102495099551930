import { create } from 'zustand'
import { GetMaterialsForRecordTableDTO } from '../ApiOld/ApiServerVov'

interface DiaryMaterialsCopyState {
    materialsStore?: GetMaterialsForRecordTableDTO[] | undefined
    loadMaterialsStore: (mats: GetMaterialsForRecordTableDTO[] | undefined) => void
}
export const useDiaryMaterialsCopyStore = create<DiaryMaterialsCopyState>()((set) => ({
    materialsStore: undefined,
    loadMaterialsStore: (by) => set((state) => ({ materialsStore: by })),
}))