import { Check, Clear, Save } from '@mui/icons-material';
import { Button, TextField, Grid, Box, useMediaQuery, useTheme, Select, MenuItem, FormControl, Stack } from '@mui/material';
import { AutoDisabler, ValidationGroup } from 'mui-validate';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { WealthDTO, WealthRentalDTO, FileParameter, WealthRentalItemDTO } from '../../../ApiOld/ApiServerVov';
import BuildingSelect from '../../Shared/BuildingSelect';
import EmployeeSelect from '../../Shared/EmployeeSelect';
import delay from '../../Shared/Functions/Delay';
import InitSfRTBLocale from '../../../Localization/SFRichEditLanguageIntializer';
import MuiDatagrid from '../../Shared/MuiDatagrid';
import { GridColDef, GridRowModes, GridRowModesModel, GridRowSelectionModel, GridRowsProp, GridSlotsComponent, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers";
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import moment from 'moment';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

InitSfRTBLocale();

export default function WealthRental() {
    const param = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [wealthId, setWealthId] = useState<number>(0);
    const [rentalId, setRentalId] = useState<number>(0);
    const [rentalData, setRentalData] = useState<WealthRentalDTO | null>(new WealthRentalDTO());
    const [saveTriggered, setSaveTriggered] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [newId, setNewId] = useState<number>(-1);
    const [lendWealthDTO, setLendWealthDTO] = useState<WealthDTO[] | null>(null);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [signatureChanged, setSignatureChanged] = React.useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(location.pathname.includes('/rental/edit/'));
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(1100));
    const signRef = useRef<SignatureComponent>(null);
    const { t, i18n } = useTranslation();

    const reloadData = useCallback(() => {
        new APIOldWealthClient().GetWealthRental(wealthId, rentalId).then((data) => {
            setRentalData(data?.wealthRental || null);
            setRows(data?.wealthRental?.items || []);
        });
        new APIOldWealthClient().GetWealthsForAdminUser(false).then((data) => {
            if (data.wealths !== undefined && data.wealths !== null) {
                setLendWealthDTO(data.wealths);
            }
        });
    }, [rentalId, wealthId]);

    useEffect(() => {
        const id = parseInt(param['id']!);
        setIsEdit(location.pathname.includes('/rental/edit/'));
        if (!isNaN(id)) {
            if (location.pathname.includes('/rental/edit/')) {
                setRentalId(id);
            } else {
                setWealthId(id);
            }
        }
    }, [param, location.pathname]);
    useEffect(() => {
        if (rentalId !== 0 || wealthId !== 0)
            reloadData();
    }, [rentalId, wealthId, reloadData]);

    useEffect(() => {
        if (saved) delay(1000).then(() => { setSaved(false) })
    }, [saved]);

    const save = useCallback(() => {
        rentalData && new APIOldWealthClient().SaveWealthRental(new WealthRentalDTO({ ...rentalData, items: rows.map((row) => { return new WealthRentalItemDTO(row) }) })).then((e) => {
            if (e.ok === true) {
                setSaved(true);
                !isEdit && navigate(
                    {
                        pathname: `/${i18n.language}/${param['domain']!}/rental/edit/${e.id}`,
                    },
                );
            }
        });
    }, [i18n.language, param, rentalData, navigate, rows, isEdit]);

    useEffect(() => {
        if (saveTriggered && rentalData !== null) {
            save();
            setSaveTriggered(false);
        }
    }, [rentalData, saveTriggered, save]);

    const processRowUpdate = (newRow) => {
        setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'wealthId',
            headerName: t('Majetek'),
            type: 'singleSelect',
            editable: true,
            flex: 1,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: lendWealthDTO ? lendWealthDTO.map((wealth) => ({ code: wealth.id, name: `${wealth.code} - ${wealth.name} ${wealth.wealthState === "available" ? "[" + t('wealth.available') + "]" : wealth.wealthRentalType === 0 ? "[" + t('Internal') + "]" : "[" + t('External') + "]"}` })) : [],
        },
        {
            field: 'datetimeTo',
            valueFormatter: (value?: Date) => {
                if (value == null) {
                    return '';
                }
                return `${moment(value).format('DD.MM.yyyy')}`;
            },
            headerName: t('LoanedTo'),
            type: 'date',
            editable: true,
            minWidth: 150,
        }
    ]

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const newRow = new WealthRentalItemDTO({
                id: newId,
                wealthId: 0,
                isDeleted: false,
                datetimeTo: new Date(),
            });
            setRows((oldRows) => [...oldRows, newRow]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [newId]: { mode: GridRowModes.Edit },
            }));
            setNewId((prevId) => prevId - 1);
        };

        const handleDeleteClick = () => {
            const selectedIDs = new Set(selectionModel);
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id !== undefined && selectedIDs.has(row.id)
                        ? { ...row, isDeleted: true } as WealthRentalItemDTO
                        : row
                )
            );
        }
        return (
            <GridToolbarContainer>
                <Stack sx={{ width: '100%', alignItems: 'center', padding: 1.5, borderBottom: '1px solid lightgray' }} spacing={2} direction={'row-reverse'}>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={selectionModel.length === 0}
                    >
                        {t('Delete')}
                    </Button>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<AddIcon />}
                        onClick={handleClick}>
                        {t('Add')}
                    </Button>
                </Stack>
            </GridToolbarContainer>
        );
    }

    if (rentalData === null || (rentalId === 0 && wealthId === 0))
        return <div>{t('loading...')}</div>;
    return (

        <div>
            <ValidationGroup>
                <Box sx={{ p: 4 }}>
                    <div style={{ display: 'flex' }}>
                        <AutoDisabler>
                            <Button variant='contained' size='small' sx={{ margin: '8px' }} startIcon={<Save />} onClick={() => setSaveTriggered(true)}>
                                {t('save')}
                            </Button>
                        </AutoDisabler>
                        {saved && <Check sx={{ marginTop: '10px' }} color='success' />}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid component={'form'} container direction={'row'} spacing={1}>
                            <Grid item xs={3}>
                                <h4 style={{ borderBottom: '1px solid gray', margin: '8px' }}>{t('Info')}</h4>
                                <Grid container direction={'column'} spacing={1} margin={0}>
                                    <TextField
                                        sx={{ margin: '8px' }}
                                        size='small'
                                        label={t('Name')}
                                        helperText={!rentalData?.name ? t('FieldRequired') : ''}
                                        value={rentalData?.name || ''}
                                        error={!rentalData?.name}
                                        onChange={(e) => {
                                            setRentalData((prevState) => ({ ...prevState, name: e.currentTarget.value } as WealthRentalDTO))
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <Select
                                            value={Number(rentalData?.type)}
                                            label={t('RentalType')}
                                            sx={{ margin: '8px' }}
                                            size="small"
                                            onChange={(e) => {
                                                setRentalData({ ...rentalData, type: e.target.value } as WealthRentalDTO)
                                            }}>
                                            <MenuItem value={0}>{t('Internal')}</MenuItem>
                                            <MenuItem value={1}>{t('External')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {rentalData?.type === 0 &&
                                        <div>
                                            <div style={{ margin: '8px' }}>
                                                <BuildingSelect label={t('Projekt')} selectedProjectId={rentalData?.buildingId} onChange={(data) => {
                                                    setRentalData({ ...rentalData, buildingId: data?.id ?? undefined } as WealthRentalDTO)
                                                }} />
                                            </div>
                                            <div style={{ margin: '8px' }}>
                                                <EmployeeSelect selectedEmployeeId={rentalData?.employeeId} label={t('Employee')} onChange={(id) => { setRentalData({ ...rentalData, employeeId: id.id } as WealthRentalDTO) }} />
                                            </div>
                                            <TextField
                                                sx={{ margin: '8px' }}
                                                size='small'
                                                label={t('Advance')}
                                                type='number'
                                                value={rentalData?.advance}
                                                onChange={(e) => {
                                                    let num = parseInt(e.currentTarget.value);
                                                    if (!isNaN(num)) {
                                                        setRentalData({ ...rentalData, advance: num } as WealthRentalDTO)
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </Grid>

                            </Grid>
                            <Grid item xs={6}>
                                <h4 style={{ margin: '8px 0px 0px 8px', }}>{t('ItemsForHire')}</h4>
                                <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '10px', overflow: 'hidden' }}>
                                    <MuiDatagrid
                                        editMode='row'
                                        columns={columns}
                                        rows={rows.filter((row) => !row.isDeleted)}
                                        sx={{ borderRadius: '10px' }}
                                        checkboxSelection
                                        onRowSelectionModelChange={(ids) => {
                                            setSelectionModel(ids);
                                        }}
                                        rowModesModel={rowModesModel}
                                        onRowModesModelChange={(newModel) => { setRowModesModel(newModel) }}
                                        processRowUpdate={processRowUpdate}
                                        slots={{
                                            toolbar: EditToolbar as GridSlotsComponent['toolbar'],
                                        }}
                                        slotProps={{
                                            toolbar: {
                                                setRows,
                                                setRowModesModel,
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <h4 style={{ margin: '8px', borderBottom: '1px solid gray' }}>{t('DateOfLoan')}</h4>
                                <div style={{ margin: '8px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('DateFrom')}
                                            inputFormat="DD.MM.YYYY"
                                            value={rentalData?.datetimeFrom}
                                            onChange={(newValue) => {
                                                setRentalData({ ...rentalData, datetimeFrom: newValue } as WealthRentalDTO)
                                            }}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <Box sx={{ margin: '8px' }} >
                                    {rentalData?.signatureImage ?
                                        <img
                                            alt=''
                                            width='100%'
                                            height={'200px'}
                                            style={{ backgroundColor: '#F9F9F9', marginTop: '8px' }}
                                            src={"https://" + param['domain'] + ".vimovsem.cz/files/signatures/" + rentalData.signatureImage}
                                        />
                                        :
                                        <SignatureComponent change={(e) => {
                                            setSignatureChanged(true);
                                        }} style={{
                                            backgroundColor: '#F9F9F9',
                                            marginTop: '8px',
                                            height: '200px',
                                            width: '100%',
                                        }}
                                            ref={signRef}></SignatureComponent>
                                    }
                                    <div style={{ margin: '0px 10px' }}>
                                        <Button sx={{ width: biggerThan700 ? '106px' : '32px', margin: '8px' }} disabled={!signatureChanged && (rentalData.signatureImage === undefined || rentalData.signatureImage === "")} startIcon={<Clear />} variant='contained' size='small' onClick={(e) => {
                                            if (signRef.current?.clear != null)
                                                signRef.current!.clear();
                                            else
                                                setRentalData({ ...rentalData, signatureImage: "" } as WealthRentalDTO);
                                            setSignatureChanged(false);
                                        }}>{biggerThan700 && t('Cancel')}</Button>
                                        {
                                            !rentalData.signatureImage && <Button sx={{ width: biggerThan700 ? '106px' : '32px', margin: '8px' }} disabled={!signatureChanged}
                                                startIcon={<Check />} color='success' variant='contained' size='small' onClick={(e) => {
                                                    setSignatureChanged(false);
                                                    var blob = signRef.current!.saveAsBlob();
                                                    let fileParameter: FileParameter = { data: blob, fileName: 'sign.png' };
                                                    new APIOldWealthClient().SaveWealthRentalSignature(fileParameter).then((e) => {
                                                        if (e.ok === true) {
                                                            setRentalData({ ...rentalData, signatureImage: e.signature } as WealthRentalDTO);
                                                        }
                                                    });
                                                }}>{biggerThan700 && t('Confirm')}</Button>
                                        }
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </ValidationGroup>
        </div>
    );
};