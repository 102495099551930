import { Box, Button, Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowModel, GridRowModes, GridRowModesModel, GridRowSelectionModel, GridRowsProp, GridSlots, GridToolbarContainer } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from "react-i18next";
import getFilesFromDropEvent from "../../Shared/GetFilesFromDropEvent";
import { InvoiceFileDTO } from "../../../ApiOld/ApiServerVov";
import MuiDatagrid from "../../Shared/MuiDatagrid";
import { ArrowForward } from "@mui/icons-material";

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

interface InvoiceFilesProps {
    files: InvoiceFileDTO[] | undefined;
    saveFiles: (files: InvoiceFileDTO[]) => void;
    permissions: any;
    onEditModeChange: (isEditing: boolean) => void;
}

export default function InvoiceFiles(props: InvoiceFilesProps) {
    const { files, saveFiles, permissions, onEditModeChange } = props;
    const [rows, setRows] = useState(files || []);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { t } = useTranslation();
    const [isSaveTriggered, setIsSaveTriggered] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (files) {
            setRows(files);
        }
    }, [files]);

    useEffect(() => {
        if (isSaveTriggered) {
            saveFiles(rows);
            setIsSaveTriggered(false);
        }
    }, [isSaveTriggered, rows, saveFiles]);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);

        const isEditing = Object.values(newRowModesModel).some(
            (rowMode) => rowMode.mode === 'edit'
        );
        onEditModeChange(isEditing);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isUpdated: true } as InvoiceFileDTO;
        setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
        setIsSaveTriggered(true);
        return updatedRow;
    };

    const handleUpload = useCallback(async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const content: any = await getFilesFromDropEvent(event.dataTransfer.items);
        const files = content.files;

        if (files && files.length > 0) {
            const updatedFiles: InvoiceFileDTO[] = [];
            for (const file of files) {
                let id: number;
                do {
                    id = Math.floor(Math.random() * 1000000);
                } while (rows.some((row) => row.id === id));

                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    const base64String = (reader.result as string).split(',')[1];

                    const newFile = new InvoiceFileDTO({
                        id: id,
                        name: file.name,
                        url: '',
                        isNew: true,
                        isUpdated: false,
                        isDeleted: false,
                        file: base64String,
                    });

                    updatedFiles.push(newFile);

                    if (updatedFiles.length === files.length) {
                        setRows((oldRows) => [...oldRows, ...updatedFiles]);
                        setIsSaveTriggered(true);
                    }
                };

                reader.onerror = (error) => {
                    console.error("Error reading file:", error);
                };
            }
        }
    }, [rows]);

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        function handleClick() {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.multiple = true;

            fileInput.onchange = (event: any) => {
                const selectedFiles = Array.from(event.target.files as FileList);
                if (selectedFiles.length > 0) {
                    const updatedFiles: InvoiceFileDTO[] = [];

                    selectedFiles.forEach((file: File) => {
                        let id: number;
                        do {
                            id = Math.floor(Math.random() * 1000000);
                        } while (rows.some((row) => row.id === id));

                        const reader = new FileReader();
                        reader.readAsDataURL(file);

                        reader.onload = () => {
                            const base64String = (reader.result as string).split(',')[1];

                            const newFile = new InvoiceFileDTO({
                                id: id,
                                name: file.name,
                                url: '',
                                isNew: true,
                                isUpdated: false,
                                isDeleted: false,
                                file: base64String,
                            });

                            updatedFiles.push(newFile);

                            if (updatedFiles.length === selectedFiles.length) {
                                setRows((oldRows) => [...oldRows, ...updatedFiles]);
                                setIsSaveTriggered(true);
                            }
                        };

                        reader.onerror = (error) => {
                            console.error("Error reading file:", error);
                        };
                    });
                }
            };

            fileInput.click();
        }

        const handleDeleteClick = useCallback(() => {
            const selectedIDs = new Set(selectionModel);
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id !== undefined && selectedIDs.has(row.id)
                        ? { ...row, isDeleted: true } as InvoiceFileDTO
                        : row
                )
            );
            setIsSaveTriggered(true);
        }, [setRows]);

        return (
            <GridToolbarContainer>
                <Stack sx={{ width: '100%', alignItems: 'center', padding: 1.5, borderBottom: '1px solid lightgray' }} spacing={2} direction={'row-reverse'}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={
                            !selectionModel ||
                            selectionModel.length === 0 ||
                            !permissions ||
                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetRelItemValues)
                        }
                    >
                        {t('Delete')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleClick}
                        disabled={
                            !permissions ||
                            (!permissions.buildingBillsEdit)
                        }
                    >
                        {t('Add')}
                    </Button>
                </Stack>
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('Name'),
            flex: 1,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            maxWidth: 40,
            minWidth: 40,
            getActions: (params) => [
                <GridActionsCellItem
                    label="Open"
                    icon={
                        <Tooltip title={t('ShowFile')} placement="left">

                            <ArrowForward width={20} height={20} />
                        </Tooltip>
                    }
                    onClick={() => {
                        const row = params.row as InvoiceFileDTO;
                        window.open(row.url, '_blank');
                    }}
                />
            ],
        }
    ];

    return (
        <Box mt={5}
            onDragEnter={(e) => { e.stopPropagation(); setIsDragging(true); }}
            onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); }}
            onDragLeave={(e) => {
                setIsDragging(false);
            }}
            onDrop={handleUpload}
        >
            <h4>{t('Files')}</h4>
            <MuiDatagrid
                columns={columns}
                rows={rows.filter((row) => !row.isDeleted)}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                }}
                checkboxSelection
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </Box>
    );
}