import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { DeleteWealthRequest, EmployeeGroupsDTO, FileParameter, GetEmployeeGroupsNamesResponse, GetWealthDetailsResponse, GetWealthExpendituresResponse, GetWealthRentalResponse, GetWealthsForAdminUserResponse, MoveWealthsFromTrashRequest, SaveWealthDetailsRequest, SaveWealthDetailsResponse, SaveWealthExpendituresRequest, SaveWealthRentalRequest, SaveWealthRentalResponse, SaveWealthRentalSignatureResponse, WealthExpenditureDTO, WealthHistoryDTO, WealthRentalDTO } from "./ApiServerVov";

export class APIOldWealthClient extends ApiOldBaseHelper {
    public async GetWealthsForAdminUser(deleted: boolean): Promise<GetWealthsForAdminUserResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthsForAdminUser(deleted));
    }
    public async GetWealthDetails(id: number): Promise<GetWealthDetailsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthDetails(id));
    }

    public async GetEmployeeGroupsNames(): Promise<GetEmployeeGroupsNamesResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeeGroupsNames());
    }

    public async GetWealthExpenditures(id: number): Promise<GetWealthExpendituresResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthExpenditures(id));
    }

    public async SaveWealthDetails(data: Partial<GetWealthDetailsResponse>): Promise<SaveWealthDetailsResponse | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().saveWealthDetails(new SaveWealthDetailsRequest({
            id: data.id,
            name: data.name,
            description: data.description,
            isActive: data.isActive,
            code: data.code,
            serialNumber: data.serialNumber,
            showToEmps: data.showToEmps,
            manufacturer: data.manufacturer,
            priceA: data.priceA,
            priceB: data.priceB,
            priceC: data.priceC,
            priceForRent: data.priceForRent,
            datetimeImage: data.datetimeImage,
            gpsXImage: data.gpsXImage,
            gpsYImage: data.gpsYImage,
            employeeId: data.employeeId,
            buildingId: data.buildingId,
            warehouseId: data.warehouseId,
            employeeGroups: data.employeeGroups,
        })));
    }

    public async saveWealthExpenditures(wealthId: number, wealthExpenditures: WealthExpenditureDTO[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().saveWealthExpenditures(new SaveWealthExpendituresRequest({
            wealthId: wealthId,
            wealthExpenditures: wealthExpenditures
        }))).ok!;
    }

    public async deleteWealths(wealthsIds: number[]): Promise<boolean> {
        return ((await this.GetAdressedAuthRefreshableClient().deleteWealths(new DeleteWealthRequest({ wealthIds: wealthsIds }))).ok!);
    }
    public async MoveWealthsFromTrash(wealthsIds: number[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().moveWealthsFromTrash(new MoveWealthsFromTrashRequest({ deletedWealthsIds: wealthsIds }))).ok!;
    }

    public async GetEmployeeGroupsForAdminUser(): Promise<EmployeeGroupsDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeeGroupsForAdminUser()).employeeGroups;
    }

    public async GetWealthHistory(wealthId: number): Promise<WealthHistoryDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthHistory(wealthId)).wealthHistory;
    }

    public async GetWealthRental(wealthId: number, rentalId: number): Promise<GetWealthRentalResponse | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthRental(wealthId, rentalId));
    }

    public async SaveWealthRental(data: WealthRentalDTO): Promise<SaveWealthRentalResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveWealthRental(new SaveWealthRentalRequest({ wealthRental: data })));
    }

    public async SaveWealthRentalSignature(signature: FileParameter): Promise<SaveWealthRentalSignatureResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveWealthRentalSignature(signature));
    }
}