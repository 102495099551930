import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Validate, ValidationGroup } from 'mui-validate';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { AddImageCategoryRequest, RenameCategoryBuildingRequest, RenameImagesBuildingRequest, VovClient } from '../../../../ApiOld/ApiServerVov';

interface RenameCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm?: (id: number, name: string) => void;
    oldName: string;
    categoryId: number | undefined;
}
export default function RenameCategoryDialog(props: RenameCategoryDialogProps) {
    const { open, onClose, onConfirm, oldName, categoryId } = props;

    const [name, setName] = React.useState<string>();
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
    const [openLoginError, setOpenLoginError] = React.useState(false);

    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
        console.log(oldName, 'oldName');
        setName(oldName);
    }, [oldName]);

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="create-category-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('rename')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div>
                        <Validate name='name-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                            <TextField sx={{ margin: '12px', width: '526px' }} label={t('name')}
                                size='small'
                                name='userName'
                                variant='outlined'
                                value={name}
                                error={!name}
                                onChange={(e) => { setName(e.target.value) }} />
                        </Validate>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled || name === undefined || name === ''} onClick={() => {
                                if (name && categoryId) {
                                    setButtonDisabled(true);
                                    authorizedApi.renameCategoryBuilding(new RenameCategoryBuildingRequest({ newName: name, categoryId: categoryId, oldName: oldName })).then((r) => {
                                        setButtonDisabled(false);
                                        if (r.ok) {
                                            if (onConfirm)
                                                onConfirm(categoryId, name);

                                            setName(undefined);
                                            onClose();
                                        }
                                        else {
                                            setOpenLoginError(true);
                                        }
                                    });
                                }
                            }}>{t('save')}</Button>
                        </div>
                    </div>
                </ValidationGroup>
                <div className="create-form-popup">
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('files.categoryAlreadyExists')}</AlertTitle>
                            {t('files.unableToCreateCat')}
                        </Alert>
                    </Snackbar>
                </div>
            </DialogContent>
        </Dialog>
    )
}
