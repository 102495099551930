import { Box, Grid } from "@mui/material";
import { GetBuildingDataDetailResponse } from "../../../ApiOld/ApiServerVov";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useBuildingsPermissionContext } from "../../../Contexts/Permissions/BuildingsPermissionContext";
import ProjectInvestorsComponent from "./Components/ProjectInvestorsComponent";

interface ProjectInvestorsProps {
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectInvestors(props: ProjectInvestorsProps) {
    const { t } = useTranslation();
    const { projectData, setProjectData } = props;

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    return (
        projectData === null ? <div>{t('Loading')}</div> :
            <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <Box sx={{ marginTop: '8px' }}>
                    <Grid container spacing={1} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                        <ProjectInvestorsComponent investors={projectData.assignedInvestors ?? []} setProjectData={setProjectData} />
                    </Grid>
                </Box>
            </Box>
    );
}