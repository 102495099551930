
import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, useTheme } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { GetDailyRecordsForUserDTO, GetDiariesForUserDTO } from '../../../../ApiOld/ApiServerVov';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';

interface CreateDiaryRecordProps {
    open: boolean;
    onClose: (refresh: boolean, newId?: GetDiariesForUserDTO) => void;
    diaryRecords: GetDailyRecordsForUserDTO[] | null;
    isSubdodavatel?: boolean;
    isInvestor?: boolean;
}

export default function CreateDiaryRecord(props: CreateDiaryRecordProps) {
    const { t, i18n } = useTranslation();
    const { open, onClose, diaryRecords, isSubdodavatel, isInvestor } = props;
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [changed, setChanged] = useState<boolean>(false);
    const [dailyRecordsLocal, setDailyRecordsLocal] = useState<GetDailyRecordsForUserDTO[] | null>(null);
    const [diaries, setDiaries] = useState<GetDiariesForUserDTO[]>([]);
    const [insertedDiaries, setInsertedDiaries] = useState<string[]>([]);
    const [selectedDiary, setSelectedDiary] = useState<GetDiariesForUserDTO | null>();
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    useEffect(() => {
        setDailyRecordsLocal(diaryRecords);
    }, [diaryRecords,]);

    useEffect(() => {
        setSelectedDate(null);
        setSelectedDiary(null);
        setChanged(false);
        setInsertedDiaries([]);
        if (open)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetDiaries(false, false).then((d) => {
                setDiaries(d);
            })
    }, [open]);
    function save(close: boolean) {
        if (selectedDiary && selectedDate)
            new ApiDiaryBaseHelper(isSubdodavatel == undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).createDiaryRecord(selectedDiary.id!, selectedDate).then((e) => {
                if (typeof e === "number") {
                    setDailyRecordsLocal([...dailyRecordsLocal!, new GetDailyRecordsForUserDTO({ date: selectedDate, diaryId: selectedDiary.id })]);

                    setInsertedDiaries([...insertedDiaries, t('diary.diaryRecordCreated') + new Date(selectedDate).toLocaleDateString(i18n.language) + " " + t('diary.onDiary') + selectedDiary.buildingName]);
                    setSelectedDate(null);
                    if (close) {
                        onClose(true, new GetDiariesForUserDTO({ id: e }));
                    }
                    else setChanged(true);
                }
            });
    }
    return (

        <Dialog
            disablePortal
            maxWidth='lg'
            open={open}
            onClose={(e) => { onClose(changed) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('diary.createDailyRecord')}
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                    <IconButton sx={{ marginRight: '-12px', marginTop: '-8px' }} onClick={(e) => onClose(changed)} >
                        <Close />
                    </IconButton>
                </div></DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div style={{ minHeight: '300px', minWidth: biggerThan700 ? '500px' : '200px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Validate name={'email-signup-validate-input'} required>
                                <div style={{ margin: '10px 20px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        onChange={(e, v) => {
                                            setSelectedDiary(v);
                                            // setSelectedProject(v);
                                            // onChange(v);
                                        }}
                                        getOptionLabel={o => o !== null ? o!.name! : ""}
                                        value={selectedDiary}
                                        options={diaries}

                                        fullWidth={biggerThan700}
                                        sx={{ minWidth: 288 }}
                                        renderInput={(params) => <TextField  {...params} label={t('diary.diary')}

                                        />}
                                    />

                                </div>
                            </Validate>
                            <div style={{ margin: '10px 20px' }}>
                                <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled={selectedDiary === null}
                                        shouldDisableDate={(d) => {
                                            const currentDate = d.toISOString().split("T")[0];
                                            // if (selectedDiary)
                                            //     console.log(selectedDiary.buildingId, diaryRecords);
                                            if (selectedDiary && dailyRecordsLocal && (dailyRecordsLocal.some(x => x.date!.toISOString().split("T")[0] === currentDate && x.diaryId === selectedDiary.id)))
                                                return true;
                                            else return false;
                                        }}
                                        inputFormat="DD.MM.YYYY"
                                        value={selectedDate}
                                        onChange={(e, v) => {
                                            if (e)
                                                setSelectedDate(e);
                                        }}

                                        renderInput={
                                            (params) => <TextField label=''
                                                fullWidth
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        color: "black",
                                                        fontSize: biggerThan700 ? 13 : 9,
                                                        borderColor: theme.palette.primary.main,
                                                        backgroundColor: 'white',
                                                        borderWidth: '2px',
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: theme.palette.primary.main,
                                                        fontSize: biggerThan700 ? 13 : 9,
                                                        borderWidth: '2px'
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: theme.palette.primary.main,
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderWidth: '2px'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: theme.palette.primary.main,
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderWidth: '2px',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: theme.palette.primary.main,
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderWidth: '2px'
                                                        },
                                                    }
                                                }}

                                                size='small' {...params} />
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                            {(selectedDate === null || selectedDiary === null) && <p style={{ fontWeight: 'bold', marginLeft: '24px' }}>{t('diary.fillInBothFields')}</p>}
                            <div style={{ maxHeight: '70px', overflowY: 'scroll' }}>
                                {insertedDiaries.map((item) => {
                                    return <div>

                                        <p>{item}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div style={{
                            position: 'absolute', bottom: '0', width: '90%', display: 'flex', flexDirection: 'column',
                            justifyContent: 'center', alignContent: 'center', alignItems: 'center'
                        }}>
                            <Button disabled={selectedDate === null || selectedDiary === null} sx={{ width: '200px', margin: '0px' }} variant='outlined' onClick={(e) => { save(false) }}>{t('save')}</Button>
                            <Button disabled={selectedDate === null || selectedDiary === null} sx={{ width: '200px', margin: '12px' }} variant='contained' onClick={(e) => { save(true) }}>{t('saveAndClose')}</Button>
                        </div>
                    </div>

                </ValidationGroup>

            </DialogContent></Dialog>);
}