import { Close, CloseFullscreen, Fullscreen } from '@mui/icons-material';
import { Button, Dialog, DialogTitle, IconButton, Popover, styled, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import validator from 'validator';
import { APIVovSubcontractorHelper } from '../../ApiOld/ApiSubcontractorOldClient';


export interface SubcontractorPasswordChangeSceneProps {
    open: boolean;
    onClose: (value: string) => void;
    onRefresh: (changed: boolean) => void;
}


export default function SubcontractorPasswordChangeScene(props: SubcontractorPasswordChangeSceneProps) {
    const { onClose, open } = props;
    const { t, i18n } = useTranslation();
    const [oldPass, setOldPass] = React.useState('');
    const [newPass, setNewPass] = React.useState('');
    const [newPassCheck, setNewPassCheck] = React.useState('');
    const [isBadHeslo, setIsBadHeslo] = React.useState(false);

    async function updateSubcontractor(): Promise<boolean> {
        if (newPass.length > 0 && newPass === newPassCheck) {
            let t = await (new APIVovSubcontractorHelper()).UpdateSubcontractorPassword(oldPass, newPass);
            if (t.ok) {

                setDefaultState();
                onClose("");
                return true;
            }
            else
                setIsBadHeslo(true);
        }
        return false;
    }

    function setDefaultState() {
        setOldPass('');
        setNewPass('');
        setNewPassCheck('');
        setIsBadHeslo(false);
    }

    function onSaveClicked() {

        let save = updateSubcontractor().then((saved) => {
            /*  if (saved) {
                 onClose("");
             } */
        })
    }

    const handleKeyDown = async (event: any) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && !(event.shiftKey) && charCode === 's') {
            // console.log("CTRL+S Pressed");
            event.preventDefault();
            updateSubcontractor();
            // onClose("");

        }
        if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && charCode === 's') {
            // console.log("CTRL+SHIFT+S Pressed");
            event.preventDefault();
            if (await updateSubcontractor())
                onClose("");
        }
    }


    const [fullScreen, setFullScreen] = useState(false);
    function dialogClosed() { setDefaultState(); onClose(""); }

    return (
        <Dialog onKeyDown={handleKeyDown} maxWidth="lg" fullScreen={fullScreen} open={open} onClose={onClose}>
            <DialogTitle height='40px' sx={{ zIndex: "28" }}>
                <div style={{ backgroundColor: '#F2F2F2', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('login.changeOfPassword')} </Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                        {/* {biggerThan600 && task!.date && <div>
                            <Typography variant='body1' sx={{ marginRight: '12px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString() : ""} </Typography>
                        </div>} */}
                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => {
                                setFullScreen(!fullScreen);
                            }} >
                                {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                            </IconButton>
                            <IconButton onClick={dialogClosed} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F2F2F2' }} >
                <ValidationGroup>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>
                            <Validate name='oldpass-investor-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    size='small' type={'password'}
                                    error={isBadHeslo}
                                    fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => { setIsBadHeslo(false); setOldPass(e.target.value) }}
                                    label={t('login.oldPassword')} value={oldPass} />
                            </Validate>
                        </div>

                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>
                            <Validate name='newpass-investor-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    size='small' type={'password'}
                                    error={newPass !== null && newPass !== undefined && newPass !== '' && newPass !== newPassCheck}
                                    fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => setNewPass(e.target.value)}
                                    label={t('login.newPassword')} value={newPass} />
                            </Validate>
                        </div>

                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>
                            <Validate name='newpasscheck-investor-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    size='small' type={'password'}
                                    error={newPassCheck !== null && newPassCheck !== undefined && newPassCheck !== '' && newPass !== newPassCheck}
                                    fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => setNewPassCheck(e.target.value)}
                                    label={t('login.newPasswordCheck')} value={newPassCheck} />
                            </Validate>
                        </div>

                        <div style={{ height: '1px', marginTop: '4px', backgroundColor: '#F2F2F2' }}></div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                <AutoDisabler>
                                    <Button onClick={() => { onSaveClicked() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>{t('saveAndClose')}</Button>
                                </AutoDisabler>
                            </div>

                        </div>
                    </div>
                </ValidationGroup>
            </div >
        </Dialog >
    );
};
