import { useEffect, useRef, useState } from "react";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { FileParameter, GetSignaturesForRecordResponse } from "../../../../../ApiOld/ApiServerVov";
import { Box, Button, CircularProgress, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SignatureComponent } from "@syncfusion/ej2-react-inputs";
import { useTranslation } from "react-i18next";
import { Check, Clear, EditOutlined, PictureAsPdf } from "@mui/icons-material";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface SignaturesWidgetSceneProps {
    recordId?: number;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    onSigned?: () => void;
    isLocked?: boolean;
    refresh?: boolean;
    onRefreshed?: () => void;
    onSigniClicked?: () => void;
}

export default function SignaturesWidgetScene(props: SignaturesWidgetSceneProps) {
    const [signatures, setSignatures] = useState<GetSignaturesForRecordResponse | null>(null);
    const [signatureChanged, setSignatureChanged] = useState<boolean>(false);
    const [uploadingSignedPDF, setUploadingSignedPDF] = useState<boolean>(false);
    const [signedPDFPath, setSignedPDFPath] = useState<string | undefined>(undefined);
    const { recordId, isInvestor, onSigned, isLocked, isSubdodavatel, refresh, onRefreshed } = props;
    const { permissions, setRefreshDailyRecordsNeeded } = useDiaryTabsContext();
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(1100));
    const signRef = useRef<SignatureComponent>(null);
    const { t, i18n } = useTranslation();
    function refreshSignatures() {
        if (recordId !== undefined)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetSignaturesForRecord(recordId).then((data) => {
                if (data !== undefined) {
                    setSignatures(data); setSignedPDFPath(data.digitalSignaturePath);
                }

                if (onRefreshed)
                    onRefreshed();
            });
    }
    useEffect(() => {
        refreshSignatures();
    }, [recordId]);
    useEffect(() => {
        if (refresh === true)
            refreshSignatures();
    }, [refresh]);
    async function uploadSignedPDF(files: FileList) {
        if (recordId) {
            setUploadingSignedPDF(true);


            let fileParameter: FileParameter = { data: files.item(0)!, fileName: files.item(0)!.name };

            let a = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).UploadDigitallySignedPDF(fileParameter, recordId);

            if (a.ok && a.url) {
                setUploadingSignedPDF(false);
                setSignedPDFPath(a.url);
                setRefreshDailyRecordsNeeded(true);
            }
        }

    }
    const inputFile = useRef<HTMLInputElement | null>(null);

    return (
        <Box sx={{ marginX: '40px', background: 'white', padding: '30px', borderRadius: '15px', minHeight: 500 }}>
            <input
                accept="application/pdf"
                onChange={(e) => {
                    if (e.target && e.target.files && e.target.files.length === 1)
                        uploadSignedPDF(e.target.files);

                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                {signedPDFPath !== undefined && signedPDFPath !== null && !uploadingSignedPDF && (
                    <Tooltip title={t('diary.DigitallySigned')}>
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (signedPDFPath !== undefined) {
                                    const link = document.createElement('a');
                                    link.href = signedPDFPath;
                                    link.target = '_blank';
                                    document.body.appendChild(link);
                                    link.click();
                                    try {
                                        document.body.removeChild(link);
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                            }}
                            style={{
                                cursor: 'pointer',
                                width: '20px',
                                height: '20px',
                                background: theme.palette.success.main,
                                borderRadius: '20px',
                                marginLeft: '20px',
                                marginRight: '10px',
                                boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Check style={{ width: '16px', height: '16px', color: 'white' }} />
                        </div>
                    </Tooltip>
                )}
                {uploadingSignedPDF && <CircularProgress sx={{ marginLeft: '20px' }} size={20} />}
                {signatures !== null && signatures !== undefined && signatures.signatures !== undefined && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {signatures.signatures.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    marginTop: '2px',
                                    marginLeft: '8px',
                                    backgroundColor: item.isInvestor
                                        ? theme.palette.success.main
                                        : item.isMySignature
                                            ? theme.palette.primary.main
                                            : 'lightgray',
                                    padding: '4px 10px',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: item.isInvestor ? 'white' : theme.palette.secondary.main,
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {item.userName}
                                </Typography>
                            </div>
                        ))}
                    </div>
                )}
            </div>




            {(isLocked === undefined || isLocked === false) && <Tooltip title={t('diary.uploadDigitallySignedPDF')}>
                <Button variant='contained' disabled={uploadingSignedPDF} size='small' style={{ height: '24px', margin: '8px', padding: '16px' }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        inputFile.current!.click();
                    }}
                    startIcon={<PictureAsPdf />} >{biggerThan700 ? t('diary.uploadDigitallySignedPDF') : ''}</Button>
            </Tooltip>}
            {permissions && permissions.hasSigni && <Tooltip title={t('diary.sign')}>
                <Button variant='contained' disabled={uploadingSignedPDF} size='small' style={{ height: '24px', margin: '8px', padding: '16px' }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (props.onSigniClicked)
                            props.onSigniClicked();
                    }}
                    startIcon={<EditOutlined />} >{biggerThan700 ? t('diary.tiskSigni') : ''}</Button>
            </Tooltip>}
            {recordId !== undefined &&
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {signatures === null || signatures === undefined || signatures.signedByLoggedIn === undefined || signatures.signedByLoggedIn === false &&
                        <div style={{ margin: '6px', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: 'lightgray', padding: '8px', borderRadius: '6px' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '13px', margin: '0px 20px' }}>{t('diary.yourSignature')}</Typography>
                            <SignatureComponent change={(e) => {
                                setSignatureChanged(true);
                            }} style={{
                                maxWidth: biggerThan700 ? '260px' : '200px', margin: '12px',
                                backgroundColor: '#F9F9F9'
                            }}
                                ref={signRef}></SignatureComponent>
                            <div style={{ margin: '0px 10px' }}>
                                <Button sx={{ width: biggerThan700 ? '106px' : '32px', margin: '4px 12px' }} disabled={!signatureChanged} startIcon={<Clear />} variant='contained' size='small' onClick={(e) => {
                                    signRef.current!.clear();
                                    setSignatureChanged(false);
                                }}>{biggerThan700 && t('Cancel')}</Button>
                                <Button sx={{ width: biggerThan700 ? '106px' : '32px', margin: '4px 12px' }} disabled={!signatureChanged} startIcon={<Check />} color='success' variant='contained' size='small' onClick={(e) => {
                                    setSignatureChanged(false);
                                    var blob = signRef.current!.saveAsBlob();
                                    let fileParameter: FileParameter = { data: blob, fileName: 'sign.png' };
                                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).UploadSignature(fileParameter, recordId, isInvestor === undefined ? false : isInvestor).then((e) => {
                                        if (e.ok === true) {
                                            refreshSignatures();
                                            setRefreshDailyRecordsNeeded(true);
                                            if (onSigned)
                                                onSigned();
                                        }
                                    });
                                }}>{biggerThan700 && t('diary.sign')}</Button>
                            </div>
                        </div>}
                    {signatures && signatures.signatures && signatures!.signatures!.length > 0 && signatures!.signatures!.map((item) => {
                        return <div style={{ margin: '6px', textAlign: 'center', border: '1px solid', borderColor: 'lightgray', borderRadius: '6px' }}>
                            <div style={{ position: 'relative', top: '6px', right: '0px', float: 'right', margin: '0px 10px' }}>
                                <div style={{
                                    backgroundColor: item.isInvestor ? theme.palette.success.main : (item.isMySignature ? theme.palette.secondary.main : 'lightgray'),
                                    borderRadius: '6px'
                                }}>
                                    <Typography sx={{
                                        margin: '4px 10px', fontWeight: 'bold', fontSize: '13px',
                                        color: item.isMySignature ? 'white' : (item.isInvestor ? 'white' : theme.palette.secondary.main)
                                    }}>{item.userName}</Typography>
                                </div>
                                <div style={{ display: 'flex', marginLeft: '8px' }}>
                                    <Typography sx={{ marginRight: '4px', fontSize: '10px', color: 'gray' }}>{item.dateTime && item.dateTime.toLocaleDateString(i18n.language)}</Typography>

                                    <Typography sx={{ fontSize: '10px', color: 'gray' }}>{item.dateTime && item.dateTime.toLocaleTimeString(i18n.language)}</Typography>
                                </div>
                            </div>
                            <div style={{
                                width: '240px',
                                height: '150px',
                                overflow: 'hidden', transform: 'translateY(30%)',
                            }}>
                                <img style={{
                                    position: 'relative', width: '240px', backgroundColor: 'transparent'
                                }} height={100} src={item.url} />
                            </div>
                        </div>
                    })}
                </div>}
            {recordId === undefined && <Typography>
                bad data
            </Typography>}
        </Box>
    )
}