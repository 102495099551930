import React, { useRef, useState } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, EditSettingsModel, Page } from "@syncfusion/ej2-react-grids";
import { useTranslation } from "react-i18next";
import standardFilterOptions from "../../Shared/Grids/StandardFilterOptions";
import { ChangeLogRecord } from "../../../ApiOld/ApiServerVov";

interface ChangeLogProps {
    onChangeToSave?: () => void;
    logs?: ChangeLogRecord[];
}

const ChangeLog: React.FC<ChangeLogProps> = ({ logs, onChangeToSave }) => {
    const { t, i18n } = useTranslation();
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const saving = useRef<boolean>(false);
    const grid = useRef<GridComponent>(null);

    const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };

    const BoldTemplate = (props) => {
        return <span style={{ fontWeight: 'bold' }}>{props.userName}</span>;
    };

    const ValueTemplate = (props) => {
        const value = props.value;
        if (value === 'True' || value === 'False') {
            return <span>{value === 'True' ? t('yesL') : t('noL')}</span>;
        } else if (typeof value === 'string' && Date.parse(value)) {
            const date = new Date(value);
            const formattedDate = date.toLocaleDateString(i18n.language === 'en' ? 'en-US' : 'cs-CZ', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            return <span>{formattedDate}</span>;
        } else {
            return <span>{value}</span>;
        }
    };

    function setDefaultData(e: any) {
        e.defaultData.id = 0;
        e.defaultData.name = '';
        e.defaultData.code = '';
        e.defaultData.isShared = true;
        e.defaultData.adminCreatorName = '';
    }

    return (
        <GridComponent style={{ borderColor: 'transparent' }} id="grid-component-machines" allowSorting={true}
            beforeBatchAdd={(e) => { setIsGridEditting(true); setDefaultData(e); }}
            cellEdit={(e) => { setIsGridEditting(true); if (onChangeToSave) onChangeToSave(); }}
            allowPaging={true}
            pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
            ref={grid}
            allowReordering={true} allowResizing={true} showColumnChooser={true}
            allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
            locale={i18n.language}
            enablePersistence={false}
            filterSettings={standardFilterOptions}
            selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
            allowFiltering={false}
            dataSource={logs}
        >
            <ColumnsDirective>
                <ColumnDirective field='userName' headerText={t('user')} width='150' template={BoldTemplate} />
                <ColumnDirective field={'column'} headerText={t('invoices.editedBy')} valueAccessor={(field, data) => t(data[field] as string)} width='400' />
                <ColumnDirective field={'value'} headerText={t('value')} width='400' template={ValueTemplate} />
                <ColumnDirective field='datetime' type='datetime' format={i18n.language === 'en' ? 'MM/dd/yyyy HH:mm:ss' : 'dd.MM.yyyy HH:mm:ss'} headerText={t('invoices.dateAndTime')} width='400' />
            </ColumnsDirective>
            <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
        </GridComponent>
    );
};

export default ChangeLog;
