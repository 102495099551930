import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { EmployeeDTO, EmployeeGroupsDTO, WealthExpenditureDTO } from '../../../ApiOld/ApiServerVov';
import { SolveEmployeesContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from '../../../Contexts/LoginOldContext';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    GridColDef,
    GridRowModes,
    GridRowModesModel,
    GridRowSelectionModel,
    GridRowsProp,
    GridToolbarContainer,
    GridSlotsComponent,
} from '@mui/x-data-grid';
import moment from 'moment';
import MuiDatagrid from '../../Shared/MuiDatagrid';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

export default function WealthExpenditures() {
    const { id, lang } = useParams();
    const [wealthId, setWealthId] = useState<number>(id ? parseInt(id) : 0);
    const [newId, setNewId] = useState<number>(-1);
    const [wealthExpenditures, setWealthExpenditures] = useState<WealthExpenditureDTO[]>([]);
    const [employees, setEmployees] = useState<EmployeeDTO[]>([]);
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    useEffect(() => {
        SolveEmployeesContext(globalContext, setGlobal).then((res) => {
            setEmployees(res);
        });
    }, [globalContext, setGlobal]);

    const [rows, setRows] = useState<GridRowsProp>(wealthExpenditures);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { t } = useTranslation();

    useEffect(() => {
        refresh();
    }, [wealthId]);

    function refresh() {
        if (wealthId) {
            new APIOldWealthClient().GetWealthExpenditures(wealthId).then((r) => {
                if (r.wealthExpenditures) setWealthExpenditures(r.wealthExpenditures);
                setRows(r.wealthExpenditures || []);
            });
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'date', headerName: t('Date'), type: 'date', editable: true,
            valueFormatter: (value?: Date) => {
                if (value == null) {
                    return '';
                }
                return `${moment(value).format('DD.MM.yyyy')}`;
            },
        },
        { field: 'name', headerName: t('name'), flex: 1, editable: true },
        {
            field: 'employeeId',
            headerName: t('Employee'),
            type: 'singleSelect',
            editable: true,
            minWidth: 150,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: employees.map((employee) => ({ code: employee.id, name: `${employee.firstname} ${employee.lastname}` })),
        },
        { field: 'description', editable: true, headerName: t('Description'), flex: 1 },
        {
            field: 'price', editable: true, headerName: t('AmountC'), minWidth: 250, type: 'number',
            valueFormatter: (value?: number) => {
                if (value == null) {
                    return '';
                }
                return `${value.toLocaleString(lang)} Kč`;
            },
        },
    ];
    const processRowUpdate = (newRow) => {
        setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const isCellEditable = (params) => {
        return params.id < 0;
    };

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const newRow = new WealthExpenditureDTO({
                id: newId,
                date: new Date(),
                name: "",
                employeeId: 0,
                description: "",
                price: 0,
                isDeleted: false
            });

            setRows((oldRows) => [...oldRows, newRow]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
            setNewId((prevId) => prevId - 1);
        };

        const handleSaveClick = () => {
            const serializedRows = rows.map(row => {
                return new WealthExpenditureDTO(row);
            });
            new APIOldWealthClient().saveWealthExpenditures(wealthId, serializedRows).then((r) => {
                if (r) {
                    refresh();
                }
            });
        }

        const handleDeleteClick = () => {
            const selectedIDs = new Set(selectionModel);
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id !== undefined && selectedIDs.has(row.id)
                        ? { ...row, isDeleted: true } as WealthExpenditureDTO
                        : row
                )
            );
        }
        return (
            <GridToolbarContainer>
                <Stack sx={{ width: '100%', alignItems: 'center', padding: 1.5, borderBottom: '1px solid lightgray' }} spacing={2} direction={'row-reverse'}>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={selectionModel.length === 0}
                    >
                        {t('Delete')}
                    </Button>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<SaveIcon />}
                        onClick={handleSaveClick}
                        disabled={!rows.some(row => row.id < 0 || row.isDeleted) || rows === wealthExpenditures}
                    >
                        {t('save')}
                    </Button>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<AddIcon />} onClick={handleClick}>
                        {t('Add')}
                    </Button>
                </Stack>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '10px', overflow: 'hidden' }}>
            {wealthExpenditures === null ? (
                <div>{t('loading...')}</div>
            ) : (
                <Box sx={{ padding: 4 }}>
                    <MuiDatagrid
                        editMode='row'
                        rows={rows.filter((row) => !row.isDeleted)}
                        columns={columns}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newModel) => { setRowModesModel(newModel) }}
                        checkboxSelection
                        onRowSelectionModelChange={(ids) => {
                            setSelectionModel(ids);
                        }}
                        isCellEditable={isCellEditable}
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: EditToolbar as GridSlotsComponent['toolbar'],
                        }}
                        slotProps={{
                            toolbar: {
                                setRows,
                                setRowModesModel,
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
}
