import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { getFileExtension } from "../Functions/GetFileExtension";
import { Article, Description, PictureAsPdf, TableView, Image } from "@mui/icons-material";

export function GetFileIcon(props: string, key: string, width?: string, height?: string): ReactJSXElement | undefined {
    console.log(props, getFileExtension(props));
    switch (getFileExtension(props)) {
        case '.pdf':
            return (<PictureAsPdf key={key + 'icon'} color='primary' sx={{ width: width ?? '90px', height: height ?? '90px' }} />)
        case '.xlsx':
        case '.xls':
            return <TableView key={key + 'icon'} color='primary' sx={{ width: width ?? '100px', height: height ?? '100px' }} />;
        case '.docx':
        case '.doc':
            return <Article key={key + 'icon'} color='primary' sx={{ width: width ?? '100px', height: height ?? '100px' }} />;
        case '.jpg':
        case '.jpeg':
        case '.png':
        case '.tiff':
            return <Image key={key + 'icon'} color='primary' sx={{ width: width ?? '100px', height: height ?? '100px' }} />;
        default: return <Description sx={{ width: width ?? '100px', height: height ?? '100px' }} />;
    }
}