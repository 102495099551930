import { Photo } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { APIVovInvestorHelper } from '../../ApiOld/ApiInvestorOldClient';
import { BuildingDTO, BuildingInfoDTO, ImageDiaryDTO, RequestsDTO } from '../../ApiOld/ApiServerVov';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import { FormControl, InputLabel, Typography } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import InvestorTaskCreateDialog from './Components/InvestorTaskCreateDialog';
import InvestorRenderBuildingsPhotos from './Components/InvestorRenderBuildingsPhotos';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';

interface InvestorPhotosSceneProps extends WithLoadingProps {
    buildingId?: number;
    buildingName?: string;
}
function InvestorPhotosScene(props: InvestorPhotosSceneProps) {
    const { fireLoading, stopLoading, buildingId, buildingName } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [buildings, setBuildings] = useState<BuildingInfoDTO[]>([]);
    const { t, i18n } = useTranslation();
    const [newOpen, setNewOpen] = useState(false);
    const [task, setTask] = useState<RequestsDTO>();
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [buildingImageId, setBuildingImageId] = useState<number | undefined>(undefined);
    const { investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();

    useEffect(() => {
        if (buildingId === undefined)
            loadBuildings();
        else {
            setBuildings([new BuildingInfoDTO({ id: buildingId, name: buildingName })]);
            stopLoading!();
        }
        setTask(new RequestsDTO());

        if (!investorPermissions)
            refreshInvestorPermissions();
    }, []);

    useEffect(() => {
        if (!investorPermissions)
            refreshInvestorPermissions();
    });

    useEffect(() => {
        if (buildingId === undefined)
            loadBuildings();
        else {
            setBuildings([new BuildingInfoDTO({ id: buildingId, name: buildingName })]);
            stopLoading!();
        }
        setTask(new RequestsDTO());
    }, [buildingId]);

    function setDefaultState() {
        setBuildings([]);
    }

    function loadBuildings() {
        //setDefaultState();
        // fireLoading!();
        console.log('refreshing');
        new APIVovInvestorHelper().GetInvestorBuildings().then((data) => {
            setBuildings(data);
            stopLoading!();
        }).catch(() => { stopLoading!(); console.log('err'); });
    }

    function handleNewRequestImageClick(image: ImageDiaryDTO) {
        setBuildingImageId(image.buildingId);
        setImageUrl(image.url);

        // setNewOpen(true);
    }
    useEffect(() => {
        if (imageUrl !== undefined && buildingImageId !== undefined) {
            setNewOpen(true);
        }
        else if (imageUrl === undefined && buildingImageId === undefined) setNewOpen(false);
    }, [imageUrl, buildingImageId])
    function newProjectClosed() {
        setImageUrl(undefined);
        setBuildingImageId(undefined);
        // setNewOpen(false);
    }


    if (!investorPermissions)
        return (<div>{t('loading...')}</div>);
    else if (investorPermissions.buildingImagesList !== true)
        return (<div>{t('noViewingPermissions')}</div>);
    else return (<div><div style={{ height: '78px', backgroundColor: '#F2F2F2', border: '1px solid', borderColor: '#C3C3C3', marginBottom: '12px' }}>
        <div style={{ backgroundColor: '#F2F2F2' }}>
            <div style={{ float: 'left' }}>
                <div style={{ height: '78px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginLeft: '20px' }}>
                        <Photo />
                    </div>
                    {biggerThan700 && <Typography sx={{ fontWeight: 'bold', marginLeft: '12px' }}>{t('photos')}</Typography>}
                </div>
            </div>

        </div>
    </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 0.5, flexWrap: 'wrap' }}>
            {
                buildings.map((item, i) => {
                    return (<InvestorRenderBuildingsPhotos onNewRequest={handleNewRequestImageClick} buildingId={item.id} buildingName={item.name} />);
                })
            }
        </div>
        <InvestorTaskCreateDialog imageUrl={imageUrl}
            selectedBuildingId={buildingImageId} onRefresh={() => { }}
            todo={task} open={newOpen} selectedValue={t('singleTodo.newTask')} onClose={newProjectClosed} />

    </div>);
}
export default withLoading(InvestorPhotosScene);