import { CircularProgress, Grid, Menu, MenuItem, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowModesModel, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import { csCZ } from '@mui/x-data-grid/locales';
import { useRequestsPermissionContext } from '../../../Contexts/Permissions/RequestsPermissionContext';
import MuiDatagrid from '../../Shared/MuiDatagrid';
import { ConversationFileDto, TodoMainVovClient } from '../../../ApiOld/Api/ApiServerVov';
interface TodoAttachmentListSceneProps {
    files: ConversationFileDto[];
    selectedIds: number[];
    onSelect: (ids: number[] | undefined) => void;

    onImageClick: (index: number | undefined) => void;
}

export default function TodoAttachmentListScene(props: TodoAttachmentListSceneProps) {
    const { files, selectedIds, onSelect, onImageClick } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { permissions, reloadPermissions } = useRequestsPermissionContext();

    useEffect(() => {
        if (files && files.length > 0) {
            setSelectionModel(selectedIds);
        }
    }, [selectedIds, files]);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const columns: GridColDef[] = [
        {
            field: 'fileName',
            headerName: t('file'),
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'text',
            headerName: t('todo.log.conversation_text_add'),
            flex: 1,
            minWidth: 100
        },
        {
            field: 'creator',
            headerName: t('files.creator'),
            flex: 1,
            minWidth: 100
        },
        {
            field: 'created',
            headerName: t('files.datetimeCreated'),
            type: 'dateTime',
            flex: 1,
            minWidth: 100
        }
    ];

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowDoubleClick = (params) => {
        const url = params.row.fileUrl;
        if (url) {
            window.open(url, '_blank'); // Opens the URL in a new tab
        }
    };

    const apiRef = useGridApiRef();

    if (loading)
        return <CircularProgress />
    else
        return <Grid item xs={12} lg={12}>
            <MuiDatagrid
                apiRef={apiRef}
                columns={columns}
                rows={files ?? []}
                onRowDoubleClick={handleRowDoubleClick}
                getRowId={(row) => row.id}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection
                disableRowSelectionOnClick
                rowModesModel={rowModesModel}
                onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    onSelect(ids.map(p => p as number));
                }}
                rowSelectionModel={selectionModel}
                onRowModesModelChange={handleRowModesModelChange}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    },
                }}
            />
            {/* <Menu
                open={contextMenu !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem
                    disabled={!permissions || !permissions. || selectedImagesIds.length < 1}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (renameImage) {
                            setRenameOpen(true);
                        }
                    }}
                >
                    {t('rename')}
                </MenuItem>
                <MenuItem
                    disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCategoryChange();
                    }}
                >
                    {t('buildings.moveToFolder')}
                </MenuItem>
            </Menu> */}
        </Grid>;
}
