import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ILocalizationProviderWithLanguagesProps {
    children: React.ReactNode;
}

export default function LocalizationProviderWithLanguages(props: ILocalizationProviderWithLanguagesProps) {
    const { i18n } = useTranslation();
    return (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
            {props.children}
        </LocalizationProvider>
    );
}
