import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import tabProps from "../../Shared/Tabs/TabProps";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import { Info } from '@mui/icons-material';
import WealthRental from "./WealthRental";
import StyledTabs from "../../Shared/Tabs/StyledTabs";

export default function WealthRentalTabs() {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const biggerThan700 = useMediaQuery('(min-width:1100px)');

    const tabs = [
        {
            icon: <Info style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
        }
    ];
    return (
        <Box style={{ backgroundColor: '#f5f5f5', margin: '24px' }}>
            <Box sx={{ width: '100%', zIndex: 5000 }}>
                <StyledTabs
                    tabs={tabs}
                    selectedTabIndex={value}
                    onTabChange={setValue}
                />
            </Box>
            <TabPanel value={value} index={0}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', borderRadius: '10px', borderTopLeftRadius: '0px', backgroundColor: '#FFFFFF' }}>
                    <WealthRental />
                </Box>
            </TabPanel>
        </Box>
    );
}
