import { Close } from '@mui/icons-material';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';

export interface CircularProgressDialogProps {
    open: boolean;
    title: string;
    onClose: () => void;
}


export default function CircularProgressDialog(props: CircularProgressDialogProps) {
    const { onClose, open, title } = props;
    const { t, i18n } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <Dialog maxWidth="md"
            open={open} onClose={onClose}
            sx={{ margin: 'auto', marginTop: '40px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "28", backgroundColor: '#F5F5F5' }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{title} </Typography>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px' // Adjust this value according to your content height
                }}
            >
                <CircularProgress sx={{ opacity: '1', width: '16px', height: '16px' }} />
            </DialogContent>
        </Dialog >
    );
};
