import { Box, Tab, Tabs, IconButton, Checkbox, FormControlLabel, Typography, Tooltip, useTheme } from "@mui/material";
import { Check, Clear, Construction, DoneAll, Info, Notes, PrivacyTip, Reply, Save, Visibility } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import tabProps from '../../Shared/Tabs/TabProps';
import SplitButton from "../../Shared/SplitButton";
import delay from '../../Shared/Functions/Delay';
import { AssignedUserDTO, EmployeeDetailDTO, GetBuildingDataDetailResponse, SetIsEmployeeActiveRequest } from "../../../ApiOld/ApiServerVov";
import { useNavigate } from 'react-router-dom';
import { ChangeEventArgs, HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useMediaPredicate } from "react-media-hook";
import { APIVovEmployeeHelper } from "../../../ApiOld/ApiOldEmployeeClient";
import EmployeeBasicDetailScene from "./Components/EmployeeBasicDetailScene";
import EmployeePersonalDetailScene from "./Components/EmployeePersonalDetailScene";
import EmployeeAdminUserVisibleScene from "./Components/EmployeeAdminUserVisibleScene";
import EmployeeBuildingsScene from "./Components/EmployeeBuildingsScene";
import { useUserLayoutContext } from "../../../Contexts/Providers/UserLayoutMenuProvider";
import { useEmployeesPermissionContext } from "../../../Contexts/Permissions/EmployeesPermissionContext";
import EmployeesMenuButtons from "./Components/EmployeesMenuButtons";
import ReusableTabs from "../InvoiceComponents/ReusableTabs";
import { TabItem } from "@syncfusion/ej2-react-navigations";

interface EmployeeDetailProps {
}

export default function EmployeeDetail(props: EmployeeDetailProps) {
    const [employee, setEmployee] = useState<EmployeeDetailDTO | undefined>(undefined);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<number>(0);

    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const theme = useTheme();

    const { permissions, reloadPermissions } = useEmployeesPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions, permissions]);

    const { setMenuButtons, setBackMenuFunction, setTitle } = useUserLayoutContext();
    useEffect(() => {
        setBackMenuFunction(() => () => { redirectToList() });
    }, []);

    useEffect(() => {
        if (params['id'] && !isNaN(parseInt(params['id']))) {
            if (parseInt(params['id']) > 0) {
                new APIVovEmployeeHelper().GetEmployeeDetail(parseInt(params['id'])).then(p => {
                    setEmployee(p);
                });
            }
            else {
                let n = new EmployeeDetailDTO({ id: 0 });
                setEmployee(n);
            }
        }
        else
            setEmployee(undefined);
    }, [params]);

    useEffect(() => {
        setMenuButtons(employee && permissions && <EmployeesMenuButtons
            permissions={permissions}
            employeeData={employee}
            saveDone={saveDone}
            setSaveDone={setSaveDone}
            error={errorCode}
            saveEmployee={saveEmployee}
            selectedTabIndex={selectedTabIndex}
        /* onAddNote={addNote}
        onCreateInvestor={addInvestor} */
        />);
    }, [employee, permissions, saveDone, selectedTabIndex]);


    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    async function saveEmployee(close: boolean) {
        if (employee !== undefined) {
            new APIVovEmployeeHelper().SaveEmployee(employee).then(p => {
                setSaveDone(true);

                if (close) {
                    redirectToList();
                }
            });
        }
    }

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/employees/')
    }

    const checkAllAdminUser = useCallback((group: string | undefined, isAssigned: boolean) => {
        /* setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                p.group === group ? new AssignedUserDTO({ id: p.id, isAssigned: isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        }); */
    }, [/* setProjectData, setGroupedEmployees, setGroupedUsers */]);

    const tabs = useMemo(() => employee && [
        {
            icon: <Info style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation'),
            panel: <EmployeeBasicDetailScene employee={employee} setEmployee={setEmployee} />
        },
        {
            icon: <PrivacyTip style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('privateData'),
            panel: <EmployeePersonalDetailScene employee={employee} setEmployee={setEmployee} />
        },
        {
            icon: <Visibility style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('visibleForUsers'),
            panel: <EmployeeAdminUserVisibleScene employee={employee} setEmployee={setEmployee} />
        },
        {
            icon: <Construction style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('Projekty'),
            panel: <EmployeeBuildingsScene employee={employee} setEmployee={setEmployee} />
        },
        {
            icon: <Notes style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('notes'),
            panel: <Box sx={{ background: 'white', p: 2, boxShadow: 3, borderRadius: '10px', width: '100%' }}>
                <RichTextEditorComponent height={600} locale={i18n.language} value={employee.notes} change={(eve: ChangeEventArgs) => {
                    setEmployee(new EmployeeDetailDTO({ ...employee, notes: eve.value }));
                }}>
                    <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                </RichTextEditorComponent>
            </Box>
        },
    ], [biggerThan700, params, permissions, employee, t]);


    if (employee)
        return (
            <Box sx={{ m: 2, maxWidth: biggerThan700 ? '100vw' : '92vw', height: '100vh', overflowY: biggerThan700 ? 'inherit' : 'auto', p: 1 }}>
                {/* <div style={{ position: 'absolute', left: '40px', top: '60px', zIndex: 30 }}>
                    <IconButton color="primary" onClick={redirectToList}>
                        <Reply />
                    </IconButton>
                </div>
                <div style={{ position: 'fixed', display: 'flex', right: '40px', top: '60px', zIndex: 30 }}>
                    {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                    <div style={{ marginRight: '10px' }}>
                        <SplitButton selectedIndexInput={0} disabled={employee === undefined || employee === null} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveEmployee(false);
                                }
                                else if (e === 1) {
                                    saveEmployee(true);
                                }
                            }}></SplitButton></div>
                </div> */}

                <ReusableTabs
                    variant="scrollable"
                    tabs={tabs/* ?.filter(Boolean) as TabItem[] */}
                    onSelectedTabChange={(index) => setSelectedTabIndex(index)}
                />

                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('buildings.basicInformation')} {...tabProps(0)} />
                        <Tab label={t('privateData')} {...tabProps(1)} />
                        <Tab label={t('visibleForUsers')} {...tabProps(2)} />
                        <Tab label={t('notes')} {...tabProps(3)} />
                        <Tab label={t('Projekty')} {...tabProps(3)} />
                    </Tabs>
                </Box>

                <TabPanel value={selectedTabIndex} index={0}>
                    <EmployeeBasicDetailScene employee={employee} setEmployee={setEmployee} />
                </TabPanel>

                <TabPanel value={selectedTabIndex} index={1}>
                    <EmployeePersonalDetailScene employee={employee} setEmployee={setEmployee} />
                </TabPanel>

                <TabPanel value={selectedTabIndex} index={2}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: biggerThan700 ? '100%' : '' }}>
                            <EmployeeAdminUserVisibleScene employee={employee} setEmployee={setEmployee} />
                        </div>
                    </div>
                </TabPanel>


                <TabPanel value={selectedTabIndex} index={3}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: '100%' }}>
                            <RichTextEditorComponent height={600} locale={i18n.language} value={employee.notes} change={(eve: ChangeEventArgs) => {
                                setEmployee(new EmployeeDetailDTO({ ...employee, notes: eve.value }));
                            }}>
                                <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={4}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: biggerThan700 ? '100%' : '' }}>
                            <EmployeeBuildingsScene employee={employee} setEmployee={setEmployee} />
                        </div>
                    </div>
                </TabPanel> */}
            </Box >
        );
    return <div>{t('loading...')}</div>

}