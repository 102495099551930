import { Clear, DoneAll } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { GetBuildingDataDetailResponse, InvestorDTO } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import ProjectSearchBar from './SearchBars/ProjectSearchBar';

interface ProjectInvestorsComponentProps {
    investors: InvestorDTO[];
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectInvestorsComponent(props: ProjectInvestorsComponentProps) {
    const { investors, setProjectData } = props;
    const [search, setSearch] = useState<string>();
    const { t } = useTranslation();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const checkAll = useCallback((isAssigned: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedInvestors: InvestorDTO[] = prev.assignedInvestors!.map((p) =>
                new InvestorDTO({ id: p.id, isAssigned: isAssigned, name: p.name })
            );

            return {
                ...prev,
                assignedInvestors: updatedInvestors,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);

    return <Grid item xs={12} lg={4}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: '24px', marginBottom: '0px', fontWeight: 'bold' }}>{t('investors')}
            </Typography>

            <Box sx={{ marginTop: '0px', marginLeft: '16px' }}>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        </Box>
        <Box sx={{ padding: '0px 8px 0px 8px' }}>
            <ProjectSearchBar onSearch={setSearch} />
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {investors?.map((investor, i) => {
                if (search && investor.name!.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                    return null;
                }
                return <div key={'key' + i}>
                    <FormControlLabel
                        sx={{ marginLeft: '8px', marginRight: '32px' }}
                        key={investor.id}
                        label={investor.name}
                        control={
                            <Checkbox
                                checked={investor.isAssigned}
                                onChange={(e, checked) => {
                                    setProjectData((prev) => {
                                        if (!prev) {
                                            return prev;
                                        }

                                        const updatedInvestors: InvestorDTO[] = prev.assignedInvestors!.map((p) =>
                                            investor.id === p.id ? new InvestorDTO({ id: p.id, isAssigned: checked, name: p.name }) : p
                                        );

                                        return {
                                            ...prev,
                                            assignedInvestors: updatedInvestors,
                                        } as Partial<GetBuildingDataDetailResponse>;
                                    });
                                }}
                            />
                        }
                    />
                </div>
            })}
        </Box>
    </Grid>;
}
