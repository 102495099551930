import { BorderColor, Cloud, Construction, Engineering, Grading, Photo, Lock, Check } from '@mui/icons-material';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../Api/UsersMicroserviceClient';
import { GetDailyRecordsForUserDTO, InvestorVovClient } from '../../ApiOld//Api/ApiServerVov';
import InitSfGridLocale from '../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../Shared/Layouts/UpperMenu';
import CreateDiaryRecord from '../Scenes/DiaryComponents/Widgets/CreateDiaryRecord';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';
import { useApi } from '../../ApiOld/Api/useApi';

InitSfGridLocale();
interface InvestorDiaryRecordsSceneProps {
    buildingId?: number
}

export default function InvestorDiaryRecordsScene(props: InvestorDiaryRecordsSceneProps) {
    const { buildingId } = props;
    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const navigate = useNavigate();
    const { authorizedInvestorApi } = useApi(InvestorVovClient);
    const [newOpen, setNewOpen] = useState(false);
    const [records, setRecords] = useState<GetDailyRecordsForUserDTO[] | null>(null);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const params = useParams();
    const { investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();

    function GridTemplate(props: GetDailyRecordsForUserDTO) {
        const [hover, setHover] = useState(false);
        return (<div style={{ cursor: 'pointer' }} onClick={() => {
            navigateToDetail(props);
        }} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <a style={{
                color: theme.palette.text.primary, textDecoration: hover ? 'underline' : 'none',
                fontWeight: 'bold',

            }}

            >{props.date!.toLocaleDateString(i18n.language)}</a>
        </div >);
        // return (<div>
        //     <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigateToDetail(props) }}>{props.date!.toLocaleDateString(/* i18n.language */)}</a>

        // </div >);
    }
    function headerTemplatePhotos(props: GetDailyRecordsForUserDTO) {
        return (<Photo />);

    }
    function cellTemplatePhotos(props: GetDailyRecordsForUserDTO) {
        if (props.hasPhotos !== undefined && props.hasPhotos === true)
            return (<Photo color='action' />);
        else return (<div></div>);

    }
    function headerTemplateLock(props: GetDailyRecordsForUserDTO) {
        return (<Tooltip title={t('diary.locked')}><Lock /></Tooltip>);

    }
    function cellTemplateLock(props: GetDailyRecordsForUserDTO) {
        if (props.isLocked !== undefined && props.isLocked === true)
            return (<Tooltip title={t('diary.locked')}><Lock color='action' /></Tooltip>);
        else return (<div></div>);

    }
    function headerTemplateSign(props: GetDailyRecordsForUserDTO) {
        return (<Tooltip title={t('diary.signByInvestor')}><BorderColor /></Tooltip>);

    }
    function cellTemplateSign(props: GetDailyRecordsForUserDTO) {
        if (props.isLocked !== undefined && props.isLocked === true)
            return (<Tooltip title={t('diary.signByInvestor')}><BorderColor color='action' /></Tooltip>);
        else return (<div></div>);

    }
    function headerTemplateWorkers(props: GetDailyRecordsForUserDTO) {
        return (<Engineering />);

    }
    function cellTemplateWorkers(props: GetDailyRecordsForUserDTO) {
        if (props.hasWorkerRecords !== undefined && props.hasWorkerRecords === true)
            return (<Engineering color='action' />);
        else return (<div></div>);

    }
    function headerTemplateWorks(props: GetDailyRecordsForUserDTO) {
        return (<Construction />);

    }
    function cellTemplateWorks(props: GetDailyRecordsForUserDTO) {
        if (props.hasCompletedWorksRecords !== undefined && props.hasCompletedWorksRecords === true)
            return (<Construction color='action' />);
        else return (<div></div>);

    }
    function headerTemplateWeathers(props: GetDailyRecordsForUserDTO) {
        return (<Cloud />);

    }
    function cellTemplateWeathers(props: GetDailyRecordsForUserDTO) {
        if (props.hasWeatherRecords !== undefined && props.hasWeatherRecords === true)
            return (<Cloud color='action' />);
        else return (<div></div>);

    }
    function pdfTemplate(props: GetDailyRecordsForUserDTO) {
        if (props.signedPdfPath === undefined || props.signedPdfPath === null || props.signedPdfPath === '') {

            return <div></div>;
        }
        else {
            return <Tooltip enterTouchDelay={0} title={t('diary.DigitallySigned')}>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const link = document.createElement('a');
                    link.href = props.signedPdfPath!;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    try {
                        document.body.removeChild(link);
                    } catch (e) {
                        console.log(e);
                    }
                }}
                    style={{
                        cursor: 'pointer', width: '20px', height: '20px', background: theme.palette.success.main, borderRadius: '20px', boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px'
                    }
                    }>
                    <Check style={{ width: '16px', height: '16px', color: 'white', marginLeft: '1px', marginTop: '2px' }} />
                </div>
            </Tooltip>
        }
    }
    function gridTemplateSignatures(props: GetDailyRecordsForUserDTO) {
        if (props.signatures)
            return <div > {props.signatures!.map((item) => {
                return <div key={item.id?.toString()} style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                    <div style={{
                        backgroundColor: item.isInvestor !== undefined && item.isInvestor === true ? theme.palette.success.main : ((item.isSubcontractor === undefined || item.isSubcontractor === false) ? theme.palette.primary.main : '#F5F5F5'),
                        zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '0px 4px'
                    }} >
                        <Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{item.name}</Typography>
                    </div>
                </div>
            })}</div>;
        else
            return (<div>

            </div>);
    }

    function navigateToDetail(props: GetDailyRecordsForUserDTO) {
        navigate({
            pathname: '/' + i18n.language + '/' + params['domain']! + '/investor/main/tabs/diary/' + props.id!.toString(),

        }, { state: { id: props.id, inputRecords: records, diaryId: props.diaryId, diaryName: props.diaryName, buildingName: props.buildingName } });

    }
    const grid = useRef<GridComponent>(null);
    useEffect(() => {
        if (records === null || records.length === 0)
            refresh();
        else if (grid.current) {
            grid.current.dataSource = records ?? [];
            grid.current.refresh();
        }

        if (!investorPermissions)
            refreshInvestorPermissions();

        return () => {
            if (grid.current)
                grid.current.dataSource = [];
        }
    }, []);

    /* useEffect(() => {
        if (!investorPermissions)
            refreshInvestorPermissions();
    }); */

    useEffect(() => {
        refresh();
    }, [buildingId]);

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    async function refresh() {
        authorizedInvestorApi.getInvestorDiaryRecords(buildingId).then((r) => {

            if (grid.current) {
                grid.current.dataSource = r?.records ?? [];
                grid.current.refresh();
            }

            setRecords(r?.records ?? []);
        });
    }
    function gridTemplateSignedPdfAuthor(props: GetDailyRecordsForUserDTO) {
        if (props.signedPdfAuthor)
            return (<div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}><div style={{
                backgroundColor: props.isSignedPdfAuthorInvestor ? theme.palette.success.main : theme.palette.primary.main,
                zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '0px 4px'
            }} >
                <Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{props.signedPdfAuthor}</Typography>
            </div></div>);
        else
            return (<div>

            </div>);
    }

    if (!investorPermissions || records === null)
        return (<div>{t('loading...')}</div>);
    else if (investorPermissions.buildingDiaryRecordsList !== true)
        return (<div>{t('noViewingPermissions')}</div>);
    else if (records)
        return <div style={{ display: "flex", flexDirection: 'column' }}>
            <UpperMenu moduleName={t('diary.dailyRecord')}
                gridType={GridType.Users}
                gridId='grid-component-daily-records-investor'
                icon={<Grading />}
                onCreate={(e) => { setNewOpen(true) }}
                canAdd={investorPermissions && investorPermissions.buildingDiaryRecordsEdit === true}
                // onDelete={(e) => { new APIVovHelper().deleteDailyRecords(e as number[]).then((e) => { if (e) refresh(); }) }}
                canChangeEyeView={false}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')} isInvestor={true}
                searchLabel={t('SearchInRecords')}
                canExcelExport
            />
            <GridComponent ref={grid} id="grid-component-daily-records-investor" allowSorting={true}
                allowReordering={true} allowResizing={true} showColumnChooser={true}
                allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language} enablePersistence={true}
                recordDoubleClick={(e: RecordDoubleClickEventArgs) => { navigateToDetail(e.rowData as GetDailyRecordsForUserDTO) }}
                filterSettings={filterOptions}
                allowPaging={true}
                style={{ backgroundColor: '#F5F5F5' }}
                pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                toolbarClick={toolbarClickExcel}
                //  columnDataStateChange={(e) => { console.log(e) }}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                allowFiltering={true} groupSettings={groupOptions}
                /* dataSource={records} */>
                <ColumnsDirective>
                    <ColumnDirective type='checkbox' width='30' showInColumnChooser={false} />
                    <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} showInColumnChooser={false} />
                    <ColumnDirective field='date' type='date' format='dd.MM.yyyy' template={GridTemplate} headerText={t('Date')} width='60' minWidth='60' />
                    <ColumnDirective field='buildingName' headerText={t('Projekt')} width='100' />
                    <ColumnDirective field='diaryName' headerText={t('diary.diary')} width='100' />
                    {/* <ColumnDirective field='signedPdfPath' headerText={'Pdf'} template={pdfTemplate} width='60' minWidth='60' /> */}
                    <ColumnDirective field='signedPdfAuthor' headerText={t('digitalSignature')} template={gridTemplateSignedPdfAuthor} width='160' minWidth='160' />
                    <ColumnDirective field='signatures' headerTemplate={headerTemplateSign} template={gridTemplateSignatures} width='80' />
                    <ColumnDirective field='isLocked' headerTemplate={headerTemplateLock} template={cellTemplateLock} width='46' />
                    <ColumnDirective field='hasPhotos' type='boolean' headerTemplate={headerTemplatePhotos} template={cellTemplatePhotos} headerText={t('dashboard.photos')} width='46' />
                    <ColumnDirective field='hasWorkerRecords' type='boolean' template={cellTemplateWorkers} headerTemplate={headerTemplateWorkers} headerText={t('diary.humans')} width='46' />
                    <ColumnDirective field='hasCompletedWorksRecords' type='boolean' template={cellTemplateWorks} headerTemplate={headerTemplateWorks} headerText={t('diary.works')} width='46' />
                    <ColumnDirective field='hasWeatherRecords' type='boolean' template={cellTemplateWeathers} headerTemplate={headerTemplateWeathers} headerText={t('dashboard.weather')} width='46' />


                </ColumnsDirective>
                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
            </GridComponent>
            <CreateDiaryRecord isInvestor={true} isSubdodavatel={false} diaryRecords={records} open={newOpen} onClose={(e, newId) => {

                setNewOpen(false);
                // if (e) {
                // if (newId === undefined) příprava na refreshe, které jsou víc "lightweight"
                if (e) refresh();
                // else
                // }
            }} />
        </div>

    return <div>{t('loading...')}</div>;
}