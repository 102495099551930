import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { Settings, PersonOutline, AccessTimeOutlined } from '@mui/icons-material';
import { IsImage } from '../../../Shared/Files/IsImage';
import { GetFileIcon } from '../../../Shared/Files/GetFileIcon';

// Utility functions (e.g., IsImage, GetFileIcon) are assumed to be imported or defined elsewhere.

export const ConversationItem = ({ conversation, setSelectedImage, setSelectedMessage, setDetailOpen }) => {
    const getFirstNonEmptyValue = (...values) =>
        values.find((value) => value && value.trim() !== '') || '';

    const renderFileContent = () => {
        if (conversation.fileName && IsImage(conversation.fileName)) {
            return (
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img
                        src={conversation.fileThumbnailUrl ?? conversation.fileUrl}
                        placeholder={conversation.fileName}
                        alt={conversation.fileName}
                        style={{
                            maxHeight: '120px',
                            width: 'auto',
                            cursor: 'pointer',
                        }}
                        onClick={() => setSelectedImage(conversation.fileUrl ?? null)}
                    />
                </Grid>
            );
        } else if (conversation.fileName) {
            return (
                <Grid
                    container
                    onClick={() => {
                        if (conversation.fileUrl) {
                            const link = document.createElement('a');
                            link.href = conversation.fileUrl;
                            link.target = '_blank'; // Optional: open in a new tab
                            link.download = conversation.fileName || 'file'; // Optional: suggest a file name for download
                            link.click();
                        }
                    }}
                    sx={{ cursor: 'pointer' }} // Add a pointer cursor to indicate interactivity
                >
                    <Grid item>
                        {GetFileIcon(conversation.fileName, conversation.id + '', '16px', '16px')}
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography variant="body2" sx={{ fontSize: '11px' }}>
                            {conversation.fileName}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };

    const renderMetadata = () => {
        const name = getFirstNonEmptyValue(
            conversation.employee,
            conversation.adminUser,
            conversation.investor
        );

        return (
            <>
                <Grid style={{ display: 'flex', fontSize: '14px' }}>
                    <PersonOutline fontSize="small" sx={{ float: 'left', color: 'black' }} />
                    <Typography>{name}</Typography>
                </Grid>
                <Grid style={{ display: 'flex', fontSize: '12px' }}>
                    <AccessTimeOutlined fontSize="small" sx={{ height: '16px', color: 'black', marginTop: '4px' }} />
                    <Typography>
                        {conversation.datetime ? conversation.datetime.toLocaleString() : ''}
                    </Typography>
                </Grid>
            </>
        );
    };

    if (conversation.employeeId)
        return <Grid container style={{ minHeight: '64px' }} key={'key' + conversation.id}>
            <Grid item style={{ fontSize: '14px', margin: '3px' }} xs={4}>
                <Grid style={{ background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversation.text}</div>
                        <IconButton sx={{ width: '30px', alignSelf: 'flex-end' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}><Settings /></IconButton>
                    </Grid>
                    {renderFileContent()}
                    <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                    {renderMetadata()}
                </Grid>
            </Grid>
            <Grid item style={{ background: conversation.toAdmin ? 'lightgray' : 'white', border: '0px solid white', borderRadius: '4px', margin: '3px' }} xs={4}></Grid>
            <Grid item style={{ background: conversation.toInvestor ? 'lightgray' : 'white', border: '0px solid white', borderRadius: '4px', margin: '3px' }} xs={4}></Grid>
        </Grid>
    else if (conversation.adminUserId)
        return <Grid container style={{ minHeight: '64px' }} key={'key' + conversation.id}>
            <Grid item style={{ background: conversation.toEmployee ? 'lightgray' : 'white', border: '0px solid white', borderRadius: '4px', margin: '3px' }} xs={4}></Grid>
            <Grid item style={{ fontSize: '18px', margin: '3px' }} xs={4}>
                <Grid style={{ background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversation.text}</div>
                        <IconButton sx={{ width: '30px', alignSelf: 'flex-start' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedMessage(conversation); setDetailOpen(true); }}><Settings /></IconButton>
                    </Grid>
                    {renderFileContent()}
                    <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                    {renderMetadata()}
                </Grid>
            </Grid>
            <Grid item style={{ background: conversation.toInvestor ? 'lightgray' : 'white', border: '0px solid white', borderRadius: '4px', margin: '3px' }} xs={4}></Grid>
        </Grid>
    else if (conversation.investorId)
        return <Grid container style={{ minHeight: '64px' }} key={'key' + conversation.id}>
            <Grid item style={{ background: conversation.toEmployee ? 'lightgray' : 'white', border: '0px solid white', borderRadius: '4px', margin: '3px' }} xs={4}></Grid>
            <Grid item style={{ background: conversation.toAdmin ? 'lightgray' : 'white', border: '0px solid white', borderRadius: '4px', margin: '3px' }} xs={4}></Grid>
            <Grid item style={{ fontSize: '14px', margin: '3px' }} xs={4}>
                <Grid style={{ background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversation.text}</div>
                        <IconButton sx={{ width: '30px', alignSelf: 'flex-start' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedMessage(conversation); setDetailOpen(true); }}><Settings /></IconButton>
                    </div>
                    {renderFileContent()}
                    <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                    {renderMetadata()}
                </Grid>
            </Grid>
        </Grid>
    return <Grid container key={'key' + conversation.id}>
        <Grid item style={{ background: 'white' }} xs={4}></Grid>
        <Grid item style={{ background: 'white' }} xs={4}></Grid>
        <Grid item style={{ background: 'white' }} xs={4}></Grid>
    </Grid>
};

export default ConversationItem;
