import { Box, CircularProgress, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Reply } from '@mui/icons-material';
import { ColumnChooser, ColumnDirective, ColumnsDirective, ExcelExport, Filter, GridComponent, Group, Inject, Page, RecordDoubleClickEventArgs, Reorder, Resize, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { VovClient, TodoHistoryDTO } from '../../../ApiOld/ApiServerVov';
import { useApi } from '../../../ApiOld/Api/useApi';

const todoHistoryBooleanColumns = ['show_in_gantt', 'is_done'];

interface TodoHistorySceneProps {
}

export default function TodoHistoryScene(props: TodoHistorySceneProps) {
    const { t, i18n } = useTranslation();
    const [logs, setLogs] = useState<TodoHistoryDTO[]>();
    const params = useParams();
    const navigate = useNavigate();
    const grid = useRef<GridComponent>(null);

    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
    }, []);

    useEffect(() => {
        if (params['id'] && !isNaN(parseInt(params['id']))) {
            authorizedApi.getTodoHistory(parseInt(params['id'])).then((data) => {
                setLogs(data.logs);
            });
        }
        else
            setLogs(undefined);
    }, [params]);

    function getCompareValueVisual(props: TodoHistoryDTO) {
        /* if ((props.oldValue || !props.oldValue) && props.columnName === 'completed_work') {
            return <div>
                <Tooltip title='Porovnat'>
                    <IconButton sx={{ color: 'black', fontSize: 'small' }} onClick={() => {
                        setOldValue(props.oldValue ? props.oldValue : '');
                        setNewValue(props.value ? props.value : '');
                        setCompareOpen(true);
                    }}><Compare sx={{ fontSize: '14px' }} />
                    </IconButton>
                </Tooltip>
            </div>;
        } */
        return <div></div>;
    };

    function getPropertyVisual(props: TodoHistoryDTO) {
        if (props.columnName) {
            return <div>{t('todo.log.' + props.columnName.toLocaleLowerCase())}</div>;
        }
        return <div></div>;
    };

    function getValueVisual(props: TodoHistoryDTO) {
        if (props.columnName && todoHistoryBooleanColumns.includes(props.columnName)) {
            return <div>{Number(props.value) > 0 ? t('yesL') : t('noL')}</div>;
        }
        else if (props.columnName)
            return <div>{props.value}</div>;
        return <div></div>;
    };

    function getUserType(type: TodoHistoryDTO | undefined) {
        if (!type) {
            return <div></div>;
        }

        if (type?.adminUser) {
            return <div>{t('user')}</div>;
        }
        else if (type?.employee) {
            return <div>{t('Employee')}</div>;
        }
        else if (type?.investor) {
            return <div>{t('Investor')}</div>;
        }
    }

    function getName(type: TodoHistoryDTO | undefined) {
        if (!type) {
            return <div></div>;
        }

        if (type?.adminUser) {
            return <div>{type.adminUser}</div>;
        }
        else if (type?.employee) {
            return <div>{type.employee}</div>;
        }
        else if (type?.investor) {
            return <div>{type.investor}</div>;
        }
    }

    return (
        <Box sx={{ background: 'white', p: 1, width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>{t('logOfChanges')}</h4>
            </div>

            {logs && <GridComponent ref={grid} id="grid-component-daily-records-log-history" allowSorting={true}
                allowReordering={true} allowResizing={true}
                allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language} enablePersistence={false}
                recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                    /* let data = e.rowData as DiaryLogDTO;
                    if (data) {
                        setOldValue(data.oldValue ? data.oldValue : '');
                        setNewValue(data.value ? data.value : '');
                        setCompareOpen(true);
                    } */
                }}
                allowPaging={true}
                style={{ backgroundColor: '#F5F5F5' }}
                pageSettings={{ pageSize: 10, pageSizes: [5, 10, 20, 30, 50, 100] }}
                dataSource={logs}>
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='ID' visible={false} showInColumnChooser={false} />
                    {/* <ColumnDirective field='adminUser' headerText={t('userType')} template={getUserType} /> */}
                    <ColumnDirective field='adminUser' headerText={t('user')} template={getName} />
                    <ColumnDirective field='column' headerText={t('Field')} template={getPropertyVisual} />
                    <ColumnDirective field='value' headerText={t('value')} template={getValueVisual} />
                    {/* <ColumnDirective headerText={t('log.compare')} template={getCompareValueVisual} width={'100px'} /> */}
                    <ColumnDirective field='datetime' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy HH:mm' : 'dd.MM.yyyy HH:mm'} headerText={t('Date')} />
                </ColumnsDirective>

                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
            </GridComponent>}
            {!logs && <div><CircularProgress /></div>}
        </Box>);
}