import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from 'react-media-hook';
import { APIVovInvestorHelper } from '../../ApiOld/ApiInvestorOldClient';
import { DochazkaRecordDTO } from '../../ApiOld/ApiServerVov';
import withLoading, { WithLoadingProps } from "../Shared/Loader";
import { groupBy } from '../Shared/GroupBy';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';

interface InvestorAttendanceSceneProps extends WithLoadingProps {
    buildingId?: number
}

function InvestorAttendanceScene(props: InvestorAttendanceSceneProps) {
    const { fireLoading, stopLoading, buildingId } = props;
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [grouped, setGrouped] = useState<[string, DochazkaRecordDTO[]][]>([]);
    const { investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();

    useEffect(() => {
        refreshData();

        if (!investorPermissions)
            refreshInvestorPermissions();
    }, []);

    useEffect(() => {
        if (!investorPermissions)
            refreshInvestorPermissions();
    });

    useEffect(() => {
        refreshData();
    }, [buildingId]);

    function refreshData() {
        fireLoading!();
        setGrouped([]);

        try {
            new APIVovInvestorHelper().GetDochazka(buildingId).then((r) => {
                const data = groupBy(r, i => i.building!);

                for (const b in data) {
                    grouped?.push([b, data[b]])
                }

                setGrouped(grouped);
                stopLoading!();
            });
        }
        catch (e: any) {
            stopLoading!();
        }
    }

    if (!investorPermissions)
        return (<div>{t('loading...')}</div>);
    else if (investorPermissions.employeesList !== true)
        return (<div>{t('noViewingPermissions')}</div>);
    return (<div><div style={{ height: '78px', backgroundColor: '#F2F2F2', border: '1px solid', borderColor: '#C3C3C3', marginBottom: '12px' }}>
        <div style={{ backgroundColor: '#F2F2F2' }}>
            <div style={{ float: 'left' }}>
                <div style={{ height: '78px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginLeft: '20px' }}>
                        <AccessTimeIcon />
                    </div>
                    {biggerThan700 && <Typography sx={{ fontWeight: 'bold', marginLeft: '12px', }}>{t('attendance')}</Typography>}
                </div>
            </div>
            <div style={{ float: 'right' }}>
                <div style={{ height: '78px', display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                    {/*  {<FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{menuName}</InputLabel>
                        <Select sx={{ width: '140px' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={menuName}
                            value={view}
                            onChange={handleChange}
                            size='small'
                        >
                            {items && items.map((item, i) => {
                                return (<MenuItem value={item.data} key={item.data}>{item.caption}</MenuItem>)

                            })}
                        </Select>
                    </FormControl>} */}


                    {/* {biggerThan700 && <Tooltip title={t('Settings')}>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={toolbarEnabled} onChange={event => { toolbarSwitch(event) }} icon={<Settings color='secondary' />} checkedIcon={<Settings />} />}
                            label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Možnosti</Typography>}
                            labelPlacement="end"
                        />

                    </Tooltip>}
                    {!biggerThan700 && <Tooltip title={t('Settings')}>
                        <Checkbox checked={toolbarEnabled} onChange={event => { toolbarSwitch(event) }} icon={<Settings color='secondary' />} checkedIcon={<Settings />} />


                    </Tooltip>} */}


                </div>
            </div>
        </div>
    </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 0.5, margin: '5px', fontFamily: 'Roboto' }}>
            {grouped.length > 0 &&
                grouped.map((item, i) => {
                    return (<div style={{ boxShadow: '1px 3px 3px 3px lightgray', marginBottom: '5px' }}>
                        <Typography sx={{ fontWeight: 'bold', margin: '8px', fontSize: '14px' }}>{item[0]}</Typography>
                        {
                            item[1].map((item2, i) => {
                                return (<div style={{ margin: '10px', marginLeft: '20px' }}> {item2.employee} </div>);
                            })
                        }
                    </div>);
                })
            }
            {grouped.length === 0 && <div style={{ margin: '40px', fontWeight: 'bold', fontSize: '16px' }}>{t('investor.noEmployeesOnProject')}</div>}
        </div>
    </div>);
};
export default withLoading(InvestorAttendanceScene);