
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductFruits } from "react-product-fruits";
import { useParams } from "react-router";
import { Navigate } from "react-router";
import { useInvestorStore } from "../../../Contexts/InvestorZContext";
import ProductFruitsInvestor from "../../product-fruits/ProductFruitsInvestor";

export interface ProtectedInvestorRouteProps {
    redirectPath?: string;
    children: any;
}


export default function ProtectedInvestorRoute(props: ProtectedInvestorRouteProps) {
    const { redirectPath, children } = props;
    const { t, i18n } = useTranslation();
    const params = useParams();

    // console.log(children);

    var token = localStorage.getItem('investor_token_old');
    var tokenExp = localStorage.getItem('investor_token_old_expires');

    // console.log(token);
    if (token === null || token === undefined || tokenExp === null || tokenExp === undefined || new Date(tokenExp) < new Date()) {


        return <Navigate to={'/' + i18n.language + '/' + params['domain']! + '/investor'} replace state={{ url: window.location.pathname }} />;

    }
    return <Box>
        <ProductFruitsInvestor />
        {children}
    </Box>;
    // else {
    //     let username = localStorage.getItem('username');
    //     let inits = localStorage.getItem('initials');
    //     let company = localStorage.getItem('company');
    //     if (username !== null && username !== undefined && inits !== null && inits !== undefined) {
    //         globalContext.name = username;
    //         globalContext.initials = inits;
    //         if (company !== null)
    //             globalContext.companyName = company;
    //         setGlobal(globalContext);
    //     }

    //     
    // }

}


