import { Check, CloudUploadOutlined } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { FileParameter } from '../../../ApiOld/ApiServerVov';
import { FileUpload } from '../../Scenes/DiaryComponents/Widgets/ChoosePhotoDialog';
import { useTranslation } from "react-i18next";
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { is } from 'date-fns/locale';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
interface BasicUploadProps {
    onlyImages?: boolean;
    recordId?: number;
    onNeedRefresh?: () => void;
    uploadStarted?: () => void;
    uploadCompleted?: () => void;
    isInvestor: boolean;
    isSubdodavatel?: boolean;
    isBuilding?: boolean;
}
export default function BasicUpload(props: BasicUploadProps) {
    const { onlyImages, recordId, onNeedRefresh, uploadCompleted, uploadStarted, isInvestor, isSubdodavatel, isBuilding } = props;
    const inputFile = React.useRef<HTMLInputElement>(null);
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    const biggerThan1000 = useMediaPredicate("(min-width: 1000px)");
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [uploadsVisual, setUploadsVisual] = useState<FileUpload[]>([]);
    const { t, i18n } = useTranslation();
    async function uploadFiles() {
        if (recordId) {
            if (uploadStarted)
                uploadStarted();
            for (let i = 0; i < uploads.length; i++) {
                // setUploading(true);

                let fileParameter: FileParameter = { data: uploads[i].data, fileName: uploads[i].fileName };
                if (onlyImages) {
                    if (isBuilding === true) {
                        let e = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor == undefined ? false : isInvestor).AddImageToProject(recordId, fileParameter);
                        if (e && e.ok && e.url) {

                            let currentItem = uploads.find(x => x.fileName === uploads[i].fileName);
                            if (currentItem) {
                                currentItem.isUploading = false;
                                var t = [...uploads.filter(x => x.fileName !== uploads[i].fileName), currentItem];
                                setUploadsVisual(t);
                            }
                            if (onNeedRefresh)
                                onNeedRefresh();
                            // if (onUploaded)
                            //     onUploaded(e.url);

                            // if (isUploading)
                            //     isUploading(false);
                            // e.url
                        }

                    }
                    else {
                        let e = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor == undefined ? false : isInvestor).addImageToDiary(recordId, fileParameter);
                        if (e.ok && e.url) {

                            let currentItem = uploads.find(x => x.fileName === uploads[i].fileName);
                            if (currentItem) {
                                currentItem.isUploading = false;
                                var t = [...uploads.filter(x => x.fileName !== uploads[i].fileName), currentItem];
                                setUploadsVisual(t);
                            }
                            if (onNeedRefresh)
                                onNeedRefresh();
                            // if (onUploaded)
                            //     onUploaded(e.url);

                            // if (isUploading)
                            //     isUploading(false);
                            // e.url
                        }
                    }
                }
                else {
                    await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor == undefined ? false : isInvestor).addFileToDiary(recordId, fileParameter).then((e) => {
                        if (e.ok && e.url) {

                            let currentItem = uploads.find(x => x.fileName === uploads[i].fileName);
                            if (currentItem) {
                                currentItem.isUploading = false;
                                var t = [...uploads.filter(x => x.fileName !== uploads[i].fileName), currentItem];
                                setUploadsVisual(t);
                            }
                            if (onNeedRefresh)
                                onNeedRefresh();
                            // if (onUploaded)
                            //     onUploaded(e.url);

                            // if (isUploading)
                            //     isUploading(false);
                            // e.url
                        }
                    });

                }
                // .then((e) => {

            }
            if (uploadCompleted)
                uploadCompleted();

        }

    }
    useEffect(() => {
        uploadFiles();
    }, [uploads]);
    async function upload(files: FileList) {
        if (recordId) {
            let newUps: FileUpload[] = [];
            for (let i = 0; i < files.length; i++) {
                let newFile: FileUpload = { data: files[i], fileName: files[i].name, isUploading: true };
                newUps.push(newFile);
            }
            // var w = [...uploads, ...newUps];
            setUploads(newUps);
            setUploadsVisual(newUps);
            // await delay(3000)
            console.log('iploading');



        }
    }

    const handleDragOver = (e: any) => {
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: any) => {
        console.log('drop', e);
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        console.log(files);
        if (files && files.length) {
            upload(files);
            // onUpload(files);
        }
    };
    return (

        <div>
            {onlyImages !== undefined && onlyImages === true && <input
                multiple
                accept="image/png, image/jpeg, image/jfif"
                onChange={(e) => {
                    if (e.target && e.target.files)
                        upload(e.target.files);

                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />}
            {(onlyImages === undefined || onlyImages === false) && <input
                multiple
                onChange={(e) => {
                    if (e.target && e.target.files)
                        upload(e.target.files);

                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />}
            {!biggerThan700 && <div>
                <Button size='small' sx={{ position: 'absolute', margin: '0 auto', right: '20px', top: '80px' }} onClick={(e) => {
                    inputFile.current!.click();
                }} variant='contained'><CloudUploadOutlined /></Button>
            </div>}
            <div id='dropArea' onDrop={handleDrop} onDragOver={handleDragOver}>
                {biggerThan700 && <div style={{
                    backgroundColor: 'white', width: '100%',
                    height: '360px', margin: '56px 20px', boxShadow: '2px 2px 6px 2px gray', fontFamily: 'Roboto'

                }}>
                    <div style={{ width: '60%', height: '50%', top: '5%', position: 'relative', margin: '0 auto' }}>
                        <CloudUploadOutlined style={{ width: biggerThan1000 ? '180px' : '80px', height: biggerThan1000 ? '180px' : '80px', marginLeft: '20px' }} />
                        <h6>{t('files.dragFilesOrPress')}</h6>
                    </div>
                    <div style={{ width: biggerThan1000 ? '50%' : '70%', height: '50%', top: '25%', left: '7%', position: 'relative', margin: '0 auto' }}>
                        <Button size='small' onClick={(e) => {
                            inputFile.current!.click();
                        }} variant='contained'>{t('files.uploadFiles')}</Button>
                    </div>

                </div>}
                {uploads.map((upload) => {
                    return <div>{upload.fileName}
                        {upload.isUploading && <CircularProgress size={'16px'} sx={{ width: '16px', height: '16px' }} />}
                        {!upload.isUploading && <Check />}
                    </div>
                })}
                {/* <UploaderComponent template={uploaderTemplate} /> */}
            </div></div >);
}