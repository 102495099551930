import { Box } from "@mui/material";
import { AccountBalance, Chat, Feed, History, Info } from '@mui/icons-material';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import delay from '../../Shared/Functions/Delay';
import { GetInvoiceDataDetailResponse, InvoiceFileDTO, InvoiceItem } from "../../../ApiOld/ApiServerVov";
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import { ApiOldInvoicesClient } from "../../../ApiOld/ApiOldInvoicesClient";
import AdminUserSelect from "../../Shared/AdminUserSelect";
import BankInput from "../../Shared/BankInput";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ChangeLog from "./ChangeLog";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import InvoiceItems from "./InvoiceItems";
import { useBuildingBillsPermissionContext } from "../../../Contexts/Permissions/BuildingBillsPermissionContext";
import StyledTabs from "../../Shared/Tabs/StyledTabs";
import { useMediaPredicate } from "react-media-hook";
import { useUserLayoutContext } from "../../../Contexts/Providers/UserLayoutMenuProvider";
import InvoiceMenuButtons from "./InvoiceMenuButtons";
import InvoiceFiles from "./InvoiceFiles";
import { InvoiceComments } from "./InvoiceComments";


interface InvoiceDetailProps {
    invoiceId?: number;
    fromProject?: boolean;
}

export default function InvoiceDetail(props: InvoiceDetailProps) {
    const { fromProject, invoiceId } = props;
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [invoiceData, setInvoiceData] = useState<Partial<GetInvoiceDataDetailResponse> | null>(null);
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[] | undefined>();
    const [invoiceFiles, setInvoiceFiles] = useState<InvoiceFileDTO[] | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const { setMenuButtons, setBackMenuFunction } = useUserLayoutContext();
    const redirectToList = useCallback(() => {
        if (fromProject) {
            if (invoiceData && invoiceData.buildingId)
                navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/projectDetail/' + invoiceData.buildingId);
            else
                navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/projects');
        } else {
            navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/invoices');
        }
    }, [fromProject, navigate, i18n.language, params, invoiceData]);

    const { permissions, reloadPermissions } = useBuildingBillsPermissionContext();

    const reloadData = useCallback(() => {
        if (permissions && permissions.buildingBillsDetail) {
            if (invoiceId && invoiceId !== 0) {
                new ApiOldInvoicesClient().GetInvoiceDataDetail(invoiceId).then((d) => {
                    setInvoiceData(d);
                    setInvoiceFiles(d.files);
                    if (d.id) {
                        new ApiOldInvoicesClient().GetInvoiceItems(d.id).then((x) => {
                            if (x.invoiceItems) {
                                setInvoiceItems(x.invoiceItems);
                            }
                        });
                    }
                });
            }
            else {
                setInvoiceData(new GetInvoiceDataDetailResponse({
                    dateTaxable: new Date(),
                    dateExpire: new Date(),
                    dateReceive: undefined,
                    paymentDueDate: undefined,
                    commentAdd: "",
                }));
                setInvoiceItems([]);
                setInvoiceFiles([]);
            }
        }
    }, [invoiceId, permissions]);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
        else if (invoiceId || invoiceId === 0) {
            reloadData();
        }
    }, [reloadPermissions, permissions, invoiceId, reloadData]);

    const saveInvoice = useCallback(() => {
        if (invoiceData && invoiceItems && invoiceFiles && permissions && (permissions.buildingBillsEdit || permissions.buildingBillsSetRelItemValues)) {
            new ApiOldInvoicesClient().SaveWholeInvoice(invoiceData, invoiceItems.map((invIt) => {
                return new InvoiceItem({ ...invIt })
            }), invoiceFiles.map((invFile) => {
                return new InvoiceFileDTO({ ...invFile })
            })).then((d) => {
                if (invoiceId === 0 && d.id)
                    navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/invoiceDetail/' + d.id);
                if (d) {
                    setInvoiceData({ ...invoiceData });
                    setSaveDone(true);
                    reloadData();
                }
            });
        }
    }, [i18n.language, invoiceData, invoiceFiles, invoiceId, invoiceItems, navigate, params, permissions, reloadData]);

    useEffect(() => {
        if (invoiceData?.commentAdd && invoiceData.commentAdd !== ""
        )
            saveInvoice();
    }, [invoiceData?.commentAdd]);

    useEffect(() => {
        if (setMenuButtons) {
            setMenuButtons(
                <InvoiceMenuButtons
                    redirectToList={() => {
                        redirectToList();
                    }}
                    saveInvoice={() => {
                        saveInvoice();
                        setSaveDone(false)
                    }}
                    saveDone={saveDone}
                    invoiceData={invoiceData}
                    permissions={permissions}
                    saveDisabled={saveDisabled}
                />
            );
        }
    }, [setMenuButtons, redirectToList, saveInvoice, saveDone, invoiceData, permissions, saveDisabled]);

    useEffect(() => {
        if (setBackMenuFunction) {
            setBackMenuFunction(() => redirectToList);
        }
    }, [setBackMenuFunction, redirectToList]);

    const handleSelectChange = (property, value) => {
        setInvoiceData((prevData) => ({
            ...prevData,
            [property]: Boolean(value)
        }));
    };

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    const tabs = [
        {
            icon: <Feed style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('buildings.basicInformation')
        },
        {
            icon: <Info style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('additionalInformation')
        },
        {
            icon: <AccountBalance style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('invoices.detailsForPaymentOrder')
        },
        ...(invoiceId !== 0 && permissions && permissions.buildingBillsLog ? [
            {
                icon: <History style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
                label: t('logOfChanges')
            }
        ] : [])
    ];

    if (invoiceData)
        return (
            <Box sx={{ marginX: '24px', mt: '30px' }}>
                <StyledTabs
                    tabs={tabs}
                    selectedTabIndex={selectedTabIndex}
                    onTabChange={setSelectedTabIndex}
                />
                <TabPanel value={selectedTabIndex} index={0} >
                    <Box sx={{ background: 'white', borderRadius: '16px', borderTopLeftRadius: '0px', boxShadow: '4px 4px 16px 4px lightgray', p: '30px' }}>
                        <h4>{t('invoices.Invoice')}</h4>
                        <ValidationGroup>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                        <TextField label={t('Name')} variant="outlined" fullWidth size='small'
                                            name='name'
                                            value={invoiceData.name}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetName)
                                            }
                                            onChange={(e) => { setInvoiceData({ ...invoiceData, name: e.target.value }) }}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('is_posted')}</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="isPosted"
                                            value={Number(invoiceData.isPosted)}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetIsPosted)
                                            }
                                            label={t('is_posted')}
                                            onChange={(e) => handleSelectChange('isPosted', e.target.value)}
                                        >
                                            <MenuItem value={1}>{t('yesL')}</MenuItem>
                                            <MenuItem value={0}>{t('noL')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('is_approved')}</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="isDone"
                                            value={Number(invoiceData.isDone)}
                                            label={t('is_approved')}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetIsDone)
                                            }
                                            onChange={(e) => handleSelectChange('isDone', e.target.value)}
                                        >
                                            <MenuItem value={1}>{t('yesL')}</MenuItem>
                                            <MenuItem value={0}>{t('noL')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('is_done')}</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="isApproved"
                                            value={Number(invoiceData.isApproved)}
                                            label={t('is_done')}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetIsApproved)
                                            }
                                            onChange={(e) => handleSelectChange('isApproved', e.target.value)}
                                        >
                                            <MenuItem value={1}>{t('yesL')}</MenuItem>
                                            <MenuItem value={0}>{t('noL')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('is_postponed')}</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="isPostponed"
                                            value={Number(invoiceData.isPostponed)}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetIsPostponed)
                                            }
                                            label={t('is_postponed')}
                                            onChange={(e) => handleSelectChange('isPostponed', e.target.value)}
                                        >
                                            <MenuItem value={1}>{t('yesL')}</MenuItem>
                                            <MenuItem value={0}>{t('noL')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('date_taxable')}
                                            inputFormat="DD.MM.YYYY"
                                            value={invoiceData.dateTaxable}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetDateTaxable)
                                            }
                                            onChange={(newValue) => {
                                                setInvoiceData({ ...invoiceData, dateTaxable: newValue ? newValue : undefined });
                                            }}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('date_expire')}
                                            inputFormat="DD.MM.YYYY"
                                            value={invoiceData.dateExpire}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetDateExpires)
                                            }
                                            onChange={(newValue) => {
                                                setInvoiceData({ ...invoiceData, dateExpire: newValue ? newValue : undefined });
                                            }}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <AdminUserSelect
                                            label={t("admin_users_id_from")}
                                            selectedUserId={invoiceData.adminUserFrom}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetFrom)
                                            }
                                            onChange={(selectedUser) => {
                                                const userId = selectedUser ? selectedUser.id : null;
                                                setInvoiceData((prevData) => ({ ...prevData, adminUserFrom: userId } as Partial<GetInvoiceDataDetailResponse>));
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <AdminUserSelect
                                            label={t("admin_users_id_to")}
                                            selectedUserId={invoiceData.adminUserTo}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetTo)
                                            }
                                            onChange={(selectedUser) => {
                                                const userId = selectedUser ? selectedUser.id : null;
                                                setInvoiceData((prevData) => ({ ...prevData, adminUserTo: userId } as Partial<GetInvoiceDataDetailResponse>));
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </ValidationGroup>
                        <Box sx={{ mt: 3 }}>
                            <h4>{t('items')}</h4>
                            <InvoiceItems
                                permissions={permissions}
                                invoiceItems={invoiceItems}
                                saveInvoiceItems={setInvoiceItems}
                                onEditModeChange={setSaveDisabled}
                            />
                        </Box>
                        <InvoiceFiles
                            permissions={permissions}
                            saveFiles={(e) => {
                                if (e) {
                                    setInvoiceFiles(e)
                                }
                            }}
                            files={invoiceFiles}
                            onEditModeChange={setSaveDisabled}
                        />
                        <Box sx={{ mt: 3 }}>
                            <h4>{t('comments')}</h4>
                            <InvoiceComments text={invoiceData.comments} onSend={(comment) => {
                                setInvoiceData({ ...invoiceData, commentAdd: comment })
                            }} />
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <Box sx={{ background: 'white', borderRadius: '16px', boxShadow: '4px 4px 16px 4px lightgray', p: '30px' }}>
                        <ValidationGroup>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField label={t('supplier')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        value={invoiceData.supplier}
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetSupplier)
                                        }
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, supplier: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('date_recieve')}
                                            inputFormat="DD.MM.YYYY"
                                            value={invoiceData.dateReceive ? invoiceData.dateReceive : null}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetDateReceive)
                                            }
                                            onChange={(newValue) => {
                                                setInvoiceData({ ...invoiceData, dateReceive: newValue ? newValue : undefined });
                                            }}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('variable_symbol')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        value={invoiceData.variableSymbol}
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetVariableSymbol)
                                        }
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, variableSymbol: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('subject')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        value={invoiceData.subject}
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetSubject)
                                        }
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, subject: e.target.value }) }}
                                    />
                                </Grid>
                            </Grid>
                        </ValidationGroup>
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={2}>
                    <Box sx={{ background: 'white', borderRadius: '16px', boxShadow: '4px 4px 16px 4px lightgray', p: '30px' }}>
                        <ValidationGroup>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <BankInput
                                        label={t('debitAccount')}
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value1={invoiceData.paymentPayerBankNumber}
                                        value2={invoiceData.paymentPayerBankCode}
                                        onValue1Change={(e) => setInvoiceData({ ...invoiceData, paymentPayerBankNumber: e.target.value })}
                                        onValue2Change={(e) => setInvoiceData({ ...invoiceData, paymentPayerBankCode: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BankInput
                                        label={t('creditAccount')}
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value1={invoiceData.paymentRecipientBankNumber}
                                        value2={invoiceData.paymentRecipientBankCode}
                                        onValue1Change={(e) => setInvoiceData({ ...invoiceData, paymentRecipientBankNumber: e.target.value })}
                                        onValue2Change={(e) => setInvoiceData({ ...invoiceData, paymentRecipientBankCode: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('date_expire')}
                                            disabled={
                                                !permissions ||
                                                (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                            }
                                            inputFormat="DD.MM.YYYY"
                                            value={invoiceData.paymentDueDate ? invoiceData.paymentDueDate : null}
                                            onChange={(newValue) => {
                                                setInvoiceData({ ...invoiceData, paymentDueDate: newValue ? newValue : undefined });
                                            }}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('payment_recipient_name')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value={invoiceData.paymentRecipientName}
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, paymentRecipientName: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('payment_message')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value={invoiceData.paymentMessage}
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, paymentMessage: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('payment_variable_symbol')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value={invoiceData.paymentVariableSymbol}
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, paymentVariableSymbol: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('payment_constant_symbol')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value={invoiceData.paymentConstantSymbol}
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, paymentConstantSymbol: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('payment_specific_symbol')} variant="outlined" fullWidth size='small'
                                        name='name'
                                        disabled={
                                            !permissions ||
                                            (!permissions.buildingBillsEdit && !permissions.buildingBillsSetPaymentValues)
                                        }
                                        value={invoiceData.paymentSpecificSymbol}
                                        onChange={(e) => { setInvoiceData({ ...invoiceData, paymentSpecificSymbol: e.target.value }) }}
                                    />
                                </Grid>
                            </Grid>
                        </ValidationGroup>
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={3}>
                    <Box sx={{ background: 'white', borderRadius: '16px', boxShadow: '4px 4px 16px 4px lightgray', p: '30px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ChangeLog logs={invoiceData.changeLogs}></ChangeLog>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
            </Box >
        );
    return <div>{t('loading...')}</div>

}