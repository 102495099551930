import * as React from 'react';
import {
    DataGrid,
    DataGridProps,
    useGridApiContext,
    gridPageSelector,
    gridPageCountSelector,
    useGridSelector,
} from '@mui/x-data-grid';
import { csCZ } from '@mui/x-data-grid/locales';
import { Box, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';

function CustomPagination() {
    const { t } = useTranslation();
    const biggerThan700 = useMediaPredicate('(min-width: 1100px)');
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;

    const handlePrevious = () => {
        if (page > 0) {
            apiRef.current.setPage(page - 1);
        }
    };

    const handleNext = () => {
        if (page < pageCount - 1) {
            apiRef.current.setPage(page + 1);
        }
    };

    return (
        <Grid container alignItems="center" justifyContent="space-between" spacing={0} sx={{ p: 1 }}>
            <Grid item md xs>
                <Box display="flex" alignItems="center">
                    {biggerThan700 && <Typography variant="body2" sx={{ marginRight: 1 }}>
                        {t('ItemsPerPage')}:
                    </Typography>}
                    <Select
                        value={pageSize}
                        onChange={(e) =>
                            apiRef.current.setPageSize(parseInt(e.target.value as string, 10))
                        }
                        variant="outlined"
                        size="small"
                    >
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Grid item md="auto" xs={'auto'}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <IconButton
                        onClick={handlePrevious}
                        disabled={page === 0}
                        size="small"
                    >
                        <ArrowBackIcon />
                    </IconButton>

                    <Typography variant="body2" sx={{ mx: 1 }}>
                        {pageCount === 0 ? 0 : page + 1} {t('of')} {pageCount}
                    </Typography>

                    <IconButton
                        onClick={handleNext}
                        disabled={page === pageCount - 1}
                        size="small"
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Box>
            </Grid>
            <Grid item md xs>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <Typography variant="body2" textAlign={'right'}>
                        {t('Total')}: {apiRef.current.state.pagination.rowCount}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}
const MuiDatagrid: React.FC<DataGridProps> = ({ ...props }) => {
    return (
        <DataGrid
            {...props}
            localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
            rowHeight={props.rowHeight || 35}
            autoHeight
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 30,
                    },
                },
            }}
            slots={{
                ...props.slots,
                pagination: CustomPagination,
            }}
        />
    );
};

export default MuiDatagrid;
