import { Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';


import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { GetDiaryPermissionsResponse } from '../../../ApiOld/ApiServerVov';
import NoRights from '../../Shared/Layouts/NoRights';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import KrosAdminUsersListScene from './KrosAdminUsersListScene';
import KrosAdminApplicationsScene from './KrosAdminApplicationsListScene';
import KrosAdminStatisticsScene from './KrosAdminStatisticsScene';
import KrosAdminUpperMenu from './Details/KrosAdminUpperMenu';

interface KrosAdminMainSceneProps {
    selected?: number;
}

export default function KrosAdminMainScene(props: KrosAdminMainSceneProps) {
    const { t, i18n } = useTranslation();
    const { selected } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (selected !== undefined)
            setSelectedTabIndex(selected);
    }, [selected]);
    /* useEffect(() => {
        if (isSubdodavatel) {
            if (selectedTabIndex === 0)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries");
            else if (selectedTabIndex === 1)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diariesDiary");
        }
        else {
            if (selectedTabIndex === 0 && window.location.pathname.endsWith('diaries')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvov");
            }
            else if (selectedTabIndex === 1 && window.location.pathname.endsWith('diaryvov')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvovdiaries");
            }
        }
    }, [selectedTabIndex]); */
    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }

        /*  if (permissions === undefined)
             loadDiaryStore(false).then((d) => {
                 load(d);
             }) */
    }, []);

    const navigateToItem = (n: number) => {
        if (n === 0)
            navigate('/' + params['lang'] + '/kros/admin/users');
        else if (n === 1)
            navigate('/' + params['lang'] + '/kros/admin/applications');
        else if (n === 2)/*  */
            navigate('/' + params['lang'] + '/kros/admin/stats');
    }

    return (
        <div>
            {/* <div>
            <div>hledání</div>
            <div>Nový záznam</div>
            <div>Tisk dropdown</div>
            <div>Smazat </div>
        </div> */}

            <KrosAdminUpperMenu />
            <Box sx={{ marginTop: '50px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { /* setSelectedTabIndex(n) */navigateToItem(n) }} aria-label="basic tabs example">
                        <Tab label={'UŽIVATELÉ'} {...tabProps(0)} />
                        <Tab label={'APLIKÁCIE'} {...tabProps(1)} />
                        <Tab label={'STATISTIKY'} {...tabProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <div>
                        <KrosAdminUsersListScene />
                    </div>

                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div>
                        <KrosAdminApplicationsScene />
                        {/* {!permissions.canViewDiaryList && <NoRights title={t('noViewingPermissions')} />} */}

                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={2}>
                    <div>
                        <KrosAdminStatisticsScene />
                        {/* {!permissions.canViewDiaryList && <NoRights title={t('noViewingPermissions')} />} */}

                    </div>
                </TabPanel>
            </Box></div>);
}