import { Box, Checkbox, FormControlLabel, FormControlLabelProps, SxProps, Theme, Typography } from '@mui/material';
import React from 'react'
interface ClickableCheckboxProps {
  outerSx?: SxProps<Theme>;
  checkboxSx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
  boxSX?: SxProps<Theme>;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: React.ReactNode;
  noPropagation?: boolean;
}

export default function ClickableCheckbox(props: Readonly<ClickableCheckboxProps> & Omit<FormControlLabelProps, 'control'>) {
  const { outerSx, checkboxSx, typographySx, boxSX, checked, onChange, label, disabled, defaultChecked, noPropagation } = props;
  return (
    <Box sx={{ ...boxSX }}>
      <FormControlLabel onClick={(e) => { if (noPropagation) e.stopPropagation(); }} sx={outerSx} disabled={disabled}
        control={
          <Checkbox size='small' sx={{ ...checkboxSx }} onClick={(e) => { if (noPropagation) e.stopPropagation(); }} defaultChecked={defaultChecked} checked={checked} onChange={onChange}
          />}
        label={<Typography sx={typographySx}>{label}</Typography>}
        labelPlacement="end"
      />
    </Box>
  )
}
