import * as React from 'react';
import DialogBase from '../../../Shared/DialogBase';
import { SaveImageMapTodoDetailRequest, TodoListDTO, TodoMainVovClient } from '../../../../ApiOld/Api/ApiServerVov';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TodoStates } from '../../ToDoOld/TodoStates';
import AdminUserSelect from '../../../Shared/AdminUserSelect';
import ColorPickerDialog from '../../../Shared/ColorPickerDialog';
import { useApi } from '../../../../ApiOld/Api/useApi';

export interface IImageMapTaskDetailDialogProps {
    open: boolean;
    onClose: (newId: number | undefined) => void;
    task: TodoListDTO;
    setTask: (task: TodoListDTO) => void;
    mapId: number;
}

export default function ImageMapTaskDetailDialog(props: IImageMapTaskDetailDialogProps) {
    const { open, onClose, task, setTask } = props;
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const params = useParams();
    const optionsStates = TodoStates(t);
    const [changed, setChanged] = React.useState(false);
    const [pickingColor, setPickingColor] = React.useState(false);
    const { authorizedApi } = useApi(TodoMainVovClient);
    const save = React.useCallback(async () => {
        const id = await authorizedApi.saveImageMapTodoDetail(new SaveImageMapTodoDetailRequest({
            id: task.id,
            name: task.name,
            responsibleId: task.responsiblePersonId,
            status: task.state,
            mapTagColor: task.mapTagColor,
            mapId: props.mapId,
            mapLeft: task.mapLeft,
            mapTop: task.mapTop,

        }));
        setChanged(false);
        onClose(id.id);
    }, [authorizedApi, task, onClose, props.mapId]);
    return (
        <DialogBase
            dialogActions={
                <Box>
                    <Button onClick={() => onClose(undefined)}>xxx Zavřít</Button>
                    <Button onClick={save} disabled={!changed || !((task.name?.length ?? 0) > 0)} >xxx Uložit</Button>
                </Box>
            }
            open={open} onClose={onClose} title='xxx Úkol - mapa'>
            <Grid mt={1} container spacing={2}>
                <Grid item xs={8}>



                    <AdminUserSelect selectedUserId={task.responsiblePersonId} onChange={(res) => {
                        setTask({ ...task, responsiblePersonId: res ? res.id : undefined } as TodoListDTO);
                        setChanged(true);
                    }} label='Vyřizuje' />

                </Grid>
                <Grid item xs={4}>
                    <Button onClick={() => navigate('/' + i18n.language + '/' + params['domain'] + '/userlayout/todos/tododetail/' + task.id)}>xxx přejít na úkol</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={task.name}
                        fullWidth
                        size='small'
                        onChange={(e) => { setTask({ ...task, name: e.currentTarget.value } as TodoListDTO); setChanged(true); }}
                        label='xxx Název'
                    />
                </Grid>

                <Grid item xs={12}>
                    <ColorPickerDialog open={pickingColor} onClose={() => { setPickingColor(false) }}
                        onChangeCompleted={(color) => { setTask({ ...task, mapTagColor: color } as TodoListDTO); setChanged(true); }} color={task.mapTagColor ?? '#000000'} />


                    <TextField
                        size='small'
                        fullWidth
                        label='Barva'
                        value={task.mapTagColor || '#000000'} // Assuming task.color holds the hex value
                        onChange={(e) => {
                            const color = e.target.value;
                            // Basic hex validation
                            if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
                                setTask({ ...task, mapTagColor: color } as TodoListDTO);
                            }
                        }}
                        placeholder='#000000'
                        InputProps={{
                            startAdornment: (
                                <Box onClick={() => { setPickingColor(true) }} width={10} height={10} bgcolor={task.mapTagColor ?? '#000000'}></Box>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal

                        disableClearable
                        id="combo-box-demo"
                        size="small"
                        onChange={(e, v) => {
                            setTask({ ...task, state: (v !== undefined && v !== null ? v!.id : undefined) } as TodoListDTO);
                            setChanged(true);
                            // console.log("contextId: " + v !== undefined && v !== null ? v!.id : "NIC");
                        }}
                        value={optionsStates.find((opt) => opt.id === task.state)}
                        options={optionsStates}

                        renderInput={(params) => <TextField
                            error={false}
                            helperText={''}
                            {...params}
                            label={t('state')} />}
                    />
                </Grid>
            </Grid>
        </DialogBase>
    );
}
