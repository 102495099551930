export enum StavarioModule {
    DiaryRecordsList = 'diaryrecordslist',
    DiaryRecordDetail = 'diaryrecorddetail',
    DiariesList = 'diarieslist',
    DiaryDetail = 'diarydetail',
    ProjectList = 'projectlist',
    ProjectDetail = 'projectdetail',
    WealthList = 'wealthlist',
    WealthDetail = 'wealthdetail',
    TodosMap = 'todosmap',
    WarehouseList = 'warehouselist',
    ManufacturerList = 'manufacturerList',
    ProductCategoryList = 'ProductCategoryList',
    ProductList = 'productList',
    TodoList = 'todolist',
    TodoDetail = 'tododetail',
    EmployeeList = 'employeelist',
    EmployeeDetail = 'employeedetail',
}