import { ArrowDropDown, ArrowRight, Clear, DoneAll, Folder, FolderSpecial, InsertDriveFile } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { AssignedUserDTO, CopyCutPasteType, FileDirectoryDTO, FileParameter, GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import ProjectSearchBar from './SearchBars/ProjectSearchBar';

interface ProjectPeopleNotificationsComponentProps {
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectPeopleNotificationsComponent(props: ProjectPeopleNotificationsComponentProps) {
    const { projectData, setProjectData } = props;
    const [search, setSearch] = useState<String>();
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const checkAllNotificationRequests = (enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: enabled, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }

    const checkAllNotificationDiaryRecords = useCallback((enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: enabled, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);


    const checkAll = useCallback((enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: enabled, notificationsEnabledRequests: enabled, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);

    return <Grid item xs={12} lg={4}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: '24px', fontWeight: 'bold' }}>{t('buildings.notificationRecipients')}</Typography>

            <Box sx={{ marginTop: '0px', marginLeft: '16px' }}>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        </Box>
        <Box sx={{ padding: '0px 8px 0px 8px' }}>
            <ProjectSearchBar onSearch={setSearch} />
        </Box>
        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '8px', margin: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>

                <Typography sx={{ marginLeft: '12px', fontWeight: 'bold', fontSize: '14px' }}>{t('todo.tasks')}</Typography>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAllNotificationRequests(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAllNotificationRequests(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {projectData && projectData.assignedUsers && projectData.assignedUsers.filter(p => p.isAssigned === true).map((adminUser, i) => {
                    if (search && adminUser.name!.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                        return null;
                    }
                    return <div key={'key' + i}>
                        <FormControlLabel
                            sx={{ marginLeft: '8px', marginRight: '32px' }}
                            key={adminUser.id}
                            label={adminUser.name}
                            control={
                                <Checkbox
                                    checked={adminUser.notificationsEnabledRequests}
                                    onChange={(e) => {
                                        setProjectData((prev) => {
                                            if (!prev) {
                                                return prev;
                                            }

                                            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                                                adminUser.id === p.id ? new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: !p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                            );

                                            return {
                                                ...prev,
                                                assignedUsers: updatedAssignedUsers,
                                            } as Partial<GetBuildingDataDetailResponse>;
                                        });
                                    }}
                                />
                            }
                        />
                    </div>
                })}
            </Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ marginLeft: '12px', fontWeight: 'bold', fontSize: '14px' }}>{t('diary.dailyRecord')}</Typography>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('chooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAllNotificationDiaryRecords(true); }}>
                            <DoneAll sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('unchooseAll')} >
                        <IconButton color="primary" onClick={(e) => { checkAllNotificationDiaryRecords(false); }}>
                            <Clear sx={{ height: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {projectData && projectData.assignedUsers && projectData.assignedUsers.filter(p => p.isAssigned === true).map((adminUser, i) => {

                    if (search && adminUser.name!.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                        return null;
                    }
                    return <div key={'key' + i}>
                        <FormControlLabel
                            sx={{ marginLeft: '8px', marginRight: '32px' }}
                            key={adminUser.id}
                            label={adminUser.name}
                            control={
                                <Checkbox
                                    checked={adminUser.notificationsEnabledBuildingDiaryRecords}
                                    onChange={(e) => {
                                        setProjectData((prev) => {
                                            if (!prev) {
                                                return prev;
                                            }

                                            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                                                adminUser.id === p.id ? new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: !p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                            );

                                            return {
                                                ...prev,
                                                assignedUsers: updatedAssignedUsers,
                                            } as Partial<GetBuildingDataDetailResponse>;
                                        });
                                    }}
                                />
                            }
                        />
                    </div>
                })}
            </Box>
        </Box>
    </Grid>;
}
