import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovInvestorHelper } from '../../ApiOld/ApiInvestorOldClient';
import { DeleteRequestDTO, RequestsDTO } from '../../ApiOld/ApiServerVov';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, RecordDoubleClickEventArgs, Search, Sort, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, Page } from '@syncfusion/ej2-react-grids';
import delay from '../Shared/Functions/Delay';
import UpperMenu from '../Shared/Layouts/UpperMenu';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import InitSfGridLocale from '../../Localization/SfGridLanguageInitializer';
import { useNavigate, useParams } from 'react-router';
import { Book } from '@mui/icons-material';
import { GridType } from '../../Api/UsersMicroserviceClient';
import { Typography, useTheme } from '@mui/material';
import InvestorTaskDetailDialog from './Components/InvestorTaskDetailDialog';
import InvestorTaskCreateDialog from './Components/InvestorTaskCreateDialog';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';

interface InvestorTasksSceneProps extends WithLoadingProps {
    buildingId?: number
}

function InvestorTasksScene(props: InvestorTasksSceneProps) {
    InitSfGridLocale();
    const { fireLoading, stopLoading, buildingId } = props;

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };

    const [todos, setTodos] = useState<RequestsDTO[] | null>(null);
    const [newOpen, setNewOpen] = useState(false);
    const [detailOpen, setDetailOpen] = useState(false);
    const [selectedTodo, setSelectedTodo] = useState<RequestsDTO | undefined>();
    const [selectedDetailTodo, setSelectedDetailTodo] = useState<RequestsDTO | undefined>();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const grid = useRef<GridComponent>(null);
    const { investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();

    useEffect(() => {
        refreshData();

        if (!investorPermissions)
            refreshInvestorPermissions();
    }, []);

    useEffect(() => {
        if (!investorPermissions)
            refreshInvestorPermissions();
    });

    useEffect(() => {
        refreshData();
    }, [buildingId]);

    useEffect(() => {
        if (selectedTodo !== undefined) {
            setDetailOpen(false);
            setNewOpen(true);
        }
    }, [selectedTodo]);

    useEffect(() => {
        if (selectedDetailTodo !== undefined) {
            setNewOpen(false);
            setDetailOpen(true);
        }
    }, [selectedDetailTodo]);

    function refreshData() {
        fireLoading!();
        new APIVovInvestorHelper().GetRequests(buildingId === undefined ? 0 : buildingId).then((r) => {
            r.forEach(props => props.isDoneString = (props.isDone === 0 ? t('singleTodo.new') : (props.isDone === 1 ? t('singleTodo.active') : (props.isDone === 2 ? t('singleTodo.resolved') : t('error')))));

            setTodos(r);
            stopLoading!();
        });
    }

    function GridTemplate(props: RequestsDTO) {
        const [hover, setHover] = useState(false);
        return (<div style={{ cursor: 'pointer' }} onClick={() => {
            setSelectedDetailTodo(props);
        }} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <a style={{
                color: theme.palette.text.primary, textDecoration: hover ? 'underline' : 'none',
                fontWeight: props.hasUnreadConversation !== undefined && props.hasUnreadConversation === true ? 'bold' : 'normal',

            }}

            >{props.name}</a>
        </div >);
    }

    function gridUsersTemplate(props: RequestsDTO) {
        let users = props.adminUsers!.map((item) => {
            return <div key={item.id?.toString()} style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                <div style={{ backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '5px' }} >
                    <Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{item.name}</Typography>
                </div>
            </div>
        })
        return (<div>
            {users}
        </div>);
    }

    function createRequest(event: any) {
        if (investorPermissions && investorPermissions.buildingRequestsCreate === true) {
            let todo: RequestsDTO = new RequestsDTO({ id: 0, name: "", progress: 0, adminUsers: [] });
            setSelectedDetailTodo(undefined);
            setSelectedTodo(todo);
        }
    }

    function getDeleteRequestsMap(todo: RequestsDTO): DeleteRequestDTO {
        let ret: DeleteRequestDTO = new DeleteRequestDTO();
        ret.id = todo.id;
        ret.rowVersion = "todo.rowVersion";
        return ret;
    }

    function deleteRequest(event: any): void {
        var rowsSelected = grid.current!.getSelectedRecords();
        let result: DeleteRequestDTO[] = rowsSelected.map(a => getDeleteRequestsMap(a as RequestsDTO));
        new APIVovInvestorHelper().DeleteRequests(result).then(e => refreshData());
    }

    function showDetail(event: RecordDoubleClickEventArgs): void {
        setSelectedTodo(undefined);
        setSelectedDetailTodo(event.rowData as RequestsDTO);
    }

    function newProjectClosed() { setNewOpen(false); setSelectedTodo(undefined); refreshData(); }
    function detailProjectClosed() { setDetailOpen(false); setSelectedDetailTodo(undefined); refreshData(); }

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const gridStatusTemplate = (props: any) => {
        return (<div>
            {props.isDone === 0 ? t('singleTodo.new') : (props.isDone === 1 ? t('singleTodo.active') : (props.isDone === 2 ? t('singleTodo.resolved') : t('error')))}
        </div>);
    };

    if (!investorPermissions)
        return (<div>{t('loading...')}</div>);
    else if (investorPermissions.buildingRequestsList !== true)
        return (<div>{t('noViewingPermissions')}</div>);
    else if (todos !== null)
        return (
            <div>
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
                    <UpperMenu moduleName={t('investor.tasksList')}
                        gridType={GridType.Todos}
                        gridId='grid-component-todos-investor'
                        onCreate={createRequest}
                        onDelete={deleteRequest}
                        canAdd={investorPermissions.buildingRequestsCreate}
                        canDelete
                        canChangeEyeView={false}
                        grid={grid} canChangeGroup canToolbarGrid
                        menuName={t('View')}
                        icon={<Book />}
                        isInvestor={true}
                        canExcelExport
                    />
                    <GridComponent ref={grid} id="grid-component-todos-investor" allowSorting={true}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language} enablePersistence={true}
                        recordDoubleClick={showDetail}
                        filterSettings={filterOptions}
                        allowPaging={true}
                        style={{ backgroundColor: '#F5F5F5' }}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        toolbarClick={toolbarClickExcel}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={true} groupSettings={groupOptions}
                        dataSource={todos}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width='60' toolTip='checkbox' />
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} toolTip='' showInColumnChooser={false} />
                            <ColumnDirective template={GridTemplate} field='name' headerText={t('Name')} toolTip={t('Name')} />
                            <ColumnDirective field='investor' headerText={t('investor.investor')} toolTip={t('investor.investor')} />
                            <ColumnDirective field='isDoneString' headerText={t('state')} toolTip={t('state')} />
                            <ColumnDirective field='progress' headerText={t('todo.progress')} toolTip={t('todo.progress')} />
                            <ColumnDirective headerText={t('visibleTo')} toolTip='viditelné' template={gridUsersTemplate} />
                            <ColumnDirective field="date" type='date' format='dd.MM.yyyy HH:mm' headerText={t('todo.dateEntered')} toolTip={t('todo.dateEntered')} />
                            <ColumnDirective field="dateTo" type='date' format='dd.MM.yyyy HH:mm' headerText={t('todo.deadline')} toolTip={t('todo.deadline')} />
                            <ColumnDirective field='building' headerText={t('Projekt')} toolTip='building' />

                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                    </GridComponent>
                </div>
                <InvestorTaskDetailDialog onRefresh={() => { /* refreshData(); */ }} todo={selectedDetailTodo} open={detailOpen} selectedValue={t('investor.taskDetail')} onClose={detailProjectClosed} />

                <InvestorTaskCreateDialog onRefresh={() => { /* refreshData();  */ }} todo={selectedTodo} open={newOpen} selectedValue={t('singleTodo.newTask')} onClose={newProjectClosed} />
            </div>
        );
    return <div>{t('noRecords')}</div>;
};
export default withLoading(InvestorTasksScene);